import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import globalRequest from "../../../GlobalModule/globalRequest";
import IntelView from "../../../GlobalModule/IntelView";
import { AUTO_BUILD } from "../../../Helpers/Enums";
import { tooledUpLocalData } from "../../../redux/reducers/getTooledupData";
import { Boxed } from "../../../Styles-Elements/Box";

import "./autoricheditor.css";
import beautifyString from "../../../GlobalModule/beautifyString";
import removeAllSpaces from "../../../editor/removeAllSpaces";
import { currentIntelCount, setIntelCountKeywordSuggestion } from "../../../redux/reducers/intelCountKeywordSuggestion";
import { setSnackbar } from "../../../redux/reducers/snackbar";
import getCredit from "../../../GlobalModule/getCredit";
import { changeCredit, currentCredit } from "../../../redux/reducers/getCredit";
import UseCreditDialog from "../../../dialogs/UseCreditDialog";
import { currentTrial } from "../../../redux/reducers/getTrial";
import replaceNewLine from "../../../editor/replaceNewLine";

let removedWord = [];

const AutoRichEditor = ({
  string = "",
  decontructValue = false,
  intelRacoonData,
  wordArray = [],
  stringId = 0,
  stringCallback,
  cancelIntel,
}) => {
  const [str, setStr] = useState("");
  const [html, setHtml] = useState("");
  const [autoRichData, setAutoRichData] = useState([]);
  const [includeWord, setIncludeWord] = useState([]);
  const auth = useSelector(tooledUpLocalData);
  const dispatch = useDispatch();
  const [racoonIntel, setRacoonIntel] = useState(true);
  let count = useSelector(currentIntelCount);
  const htmlRef = useRef();
  const [wordUsed, setWordUsed] = useState([]);
  const [wordForCredit, setWordForCredit] = useState([]);
  const [remove, setRemove] = useState([]);
  const [openUseCredit, setOpenUseCredit] = useState(false);
  const creditData = useSelector(currentCredit);
  const trialData = useSelector(currentTrial);

  useEffect(() => {
    try {
      if (Object.keys(intelRacoonData).length !== 0) {
        let data = intelRacoonData.autoRichData;
        let a = [];
        let small = [];
        data.forEach((obj) => {
          if (obj.data.length) {
            obj.data.forEach((obj) => {
              let word = obj.word;
              let find = a.find((a) => a == word);
              if (find == undefined) {
                a.push(word);
                small.push(word.replaceAll('"', "").trim().toLowerCase());
              }
            });
          }
        });
        setIncludeWord(a);
        setWordForCredit(small);
        setAutoRichData(data);
      }
    } catch (e) {}
  }, [intelRacoonData]);

  useEffect(() => {
    if (string) {
      setStr(string);
    }
  }, [string]);

  useEffect(() => {
    if (str) {
      if (decontructValue) {
        let html = "";
        wordArray.forEach((obj) => {
          let ob = addInString(removeAllSpaces(obj.string));
          html += `<div class="textInputKeyword">${convertHtml(ob.stringHtml, ob.includedWord)}</div>`;
        });
        const div = `<div id="auto-enrich" class="textEditable-autoenrich editor-box-autoenrich">${html}</div>`;
        setHtml(div);
      } else {
        let findNot = String(str).toLowerCase().substring(0, 4).match(/not\s/g);
        let obj = addInString(removeAllSpaces(str));
        let div = `<div id="auto-enrich" class="textEditable-autoenrich editor-box-autoenrich">${
          findNot !== null ? `<span class="not-color-translate">NOT </span>` : ""
        }${convertHtml(obj.stringHtml, obj.includedWord)}</div>`;
        setHtml(div);
      }

      getCountUsingClass();
    }
  }, [str, decontructValue, wordArray, racoonIntel]);

  const getCountUsingClass = () => {
    setTimeout(() => {
      let d = window.document.getElementsByClassName("pink-chip");
      dispatch(setIntelCountKeywordSuggestion(d.length));
    }, 100);
  };

  const addInString = (str = "") => {
    let ht = str;

    const stringArray = [
      ...new Set(
        String(str)
          .match(/"([^"]*)"/g)
          ?.map((str) => str.replace(/"/g, ""))
      ),
    ];

    if (!racoonIntel) {
      return {
        stringHtml: ht,
        includedWord: [],
      };
    } else {
      let a = [];
      let usedString = [];
      let allString = [];
      let strs = str;

      autoRichData.forEach((obj) => {
        let string = obj.string;
        let data = obj.data.filter(
          (a) => beautifyString(a.word) !== beautifyString(string) && beautifyString(a.word) != ""
        );

        let newData = [];
        data.forEach((obj) => {
          let findString = autoRichData.find((a) => a.string == obj.word);
          if (findString == undefined) {
            newData.push(obj);
          }
        });

        let replaceTo = `${data.length ? "" : ""}"${string}" ${data.length ? " OR " : ""} `;
        data.forEach((obj1, ind) => {
          let w = beautifyString(obj1.word);
          if (w && w != "") {
            replaceTo += `"${w}" ${data.length - 1 == ind ? "" : " OR "}`;
            a.push(w);
          }
        });

        allString.push({
          string: string,
          data: removeAllSpaces(replaceTo),
        });
      });

      let str1 = str.replace(/".*?"/g, (word) => {
        let w = beautifyString(word.replace(/"/g, ""));

        let find = allString.find((a) => beautifyString(a.string) == w);
        if (find == undefined) {
          return word;
        } else {
          let finds = usedString.find((a) => a == w);
          if (finds !== undefined) {
            return word;
          } else {
            usedString.push(w);
            return find.data;
          }
        }
      });

      strs = str1;

      // dispatch(setIntelCountKeywordSuggestion(a.length));

      let newOne = "";

      const insertCharAtIndex = (str, char, index) => {
        if (index > str.length) {
          return str + char;
        }
        return str.slice(0, index) + char + str.slice(index);
      };

      if (decontructValue) {
        let strThree = strs.substring(0, 3);
        let findParanthesis = strs.match(/\(/g);
        let findOr = strs.match(/OR/g);
        if (strThree !== "NOT" && strThree !== "AND") {
          if (findParanthesis) {
            newOne = strs;
          } else {
            newOne = `(${strs})`;
          }
        } else {
          if (findParanthesis) {
            newOne = strs;
          } else {
            newOne = insertCharAtIndex(strs, "(", 4);
            newOne = insertCharAtIndex(newOne, ")", newOne.length);
          }
        }
      } else {
        strs += " AND ";
        strs.replace(/.*?\s+(AND|NOT)\s+/g, (sentence, word) => {
          let findPara = String(sentence).split("");
          let strStart = sentence.substring(0, 3) === "NOT";

          let find = findPara.find((a) => a === "(");
          if (find === undefined) {
            let match = sentence.match(/OR/g);
            if (match) {
              newOne += `(${String(sentence).slice(strStart ? 3 : 0, -5)}) ${word} `;
            } else {
              newOne += `${sentence.slice(strStart ? 3 : 0)} `;
            }
          } else {
            newOne += `${sentence.slice(strStart ? 3 : 0)} `;
          }
        });

        newOne = newOne.slice(0, -5);
      }

      return {
        stringHtml: removeAllSpaces(newOne),
        includedWord: a,
      };
    }
  };

  const callMouseOverAndOut = (element, child, i) => {
    element.addEventListener(
      "mouseover",
      () => {
        element.classList.add("red-chip");
        let cross = element.getElementsByClassName("cross")[0];
        cross.style.display = "none";

        let cross1 = element.getElementsByClassName("cross1")[0];
        cross1.style.display = "block";
        cross1.addEventListener("click", () => {
          let minusOne = i - 1;
          let childMinusOne = child[minusOne];
          let childFirst = child[i];

          let word = String(childFirst.innerText).replaceAll('"', "").trim().toLowerCase();
          if (word) {
            removedWord.push(word);
          }

          childMinusOne.classList.remove("or-color-translate");
          childMinusOne.innerHTML = "";
          childFirst.classList.remove("red-chip");
          childFirst.classList.remove("pink-chip");
          childFirst.innerHTML = "";

          if (word) {
            setRemove((remove) => [...remove, word]);
          }
        });
      },
      false
    );

    element.addEventListener(
      "mouseout",
      () => {
        element.classList.remove("red-chip");
        let cross = element.getElementsByClassName("cross");
        let cross1 = element.getElementsByClassName("cross1");

        cross[0].style.display = "block";
        cross1[0].style.display = "none";
      },
      false
    );
  };

  useEffect(() => {
    if (remove.length) {
      let a = count - 1;
      dispatch(setIntelCountKeywordSuggestion(a));
      setRemove([]);
    }
  }, [remove]);

  useEffect(() => {
    if (html) {
      let current = document.getElementById("auto-enrich");
      let child = current.children;
      if (child.length) {
        for (let i = 0; i < child.length; i++) {
          let elem = child[i];
          let cls = elem.classList[0];
          if (cls == "pink-chip") {
            let element = elem;
            callMouseOverAndOut(element, child, i);
          } else if (cls == "textInputKeyword") {
            let child2 = elem.children;
            if (child2.length) {
              for (let j = 0; j < child2.length; j++) {
                let elem2 = child2[j];
                let cls2 = elem2.classList[0];
                if (cls2 == "pink-chip") {
                  callMouseOverAndOut(elem2, child2, j);
                }
              }
            }
          }
        }
      }
    }
  }, [html, count]);

  const convertHtml = (str, includedWord) => {
    let html = String(str)
      .replace(/(".*?")/gi, (match, word) => {
        let included = includedWord;
        let replaceQuotes = String(word).replace(/"/g, "").trim();
        let find = included.find((a) => String(a).toLowerCase().trim() == String(replaceQuotes).toLowerCase().trim());

        if (find !== undefined) {
          // included = included.splice(word, 1);
          return `<span class="pink-chip"><span class="word">${word}</span><img src="/cross1.svg" style="display:block" class="cross"/><img src="/cross.png" class="cross1" style="display:none" /></span>`;
        } else {
          let s = word.replace(/"/g, '<span class="quoteColor">"</span>');
          return `<span>${s}</span>`;
        }
      })
      .replace(/\(/g, '<span  class="open-breackets-color-translate">(</span>')
      .replace(/\)/g, '<span  class="open-breackets-color-translate">)</span>')
      .replace(/AND/g, '<span class="and-color-translate">AND</span>')
      .replace(/NOT/g, '<span class="not-color-translate">NOT</span>')
      .replace(/OR/g, '<span class="or-color-translate">OR</span>');

    return html;
  };

  const updateIntelConfirm = async () => {
    let enrich = document.getElementById("auto-enrich");
    let string = String(enrich.innerText);
    let uniqueValue = [];

    wordForCredit.forEach((obj) => {
      let find = removedWord.find((a) => obj === a);
      if (find === undefined) {
        uniqueValue.push(obj);
      }
    });

    let res = await globalRequest(
      "post",
      AUTO_BUILD.UPDATE_INTEL_ON_CONFIRM,
      {
        stringKeyword: includeWord,
        userId: auth?.user_id,
        stringId: stringId,
        removedWord: uniqueValue,
      },
      {},
      true
    );

    let { data } = res;
    if (data.ack === 1) {
      stringCallback(string);
      let da = await getCredit(auth?.user_id);
      dispatch(changeCredit(da));
    } else {
      dispatch(
        setSnackbar({
          snackbarMessage: data.msg,
          snackbarState: "error",
          snackbarOpen: true,
        })
      );
    }
  };

  return (
    <>
      <div className="table">
        <div className="table__textarea-div">
          <Boxed display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"space-between"}>
            <Boxed display={"flex"} alignItems={"flex-start"} justifyContent={"flex-start"} width={"100%"}>
              {decontructValue ? (
                <div ref={htmlRef} dangerouslySetInnerHTML={{ __html: html }} className="content-text"></div>
              ) : (
                <div ref={htmlRef} dangerouslySetInnerHTML={{ __html: html }} className="content-text"></div>
              )}
            </Boxed>
          </Boxed>

          <IntelView
            confirm={() => {
              if (trialData?.status === "trial") {
                setOpenUseCredit(true);
              } else {
                updateIntelConfirm();
              }
            }}
            cancel={cancelIntel}
            racoonIntelCheckbox={(e, type) => {
              if (type == "racoonIntel") {
                setRacoonIntel(e.target.checked);
              }
            }}
          />
        </div>
      </div>

      {openUseCredit ? (
        <UseCreditDialog
          closePopup={() => {
            setOpenUseCredit(false);
          }}
          type={"autoEnrich"}
          creditData={creditData}
          submit={() => {
            setOpenUseCredit(false);
            updateIntelConfirm();
          }}
        />
      ) : null}
    </>
  );
};

export default AutoRichEditor;
