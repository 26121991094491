import { useState, useEffect } from "react";
import * as Colors from "../../Styles-Elements/Colors";
import {
  Subtitle,
  Heading2Bold,
  LabelWrapper,
  LargeBodyBold,
  LargeBody,
  Subheading,
  Body,
} from "../../Styles-Elements/Labels";
import { MainRoundedButton, CustomRoundedButton } from "../../Styles-Elements/Buttons";
import "./VerificationCode.css";
import Header from "../../Styles-Elements/Heading/Header";
import MuiAlert from "@material-ui/lab/Alert";
import { useSelector, useDispatch } from "react-redux";
import { stringData } from "../../redux/reducers/localString";
import { setSnackbar } from "../../redux/reducers/snackbar";
import { changeLoader } from "../../redux/reducers/loader";
import addDeleteGetLocalStorage from "../../GlobalModule/addDeleteGetLocalStorage";
import { useHistory } from "react-router";
import globalRequest from "../../GlobalModule/globalRequest";
import { REQUEST, STORAGE } from "../../Helpers/Enums";
import OtpInput from "react-otp-input";
import { setChange } from "../../redux/reducers/getLocalData";
import stars5Icon from "../../image-assets/5stars.svg";
import teamMemberImg from "../../image-assets/team1.png";
import teamMember2Img from "../../image-assets/team2.png";
// import Box from '@material-ui/core/Box';
import { Boxed } from "../../Styles-Elements/Box";
import { setData } from "../../redux/reducers/getTooledupData";
const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};
const VerificationCode = (props) => {
  const strings = useSelector(stringData);
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const history = useHistory();

  useEffect(() => {
    let temp = addDeleteGetLocalStorage(STORAGE.TEMPORARY_DATA, {}, "get", "single");
    if (temp) {
      try {
        const json = JSON.parse(temp);
        setPassword(json.password);
        setEmail(json.email);
      } catch (e) {}
    } else {
      history.push("/");
    }
  }, []);
  const verify = async () => {
    if (otp != "") {
      dispatch(changeLoader(true));

      let response = await globalRequest(
        "post",
        REQUEST.SIGNUP_REQUEST,
        {
          email: email,
          password: password,
          type: "web",
          otp: otp,
        },
        {},
        false
      );

      let data = response.data;
      if (data.status == 1) {
        dispatch(
          setSnackbar({
            snackbarMessage: data.message,
            snackbarOpen: true,
            snackbarState: "success",
          })
        );
        addDeleteGetLocalStorage(STORAGE.TOOLED_UP_LOCAL_DATA, data.data, "add", "single");
        dispatch(setData());
        addDeleteGetLocalStorage(STORAGE.TEMPORARY_DATA, {}, "delete");
        history.push("/main-app");
      } else {
        dispatch(
          setSnackbar({
            snackbarMessage: data.message,
            snackbarOpen: true,
            snackbarState: "error",
          })
        );
      }

      dispatch(changeLoader(false));
    } else {
      dispatch(
        setSnackbar({
          snackbarMessage: "Enter OTP first ",
          snackbarOpen: true,
          snackbarState: "error",
        })
      );
    }
  };
  const resent = async () => {
    setOtp("");
    dispatch(changeLoader(true));
    let response = await globalRequest("post", REQUEST.SIGNUP_OTP_REQUEST, { email: email }, {}, false);
    let data = response.data;
    if (data?.status == 1) {
      dispatch(
        setSnackbar({
          snackbarMessage: `Verification code resent to ${email}.`,
          snackbarOpen: true,
          snackbarState: "success",
        })
      );
    } else {
      dispatch(
        setSnackbar({
          snackbarMessage: data.message,
          snackbarOpen: true,
          snackbarState: "error",
        })
      );
    }
    dispatch(changeLoader(false));
  };

  return (
    <div className="page-background">
      <Header />
      {/* <div className="grid-container ngc-new">
        
      </div> */}
      <Boxed className="verification-holder" display={"flex"} style={{}}>
        <div className="verification-left">
          <div className="grid-header" style={{ paddingBottom: "0px" }}>
            <Heading2Bold
              text={strings.weNeedToVerifyYourEmailID}
              color={Colors.nightPurple}
              textAlign={"center"}
              padding={"0 0 8px 0"}
            />
            <Boxed style={{ width: "456px" }} margin={"auto"} padding={"32px 0"}>
              <Subtitle
                text={strings.pleaseCheckYourEmail}
                color={Colors.nightPurple}
                textAlign={"center"}
                padding={"0 0 12px 0"}
              />
              <LabelWrapper
                display={"flex"}
                align-items-center={"center"}
                justifyContent={"center"}
                padding={"0 0 48px 0px"}
              >
                <LargeBody
                  text={strings.weHaveSentYouA6DigitCodeTo}
                  color={Colors.darkGray}
                  textAlign={"center"}
                  padding={"0 0 0px 0"}
                />
                <LargeBodyBold text={email} color={Colors.darkGray} textAlign={"center"} padding={"0 0px 0px 4px"} />
              </LabelWrapper>
              <Boxed padding={"0 0 48px 0"}>
                <OtpInput
                  className="otpinput"
                  value={otp}
                  onChange={(otp) => setOtp(otp)}
                  numInputs={6}
                  inputStyle={"otpinputsvlue"}
                  isInputNum={true}
                  id={"id"}
                />
              </Boxed>
              <LabelWrapper
                display={"flex"}
                align-items-center={"center"}
                justifyContent={"center"}
                padding={"0 0 32px 0"}
              >
                <LargeBody
                  text={strings.didNotReceiveACode}
                  color={Colors.darkGray}
                  textAlign={"center"}
                  padding={"0 0 0px 0px"}
                />
                <LargeBodyBold
                  text={strings.clickToResend}
                  color={Colors.darkGray}
                  textAlign={"center"}
                  padding={"0 0 0px 2px"}
                  textDecoration={"underline"}
                  cursor={"pointer"}
                  onClick={resent}
                />
              </LabelWrapper>
              <Boxed display={"flex"} justifyContent={"space-between"} width={"295px"} margin={"auto"}>
                <CustomRoundedButton
                  backgroundColor={Colors.lightGray}
                  color={Colors.white}
                  text={strings.cancel}
                  onClick={() => {
                    history.push("/");
                    addDeleteGetLocalStorage(STORAGE.TEMPORARY_DATA, {}, "delete");
                  }}
                  margin={"4px 24px 0px 24px"}
                  width={"135px"}
                />
                <MainRoundedButton
                  text={strings.verify}
                  margin={"4px 24px 0px 24px"}
                  width={"135px"}
                  onClick={verify}
                />
              </Boxed>
            </Boxed>
          </div>
        </div>
        <div className="login-signup-right">
          <img
            src={stars5Icon}
            alt=""
            className="width116"
            style={{ margin: "auto", marginTop: "0px", marginBottom: "50px" }}
          />
          <Boxed className="first-box">
            <Subheading
              text={strings.raccoonsHasSavedMeAnywhereFrom5_10AdditionalHoursOfWorkForEachSearch}
              color={Colors.mainLight}
              textAlign={"center"}
              fontStyle={"italic"}
              padding={"0 0px 50px 0px"}
            />
            <div style={{ textAlign: "center", marginBottom: "50px" }}>
              <img src={teamMember2Img} alt="" className="team-user-icon" style={{ margin: "auto" }} />
            </div>
            <LargeBody
              text={"Alex Comsia"}
              color={Colors.mainLight}
              fontWeight={"600"}
              textAlign={"center"}
              padding={"0 0px 4px 0px"}
              className=""
            />
            <Body
              text={"Recruiter, High Country Search"}
              color={Colors.mainLight}
              textAlign={"center"}
              padding={"0 0px 0px 0px"}
              className=""
            />
          </Boxed>
        </div>
      </Boxed>
    </div>
  );
};

export default VerificationCode;
