import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import 'antd/dist/antd.css'
import * as Colors from '../Styles-Elements/Colors'
import { Subtitle, LargeBody } from '../Styles-Elements/Labels'
import {
  ClearButton,
  CustomRoundedButton,
} from '../Styles-Elements/Buttons'
import booThinking from '../image-assets/boo-thinking.png'

// Material UI for the snackbar
import Scrollbar from 'react-smooth-scrollbar'
import { KEYWORD_STRING } from '../Helpers/Enums'
import closePopUpByKey from '../GlobalModule/closePopUpByKey'
import { tooledUpLocalData } from '../redux/reducers/getTooledupData'
import globalRequest from '../GlobalModule/globalRequest'
import { setSnackbar } from '../redux/reducers/snackbar'
import { changeLoader } from '../redux/reducers/loader'
const strings = require('../localisation_en.json')

const DeleteFolderContent = (props) => {

  const auth = useSelector(tooledUpLocalData);
  const dispatch = useDispatch();

  const id = props.id
  const folderName = props.folderName
  const userId = auth?.user_id
  const teamId = auth?.team_id

  useEffect(() => {
    closePopUpByKey(props.closePopup)
  }, [])

  const submitRequest = async () => {
    let url = KEYWORD_STRING.DELETE_FOLDER
    let body = {
      type: 'single',
      team_id: teamId,
      user_id: userId,
      folder_id: props.id,
    }
    dispatch(changeLoader(false))
    const response = await globalRequest("post", url, body, {}, true);
    let data = response.data
    if (data.status == 1) {
      dispatch(setSnackbar({
        snackbarMessage: data.message,
        snackbarOpen: true,
        snackbarState: 'success',
      }))
    } else {
      dispatch(setSnackbar({
        snackbarMessage: data.message,
        snackbarOpen: true,
        snackbarState: 'error',
      }))
    }
    dispatch(changeLoader(false))
    props.successCallback(true)
  }


  return (
    <>
      <div className="container">
        <div className="container-modal">
          <div className="popup-boo-container negative">
            <img src={booThinking} width={120} height={120} />
          </div>
          <Scrollbar className="container-modal-mid">
            <Subtitle
              text={
                props.countFolderCollaborator > 0
                  ? strings.deleteFolderDiff
                  : strings.doYouWantToDeleteTheFolderAndItsContent
              }
              color={Colors.nightPurple}
              textAlign={'center'}
              padding={'24px 0'}
            />
            <LargeBody
              text={
                props.countFolderCollaborator > 0
                  ? `By deleting your ${folderName}, all current collaborators will also lose permanent access.`
                  : strings.allStringsWithinThisFolderWillBeDeletedPermanently
              }
              textAlign={'center'}
              color={Colors.darkGray}
              padding={'0 0 16px 0'}
            />
            <CustomRoundedButton
              text={
                props.countFolderCollaborator > 0 ? 'proceed' : strings.delete
              }
              margin={'24px 0 8px 0'}
              color={Colors.mainLight}
              backgroundColor={Colors.lightRed}
              onClick={() => submitRequest()}
            />
            <ClearButton
              text={strings.cancel}
              onClick={() => props.closePopup()}
            />
          </Scrollbar>
        </div>
      </div>

    </>
  )
}

export default DeleteFolderContent
