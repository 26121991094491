const removeColor = (str, parent) => {
  const childrens = parent.children;
  let strArr = [...str];

  let regex =
    /("\s+an\s+")|("\s+ad\s+")|("\s+nd\s+")|("\s+nt\s+")|("\s+no\s+")|("\s+ot\s+")|("\s+o\s+")|("\s+r\s+")|(\)\s+an\s+")|(\)\s+ad\s+")|(\)\s+nd\s+")|("\s+an\s+\))|("\s+ad\s+\))|("\s+nd\s+\))|(\)\s+an\s+\()|(\)\s+ad\s+\()|(\)\s+nd\s+\()|(\)\s+no\s+")|(\)\s+nt\s+")|(\)\s+ot\s+")|("\s+no\s+\))|("\s+nt\s+\))|("\s+ot\s+\))|(\)\s+no\s+\()|(\)\s+nt\s+\()|(\)\s+ot\s+\()|(\)\s+o\s+")|(\)\s+r\s+")|("\s+o\s+\))|("\s+r\s+\))|(\)\s+o\s+\()|(\)\s+r\s+\()/g;
  let match;
  let index = [];

  while ((match = regex.exec(str.toLowerCase())) !== null) {
    let ind = match.index;
    let wordLength = String(match[0]).length;
    index.push({
      start: ind,
      end: ind + wordLength,
    });
   
  }

  if (index.length) {
    index.forEach((obj) => {
      let start = obj.start;
      let end = obj.end;

      for (let i = start; i < end; i++) {
        if (strArr[i] !== '"') {
          try {
            childrens[i].style.color = "";
            childrens[i].innerText = childrens[i].innerText.toLowerCase();
          } catch (e) {}
        }
      }
    });
  }

  //this is for last word

  const findAndOrNot = (string, lastWord) => {
    if (lastWord == "o" || lastWord == "r") {
      let i = String(string).lastIndexOf(lastWord);
      try {
        childrens[i].style.color = "";
        childrens[i].innerText = childrens[i].innerText.toLowerCase();
      } catch (e) {}
    } else if (
      lastWord == "an" ||
      lastWord == "ad" ||
      lastWord == "nd" ||
      lastWord == "nt" ||
      lastWord == "no" ||
      lastWord == "ot"
    ) {
      let i = String(string).lastIndexOf(lastWord);
      let i1 = i + 1;
      try {
        childrens[i].style.color = "";
        childrens[i].innerText = childrens[i].innerText.toLowerCase();
      } catch (e) {}

      try {
        childrens[i1].style.color = "";
        childrens[i1].innerText = childrens[i1].innerText.toLowerCase();
      } catch (e) {}
    }
  };

  let string = String(str).toLowerCase().trim();
  let split = string.split(/\s/g);
  let lastWord = split[split.length - 1];
  let firstWord = split[0];

  findAndOrNot(string, lastWord);
  findAndOrNot(string, firstWord);
};
export default removeColor;
