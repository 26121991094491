import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import "antd/dist/antd.css";
import * as Colors from "../Styles-Elements/Colors";
import { Subtitle, LargeBody } from "../Styles-Elements/Labels";
import { ClearButton, CustomRoundedButton } from "../Styles-Elements/Buttons";
import booThinking from "../image-assets/boo-thinking.png";

// Material UI for the snackbar
import Scrollbar from "react-smooth-scrollbar";
import { KEYWORD_STRING } from "../Helpers/Enums";
import Snackbars from "../GlobalModule/Snackbars";
import closePopUpByKey from "../GlobalModule/closePopUpByKey";
import globalRequest from "../GlobalModule/globalRequest";
import { tooledUpLocalData } from "../redux/reducers/getTooledupData";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../redux/reducers/snackbar";
import { changeLoader } from "../redux/reducers/loader";
// Importing localised strings
const strings = require("../localisation_en.json");

const DeleteSelectedItems = (props) => {
  const allFolder = props.allFolder;

  const auth = useSelector(tooledUpLocalData);
  const dispatch = useDispatch();

  const submitRequest = async () => {
    dispatch(changeLoader(true));

    let url = KEYWORD_STRING.DELETE_MULTI_STRING_FOLDER;
    let body = {
      team_id: auth?.team_id,
      user_id: auth?.user_id,
    };

    if (allFolder.length) {
      let a1 = [];
      allFolder.forEach((obj) => {
        a1.push(obj.id);
      });
      body.folder_id = a1;
    }

    if (props.allString.length) {
      body.string_id = props.allString;
    }

    let response = await globalRequest("post", url, body, {}, true);

    let data = response.data;
    if (data.status == 1) {
      dispatch(
        setSnackbar({
          snackbarMessage: data.message,
          snackbarOpen: true,
          snackbarState: "success",
        })
      );
      localStorage.removeItem("string");
      props.callback(true);
    } else {
      dispatch(
        setSnackbar({
          snackbarMessage: data.message,
          snackbarOpen: true,
          snackbarState: "error",
        })
      );
    }

    dispatch(changeLoader(false));
  };

  useEffect(() => {
    closePopUpByKey(props.closePopup);
  }, []);

  return (
    <div className="container">
      <div className="container-modal">
        <div className="popup-boo-container negative">
          <img src={booThinking} width={120} height={120} />
        </div>
        <Scrollbar className="container-modal-mid">
          <Subtitle
            text={strings.DoYouWantToDeleteTheSelectedItems}
            color={Colors.nightPurple}
            textAlign={"center"}
            padding={"24px 0"}
          />
          <LargeBody
            text={strings.theSelectedStringsFoldersWillBeDeletedPermanently}
            textAlign={"center"}
            color={Colors.darkGray}
            padding={"0 0 16px 0"}
          />
          <CustomRoundedButton
            text={strings.delete}
            margin={"24px 0 8px 0"}
            color={Colors.mainLight}
            backgroundColor={Colors.lightRed}
            onClick={() => submitRequest()}
          />
          <ClearButton text={strings.cancel} onClick={() => props.closePopup()} />
        </Scrollbar>
      </div>
    </div>
  );
};

export default DeleteSelectedItems;
