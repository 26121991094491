import React, { useEffect, useState } from "react";
import { Subheading } from "../Styles-Elements/Labels";
import { BorderedRoundedButton } from "../Styles-Elements/Buttons";
import closeBlue from "../image-assets/close-blue.png";
import downloadCloud from "../image-assets/download-cloud.png";
import checkGreen from "../image-assets/check-green.png";
import closePopUpByKey from "../GlobalModule/closePopUpByKey";
import { Boxed } from "../Styles-Elements/Box";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { ENVIROMENT, VENDOR_ID } from "../Helpers/Payments";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../redux/reducers/snackbar";
import globalRequest from "../GlobalModule/globalRequest";
import { NEW_API } from "../Helpers/Enums";
import { tooledUpLocalData } from "../redux/reducers/getTooledupData";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

// Importing localised strings
const strings = require("../localisation_en.json");
const BillingHistory = ({ closePopup ,data }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const auth = useSelector(tooledUpLocalData);
  useEffect(() => {
    closePopUpByKey(closePopup);
  }, []);


  const download = async (url) => {
    window.open(url, "_blank");
  };

  return (
    <div className="container">
      <div className="container-modal-new-mid">
        <img src={closeBlue} width={18} height={18} className="close-blue" onClick={() => closePopup()} alt="" />
        <Boxed display={"flex"} alignItems={"center"} justifyContent={"space-between"} padding={"0 16px"}>
          <Subheading text={strings.billingHistory} color={"#101828"} padding={"0"} />
          {/* <BorderedRoundedButton
            img={<img src={downloadCloud} alt="" />}
            text={"Download all"}
            className={"download-btn"}
          /> */}
        </Boxed>
        <Boxed padding={"24px 16px 0px 16px"}>
          <div className="billing-table">
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Invoice</TableCell>
                    <TableCell align="left">Amount</TableCell>
                    <TableCell align="left">Date</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        <span
                          style={{
                            color: "#101828",
                            fontSize: "14px",
                            fontWeight: "500",
                          }}
                        >
                          {row.invoice}
                        </span>
                      </TableCell>
                      <TableCell align="center">{row.amount}</TableCell>
                      <TableCell align="center">{row.date}</TableCell>
                      <TableCell align="center">
                        <span className="bh-status">
                          {row.status === "success" ? (
                            <>
                              <img src={checkGreen} alt="" />
                              Paid
                            </>
                          ) : (
                            <> Unpaid </>
                          )}
                        </span>{" "}
                      </TableCell>
                      <TableCell align="right" style={{ cursor: "pointer" }} onClick={() => download(row.download)}>
                        <img src={downloadCloud} alt="" />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Boxed>
      </div>
    </div>
  );
};

export default BillingHistory;
