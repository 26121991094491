import { React, useEffect, useState } from "react";
import { LargeBody, Body, Subtitle, LargeBodyRegular } from "../../Styles-Elements/Labels";
import { Boxed } from "../../Styles-Elements/Box";
import "./UserAdminSetting.css";
import lockIcon from "../../image-assets/lock-icon.svg";
import booLanding from "../../image-assets/Boo-Landing.png";
import * as Colors from "../../Styles-Elements/Colors";
import * as FontStyles from "../../Styles-Elements/FontStyles";
import { ClearButton, CustomRoundedButton } from "../../Styles-Elements/Buttons";
import strings from "../../localisation_en.json";
import KeywordAlertFrequency from "../../dialogs/KeywordAlertFrequency";
import getAlertEnrich from "../../GlobalModule/getAlertEnrich";
import { useDispatch, useSelector } from "react-redux";
import { tooledUpLocalData } from "../../redux/reducers/getTooledupData";
import ChangePassword from "../../dialogs/ChangePassword";
import { changeAlert, currentAlert } from "../../redux/reducers/enrichAlert";

const UserAdminSetting = (props) => {
  const [changePassword, setChangePassword] = useState(false);
  const [openKeywordAlertFrequency, setOpenKeywordAlertFrequency] = useState(false);
  const [alert, setAlert] = useState("Select Alert");
  const auth = useSelector(tooledUpLocalData);
  const dispatch = useDispatch();
  const alertValue = useSelector(currentAlert);

  const getA = async () => {
    let { data } = await getAlertEnrich(auth?.id);
    if (data?.ack === 1) {
      if (data?.data?.alerts) {
        dispatch(changeAlert(data?.data.alerts))
      }
    }
  };

  useEffect(() => {
    if (alertValue) {
      setAlert(alertValue);
    }
  }, [alertValue]);

  return (
    <>
      <div className="settings-holder" style={{ minHeight: "calc(100vh - 0px)" }}>
        <Subtitle text={strings.settings} color={Colors.darkPurple} padding={"0 0 24px 0"} />
        <div className="settings-grid-parent">
          <div className="settings-grid" style={{ boxShadow: "none", padding: "0px" }}>
            <div className="settings-subscription-holder">
              <LargeBody text={strings.managePassword} color={Colors.darkPurple} padding={"24px 0 16px 0"} />
              <div className="settings-manage-password">
                <div>
                  <LargeBodyRegular text={"*********"} color={Colors.darkGray} />
                </div>
                <div className="settings-icon-button">
                  <ClearButton
                    theme={FontStyles.body}
                    text={strings.changePassword}
                    textAlign={"left"}
                    color={Colors.nightPurple}
                    padding={"0 8px"}
                    onClick={() => setChangePassword(true)}
                  />
                  <img
                    src={lockIcon}
                    height={16}
                    width={16}
                    style={{ padding: "3px 0 0 0", margin: "3px 0 0 0" }}
                    alt=""
                  />
                </div>
              </div>
              <LargeBody
                text={strings.autoEnrichEmailAlertFrequency}
                color={Colors.darkPurple}
                padding={"24px 0 16px 0"}
              />
              <div className="settings-invoice-recipients">
                <div className="grid-item-flex">
                  <Boxed display="flex" alignItems="center">
                    <Body text={alert} color={Colors.nightGray} padding={"8px"} />
                  </Boxed>
                </div>
                <div>
                  <CustomRoundedButton
                    theme={FontStyles.body}
                    text={strings.update}
                    backgroundColor={Colors.mainLight}
                    color={Colors.nightPurple}
                    padding={"6px 24px"}
                    borderRadius={"5px"}
                    onClick={() => setOpenKeywordAlertFrequency(true)}
                  />
                </div>
              </div>
            </div>
            <div className="settings-detail-holder">
              <Boxed style={{ textAlign: "center" }}>
                <img src={booLanding} style={{ margin: "auto", height: "700px" }} alt="" />
              </Boxed>
            </div>
          </div>
        </div>
      </div>
      {openKeywordAlertFrequency ? (
        <KeywordAlertFrequency
          closePopup={() => {
            setOpenKeywordAlertFrequency(false);
            getA();
          }}
        />
      ) : null}

      {changePassword ? (
        <ChangePassword
          closePopup={() => setChangePassword(false)}
          callback={() => {
            setChangePassword(false);
          }}
        />
      ) : null}
    </>
  );
};
export default UserAdminSetting;
