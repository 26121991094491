import * as Colors from "../../Styles-Elements/Colors";
import { Body, Bold, LabelWrapper } from "../../Styles-Elements/Labels";
import { BorderedRoundedButton, MainRoundedButton } from "../../Styles-Elements/Buttons";
import "./CollaboratorViewing/CollaboratorViewing.css";
import strings from "../../localisation_en.json";
import wasImg from "../image-assets/was-img.png";
import { Boxed } from "../../Styles-Elements/Box";
import { useEffect, useState } from "react";
import convertIntoHtml from "../../editor/convertIntoHtml";
import { useDispatch } from "react-redux";
import { changeAutoBuildValue } from "../../redux/reducers/autoBuildValues";
import removeAllSpaces from "../../editor/removeAllSpaces";
import AdvanceViewCopyEditor from "../../advance-editor/keyword/AdvanceViewCopyEditor";

const EditorView = ({ goBack, finish, string, decontructValue, includedWord }) => {
  const [html, setHtml] = useState("");
  const dispatch = useDispatch();
  const [str, setStr] = useState("");

  useEffect(() => {
    if (string) {
      if (!decontructValue) {
        setStr(string);
      } else {
        setStr(string);
      }
    }
  }, [string]);

  useEffect(() => {
    if (str) {
      let str1 = str.replace(/".*?"/g,(first,second)=>{
        return first.replace(/\(.*?\)/g,"");
      })
      setHtml(convertIntoHtml(removeAllSpaces(str1)));
    }
  }, [str]);

  return (
    <>
      <div
        className="string-creation-holder relative"
        style={{ backgroundColor: "#fefefe", border: "2px solid #F1F1F1" }}
      >
        <Boxed
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"space-between"}
          width={"100%"}
          style={{ height: "100%" }}
        >
          <Boxed
            display={"flex"}
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
            width={"100%"}
            className="scroll-view-editor"
          >
            {decontructValue ? (
              <AdvanceViewCopyEditor onlyView={true} str={str} selectedData={() => {}} />
            ) : (
              <div dangerouslySetInnerHTML={{ __html: html }} className="content-text"></div>
            )}
          </Boxed>
          <Boxed
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            className="success-editor-bottom-view"
          >
            <Boxed display={"flex"} alignItems={"center"}>
              <img src={wasImg} width={"40px"} alt="" />
              <LabelWrapper display={"flex"} alignItems={"center"} padding={"0 0 0 10px"}>
                <Bold
                  text={strings.successExclamationMark}
                  color={Colors.sharpGreen}
                  padding={"0px"}
                  margin={"0px 0px 0px 0px"}
                />
                <Body
                  text={strings.yourBooleanStringIsReadyForAction}
                  color={Colors.nightGray}
                  padding={"0px 0px 0px 4px"}
                  margin={"0px 0px 0px 0px"}
                />
              </LabelWrapper>
            </Boxed>
            <Boxed display={"flex"} alignItems={"center"} gap={"10px"}>
              <BorderedRoundedButton
                text={strings.back}
                onClick={goBack}
                className={"sharpgreen-outline-btn"}
                padding={"8px 16px"}
              />
              <MainRoundedButton
                onClick={() => {
                  dispatch(
                    changeAutoBuildValue({
                      targetJobTitle: "",
                      targetIndustry: "",
                      roleCoreFunction: "",
                      criticalRequirement1: "",
                      requirement2: "",
                      requirement3: "",
                      isApiCall: false,
                      string: "",
                    })
                  );
                  finish();
                }}
                text={strings.finish}
                className={"sharpgreen-fill-btn"}
                padding={"8px 16px"}
              />
            </Boxed>
          </Boxed>
        </Boxed>
      </div>
    </>
  );
};
export default EditorView;
