/**
 * Size: 60px
 * Font style: Chewy
 * Weight: Medium
 */
export const largeHeading = 'largeHeading'
/**
 * Size: 48px
 * Weight: Medium
 */
export const heading1 = 'heading1'
/**
 * Size: 32px
 * Weight: Medium
 */
export const heading2 = 'heading2'
/**
 * Size: 32px
 * Weight: Bold
 */
export const heading2Bold = 'heading2Bold'
/**
 * Size: 24px
 * Weight: Medium
 */
export const heading3 = 'heading3'
/**
 * Size: 24px
 * Weight: SemiBold
 */
export const heading3SemiBold = 'heading3SemiBold'
/**
 * Size: 20px
 * Weight: Medium
 */
export const subtitle = 'subtitle'
/**
 * Size: 20px
 * Weight: SemiBold
 */
export const subtitleSemiBold = 'subtitleSemiBold'
/**
 * Size: 20px
 * Weight: Bold
 */
export const subtitleBold = 'subtitleBold'
/**
 * Size: 18px
 * Weight: Medium
 */
export const subheading = 'subheading'
/**
 * Size: 18px
 * Weight: Bold
 */
export const subheadingBold = 'subheadingBold'
/**
 * Size: 16px
 * Weight: Medium
 */
export const largeBody = 'largeBody'
/**
 * Size: 16px
 * Weight: Regular
 */
export const largeBodyRegular = 'largeBodyRegular'
/**
 * Size: 16px
 * Weight: SemiBold
 */
export const largeBodySemiBold = 'largeBodySemiBold'
/**
 * Size: 16px
 * Weight: Bold
 */
export const largeBodyBold = 'largeBodyBold'
/**
 * Size: 14px
 * Weight: Medium
 */
export const body = 'body'
/**
 * Size: 14px
 * Weight: Bold
 */
export const bold = 'bold'
/**
 * Size: 12px
 * Weight: Medium
 */
 export const largeSmall = 'largeSmall'
 /**
  * Size: 12px
  * Weight: SemiBold
  */
 export const largeSmallSemiBold = 'largeSmallSemiBold'
/**
 * Size: 10px
 * Weight: Medium
 */
export const small = 'small'
/**
 * Size: 10px
 * Weight: SemiBold
 */
export const smallSemiBold = 'smallSemiBold'

export function getFontFamily(fontStyle) {
  if (fontStyle === largeHeading) {
    return 'Chewy'
  } else {
    return 'Poppins'
  }
}

export function getFontSize(fontStyle) {
  switch(fontStyle) {
    case largeHeading: return '60px'
    case heading1: return '48px'
    case heading2, heading2Bold: return '32px'
    case heading3: return '24px'
    case heading3SemiBold: return '24px'
    case subtitle: return '20px'
    case subtitleSemiBold: return '20px'
    case subtitleBold: return '20px'
    case subheading: return '18px'
    case subheadingBold: return '18px'
    case largeBody: return '16px'
    case largeBodyRegular: return '16px'
    case largeBodySemiBold: return '16px'
    case largeBodyBold: return '16px'
    case body: return '14px'
    case bold: return '14px'
    case largeSmall: return '12px'
    case largeSmallSemiBold: return '12px'
    case small: return '10px'
    case smallSemiBold: return '10px'
    default: return
  }
}

export function getFontWeight(fontStyle) {
  switch(fontStyle) {
    case largeHeading: return '500'
    case heading1: return '500'
    case heading2: return '500'
    case heading2Bold: return '700'
    case heading3: return '500'
    case heading3SemiBold: return '600'
    case subtitle: return '500'
    case subtitleBold: return '700'
    case subtitleSemiBold: return '600'
    case subheading: return '500'
    case subheadingBold: return '700'
    case largeBody: return '500'
    case largeBodyRegular: return '400'
    case largeBodySemiBold: return '600'
    case largeBodyBold: return '700'
    case body: return '500'
    case bold: return '700'
    case largeSmall: return '500'
    case largeSmallSemiBold: return '600'
    case small: return '500'
    case smallSemiBold: return '600'
    default: return
  }
}
