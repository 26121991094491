import hasWhiteSpace from "../../editor/hasWhiteSpace";
import isEvenDoubleQuotes from "../../editor/isEvenDoubleQuotes";

const getAllWordWithOperator = (str) => {
  let strA = `${str} and `;
  let strArr = [...strA];

  let newString = "";
  let finalA = [];

  strArr.forEach((char, index) => {
    let a = strArr[index] ? strArr[index].toLowerCase() : null;
    let b = strArr[index + 1] ? strArr[index + 1].toLowerCase() : null;
    let c = strArr[index + 2] ? strArr[index + 2].toLowerCase() : null;
    let d = strArr[index + 3] ? strArr[index + 3].toLowerCase() : null;
    let e = strArr[index + 4] ? strArr[index + 4].toLowerCase() : null;

    let conditionAnd =
      isEvenDoubleQuotes(index, str) &&
      hasWhiteSpace(a) &&
      b == "a" &&
      c == "n" &&
      d == "d" &&
      hasWhiteSpace(e);
    let conditionNot =
      isEvenDoubleQuotes(index, str) &&
      hasWhiteSpace(a) &&
      b == "n" &&
      c == "o" &&
      d == "t" &&
      hasWhiteSpace(e);

    if (conditionAnd || conditionNot) {
      finalA.push({
        string: String(newString)
          .replace(/_=0188=_/g, "and")
          .replace(/_=0189=_/g, "not"),
      });
      newString = "";
    } else {
      newString += strArr[index];
    }
  });

  return finalA;
};

export default getAllWordWithOperator;
