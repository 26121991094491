const addCharInString = (index, char, str) => {

    //we are one and 

    let strArr = [...str];
    let newString = "";

    if (strArr.length) {
        strArr.forEach((ch, ind) => {

            if (index == ind) {
                newString += char + ch;
            } else {
                newString += ch;
            }

        });
    }
    return newString;
}

export default addCharInString;