const splitCharUsingComma = (str) => {
  let string = String(str).split("");

  let sp = [];
  let spStr = "";
  let ind = 0;

  if (String(str).length < 1000) {
    sp.push(str);
  } else {
    string.forEach((char, index) => {
      if (ind >= 1000 && char === ",") {
        sp.push(spStr);
        spStr = "";
        ind = 0;
      } else if (ind == String(str).length - 1) {
        sp.push(spStr);
        spStr = "";
        ind = 0;
      } else if (ind <= 1000 && index == String(str).length - 1) {
        spStr += string[index];
        sp.push(spStr);
        spStr = "";
        ind = 0;
      } else {
        spStr += char;
        ind++;
      }
    });
  }
  return sp;
};

export default splitCharUsingComma;
