import * as Colors from "../../../Styles-Elements/Colors";
import { Body } from "../../../Styles-Elements/Labels";
import "./CollaboratorViewing.css";
import booAddSqaud1Img from "../../image-assets/boo-add-sqaud1.png";
import userTypingImg from "../../image-assets/boo-happy.png";
import { Boxed } from "../../../Styles-Elements/Box";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { currentSocketUserData } from "../../../redux/reducers/socketUserData";
import { BASE_URL } from "../../../Helpers/Enums";

const CollaboratorViewing = () => {
  const currentUserData = useSelector(currentSocketUserData);

  return (
    <>
      <div className="string-creation-holder relative">
        <Boxed>
          <img src={booAddSqaud1Img} height={"200px"} alt="" />
          <div className="typing--chips">
            <img
              style={{
                height: "40px",
                width: "40px",
                borderRadius: "50px",
              }}
              src={
                currentUserData?.profile_image
                  ? `${BASE_URL}uploads/profile/${currentUserData.id}/${currentUserData?.profile_image}`
                  : userTypingImg
              }
              className="typing-user-icon"
              alt=""
            />
            <Body
              text={`${currentUserData?.first_name} is using auto-build ...`}
              color={Colors.nightGray}
              padding={"0px"}
              margin={"0px 0px 0px 8px"}
            />
          </div>
        </Boxed>
      </div>
    </>
  );
};
export default CollaboratorViewing;
