import React from "react";
import { BASE_URL } from "../Helpers/Enums";
import booHappy from "../image-assets/boo-happy.png";

const ShowProfile = ({
  profileImage,
  firstName,
  lastName,
  userId,
  cssClassName = "users-avtar",
  style  = {}
}) => {
  let profileUrl = null;
  if (profileImage) {
    profileUrl = `${BASE_URL}uploads/profile/${userId}/${profileImage}`;
  } else {
    if (firstName == " " || firstName == "") {
      profileUrl = booHappy;
    } else {
      profileUrl = "nameImage";
    }
  }

  return (
    <>
      {profileUrl == "nameImage" ? (
        <>
          <b style={style} className={` sidebar-profile-char-1`}>
            {String(firstName).charAt(0).toUpperCase()}
            {String(lastName).charAt(0).toUpperCase()}
          </b>
        </>
      ) : (
        <>
          <img style={{height : "35px",width:"35px"}} src={profileUrl} className={cssClassName} alt="" />
        </>
      )}
    </>
  );
};

export default ShowProfile;
