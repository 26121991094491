import axios from "axios";
import addDeleteGetLocalStorage from "../GlobalModule/addDeleteGetLocalStorage";
import globalRequest from "../GlobalModule/globalRequest";
import { KEYWORD_STRING, STORAGE } from "../Helpers/Enums";

const getFolder = async (id, callback) => {

  let type = addDeleteGetLocalStorage(STORAGE.NEW_SIDE_BAR, {}, "get", "single");
  let url = KEYWORD_STRING.GET_FOLDER;
  let params = {
    params: {
      user_id: id,
      string_type: type
    }
  };

  const response = await globalRequest("get", url, {}, params, true);
  callback(response);
};

export default getFolder;
