import * as Colors from "../../../Styles-Elements/Colors";
import { Subtitle } from "../../../Styles-Elements/Labels";
import "./SourcingAcademy.css";
import NewAcademySidebar from "../NewAcademySidebar/NewAcademySidebar";
import NewAcademy from "../NewAcademy/NewAcademy";
const strings = require("../../../localisation_en.json");
const SourcingAcademy = () => {
  return (
    <>
      <div className="sourcing-academy-holder">
        <Subtitle text={strings.sourcingAcademy} color={Colors.darkPurple} padding={"0 0 24px 0"} />
        <div className="sourcing-academy-white-box">
          <div className="sourcing-academy-left">
            <NewAcademySidebar />
          </div>
          <div className="sourcing-academy-right">
            <NewAcademy />
          </div>
        </div>
      </div>
    </>
  );
};
export default SourcingAcademy;
