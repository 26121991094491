import * as Colors from "../Styles-Elements/Colors";
import copyIcon from "../image-assets/copyicon.svg";
import strings from "../localisation_en.json";
import { Body, LargeSmall } from "../Styles-Elements/Labels";
import React, { useEffect, useState } from "react";
import restoreIcon from "../image-assets/restore.svg";
import playerPlayCircleIcon from "../image-assets/player-play-circle.svg";
import comboBlackIcon from "../image-assets/combo-black.svg";
import webSiteIcon from "../image-assets/translate-globe.png";
import { Box, Checkbox, ClickAwayListener } from "@material-ui/core";
import { Boxed } from "../Styles-Elements/Box";
import stopIcon from "../image-assets/stop-icon.svg";
import stopRedIcon from "../image-assets/stop-icon-red.svg";
import bulbBlueIcon from "../image-assets/bulb-blue.svg";
import bulbPinkIcon from "../image-assets/bulb-pink.svg";
import lockWiteIcon from "../image-assets/lock-white.svg";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { currentSidebar } from "../redux/reducers/sidebar";
import { tooledUpLocalData } from "../redux/reducers/getTooledupData";
import { changeAutoEnrichBulb, currentAutoEnrichBulb } from "../redux/reducers/autoEnrichBulb";
import { setSnackbar } from "../redux/reducers/snackbar";
import { currentCredit } from "../redux/reducers/getCredit";
import { currentTrial } from "../redux/reducers/getTrial";
import AiUpgradeTooltip from "./AiUpgradeTooltip";
import ManagementPlanSelection from "../dialogs/ManagementPlanSelection";
import { changeOnTranslate } from "../redux/reducers/onTranslate";
import { currentAlert } from "../redux/reducers/enrichAlert";
import KeywordAlertFrequency from "../dialogs/KeywordAlertFrequency";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "#373737",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.25)",
    fontSize: 14,
    fontWeight: 500,
    padding: "12px 16px",
    borderRadius: "4px",
    fontFamily: "Poppins",
  },
}))(Tooltip);

const CopySection = ({
  currentActiveString,
  revisionHistory,
  copyTextToClip,
  comboCallback,
  textCount,
  includeQuotationCallback,
  isTranslation = false,
  isTranslateModule = true,
  isSourceCallback = () => {},
  typingStatus = "release",
}) => {
  const [isActive, setIsActive] = useState(false);
  const [open, setOpen] = useState(false);
  const [combos, setCombos] = useState(false);
  const [includeQuotation, setIncludeQuotation] = useState(true);
  const [isSource, setIsSource] = useState(false);
  const sidebar = useSelector(currentSidebar);
  const auth = useSelector(tooledUpLocalData);
  const autoEnrichValue = useSelector(currentAutoEnrichBulb);
  const dispatch = useDispatch();
  const creditData = useSelector(currentCredit);
  const trialData = useSelector(currentTrial);
  const [openPayment, setOpenPayment] = useState(false);
  const [translateHoverClick, setTranslateHoverClick] = useState(false);
  const [autoEnrichHoverClick, setAutoEnrichHoverClick] = useState(false);
  const [comboHoverClick, setComboHoverClick] = useState(false);
  const alertValue = useSelector(currentAlert);

  const handleClick = () => {
    setOpen((prev) => !prev);
    setIsActive(!isActive);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const styles = {
    position: "absolute",
    width: 220,
    top: "40px",
    right: "0%",
    transform: "translate(0%, 0%)",
    zIndex: 50,
    borderRadius: "6px",
    p: "14px 16px",
    bgcolor: "#FEFEFE",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 5px 6px -2px rgba(0, 0, 0, 0.05), 0px 0px 15px -3px rgba(0, 0, 0, 0.10)",
  };

  useEffect(() => {
    isSourceCallback(isSource);
  }, [isSource]);

  const LockUpgrade = () => {
    return (
      <LightTooltip title={strings.upgrade} placement="bottom-start">
        <div className="css-parent-tooltip" style={{ position: "relative" }}>
          <Boxed
            className=""
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            margin={"0 15px 0 0"}
            style={{
              backgroundColor: "#EB5757",
              minWidth: "36px",
              height: "36px",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            <img src={lockWiteIcon} style={{ width: "26px", height: "26px" }} alt="" />
          </Boxed>
          <AiUpgradeTooltip
            onUpgrade={() => {
              setOpenPayment(true);
              setComboHoverClick(false);
              setTranslateHoverClick(false);
              setAutoEnrichHoverClick(false);
            }}
          />
        </div>
      </LightTooltip>
    );
  };

  return (
    <>
      <Boxed
        display="flex"
        alignItems="center"
        justifyContent={"end"}
        style={{  }}
        className="for-mainKeyword m-for-mainKeyword"
      >
        {currentActiveString?.type == "restore" && currentActiveString?.index != 0 ? (
          <Boxed className="cpytoclip" display="flex" alignItems="center" style={{ cursor: "pointer" }}>
            <img src={restoreIcon} className="gbg-icon" alt="" />
            <Body
              className="gbg-content"
              onClick={() => revisionHistory()}
              text={strings.restore}
              color={Colors.darkPurple}
              padding={"0px 8px"}
            />
          </Boxed>
        ) : null}

        <Boxed className="" display={"fex"} alignItems={"center"} margin={"0 15px 0 0"}>
          <Boxed
            onClick={copyTextToClip}
            className="cpytoclip cpytoclipbox1708"
            display="flex"
            alignItems="center"
            style={{ cursor: "pointer" }}
          >
            <img src={copyIcon} className="gbg-icon" alt="" />
            <Body
              className="gbg-content"
              text={strings.copyToClipboard}
              color={Colors.darkPurple}
              padding={"0px 8px"}
            />
          </Boxed>
          <ClickAwayListener onClickAway={handleClickAway}>
            <div style={{ position: "relative" }}>
              <Boxed
                display="flex"
                alignItems="center"
                justifyContent="center"
                onClick={handleClick}
                className={"triangle-arrow-box " + (isActive ? "active" : "")}
              >
                <span className="triangle-arrow"></span>
              </Boxed>
              {open ? (
                <Box display={"flex"} alignItems={"center"} sx={styles}>
                  <img className="play-icon-filer" src={playerPlayCircleIcon} alt="" style={{ cursor: "pointer" }} />
                  <LargeSmall
                    text={strings.includeQuotationsOnSingularWords}
                    color={Colors.nightGray}
                    padding={"0px 15px"}
                  />
                  <Checkbox
                    checked={includeQuotation}
                    onChange={(e) => {
                      setIncludeQuotation(e.target.checked);
                      includeQuotationCallback(e.target.checked);
                    }}
                  />
                </Box>
              ) : null}
            </div>
          </ClickAwayListener>
        </Boxed>

        {isTranslation ? (
          <LightTooltip
            display={"flex"}
            alignItems={"center"}
            style={{
              cursor: "pointer",
              backgroundColor: "#F5F6F9",
              height: "auto",
              padding: "6px 8px",
              borderRadius: "5px",
              marginRight: "15px",
            }}
            onClick={() => {
              setIsSource(isSource ? false : true);
            }}
            className="cpytoclip"
            title={isSource ? "Include source language" : "Exclude source language"}
          >
            <img src={isSource ? stopRedIcon : stopIcon} className="" />
          </LightTooltip>
        ) : null}

        {sidebar == "keyword" ? (
          <>
            {comboHoverClick ? (
              <div onClick={() => setComboHoverClick(false)}>
                <LightTooltip title={strings.upgrade} placement="bottom-start">
                  <div className="csb-parent-tooltip" style={{ position: "relative" }}>
                    <Boxed
                      className=""
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      textAlign="center"
                      margin={"0 15px 0 0"}
                      style={{
                        backgroundColor: "#EB5757",
                        minWidth: "123px",
                        height: "36px",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <img src={lockWiteIcon} style={{ width: "26px", height: "26px" }} alt="" />
                    </Boxed>
                    <AiUpgradeTooltip
                      onUpgrade={() => {
                        setOpenPayment(true);
                        setComboHoverClick(false);
                        setTranslateHoverClick(false);
                        setAutoEnrichHoverClick(false);
                      }}
                    />
                  </div>
                </LightTooltip>
              </div>
            ) : (
              <LightTooltip title={strings.combo_label} placement="bottom-start">
                <Boxed
                  className={`cpytoclip combos1708 ${combos ? "active" : null}`}
                  display="flex"
                  alignItems="center"
                  style={{ cursor: "pointer" }}
                  margin={"0 15px 0 0"}
                  onClick={() => {
                    if (trialData?.checkPay) {
                      if (combos) {
                        setCombos(false);
                        comboCallback(false);
                      } else {
                        setCombos(true);
                        comboCallback(true);
                      }
                    } else {
                      setComboHoverClick(true);
                    }
                  }}
                >
                  <img src={comboBlackIcon} className="gbg-icon combo-icon" alt="" />
                  <Body
                    className="gbg-content combo-content"
                    text={strings.combos}
                    color={Colors.darkPurple}
                    padding={"0px 10px 0px 10px"}
                  />
                </Boxed>
              </LightTooltip>
            )}
          </>
        ) : null}

        {!isTranslateModule ? (
          <>
            {sidebar == "keyword" ? (
              autoEnrichValue?.isFoundValue ? (
                <>
                  {autoEnrichHoverClick ? (
                    <div onClick={() => setAutoEnrichHoverClick(false)}>
                      <LockUpgrade />
                    </div>
                  ) : (
                    <LightTooltip title={strings.auto_label} placement="bottom-start">
                      <Boxed
                        className="intelblb"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        textAlign="center"
                        margin={"0 15px 0 0"}
                        style={{
                          backgroundColor: "#F5F6F9",
                          minWidth: "36px",
                          height: "36px",
                          borderRadius: "5px",
                        }}
                        onClick={() => {
                          if (trialData?.checkPay) {
                            if (trialData?.status === "trial" && creditData?.auto_enrich === 0) {
                              setAutoEnrichHoverClick(true);
                            } else {
                              if (typingStatus == "typing") {
                                dispatch(
                                  setSnackbar({
                                    snackbarState: "error",
                                    snackbarMessage: strings.YouWereTooFast,
                                    snackbarOpen: true,
                                  })
                                );
                              } else {
                                dispatch(
                                  changeAutoEnrichBulb({
                                    ...autoEnrichValue,
                                    isClicked: true,
                                  })
                                );
                              }
                            }
                          } else {
                            setAutoEnrichHoverClick(true);
                          }
                        }}
                      >
                        <img src={bulbPinkIcon} className="gbg-icon" alt="" />
                      </Boxed>
                    </LightTooltip>
                  )}
                </>
              ) : (
                <LightTooltip title={strings.auto_label} placement="bottom-start">
                  <Boxed
                    className="intelblb"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    textAlign="center"
                    margin={"0 15px 0 0"}
                    style={{
                      backgroundColor: "#F5F6F9",
                      minWidth: "36px",
                      height: "36px",
                      borderRadius: "5px",
                    }}
                  >
                    <img src={bulbBlueIcon} className="gbg-icon" alt="" />
                  </Boxed>
                </LightTooltip>
              )
            ) : null}
          </>
        ) : null}

        {!isTranslateModule && sidebar === "keyword" ? (
          <>
            {translateHoverClick ? (
              <div onClick={() => setTranslateHoverClick(false)}>
                <LockUpgrade />
              </div>
            ) : (
              <LightTooltip
                title={strings.Translate}
                placement="bottom-start"
                onClick={() => {
                  if (trialData?.status === "trial" && creditData?.translations === 0) {
                    setTranslateHoverClick(true);
                  } else {
                    if (trialData?.checkPay) {
                      dispatch(changeOnTranslate(true));
                    } else {
                      setTranslateHoverClick(true);
                    }
                  }
                }}
              >
                <Boxed
                  className="intelblb"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  textAlign="center"
                  margin={"0 15px 0 0"}
                  style={{
                    backgroundColor: "#F5F6F9",
                    minWidth: "36px",
                    height: "36px",
                    borderRadius: "5px",
                  }}
                >
                  <img src={webSiteIcon} className="icon22" alt="" />
                </Boxed>
              </LightTooltip>
            )}
          </>
        ) : null}

        <LightTooltip title={strings.count_label} placement="bottom-start">
          <Boxed
            className="cpytoclip"
            display="flex"
            alignItems="center"
            textAlign="center"
            style={{
              backgroundColor: "#F5F6F9",
              minWidth: "68px",
              height: "36px",
              borderRadius: "5px",
            }}
          >
            <Body
              className="gbg-content"
              text={textCount}
              color={Colors.darkPurple}
              padding={"0px 8px"}
              style={{ textAlign: "center", minWidth: "inherit" }}
            />
          </Boxed>
        </LightTooltip>
      </Boxed>

      {openPayment ? (
        <ManagementPlanSelection
          closePopup={() => {
            setOpenPayment(false);
          }}
        />
      ) : null}
    </>
  );
};

export default CopySection;
