import { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import Sidebar from "../../Styles-Elements/Sidebar/Sidebar";
import Settings from "../settings/Settings";
import KeywordStrings from "../keyword-strings/KeywordStrings";
import ImportStrings from "../import-strings/ImportStrings";
import Gadgets from "../Gadgets/Gadgets";
import Dashboard from "../Dashboard/Dashboard";
import ManageUsers from "../manage-users/ManageUsers";
import { SIDEBAR_STATE, SIDEBAR_STATE_FOR_LOCAL_STORAGE } from "../../Helpers/Enums";
import { stringData } from "../../redux/reducers/localString";
import { tooledUpLocalData } from "../../redux/reducers/getTooledupData";
import { useDispatch, useSelector } from "react-redux";
import { currentSidebar } from "../../redux/reducers/sidebar";
import { currentLocalValue } from "../../redux/reducers/getLocalData";
import { changeLoader } from "../../redux/reducers/loader";
import { memo } from "react";
import getCurrentPlan from "../../GlobalModule/getCurrentPlan";
import { changePage, currentPage } from "../../redux/reducers/pagesChange";
import FreeTrialHeader from "../FreeTrialHeader/FreeTrialHeader";
import SignupDetailsModal from "../new-signup-detail/SignupDetailsModal";
import { changeTrial, currentTrial } from "../../redux/reducers/getTrial";
import getCredit from "../../GlobalModule/getCredit";
import { changeCredit } from "../../redux/reducers/getCredit";
import getTrialData from "../../GlobalModule/getTrialData";
import SourcingAcademy from "../../AcademyModule/pages/SourcingAcademy/SourcingAcademy";
import "./MainApp.css";
import UserAdminSetting from "../UserAdminSetting/UserAdminSetting";
import getAlertEnrich from "../../GlobalModule/getAlertEnrich";
import { changeAlert } from "../../redux/reducers/enrichAlert";
import enrichKeywordStringUpdate from "../../GlobalModule/enrichKeywordStringUpdate";
import { currentAutoEnrichBulb } from "../../redux/reducers/autoEnrichBulb";

let timer = null;

const MainApp = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const getLocalData = useSelector(currentLocalValue);
  const pages = useSelector(currentPage);
  const strings = useSelector(stringData);
  const auth = useSelector(tooledUpLocalData);
  const typeParam = useSelector(currentSidebar);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const [sidebar, setSideBar] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("");
  const trialData = useSelector(currentTrial);
  const autoEnrich = useSelector(currentAutoEnrichBulb);

  const getCurrentPlans = useCallback(async () => {
    if (auth?.trial?.status === "payment_accepted") {
      setPaymentStatus(auth?.payment_status);
      dispatch(changeLoader(true));
      let res = await getCurrentPlan(auth.access == "owner" ? auth?.user_id : auth?.team_id);
      let data = res.data;
      if (data.status == 1) {
        setPaymentStatus(data.data.payment_status);
        dispatch(changeLoader(false));
      }
    } else {
      setPaymentStatus("trialing");
    }
  });

  const checkTrail = useCallback(async () => {
    let res = await getTrialData(auth?.access === "owner" ? auth.id : auth?.team_id);
    dispatch(changeTrial(res));
  }, []);

  const getAlert = async () => {
    let { data } = await getAlertEnrich(auth?.id);
    if (data?.ack === 1) {
      if (data?.data?.alerts) {
        dispatch(changeAlert(data?.data?.alerts));
      }
    }
  };

  useEffect(() => {
    (async () => {
      let data = await getCredit(auth?.id);
      dispatch(changeCredit(data));
    })();
    checkTrail();
    getAlert();
  }, []);

  useEffect(() => {
    getCurrentPlans();
  }, [typeParam, pages]);

  useEffect(() => {
    let isEmpty = Object.keys(auth).length == 0;

    if (isEmpty) {
      history.push("/");
    }
  }, []);

  useEffect(() => {
    for (let obj in SIDEBAR_STATE_FOR_LOCAL_STORAGE) {
      let value = SIDEBAR_STATE_FOR_LOCAL_STORAGE[obj];
      let changeValues = SIDEBAR_STATE[obj];
      if (typeParam == value) {
        dispatch(changePage(changeValues));
      }
    }
  }, [typeParam]);

  const sidebarRenders = () => {
    switch (pages) {
      case SIDEBAR_STATE.COMPANY_STRINGS:
        return <KeywordStrings />;
      case SIDEBAR_STATE.KEYWORD_STRINGS:
        return <KeywordStrings />;
      case SIDEBAR_STATE.GADGETS:
        return <Gadgets />;
      case SIDEBAR_STATE.DASHBOARD:
        return <Dashboard />;
      case SIDEBAR_STATE.IMPORT_STRINGS:
        return <ImportStrings />;
      case SIDEBAR_STATE.MANAGE_USERS:
        return <ManageUsers {...props} />;
      case SIDEBAR_STATE.SETTINGS:
        return trialData?.status === "payment_accepted" ? (
          auth?.access === "owner" ? (
            <Settings />
          ) : (
            <UserAdminSetting />
          )
        ) : (
          <UserAdminSetting />
        );
      case SIDEBAR_STATE.ACADEMY:
        return <SourcingAcademy />;
      default:
        break;
    }
  };

  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(async () => {
      await enrichKeywordStringUpdate(auth?.id);
    }, 2000);
  }, [autoEnrich]);

  return (
    <>
      <div className={sidebar ? "main-app-grid sidebar-shrink" : "main-app-grid sidebar-shrink"}>
        <div className="main-leftsidebar">
          <Sidebar paymentStatus={paymentStatus} />
        </div>
        <div className="rightside-holder">
          {String(trialData?.status) === "trial" || String(trialData?.status) === "trial_completed" ? (
            <div style={{ position: "absolute", right: "0", padding: "12px 40px 0px 20px" }}>
              <FreeTrialHeader />
            </div>
          ) : null}
          {sidebarRenders()}
          {auth.tab_fill === 2 ? <SignupDetailsModal /> : null}
        </div>
      </div>
    </>
  );
};

export default memo(MainApp);
