import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "antd/dist/antd.css";
import * as Colors from "../Styles-Elements/Colors";
import { Subtitle, LargeBodyBold, Body, LabelWrapper, LargeBody, LargeSmall } from "../Styles-Elements/Labels";
import { InputWrapper, TextSearchfield } from "../Styles-Elements/Inputs";
import { ClearButton, MainRoundedButton } from "../Styles-Elements/Buttons";
import booHappy from "../image-assets/boo-happy.png";
import searchIcon from "../image-assets/searchicon.svg";
import searchCloseIcon from "../image-assets/searchclose.svg";

// Material UI for the snackbar
import Scrollbar from "react-smooth-scrollbar";
// import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { BASE_URL, KEYWORD_STRING } from "../Helpers/Enums";
import closePopUpByKey from "../GlobalModule/closePopUpByKey";
import { tooledUpLocalData } from "../redux/reducers/getTooledupData";
import { setSnackbar } from "../redux/reducers/snackbar";
import { changeLoader } from "../redux/reducers/loader";
import globalRequest from "../GlobalModule/globalRequest";
import { changeData, currentCollab } from "../redux/reducers/letCollab";
import { Boxed } from "../Styles-Elements/Box";

// Importing localised strings
const strings = require("../localisation_en.json");

//closePopup : callback
//folderOrStringName : name of the folder or string
//folderOrStringId : id of the folder or string
//type : type should be string or folder
//stringType : type of string like favorite,string or shareWithMe

const LetsCollaborate = (props) => {
  const auth = useSelector(tooledUpLocalData);
  const letCollab = useSelector(currentCollab);
  const dispatch = useDispatch();

  const [user, setUser] = useState([]);
  const [searchingUser, setSearchingUser] = useState([]);
  const [selected, setSelected] = useState([]);
  const [grandUser, setGrandUser] = useState([]);
  const [selectedValue, setSelectedValue] = useState("edit");
  const [loading, setLoading] = useState(false);
  const [searchString, setSearchString] = useState("");

  let type = props.type;
  let folderOrStringId = props.folderOrStringId;

  useEffect(() => {
    closePopUpByKey(props.closePopup);
  }, []);

  const handleChange = (event, id) => {
    let v = event.target.value;
    if (v == "edit") {
      editView(id, "edit");
    } else if (v == "view") {
      editView(id, "view");
    } else if (v == "delete") {
      removeUser(id);
      editView(id, "delete");
    }
    setSelectedValue(v);
  };

  const getTeamUser = async () => {
    const userId = auth?.user_id;
    const url = KEYWORD_STRING.TEAM_USER;
    const params = {
      user_id: userId,
    };

    if (type == "string") {
      params.string_id = folderOrStringId;
    } else if (type == "folder") {
      params.folder_id = folderOrStringId;
    }

    let response = await globalRequest(
      "get",
      url,
      {},
      {
        params: params,
      },
      true
    );

    const data = response.data;
    if (data.status == 1) {
      setUser(data.data);
    }
  };

  useEffect(() => {
    getTeamUser();
  }, []);

  const submitRequest = async () => {
    let otherUserId = [];
    let userPermission = [];

    selected.forEach((obj) => {
      if (obj.otheruser_id != null || obj.otheruser_id != undefined) {
        otherUserId.push(obj.otheruser_id);
        userPermission.push(obj.user_permission);
      }
    });

    let url = KEYWORD_STRING.COLLABORATE;
    let body = {
      team_id: auth?.team_id,
      type: type,
      user_id: auth?.user_id,
      otheruser_id: otherUserId,
      user_permission: userPermission,
    };
    if (type == "string") {
      body.string_id = folderOrStringId;
    } else if (type == "folder") {
      body.folder_id = folderOrStringId;
    }

    dispatch(changeLoader(true));
    let response = await globalRequest("post", url, body, {}, true);
    let data = response.data;
    if (data.status == 1) {
      dispatch(
        setSnackbar({
          snackbarMessage: data.message,
          snackbarOpen: true,
          snackbarState: "success",
        })
      );
      dispatch(changeData(letCollab ? false : true));
      props.closePopup("success");
    } else {
      dispatch(
        setSnackbar({
          snackbarMessage: data.message,
          snackbarOpen: true,
          snackbarState: "error",
        })
      );
    }
    dispatch(changeLoader(false));
  };

  const searchUser = (e) => {
    const v = String(e.target.value).toLowerCase();
    setSearchString(e.target.value);
    let a = [];
    if (v == "") {
      setSearchingUser(user);
    } else {
      let condition = new RegExp(v, "g");
      user.forEach((obj) => {
        if (obj.full_name == "") {
          if (condition.test(String(obj.email_id).toLocaleLowerCase())) {
            a.push(obj);
          }
        } else {
          if (condition.test(String(obj.full_name).toLocaleLowerCase())) {
            a.push(obj);
          }
        }
      });
      setSearchingUser(a);
    }
  };

  const addUser = (obj) => {
    setSelected((selected) => [...selected, { otheruser_id: obj.id, user_permission: "edit" }]);
    setSelectedValue("edit");
  };

  const ifIdFound = (id) => {
    let k = false;
    selected.forEach((obj) => {
      if (id == obj.otheruser_id) {
        k = true;
      }
    });
    return k;
  };

  const removeUser = (id) => {
    let newArray = [];
    selected.forEach((obj) => {
      if (id != obj.otheruser_id) {
        newArray.push({
          otheruser_id: obj.otheruser_id,
          user_permission: obj.user_permission,
        });
      }
    });
    setSelected(newArray);
  };

  const editView = (id, permission) => {
    let newArray = [];
    selected.forEach((obj) => {
      if (id == obj.otheruser_id) {
        newArray.push({
          otheruser_id: id,
          user_permission: permission,
        });
      } else {
        newArray.push({
          otheruser_id: obj.otheruser_id,
          user_permission: obj.user_permission,
        });
      }
    });
    setSelected(newArray);
  };

  const selectAllUser = () => {
    let newArray = [];
    user.forEach((obj) => {
      if (obj.access == "user") {
        newArray.push({
          otheruser_id: obj.id,
          user_permission: "edit",
        });
      }
    });

    setSelected(newArray);
  };

  const removeAllUser = () => {
    setSelected([]);
  };

  useEffect(() => {
    setGrandUser(searchingUser);
  }, [searchingUser]);

  useEffect(() => {
    setGrandUser(user);

    user.forEach((obj) => {
      if (obj.permission != "" && obj.permission != 2) {
        setSelected((selected) => [
          ...selected,
          {
            otheruser_id: obj.id,
            user_permission: obj.permission == "0" ? "edit" : "view",
          },
        ]);
      }
    });
  }, [user]);

  const closeSearch = () => {
    setSearchString("");
    setSearchingUser(user);
  };

  return (
    <div className="container">
      <div className="container-modal" style={{ paddingBottom: "16px", marginTop: "60px" }}>
        <div className="popup-boo-container neutral">
          <img src={booHappy} width={120} height={120} />
        </div>
        <Scrollbar className="container-modal-mid">
          <Subtitle
            text={strings.letsCollaborate}
            color={Colors.nightPurple}
            textAlign={"center"}
            padding={"24px 0 16px"}
          />
          <div className="grid-item">
            <LabelWrapper justifyContent={"center"} margin={"0px 0 16px"}>
              <LargeBody
                text={strings.inviteTeamMembersToCollaborateOnYour}
                color={Colors.darkGray}
                padding={"0 2px 0 0"}
              />
              <LargeBodyBold
                text={props.folderOrStringName}
                color={Colors.darkGray}
                padding={"0 2px"}
                cursor={"pointer"}
              />
              <LargeBody
                text={type == "folder" ? strings.folder : strings.string}
                color={Colors.darkGray}
                padding={"0 2px"}
              />
            </LabelWrapper>
          </div>
          <div className="popup-selection--box">
            <InputWrapper>
              <TextSearchfield
                onChange={(e) => searchUser(e)}
                placeholder={strings.startTypingAName}
                backgroundColor={Colors.whiteGray}
                value={searchString}
              />
              <button className="searchbtn">
                <img src={searchIcon} className="" />
              </button>

              {searchString ? (
                <>
                  <button onClick={closeSearch} className="searchbtn" style={{ right: "16px", left: "unset" }}>
                    <img src={searchCloseIcon} className="" />
                  </button>
                </>
              ) : null}
            </InputWrapper>
            <div>
              <Boxed
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                style={{ padding: "12px 4px 0px 4px" }}
              >
                {selected.length ? (
                  <>
                    <Body
                      text={strings.removeAllUser}
                      textAlign={"left"}
                      color={Colors.darkPurple}
                      padding={"0px 0 0 0"}
                      cursor={"pointer"}
                      onClick={(e) => removeAllUser()}
                    />
                  </>
                ) : (
                  <>
                    <Body
                      text={strings.addAllUsers}
                      textAlign={"left"}
                      color={Colors.darkPurple}
                      padding={"0px 0 0 0"}
                      cursor={"pointer"}
                      onClick={(e) => selectAllUser()}
                    />
                  </>
                )}
              </Boxed>
            </div>
            <Scrollbar className="popup-scroll-box">
              {grandUser.map((obj) => {
                let profileUrl = null;
                if (obj.profile_image) {
                  profileUrl = `${BASE_URL}uploads/profile/${obj.id}/${obj.profile_image}`;
                } else {
                  if (obj.first_name == "") {
                    profileUrl = booHappy;
                  } else {
                    profileUrl = "nameImage";
                  }
                }

                const find = selected.find((e) => e.otheruser_id == obj.id);
                let permissionValue = find?.user_permission;

                let nameOrEmail = obj.first_name != "" ? obj.first_name + " " + obj.last_name : obj.email_id;

                return (
                  <>
                    <Boxed display={"flex"} alignItems={"center"} justifyContent={"space-between"} margin={"0 0 4px 0"}>
                      <Boxed display={"flex"} alignItems={"center"}>
                        {profileUrl == "nameImage" ? (
                          <>
                            <b
                              className="sidebar-profile-char"
                              style={{
                                width: "30px",
                                height: "30px",
                                fontSize: "12px",
                                marginRight: "0",
                              }}
                            >
                              {String(obj.first_name).charAt(0)} {String(obj.last_name).charAt(0)}
                            </b>
                          </>
                        ) : (
                          <>
                            <img className="users-avtar" style={{ width: "30px", height: "30px" }} src={profileUrl} />
                          </>
                        )}
                        <Body
                          text={nameOrEmail}
                          textAlign={"center"}
                          color={Colors.nightPurple}
                          padding={"0"}
                          margin={"0 12px 0 12px"}
                        />
                      </Boxed>

                      {props.stringType != "shareWithMe" ? (
                        obj.access == "user" ? (
                          ifIdFound(obj.id) ? (
                            <>
                              <div className="select-lc-drop">
                                <FormControl sx={{ m: 1, minWidth: 120 }}>
                                  <Select
                                    onChange={(e) => handleChange(e, obj.id)}
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                    defaultValue={permissionValue}
                                  >
                                    <MenuItem value="edit">
                                      <Body text={"Edit Access"} color={"#000"} padding={"0"} margin={"0"} />
                                    </MenuItem>
                                    <MenuItem value="view">
                                      <Body text={"View Only"} color={"#000"} padding={"0"} margin={"0"} />
                                    </MenuItem>
                                    <MenuItem value="delete">
                                      <Body text={"Remove User"} color={Colors.lightRed} padding={"0"} margin={"0"} />
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </div>
                            </>
                          ) : (
                            <>
                              <LargeSmall
                                text={strings.addUser}
                                textAlign={"center"}
                                color={Colors.darkPurple}
                                padding={"0"}
                                margin={"0 0px 0 12px"}
                                cursor={"pointer"}
                                onClick={(e) => addUser(obj)}
                              />
                            </>
                          )
                        ) : (
                          <LargeSmall
                            text={"Edit Access"}
                            textAlign={"center"}
                            padding={"0"}
                            margin={"0 0px 0 12px"}
                            cursor={"pointer"}
                          />
                        )
                      ) : obj.access == "user" ? (
                        obj.permission_created_by == auth?.user_id || obj.permission_created_by == "" ? (
                          ifIdFound(obj.id) ? (
                            <>
                              <div className="select-lc-drop">
                                <FormControl sx={{ m: 1, minWidth: 120 }}>
                                  <Select
                                    onChange={(e) => handleChange(e, obj.id)}
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                    defaultValue={permissionValue}
                                  >
                                    <MenuItem value="edit">
                                      <Body text={"Edit Access"} color={"#000"} padding={"0"} margin={"0"} />
                                    </MenuItem>
                                    <MenuItem value="view">
                                      <Body text={"View Only"} color={"#000"} padding={"0"} margin={"0"} />
                                    </MenuItem>
                                    <MenuItem value="delete">
                                      <Body text={"Remove User"} color={Colors.lightRed} padding={"0"} margin={"0"} />
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </div>
                            </>
                          ) : (
                            <>
                              <LargeSmall
                                text={strings.addUser}
                                textAlign={"center"}
                                color={Colors.darkPurple}
                                padding={"0"}
                                margin={"0 0px 0 12px"}
                                cursor={"pointer"}
                                onClick={(e) => addUser(obj)}
                              />
                            </>
                          )
                        ) : (
                          <LargeSmall
                            text={"Edit Access"}
                            textAlign={"center"}
                            padding={"0"}
                            margin={"0 0px 0 12px"}
                            cursor={"pointer"}
                          />
                        )
                      ) : (
                        <LargeSmall
                          text={"Edit Access"}
                          textAlign={"center"}
                          padding={"0"}
                          margin={"0 0px 0 12px"}
                          cursor={"pointer"}
                        />
                      )}
                    </Boxed>
                  </>
                );
              })}
            </Scrollbar>
          </div>
          <MainRoundedButton text={"Confirm"} margin={"24px 0 8px 0"} onClick={() => submitRequest()} />
          <ClearButton text={strings.cancel} onClick={() => props.closePopup()} />
        </Scrollbar>
      </div>
    </div>
  );
};

export default LetsCollaborate;
