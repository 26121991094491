const beautifyString = (string) => {
  let w = String(string)
    .replace(/\s/g, " ")
    .replace(/(?:\\[rn]|[\r\n]+)+/g, "")
    .toLowerCase()
    .trim();
  return w;
};

export default beautifyString;
