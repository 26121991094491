import colorTheString from './colorTheString';
import setCursorPosition from './setCursorPosition';
import addSpaceLogic from './addSpaceLogic';

const createAllNode = (str, parent, index = 0) => {

    let length = str.length;
    if (index != 0) {
        length = index;
    }
    parent.innerHTML = "";
    
    for (var i = 0; i <= str.length; i++) {

        let node = document.createElement("span");
        node.contentEditable = true;
        if (str.charAt(i) === " ") {
            node.innerHTML = "&nbsp;";
        } else {
            node.innerText = str.charAt(i);
        }

        if (parent.children.length === 0) {
            parent.appendChild(node);
        } else {
            if (
                parent.children &&
                parent.children.length > 0 &&
                parent.children[i - 1]
            ) {
                parent.children[i - 1].insertAdjacentElement("afterEnd", node);
            }
        }
    }

    colorTheString(str, parent);
    setCursorPosition(length, str, parent);

    return str;
}

export default createAllNode;