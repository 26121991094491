import { memo, useEffect, useState } from "react";
import NewStringCreation from "./NewStringCreationPrompt/NewStringCreation";
import RequirementsGather from "./RequirementsGatherSection/RequirementsGather";
import CollaboratorViewing from "./CollaboratorViewing/CollaboratorViewing";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../redux/reducers/loader";
import globalRequest from "../../GlobalModule/globalRequest";
import { AUTO_BUILD } from "../../Helpers/Enums";
import { setSnackbar } from "../../redux/reducers/snackbar";
import EditorView from "./EditorView";
import socket from "../../socket";
import { tooledUpLocalData } from "../../redux/reducers/getTooledupData";
import { changeAutoBuildValue, currentAutoBuildValue } from "../../redux/reducers/autoBuildValues";
import { changeSocketUserData } from "../../redux/reducers/socketUserData";
import { currentTrial } from "../../redux/reducers/getTrial";
import AlertBox from "../../dialogs/AlertBox";
import { changeAutoEnrichBulb } from "../../redux/reducers/autoEnrichBulb";
import UseCreditDialog from "../../dialogs/UseCreditDialog";
import { currentCredit } from "../../redux/reducers/getCredit";

const AutoBuildHome = ({ finalSubmit, currentActiveString, resetStep = false, forFinalCallback, decontructValue }) => {
  const [step, setStep] = useState(1);
  const dispatch = useDispatch();
  const [string, setString] = useState("");
  const [words, setWords] = useState([]);
  const auth = useSelector(tooledUpLocalData);
  const [visibleCollab, setVisibleCollab] = useState(false);
  const [includeWord, setIncludeWord] = useState([]);
  const trialData = useSelector(currentTrial);
  const creditData = useSelector(currentCredit);
  const [showModal, setShowModal] = useState(false);
  const [openUseCredit, setOpenUseCredit] = useState(false);

  useEffect(() => {
    if (step == 3) {
      forFinalCallback(true);
    } else {
      forFinalCallback(false);
    }
  }, [step]);

  useEffect(() => {
    dispatch(
      changeAutoBuildValue({
        targetJobTitle: "",
        targetIndustry: "",
        roleCoreFunction: "",
        criticalRequirement1: "",
        requirement2: "",
        requirement3: "",
        isApiCall: false,
        string: "",
        words: [],
      })
    );
    setStep(1);
    setString("");
  }, [resetStep]);

  useEffect(() => {
    try {
      if (Object.keys(currentActiveString).length !== 0) {
        socket.on("auto-build-typing", (data) => {
          let sId = data?.string_id;
          let userData = data?.typing_user_data[0];
          let typeStatus = data?.typingStatus;
          let string = data?.string;

          if (sId == currentActiveString?.id && userData?.id != auth?.user_id && typeStatus == "typing") {
            setVisibleCollab(true);
            dispatch(changeSocketUserData(userData));
          }

          if (sId == currentActiveString?.id && typeStatus == "release") {
            finalSubmit(string);
          }
        });
      }
    } catch (e) {}
  }, [currentActiveString, string]);

  const updateString = (stringBoolean, type, stringId) => {
    dispatch(changeLoader(true));
    globalRequest(
      "post",
      AUTO_BUILD.UPDATE_STRING,
      {
        stringId: stringId,
        type: type,
        stringBoolean: stringBoolean,
        includedWord: includeWord,
        userId: auth?.user_id,
      },
      {},
      true
    )
      .then((res) => {
        let data = res.data;
        if (data.ack == 1) {
          setTimeout(() => {
            socket.emit("auto-enrich", {
              stringData: [{ string_id: stringId }],
              userId: auth?.user_id,
              type: "single",
            });
          }, 1000);
          dispatch(
            setSnackbar({
              snackbarMessage: data.msg,
              snackbarOpen: true,
              snackbarState: "success",
            })
          );
          finalSubmit(string);
        } else {
          dispatch(
            setSnackbar({
              snackbarMessage: data.msg,
              snackbarOpen: true,
              snackbarState: "error",
            })
          );
        }
      })
      .catch((err) => {
        let msg = err.response.body.msg;
        dispatch(
          setSnackbar({
            snackbarMessage: msg,
            snackbarOpen: true,
            snackbarState: "error",
          })
        );
      })
      .finally(() => {
        dispatch(changeLoader(false));
      });
  };

  const emitData = (typingStatus, type, string) => {
    socket.emit("auto-build-typing", {
      string_id: currentActiveString?.id,
      typing_user_id: auth?.user_id,
      typing_status: typingStatus,
      string: string,
      type: type,
    });
  };

  const emitString = () => {
    let data = {
      string_id: currentActiveString?.id,
      user_id: currentActiveString?.string_detail?.created_by,
      typing_user_id: auth?.user_id,
      status: "release",
      name: auth?.firstname + " " + auth?.lastname,
      browser_type: "web",
      string_type: "keyword",
      random_id: "",
      string_boolean: string,
    };
    socket.emit("poststring", data);
  };

  const CallComponent = () => {
    switch (step) {
      case 1:
        return (
          <NewStringCreation
            clickCallback={(type) => {
              dispatch(
                changeAutoEnrichBulb({
                  isFoundValue: false,
                  isClicked: false,
                })
              );

              if (type == "manual") {
                updateString("", "manual", currentActiveString?.id);
                emitData("release", "manual", "");
              } else {
                setStep(2);
                emitData("typing", "", "");
              }
            }}
          />
        );
        break;
      case 2:
        return (
          <RequirementsGather
            goBack={() => {
              dispatch(
                changeAutoBuildValue({
                  targetJobTitle: "",
                  targetIndustry: "",
                  roleCoreFunction: "",
                  criticalRequirement1: "",
                  requirement2: "",
                  requirement3: "",
                  isApiCall: false,
                  string: "",
                  words: [],
                  includeWord: [],
                })
              );
              setStep(1);
              setString("");
            }}
            goForward={(st, includedWord) => {
              setString(st);
              setIncludeWord(includedWord);
              setStep(3);
            }}
            stringId={currentActiveString?.id}
          />
        );
        break;
      case 3:
        return (
          <EditorView
            string={string}
            goBack={() => {
              if (trialData?.status === "trial") {
                setShowModal(true);
              } else {
                setStep(2);
              }
            }}
            finish={async () => {
              updateString(string, "auto-build", currentActiveString?.id);
              emitData("release", "auto-build", string);
              emitString();
              await globalRequest(
                "post",
                AUTO_BUILD.UPDATE_INTEL_ON_CONFIRM,
                {
                  stringKeyword: includeWord,
                  userId: auth?.user_id,
                  stringId: currentActiveString?.id,
                },
                {},
                true
              );
            }}
            stringId={currentActiveString?.id}
            decontructValue={decontructValue}
            includedWord={includeWord}
          />
        );
        break;
    }
  };

  return (
    <>
      {visibleCollab ? <CollaboratorViewing /> : <CallComponent />}

      {showModal ? (
        <UseCreditDialog
          type="autoBuildBack"
          submit={() => {
            setStep(2);
            setShowModal(false);
          }}
          closePopup={() => setShowModal(false)}
          creditData={creditData}
        />
      ) : null}
    </>
  );
};

export default memo(AutoBuildHome);
