import React, { useState, useEffect } from "react";
import * as Colors from "../../Styles-Elements/Colors";
import { Subtitle, Subheading } from "../../Styles-Elements/Labels";
import { CreateButton } from "../../Styles-Elements/Buttons";
import { Textfield } from "../../Styles-Elements/Inputs";
import "./AddProfile.css";
import defaultUploadIcon from "../../image-assets/default-upload-icon.svg";
import defaultUploadGreenIcon from "../../image-assets/default-upload-green-icon.svg";
import ImgCrop from "antd-img-crop";
import { Upload, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { stringData } from "../../redux/reducers/localString";
import { setData, tooledUpLocalData } from "../../redux/reducers/getTooledupData";
import { setSnackbar } from "../../redux/reducers/snackbar";
import globalRequest from "../../GlobalModule/globalRequest";
import { changeLoader } from "../../redux/reducers/loader";
import { REQUEST, STORAGE } from "../../Helpers/Enums";
import "antd/dist/antd.css";
import addDeleteGetLocalStorage from "../../GlobalModule/addDeleteGetLocalStorage";
import { Boxed } from "../../Styles-Elements/Box";

const AddProfile = () => {
  const strings = useSelector(stringData);
  const auth = useSelector(tooledUpLocalData);
  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [fileList, setFileList] = useState([]);
  const [base64, setBase64] = useState("");
  const [ifUploaded, setIfUploaded] = useState(false);
  const [imageURL, setImageURL] = useState(defaultUploadIcon);
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    if (auth) {
      setFirstName(auth?.firstname);
      setLastName(auth?.lastname);
    }
  }, []);

  const addProfile = async (e) => {
    e.preventDefault();
    let url = REQUEST.PROFILE_UPDATE_REQUEST;
    let formData = new FormData();
    formData.append("profile_image", base64);
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("user_id", auth?.user_id);

    dispatch(changeLoader(true));
    let response = await globalRequest("put", url, formData, {}, true);
    let data = response.data;
    if (data.status == 1) {
      dispatch(
        setSnackbar({
          snackbarMessage: data.message,
          snackbarOpen: true,
          snackbarState: "success",
        })
      );
      let data1 = data.data;
      let a = {
        ...auth,
        tab_fill : 5,
        firstname: data1.first_name,
        lastname: data1.last_name,
        img_path: data1.img_path,
        profile_image: data1.profile_image,
      };
      addDeleteGetLocalStorage(STORAGE.TOOLED_UP_LOCAL_DATA, a, "add", "single");
      dispatch(setData());
    } else {
      dispatch(
        setSnackbar({
          snackbarMessage: data.message,
          snackbarOpen: true,
          snackbarState: "error",
        })
      );
    }
    dispatch(changeLoader(false));
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type == "image/jpg";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }

    setIfUploaded(isJpgOrPng && isLt2M);
    return isJpgOrPng && isLt2M;
  };

  const onImageChange = async ({ fileList }) => {
    if (ifUploaded) {
      if (fileList.length > 0) {
        const base64 = await getBase64(fileList[fileList.length - 1]?.originFileObj);
        setBase64(base64);
      } else {
        setBase64(null);
      }
      setFileList(fileList);
    }
  };

  const handleMouseEnter = () => {
    setHovered(true);
    setImageURL(defaultUploadGreenIcon);
  };

  const handleMouseLeave = () => {
    setHovered(false);
    setImageURL(defaultUploadIcon);
  };

  return (
    <div className="grid-colmn-equal user-detail-holder">
      <Boxed display={"flex"} justifyContent={"center"}>
        <form onSubmit={addProfile}>
          <Boxed style={{ width: "416px" }} margin={"auto"} textAlign={"center"}>
            <Subtitle
              text={strings.addYourProfileDetails}
              color={Colors.nightPurple}
              textAlign={"center"}
              padding={"0 0 24px 0"}
            />
            <div className="profile-upload-holder22">
              <ImgCrop grid style={{ borderRadius: "50%" }}>
                <Upload
                  listStyle={{ borderRadius: "50%" }}
                  listType="picture-card"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  onChange={onImageChange}
                >
                  {base64 != "" ? (
                    <>
                      <div>
                        <img className={"profile-upload-icon"} src={base64} alt="" />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="profile-upload-icon">
                        <img
                          src={hovered ? defaultUploadGreenIcon : defaultUploadIcon}
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                          alt=""
                          className="profile-upload-icon"
                        />
                      </div>
                    </>
                  )}
                </Upload>
              </ImgCrop>
            </div>
            <Subheading text={strings.firstName} color={"#423069"} padding={"16px 0 8px 0"} />
            <Textfield
              placeholder={strings.firstName}
              margin={"0px auto 16px auto"}
              width={"99%"}
              id="firstname"
              value={firstName}
              required={true}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <Subheading text={strings.lastName} color={"#423069"} padding={"0 0 8px 0"} />
            <Textfield
              placeholder={strings.lastName}
              margin={"0px auto 16px auto"}
              width={"99%"}
              required={true}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <CreateButton
              text={strings.LetsGo}
              margin={"24px 0 0px 0"}
              type={"submit"}
              className={"addprofile-letsgo"}
            />
          </Boxed>
        </form>
      </Boxed>
    </div>
  );
};

export default AddProfile;
