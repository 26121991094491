import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import { Autocomplete, TextField } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Scrollbar from "react-smooth-scrollbar";

import convertIntoHtml from "../editor/convertIntoHtml";
import removeAllSpaces from "../editor/removeAllSpaces";
import addDeleteGetLocalStorage from "../GlobalModule/addDeleteGetLocalStorage";
import globalRequest from "../GlobalModule/globalRequest";
import splitCharUsingComma from "../GlobalModule/splitCharUsingComma";
import { API_TRANSLATION, STORAGE } from "../Helpers/Enums";
import booHappy from "../image-assets/boo-neutral.png";
import favoriteHeartIcon from "../image-assets/favourite-fill-icon.svg";
import closeModalIcon from "../image-assets/modal-close-new.png";
import searchCloseIcon from "../image-assets/searchclose.svg";
import searchIcon from "../image-assets/searchicon.svg";
import unfavouriteIcon from "../image-assets/unfavouriteicon.svg";
import strings from "../localisation_en.json";
import { tooledUpLocalData } from "../redux/reducers/getTooledupData";
import { changeLoader } from "../redux/reducers/loader";
import { setSnackbar } from "../redux/reducers/snackbar";
import { Boxed } from "../Styles-Elements/Box";
import { MainRoundedButton } from "../Styles-Elements/Buttons";
import * as Colors from "../Styles-Elements/Colors";
import { InputWrapper, TextSearchfield } from "../Styles-Elements/Inputs";
import { LargeBody, Subtitle } from "../Styles-Elements/Labels";
import { getLanguageList, glossaryDataGet, saveDataInGlossaryFile } from "./GlobalFunction";
import masculineFeminine from "./masculineFeminine";
import reportBugsApi from "./reportBugsApi";
import { convertStringForTranslate } from "./StringGenrateForTranslate";
import TranslationComplete from "./TranslationComplete";

import "./child.css";
import "antd/dist/antd.css";
import removeNameSpace from "../GlobalModule/removeNameSpace";
import getCredit from "../GlobalModule/getCredit";
import { changeCredit, currentCredit } from "../redux/reducers/getCredit";
import { currentTrial } from "../redux/reducers/getTrial";
import UseCreditDialog from "../dialogs/UseCreditDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  dropdownmenu: {
    position: "absolute",
    top: 55,
    right: 0,
    left: 1,
    zIndex: 1,
    border: "none",
    boxShadow:
      "0px 0px 15px -3px rgba(0, 0, 0, 0.1), 0px 5px 6px -2px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
    padding: "0",
    borderRadius: "6px",
    backgroundColor: "#fff",
  },
}));

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "#373737",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.25)",
    fontSize: 14,
    fontWeight: 500,
    padding: "12px 16px",
    borderRadius: "4px",
    fontFamily: "Poppins",
  },
}))(Tooltip);

const TranslateYourString = ({ closePopup, getLastString, creditDetails, checkSubscription }) => {
  const dispatch = useDispatch();
  const auth = useSelector(tooledUpLocalData);
  const [TranslateCompleteM, setTranslateCompleteM] = useState(false);
  const classes = useStyles();
  const allLanguages = getLanguageList();
  const [sourceLang, setSourceLang] = useState({ name: "English", code: "en" });
  const [languageSearchValue, SetLanguageSearchValue] = useState("");
  const [selectedLanguageList, setSelectedLanguageList] = useState([]);
  const [languageForReportBug, setLanguageForReportBug] = useState([]);
  const [languageNotFoundError, setLanguageNotFoundError] = useState("");
  const [allTargetLanuages, setAllTargetLanuages] = useState([]);
  const [favLanguages, setFavLanguages] = useState([]);
  const [unmatchedWordInGlossary, setUnmatchedWordInGlossary] = useState([]);
  const [matchedWordInGlossary, setMatchedWordInGlossary] = useState([]);
  const [completeTranslation, setCompleteTranslation] = useState({
    translated_string: "",
    transalted_string_without_source: "",
    laststring: "",
    estimated_character: "",
    totalCount: "",
  });
  const [chatArray, setChatArray] = useState([]);
  const [strArrayLength, setStrArrayLength] = useState(0);
  const [input, setInput] = useState(null);
  const [chatResponse, setChatResponse] = useState(null);
  const [usageCount, setUsageCount] = useState(0);
  const [commaArray, setCommaArray] = useState(0);
  const [readyToSaves, setReadyToSaves] = useState(null);
  const [finalMatchData, setFinalMatchData] = useState(null);
  const [finalString, setFinalString] = useState("");
  const [charCount, setCharCount] = useState(0);
  const [selectedValue, setSelectedValue] = useState({
    code: "en",
    name: "English",
  });
  const trialData = useSelector(currentTrial);
  const creditData = useSelector(currentCredit);
  const [openUseCredit, setOpenUseCredit] = useState(false);

  useEffect(() => {
    let str = String(getLastString?.string_boolean).replace(/,/g, ``);
    setFinalString(str);
  }, [getLastString]);

  const replaceParenthesis = (word = "") => {
    return word.replace(/".*?"/g, (match, word) => {
      let str = String(match).replace(/\(.*?\)/g, "");
      return str;
    });
  };

  const removeDuplicate = (objectArr) => {
    let newArr = [];
    objectArr.forEach(({ word, gender }, ind1) => {
      let gen = [];
      gender.forEach(({ translate_male, translate_female }, ind2) => {
        translate_male = String(translate_male)
          .replace(/\(.*?\)/g, "")
          .trim();
        translate_female = String(translate_female)
          .replace(/\(.*?\)/g, "")
          .trim();

        let findMale = newArr.find((obj1) =>
          obj1.gender.find(
            (obj2, genInd) => String(obj2.translate_male).toLowerCase() === String(translate_male).toLowerCase()
          )
        );
        let findFemale = newArr.find((obj1) =>
          obj1.gender.find(
            (obj2, genInd) => String(obj2.translate_female).toLowerCase() === String(translate_female).toLowerCase()
          )
        );

        let male = "";
        let female = "";

        if (findMale === undefined) {
          let checkWordMale = objectArr.find(
            (obj1) => String(obj1.word).toLowerCase() === String(translate_male).toLowerCase()
          );
          if (checkWordMale === undefined) {
            male = translate_male;
          }
        }
        if (findFemale === undefined) {
          let checkWordFemale = objectArr.find(
            (obj1) => String(obj1.word).toLowerCase() === String(translate_female).toLowerCase()
          );
          if (checkWordFemale === undefined) {
            female = translate_female;
          }
        }
        gen.push({
          translate_male: male,
          translate_female: female,
        });
      });

      newArr.push({
        word: word,
        gender: gen,
      });
    });

    return newArr;
  };

  const printHtmlForTranslate = () => {
    if (finalMatchData !== null) {
      let str = finalString.toLowerCase();
      str = str.replace(/\s/g, " ");
      let str1 = [];
      let str2 = [];

      let totalCount = 0;

      let finalAr = [];
      let strArr = String(str).trim().split("");
      selectedLanguageList.forEach((obj) => {
        finalMatchData.forEach((obj1) => {
          let array = obj1[obj];
          if (array !== undefined) {
            array.forEach((obj3) => {
              let find = finalAr.findIndex((a) => a.word.toLowerCase() == obj3.word.toLowerCase());

              if (find !== -1) {
                finalAr[find].gender.push({
                  translate_male: obj3.translate_male,
                  translate_female: obj3.translate_female,
                });
              } else {
                finalAr.push({
                  word: obj3.word,
                  gender: [
                    {
                      translate_male: obj3.translate_male,
                      translate_female: obj3.translate_female,
                    },
                  ],
                });
              }
            });
          }
        });
      });

      let a = [];
      finalAr.forEach((obj, ind) => {
        let word = obj.word;
        let gender = obj.gender;

        let g = [];
        gender.forEach((obj1, ind1) => {
          let translateMale = obj1.translate_male;
          let translateFemale = obj1.translate_female;

          let newMale = obj1.translate_male;
          let newFemale = obj1.translate_female;

          masculineFeminine.forEach((masFem, index) => {
            let masculine = masFem.masculine;
            let feminine = masFem.feminine;

            let findMas = translateMale.indexOf(masculine);
            let findFem = translateFemale.indexOf(feminine);

            if (findMas !== -1) {
              newMale = translateMale.replace(masculine, "").replace("()", "").trim();
            }

            if (findFem !== -1) {
              newFemale = translateFemale.replace(feminine, "").replace("()", "").trim();
            }
          });

          newMale = newMale
            .replace(/\(m\)|\(e\)/g, "")
            .replace(/mâle/g, "")
            .trim();

          newFemale = newFemale
            .replace(/\(f\)|\(e\)/g, "")
            .replace(/femelle/g, "")
            .trim();

          let find = g.find((a) => String(a.translate_male).toLowerCase() == String(obj1.translate_male).toLowerCase());
          if (find == undefined) {
            g.push({
              translate_male: newMale,
              translate_female: newFemale,
            });
          }
        });

        let n = [];
        g.forEach((obj) => {
          if (String(obj.translate_male).toLowerCase().trim() == String(obj.translate_female).toLowerCase().trim()) {
            n.push({
              translate_male: obj.translate_male,
              translate_female: "",
            });
          } else {
            n.push(obj);
          }
        });

        a.push({
          word: word,
          gender: n,
        });
      });

      let finalStrOne = str;
      let finalStrTwo = str;

      a = removeDuplicate(a);

      a.forEach((obj, ind) => {
        let word = removeNameSpace(obj.word);

        let maleFemale = "";
        let removeSource = "";

        obj.gender.forEach((obj1, ind) => {
          let male = removeNameSpace(obj1.translate_male);
          let female = removeNameSpace(obj1.translate_female);

          if (word.toLowerCase() == male.toLowerCase()) {
            maleFemale += "";
            removeSource += "";
          } else if (male.toLowerCase() == female.toLowerCase()) {
            if (male && female) {
              maleFemale += ` OR "${male}"`;
              removeSource += `OR ${male}`;
            }
          } else {
            maleFemale += `${male ? ` OR "${male}"` : ""} ${female ? `OR "${female}"` : ""}`;
            removeSource += `${male ? ` OR "${male}"` : ""} ${female ? `OR "${female}"` : ""}`;
          }
        });

        let first = `"${word}" ${maleFemale}`;
        let second = removeSource.slice(3);

        if (maleFemale === "") {
          first = `"${word}"`;
          second = first;
        }

        finalStrOne = finalStrOne.toLowerCase().trim().replaceAll(`"${word.toLowerCase().trim()}"`, first);
        finalStrTwo = finalStrTwo.toLowerCase().trim().replaceAll(`"${word.toLowerCase().trim()}"`, second);

        totalCount += first.length;
      });

      let strOne = removeAllSpaces(replaceParenthesis(finalStrOne.replaceAll("(", "").replaceAll(")", "")));
      strOne += " AND ";

      let strTwo = removeAllSpaces(replaceParenthesis(finalStrTwo.replaceAll("(", "").replaceAll(")", "")));
      strTwo += " AND ";

      let newOne = "";
      let newTwo = "";

      strOne.replace(/.*?\s+(AND|NOT)\s+/g, (sentence, word) => {
        let findPara = String(sentence).split("");
        let find = findPara.find((a) => a === "(");
        if (find === undefined) {
          newOne += `(${String(sentence).slice(0, -5)}) ${word} `;
        } else {
          newOne += `${sentence} `;
        }
      });

      newOne = newOne.slice(0, -4);

      strTwo.replace(/.*?\s+(AND|NOT)\s+/g, (sentence, word) => {
        let findPara = String(sentence).split("");
        let find = findPara.find((a) => a === "(");
        if (find === undefined) {
          newTwo += `(${String(sentence).slice(0, -5)}) ${word} `;
        } else {
          newTwo += `${sentence} `;
        }
      });

      newTwo = newTwo.slice(0, -4);

      let convertHtml = convertIntoHtml(newOne);
      let convertHtml1 = convertIntoHtml(newTwo);

      return {
        withSourceLanguage: convertHtml,
        withoutSourceLanguage: convertHtml1,
        totalCount: totalCount,
      };
    } else {
      return {
        withSourceLanguage: "",
        withoutSourceLanguage: "",
        totalCount: 0,
      };
    }
  };

  useEffect(() => {
    if (matchedWordInGlossary.length) {
      let data = [...matchedWordInGlossary];
      selectedLanguageList.forEach((obj, ind) => {
        if (readyToSaves !== null) {
          let s = readyToSaves[obj];
          if (s.length) {
            s.forEach((obj1) => {
              data.forEach((obj2, ind) => {
                let a = data[ind][obj];
                if (a !== undefined) {
                  a.push(obj1);
                }
              });
            });
          }
        }
      });
      setFinalMatchData(data);
    }
  }, [matchedWordInGlossary, readyToSaves, selectedLanguageList]);

  const inputs = async () => {
    if (input !== null) {
      let target_language = input?.target_language;

      if (target_language.length === 1) {
        let targetLang = target_language[0];
        let get_mached_data = input?.glossary_data.filter((item) => Object.keys(item)[0] == target_language[0]);
        let get_unmached_data = input?.glossary_data_unmached.filter(
          (item) => Object.keys(item)[0] == target_language[0]
        );
        let uniqueGlossaryWord = get_unmached_data.length > 0 ? get_unmached_data[0][target_language[0]] : [];

        let converted_string = await convertStringForTranslate(input?.laststring);
        if (converted_string?.uniqueWord.length) {
          if (uniqueGlossaryWord.length) {
            let inputstr = {};
            inputstr.unique_words = uniqueGlossaryWord;
            inputstr.source_language = input?.source_language;
            inputstr.target_language = targetLang;
            inputstr.remove_gender = input?.remove_gender_checkbox;

            //CHAT GTP WILL IMPLEMENT HERE.

            let contents = uniqueGlossaryWord.length ? uniqueGlossaryWord.toString() : "";
            let sourceLang = input?.source_language;

            const source = allLanguages.find((a) => a.code == sourceLang);
            const target = allLanguages.find((a) => a.code == targetLang);

            let strComma = splitCharUsingComma(contents);
            setCommaArray(strComma.length);
            setStrArrayLength(strComma.length);
            strComma.forEach(async (obj, ind) => {
              try {
                let a = await globalRequest(
                  "post",
                  API_TRANSLATION.TRANSLATE_OPEN_AI_CALL,
                  {
                    translateText: obj,
                    translateFrom: source?.name,
                    translateTo: target?.name,
                    targetCode: target?.code,
                  },
                  {},
                  true
                );
                let data = a.data;
                let trim = data.data;
                let l = `[${trim}]`;
                let json = null;

                try {
                  json = JSON.parse(l);
                } catch (e) {
                  dispatch(
                    setSnackbar({
                      snackbarMessage: strings.automic,
                      snackbarOpen: true,
                      snackbarState: "error",
                    })
                  );
                  dispatch(changeLoader(false));
                  reportBugsApi({
                    user_id: auth?.user_id,
                    message: "openAi api error ",
                    type: 1,
                    error_message: "openAi api error ",
                    input_string: getLastString?.string_boolean,
                    output_language: languageForReportBug.toString(),
                  })
                    .then((res) => {})
                    .catch((err) => {});
                  return false;
                }
                setChatArray((chatArray) => [...chatArray, { lang: targetLang, ind: ind, data: json }]);
                setUsageCount(a.data?.usage?.total_tokens);
                setCharCount(String(contents).length);
              } catch (e) {
                dispatch(
                  setSnackbar({
                    snackbarMessage: strings.automic,
                    snackbarOpen: true,
                    snackbarState: "error",
                  })
                );
                dispatch(changeLoader(false));
                reportBugsApi({
                  user_id: auth?.user_id,
                  message: "openAi api error ",
                  type: 1,
                  error_message: "openAi api error ",
                  input_string: getLastString?.string_boolean,
                  output_language: languageForReportBug.toString(),
                })
                  .then((res) => {})
                  .catch((err) => {});
                return false;
              }
            });
          } else {
            let convert = printHtmlForTranslate();
            setChatResponse({
              status: 1,
              translated_string: convert.withSourceLanguage,
              transalted_string_without_source: convert.withoutSourceLanguage,
              ready_to_save: {},
              write_status: 0,
              totalCount: convert.totalCount,
            });
          }
        }
      } else {
        let convertedString = await convertStringForTranslate(input?.laststring);

        let inputData = {};
        inputData.uniqueWords = convertedString?.uniqueWord;
        inputData.source_language = input?.source_language;
        inputData.target_languages = target_language;
        inputData.glossary_data_unmached = input?.glossary_data_unmached;
        let sourceLang = input?.source_language;

        let number = 0;
        unmatchedWordInGlossary.forEach((obj, ind) => {
          selectedLanguageList.forEach((obj1) => {
            let l = obj[obj1];
            if (l !== undefined) {
              if (l.length) {
                number += l.length;
                let contents = l.toString();
                const source = allLanguages.find((a) => a.code == sourceLang);
                const target = allLanguages.find((a) => a.code == obj1);

                let strComma = splitCharUsingComma(contents);
                setCommaArray((count) => count + strComma.length);
                setStrArrayLength((strArrayLength) => strArrayLength + strComma.length);

                strComma.forEach(async (obj4, ind) => {
                  try {
                    let a = await globalRequest(
                      "post",
                      API_TRANSLATION.TRANSLATE_OPEN_AI_CALL,
                      {
                        translateText: obj4,
                        translateFrom: source?.name,
                        translateTo: target?.name,
                        targetCode: target?.code,
                      },
                      {},
                      true
                    );
                    let data = a.data;
                    let trim = data.data;
                    let l = `[${trim}]`;

                    let json = null;
                    try {
                      json = JSON.parse(l);
                    } catch (e) {
                      dispatch(
                        setSnackbar({
                          snackbarMessage: strings.automic,
                          snackbarOpen: true,
                          snackbarState: "error",
                        })
                      );
                      dispatch(changeLoader(false));
                      reportBugsApi({
                        user_id: auth?.user_id,
                        message: "openAi api error ",
                        type: 1,
                        error_message: "openAi api error ",
                        input_string: getLastString?.string_boolean,
                        output_language: languageForReportBug.toString(),
                      })
                        .then((res) => {})
                        .catch((err) => {});
                      return false;
                    }

                    let b = { lang: obj1, ind: ind, data: json };
                    setChatArray((chatMulti) => [...chatMulti, b]);
                    setUsageCount(parseInt(a.data?.usage?.total_tokens));

                    setCharCount(String(contents).length);
                  } catch (e) {
                    dispatch(
                      setSnackbar({
                        snackbarMessage: strings.automic,
                        snackbarOpen: true,
                        snackbarState: "error",
                      })
                    );
                    dispatch(changeLoader(false));
                    reportBugsApi({
                      user_id: auth?.user_id,
                      message: "openAi api error ",
                      type: 1,
                      error_message: "openAi api error ",
                      input_string: getLastString?.string_boolean,
                      output_language: languageForReportBug.toString(),
                    })
                      .then((res) => {})
                      .catch((err) => {});
                    return false;
                  }
                });
              }
            }
          });
        });

        if (number == 0) {
          let convert = printHtmlForTranslate();
          setChatResponse({
            status: 1,
            translated_string: convert.withSourceLanguage,
            transalted_string_without_source: convert.withoutSourceLanguage,
            ready_to_save: {},
            write_status: 0,
            totalCount: convert.totalCount,
          });
        }
      }
    }
  };

  useEffect(() => {
    inputs();
  }, [input]);

  const chatArrayFun = async () => {
    if (chatArray.length == commaArray && chatArray.length !== 0) {
      let readyToSave = {};
      selectedLanguageList.forEach((obj) => {
        readyToSave[obj] = [];
      });

      chatArray
        .sort((a, b) => (a.ind < b.ind ? -1 : 1))
        .forEach((obj) => {
          let datas = obj.data;
          try {
            for (let key1 in datas) {
              let data = datas[key1];
              for (let key in data) {
                let word = data[key];
                let male = "";
                let female = "";
                let word1 = word[0] ? word[0] : "";
                let word2 = word[1] ? word[1] : "";
                if (word1 === word2) {
                  male = word1;
                } else {
                  male = word1;
                  female = word2;
                }
                readyToSave[obj?.lang].push({
                  word: key,
                  translate_male: male,
                  translate_female: female,
                });
              }
            }
          } catch (e) {}
        });

      setReadyToSaves(readyToSave);
    }
  };

  useEffect(() => {
    chatArrayFun();
    (async () => {
      let da = await getCredit(auth?.user_id);
      dispatch(changeCredit(da));
    })();
  }, [chatArray, usageCount, strArrayLength]);

  const chatResponseFun = async () => {
    if (chatResponse !== null) {
      if (chatResponse?.status == 1) {
        setCompleteTranslation({
          translated_string: chatResponse?.translated_string,
          transalted_string_without_source: chatResponse?.transalted_string_without_source,
          laststring: finalString,
          totalCount: chatResponse?.totalCount,
        });

        if (chatResponse?.write_status == 1) {
          let inputforfile = {
            ready_to_save: chatResponse?.ready_to_save,
            auth: auth,
            source_language: sourceLang?.code,
            type: "",
          };
          await saveDataInGlossaryFile(inputforfile);
        }
        dispatch(changeLoader(false));
        setTranslateCompleteM(true);
        setSelectedLanguageList([]);
        setMatchedWordInGlossary([]);
        setUnmatchedWordInGlossary([]);
        setReadyToSaves(null);
      }
    }
  };

  useEffect(() => {
    chatResponseFun();
  }, [chatResponse]);

  useEffect(() => {
    if (readyToSaves !== null) {
      if (chatArray.length == commaArray && chatArray.length !== 0) {
        let convert = printHtmlForTranslate();
        setChatResponse({
          status: 1,
          translated_string: convert.withSourceLanguage,
          transalted_string_without_source: convert.withoutSourceLanguage,
          ready_to_save: readyToSaves,
          write_status: 1,
          usageCount: usageCount,
          charCount: charCount,
          totalCount: convert.totalCount,
        });
      }
    }
  }, [readyToSaves, chatArray, commaArray]);

  const selectTargetLanguage = async (e) => {
    let ischecked = e.target.checked;
    let lvalue = e.target.value;

    let langName = allLanguages.find((a) => a.code == lvalue);

    if (ischecked) {
      if (selectedLanguageList.length == 5) {
        dispatch(
          setSnackbar({
            snackbarMessage: strings?.max_language_error,
            snackbarOpen: true,
            snackbarState: "error",
          })
        );
        return false;
      }
      await loadGlossaryData(lvalue);
      setSelectedLanguageList((selectedLanguageList) => [...selectedLanguageList, lvalue]);
      setLanguageForReportBug((selectedLanguageList) => [...selectedLanguageList, langName?.name]);
    } else {
      setSelectedLanguageList((selectedLanguageList) => selectedLanguageList.filter((item) => item !== lvalue));
      setLanguageForReportBug((selectedLanguageList) => selectedLanguageList.filter((item) => item !== langName?.name));
    }
  };

  /**
   * load glossary data for specific language
   */
  const loadGlossaryData = async (target_language = "") => {
    let inputdata = {
      laststring: finalString,
      target_language: target_language,
      auth: auth,
      source_language: sourceLang?.code,
    };

    dispatch(changeLoader(true));
    let glossary_response = await glossaryDataGet(inputdata);

    if (glossary_response?.status == 0) {
      dispatch(
        setSnackbar({
          snackbarMessage: "failed to load file.",
          snackbarOpen: true,
          snackbarState: "error",
        })
      );
      dispatch(changeLoader(false));
      return false;
    }

    setUnmatchedWordInGlossary((unmatchedWordInGlossary) => [
      ...unmatchedWordInGlossary,
      { [target_language]: glossary_response?.unmatched_words },
    ]);

    setMatchedWordInGlossary((matchedWordInGlossary) => [
      ...matchedWordInGlossary,
      { [target_language]: glossary_response?.matched_words },
    ]);

    dispatch(changeLoader(false));
  };
  /**
   * Set & get default language by user id
   */
  const setDefaultLanguage = async (langcode = "", langname = "") => {
    dispatch(changeLoader(true));
    if (langname !== "") {
      setSourceLang({
        name: langname,
        code: langcode,
      });
    }
    try {
      const res = await globalRequest(
        "post",
        API_TRANSLATION.SAVE_GET_SOURCE_lANGUAGE,
        {
          user_id: auth?.user_id,
          type: langcode ? "edit" : "view",
          language_code: langcode,
          language_name: langname,
        },
        {},
        true
      );
      let data_sus = res?.data;
      if (data_sus?.status == 1) {
        if (data_sus?.data?.language_code != null) {
          //setSourceLang({ name: data_sus?.data?.language_name, code: data_sus?.data?.language_code });
          setSelectedLanguageList([]);
          setUnmatchedWordInGlossary([]);
          setMatchedWordInGlossary([]);
        }
      } else {
        dispatch(
          setSnackbar({
            snackbarMessage: data_sus?.message,
            snackbarOpen: true,
            snackbarState: "error",
          })
        );
      }
    } catch (e) {
      dispatch(
        setSnackbar({
          snackbarMessage: e?.message,
          snackbarOpen: true,
          snackbarState: "error",
        })
      );
    }
    dispatch(changeLoader(false));
  };

  const translateString = async () => {
    if (Object.keys(auth).length > 0 && finalString && getLastString?.id) {
      await checkSubscription();
      if (selectedLanguageList.length < 1) {
        dispatch(
          setSnackbar({
            snackbarMessage: strings?.atleast_one_language_error,
            snackbarOpen: true,
            snackbarState: "error",
          })
        );
        return false;
      }
      if (selectedLanguageList.indexOf(sourceLang?.code) > -1) {
        dispatch(
          setSnackbar({
            snackbarMessage: strings?.same_language_error,
            snackbarOpen: true,
            snackbarState: "error",
          })
        );
        return false;
      }

      dispatch(changeLoader(true));

      let input = {};
      input.laststring = finalString;
      input.source_language = sourceLang?.code;
      input.target_language = selectedLanguageList;
      input.glossary_data = matchedWordInGlossary;
      input.glossary_data_unmached = unmatchedWordInGlossary;

      setInput(input);
    } else {
      //location.reload();
    }
  };

  /**
   * Search value from list
   */
  useEffect(() => {
    let filteredLangs = languageSearchValue
      ? allLanguages.filter(
          (item) => item?.name.toLocaleLowerCase().indexOf(languageSearchValue.trim().toLocaleLowerCase()) > -1
        )
      : allLanguages;
    setAllTargetLanuages(filteredLangs);
    if (filteredLangs.length < 1) {
      setLanguageNotFoundError(strings?.no_language_found);
    } else {
      setLanguageNotFoundError("");
    }
  }, [languageSearchValue]);

  useEffect(() => {
    if (!languageSearchValue) {
      let fav = allLanguages.filter((item) => {
        if (favLanguages.includes(item?.code)) {
          return item;
        }
      });
      let unfav = allLanguages.filter((item) => {
        if (!favLanguages.includes(item?.code)) {
          return item;
        }
      });
      let filteredLangs = [...fav, ...unfav];
      setAllTargetLanuages(filteredLangs);
    }
  }, [favLanguages, languageSearchValue]);

  const loadFavLanguages = async () => {
    try {
      dispatch(changeLoader(true));
      let response = await globalRequest(
        "get",
        API_TRANSLATION.GET_FAVOURITE_lANGUAGE,
        {},
        {
          params: {
            user_id: auth?.user_id,
          },
        },
        true
      );
      if (response?.data?.status == 1) {
        if (typeof response?.data?.data?.favourite_lang !== "undefined") {
          setFavLanguages(response?.data?.data?.favourite_lang.split(","));
        }
      }
      dispatch(changeLoader(false));
    } catch (e) {}
  };
  /**
   * Save favorite languages
   */
  const saveFavLanguage = async (codeLang) => {
    try {
      dispatch(changeLoader(true));
      let languages = favLanguages;
      if (favLanguages.includes(codeLang)) {
        languages.splice(favLanguages.indexOf(codeLang), 1);
      } else {
        languages.push(codeLang);
      }
      let response = await globalRequest(
        "POST",
        API_TRANSLATION.SAVE_FAVOURITE_lANGUAGE,
        { user_id: auth?.user_id, language_code: languages.join(",") },
        {},
        true
      );
      await loadFavLanguages();
      if (response?.data?.status == 0) {
        dispatch(
          setSnackbar({
            snackbarMessage: strings?.try_again,
            snackbarOpen: true,
            snackbarState: "error",
          })
        );
        dispatch(changeLoader(false));
        return false;
      }
      dispatch(changeLoader(false));
    } catch (e) {}
  };

  useEffect(() => {
    loadFavLanguages();
  }, []);

  useEffect(() => {
    let a = addDeleteGetLocalStorage(STORAGE.STORE_SELECTED_LANG, {}, "get", "single");
    if (a !== null) {
      a = JSON.parse(a);
      setDefaultLanguage(a.code, a.name);
      setSelectedValue(a);
    }
  }, []);

  return (
    <>
      {TranslateCompleteM ? (
        <TranslationComplete
          closePopup={() => closePopup()}
          translatedData={completeTranslation}
          openTranslate={() => {
            setTranslateCompleteM(false);
            setFinalMatchData(null);
          }}
          selectedLanguage={languageForReportBug.toString()}
        />
      ) : (
        <div className="container">
          <div className="container-modal width1000" style={{ width: "750px", padding: "40px 30px 30px 30px" }}>
            <img src={closeModalIcon} alt="" className="close-new-modal" onClick={() => closePopup()} />
            <div className="popup-boo-container neutral">
              <img src={booHappy} width={120} height={120} />
            </div>
            <Scrollbar className="container-modal-mid">
              <Subtitle
                text={strings?.LetsTranslateYourString}
                color={Colors.nightPurple2}
                textAlign={"center"}
                padding={"0 0 30px 0"}
              />
              <div className="two-column-modal">
                <div className="tcm-left-block">
                  <div className="source-language-box" style={{ border: "none", padding: "unset" }}>
                    <Boxed display={"flex"} alignItems={"center"}>
                      <LargeBody text={strings?.SelectTheSource} color={Colors.nightPurple2} />
                      &nbsp;
                    </Boxed>
                    <div className="ks-drop-block source-drop-down">
                      <Autocomplete
                        options={allLanguages}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => {
                          if (value !== null) {
                            setDefaultLanguage(value.code, value.name);
                            setSelectedValue(value);
                            addDeleteGetLocalStorage(STORAGE.STORE_SELECTED_LANG, value, "add", "single");
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            style={{
                              fontSize: "19px",
                            }}
                          />
                        )}
                        value={selectedValue}
                      />
                    </div>
                    <LargeBody
                      text={
                        strings.forExampleIfTheKeywordsPhrasesInYourBooleanStringAreInGermanThenPleaseSelectGermanAsTheSourceLanguage
                      }
                      color={Colors.darkGray}
                      padding={"15px 0 15px 0"}
                    />
                    <LargeBody
                      text={strings.allOperatorsMUSTBeInEnglish}
                      color={Colors.darkGray}
                      padding={"15px 0 32px 0"}
                    />
                  </div>
                </div>
                <div className="search-language-box">
                  <Boxed display={"flex"} alignItems={"center"}>
                    <LargeBody text={strings?.SelectUpTo} color={Colors.nightPurple2} />
                    &nbsp;
                    <LargeBody text={"5"} color={Colors.nightPurple2} />
                    &nbsp;
                    <LargeBody text={strings?.languages} color={Colors.nightPurple2} />
                  </Boxed>
                  <div className="ks-text-search-holder px-0">
                    <InputWrapper>
                      <TextSearchfield
                        placeholder={strings?.start_typing_language}
                        backgroundColor={Colors.whiteGray}
                        onChange={(e) => {
                          SetLanguageSearchValue(e.target.value ? e.target.value.trimStart() : "");
                        }}
                        name="search"
                        id="search"
                        value={languageSearchValue}
                      />
                      <button className="searchbtn">
                        <img src={searchIcon} className="" alt="" />
                      </button>
                      {languageSearchValue.length > 0 ? (
                        <button
                          className="searchclosebtn"
                          onClick={() => {
                            SetLanguageSearchValue("");
                          }}
                        >
                          <img src={searchCloseIcon} className="" alt="" />
                        </button>
                      ) : (
                        ""
                      )}
                    </InputWrapper>
                  </div>
                  <Boxed display={"flex"} alignItems={"center"} justifyContent={"end"}>
                    <h6 className="language-select">{selectedLanguageList.length} selected</h6>
                  </Boxed>
                  <div
                    className="ks-folder-string-holder translation-fav-language-list ks-fsh-416"
                    style={{ overflow: "auto", maxHeight: "212px" }}
                  >
                    <FormGroup>
                      <span className="red_error">{languageNotFoundError} </span>
                      {allTargetLanuages.map((item, index) => {
                        return (
                          <>
                            <Table className="fav-tables">
                              <TableRow>
                                <TableCell className="f-cell">
                                  <LightTooltip
                                    key={"ind=" + index}
                                    className="fav-language"
                                    title={
                                      favLanguages.indexOf(item?.code) === -1
                                        ? strings?.addToFavourites
                                        : "Remove from favourites"
                                    }
                                    placement="bottom-start"
                                  >
                                    <a
                                      className="ks--related-actv-box"
                                      onClick={() => {
                                        saveFavLanguage(item?.code);
                                      }}
                                    >
                                      <img
                                        src={
                                          favLanguages.indexOf(item?.code) === -1 ? unfavouriteIcon : favoriteHeartIcon
                                        }
                                        className=""
                                        alt=""
                                      />
                                    </a>
                                  </LightTooltip>
                                </TableCell>
                                <TableCell className="s-cell">
                                  <FormControlLabel
                                    control={
                                      <>
                                        <Checkbox
                                          checked={selectedLanguageList.includes(item?.code) ? true : false}
                                          value={item?.code}
                                          onChange={(e) => {
                                            selectTargetLanguage(e);
                                          }}
                                        />
                                      </>
                                    }
                                    label={item?.name}
                                    value={item?.code}
                                    labelPlacement="start"
                                    key={index}
                                  />
                                </TableCell>
                              </TableRow>
                            </Table>
                          </>
                        );
                      })}
                    </FormGroup>
                  </div>
                </div>
              </div>
              <div className="">
                <MainRoundedButton
                  margin={"15px 0 0 0"}
                  className="border-radius5 dark-perple-btn-hover"
                  text={strings?.Translate}
                  onClick={() => {
                    if (trialData?.status === "trial") {
                      setOpenUseCredit(true);
                    } else {
                      translateString();
                    }
                  }}
                />
              </div>
            </Scrollbar>
          </div>
        </div>
      )}

      {openUseCredit ? (
        <UseCreditDialog
          closePopup={() => {
            setOpenUseCredit(false);
          }}
          type={"translations"}
          creditData={creditData}
          submit={() => {
            setOpenUseCredit(false);
            translateString();
          }}
        />
      ) : null}
    </>
  );
};

export default TranslateYourString;
