import {findIndexInArray} from './GlobalFunction';

export const convertStringForTranslate = async (str) => {
    let regExp = /"[^"]+"/g;
    let strArray = '';
    let allDoubleQouteStringIndex = [];
    let allDoubleQouteString = [];
    let singleWithoutDuplicate = [];
    while ((strArray = regExp.exec(str))) {
        strArray[0]=strArray[0].normalize().trim().toLowerCase().replaceAll(" ",' ').toLowerCase();
        allDoubleQouteStringIndex.push([strArray.index, strArray.index + strArray[0].length])
        allDoubleQouteString.push(strArray[0]);
        if(findIndexInArray(singleWithoutDuplicate,strArray[0].replaceAll('"', ""))==-1){
            singleWithoutDuplicate.push(strArray[0].replaceAll('"', "").trim());
        }
    }
    return {
        'allWordIndex':allDoubleQouteStringIndex,
        'uniqueWord':singleWithoutDuplicate,
        'allWord':allDoubleQouteString,
    }
}



export const getCounterWithGlossary = async (words,glossaryData,languages) => {
   // let lngs=languages[0];
    let gData=[];
    glossaryData.map((data) => {
        gData.push(data?.termsSet);
    })
    let counts=0;
    var words = words.map(v => v.toLowerCase());
    languages.map((lngs) => {
            gData.map((data) => {
                let terms=data?.terms;
                if(terms.length>0){
                    if(words!=terms[0]?.text.toLowerCase()){
                        counts +=0;
                    }else{
                        terms.map((term) => {
                            if(term?.languageCode==lngs){  
                                counts +=terms[0]?.text.length;
                            }
                        })
                    }
                }
            }) 
    }) 
    return counts; 
}



// export const countWordForTranslation = async (str,totalTargetLang,glossaryData,languages) => {
//     totalTargetLang=totalTargetLang==0?1:totalTargetLang;
//     let getString = await convertStringForTranslate(str);
//     let totalchar=0;
//     let allWord=0;
//     if (getString.uniqueWord.length > 0) {
//             let countsSubtract=await getCounterWithGlossary(getString.uniqueWord,glossaryData,languages);
//             totalchar=(getString.uniqueWord.join(',').length-(getString.uniqueWord.length-1));
//             allWord=(getString.allWord.join(',').replaceAll('"','').length-(getString.allWord.length-1)); 
//             let countsSubtracts=(totalchar*totalTargetLang)-countsSubtract;
//             return {
//                 estimate_characters:totalchar,
//                 estimate_credits:(countsSubtracts<0?0:countsSubtracts)
//             }
//     } else {
//         return {
//             estimate_characters:0,
//             estimate_credits:0
//         }
//     }
// }

