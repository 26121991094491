const librarySearch = (searchValue, datas = []) => {
  return new Promise(async (resolve, reject) => {
    let condition = new RegExp(String(searchValue).toLowerCase(), "g");
    let newArray = [];

    let a = datas;
    a.forEach((obj) => {
      let strings = [];
      obj.string.forEach((e) => {
        let strs = String(e.string_name).toLowerCase();
        if (strs.match(condition)) {
          strings.push(e);
        }

        let stringBoolean = String(e.string_boolean).replace(/\s/g, " ").toLowerCase();
        if (stringBoolean.match(condition)) {
          let find = strings.find((a) => a.string_name === e.string_name);
          if (find === undefined) {
            strings.push(e);
          }
        }
      });

      let folderStringData = [];

      obj.folder.forEach((obj) => {
        let stringData = [];
        obj.string_data.forEach((e) => {
          let strs = String(e.string_name).toLowerCase();
          if (strs.match(condition)) {
            stringData.push(e);
          }

          let stringBoolean = String(e.string_boolean).replace(/\s/g, " ").toLowerCase();
          if (stringBoolean.match(condition)) {
            let find = stringData.find((a) => a.string_name === e.string_name);
            if (find === undefined) {
              stringData.push(e);
            }
          }
        });

        if (stringData.length) {
          folderStringData.push({
            ...obj,
            string_data: stringData,
          });
          return;
        }
      });

      let allFolder = [];

      folderStringData.forEach((fol) => {
        let find = allFolder.find((a) => a.folder_name == fol.folder_name);
        if (!find) {
          allFolder.push(fol);
          return;
        }
      });

      newArray.push({
        ...obj,
        string: strings,
        folder: allFolder,
      });
    });
    resolve(newArray);
  });
};

export default librarySearch;
