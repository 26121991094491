// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  // eslint-disable-next-line no-restricted-globals
  self.onmessage = (event) => {
    let { string } = event?.data;
    const removeApartFromAndOrNot = (str) => {
      const regex =
        /("\s+and\w+\s+)|("\s+not\w+\s+)|("\s+or\w+\s+)|(\)\s+and\w+\s+)|(\)\s+not\w+\s+)|(\)\s+or\w+\s+)|(\s+and\w+)|(\s+not\w+)|(\s+or\w+)|("\s+\w+and\s+)|("\s+\w+not\s+)|("\s+\w+or\s+)|(\)\s+\w+and\s+)|(\)\s+\w+not\s+)|(\)\s+\w+or\s+)|(\s+\w+and)|(\s+\w+not)|(\s+\w+or)|(or\w+)|(and\w+)|(not\w+)/g;
      let match;
      let index = [];
      while ((match = regex.exec(str.toLowerCase())) !== null) {
        let wordLength = String(match[0]).length;
        index.push({
          start: match.index,
          end: match.index + wordLength,
        });
      }
      return index;
    };

    postMessage({
      removeApartFromAndOrNot: removeApartFromAndOrNot(string),
    });
  };
};
