import * as Colors from "../Styles-Elements/Colors";
import { BorderedRoundedButton } from "../Styles-Elements/Buttons";
import { LargeBody } from "../Styles-Elements/Labels";
import powerIcon from "../image-assets/power.png";
import strings from "../localisation_en.json";
import { useSelector } from "react-redux";
import { tooledUpLocalData } from "../redux/reducers/getTooledupData";

const AiUpgradeTooltip = ({ onUpgrade }) => {
  const auth = useSelector(tooledUpLocalData);
  return (
    <div className="upgrade-tooltip">
      {auth?.access === "owner" ? (
        <>
          <LargeBody
            textAlign={"center"}
            text={strings.upgradeToUseFeature}
            color={Colors.nightGray}
            padding={"0 0 0px 0"}
          />
          <BorderedRoundedButton
            text={strings.upgrade}
            onClick={onUpgrade}
            img={<img src={powerIcon} style={{ marginLeft: "16px" }} alt="" />}
            color={Colors.mainLight}
            className={"inh-btn"}
            width={"150px"}
            margin={"16px 0 0 0"}
          />
        </>
      ) : (
        <LargeBody
          textAlign={"center"}
          text={strings.contactYourAdminToUpgradeAndUseThisFeature}
          color={Colors.nightGray}
          padding={"0 0 0px 0"}
        />
      )}
    </div>
  );
};

export default AiUpgradeTooltip;
