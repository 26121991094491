import { createSlice } from '@reduxjs/toolkit'
import addDeleteGetLocalStorage from '../../GlobalModule/addDeleteGetLocalStorage'
import { STORAGE } from '../../Helpers/Enums'


const getFromLocal = () => {
    let stringId = addDeleteGetLocalStorage(STORAGE.STRING_ID, {}, "get", "single");
    return stringId
}

export const stringId = createSlice({
    name: 'stringId',
    initialState: {
        value: getFromLocal(),
    },
    reducers: {
        changeStringId: (state, action) => {
            state.value = getFromLocal()
        },
    },
})

// Action creators are generated for each case reducer function
export const { changeStringId } = stringId.actions
export const currentStringId = (state) => state.stringId.value;
export default stringId.reducer
