import React, { useState, useEffect } from "react";
import * as Colors from "../../Styles-Elements/Colors";
import { Body, LargeSmallSemiBold, LargeSmall } from "../../Styles-Elements/Labels";
import "./KeywordStrings.css";
import threeDottesIcon from "../../image-assets/three-dottes-icon.svg";
import folderIcon from "../../image-assets/folder-icon.svg";
import dropArrowIcon from "../../image-assets/drop-arrow-icon.svg";
import { Box } from "@material-ui/core";
import { Boxed } from "../../Styles-Elements/Box";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Collapse from "@material-ui/core/Collapse";
import stringIcon from "../../image-assets/string-icons.svg";
import ownerIcon from "../../image-assets/owner-icon.svg";
import membersIcon from "../../image-assets/members-icon.svg";
import editAccessIcon from "../../image-assets/edit-access-icon.svg";
import Checkbox from "@material-ui/core/Checkbox";
import Strings from "./Strings";
import RenameFolder from "../../dialogs/RenameFolder";
import DeleteFolderContent from "../../dialogs/DeleteFolderContent";
import LetsCollaborate from "../../dialogs/LetsCollaborate";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import addDeleteGetLocalStorage from "../../GlobalModule/addDeleteGetLocalStorage";
import ChangeStringOwner from "../../dialogs/ChangeStringOwner";
import { useDispatch, useSelector } from "react-redux";
import { tooledUpLocalData } from "../../redux/reducers/getTooledupData";
import { setSnackbar, snackObj } from "../../redux/reducers/snackbar";
import { NEW_API, STORAGE } from "../../Helpers/Enums";
import { currentSidebar } from "../../redux/reducers/sidebar";
import { setChange } from "../../redux/reducers/getLocalData";
import globalRequest from "../../GlobalModule/globalRequest";

const ShowingFolder = ({
  dataObj,
  props,
  callback,
  getFolderDataCallback,
  type = "myString", //favorite,shareWithMe,myString
  isCheckboxChecked = false,
  enableCheckbox = true,
  currentActiveString = {},
  rsbClicked = "",
  callbackFolderData,
  handleDropDown, //callback
  isEditChecked = false,
  dropDownChecked = false,
  checkboxCallback, //callback
  userInfoForChangeOwner,
  isEditCheckedString = false,
  enableCheckboxString = true,
  checkboxCallbackString, //callback
  stringAllSelection,
  typingStatus = "",
  closeMenu = false,
  folderId, //callback
  autoRichData = [],
}) => {
  const auth = useSelector(tooledUpLocalData);
  const snackbarObj = useSelector(snackObj);
  const dispatch = useDispatch();
  const typeParam = useSelector(currentSidebar);

  const [folderString, setFolderString] = useState([]);
  const strings = require("../../localisation_en.json");
  const [isCheckedOne, setIsCheckedOne] = React.useState(false);
  const [renameOpen, setRenameOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [collaborateOpen, setCollaborateOpen] = useState(false);
  const label = { inputProps: { "aria-label": `Checkbox demo ${dataObj.id}` } };
  const [checkStatus, setCheckStatus] = useState(false);
  const [checkStatusString, setCheckStatusString] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [dropDownClick, setDropDownClick] = useState(null);
  const [dropDownShow, setDropDownShow] = useState(false);
  const [openCollab, setOpenCollab] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openRename, setOpenRename] = useState(false);
  const [arrow, setArrow] = useState("");
  const [isChangeOwner, setIsChangeOwner] = useState(false);
  const [changeOwnerModal, setChangeOwnerModal] = useState(false);

  const [alreadyRun, setAlreadyRun] = useState(false);
  const [hoverActive, setHoverActive] = useState(false);
  const [folderDetail, setFolderDetail] = useState({});

  useEffect(() => {
    if (closeMenu) {
      setOpen(false);
    }
  }, [closeMenu]);

  const callDetails = async () => {
    if (!alreadyRun) {
      setAlreadyRun(true);
      const response = await globalRequest(
        "get",
        NEW_API.GET_DETAILS,
        {},
        {
          params: {
            string_id: dataObj.id, //folder id
            user_id: auth?.user_id,
            type: "folder",
          },
        },
        true
      );

      let data = response.data;

      if (data.status == 1) {
        setHoverActive(true);
        setFolderDetail(data?.data);
      }
    }
  };

  useEffect(() => {
    if (dropDownChecked) {
      setIsCheckedOne(true);
      setArrow("");
    }
  }, [dropDownChecked]);

  useEffect(() => {
    setDropDownShow(true);

    if (type == "shareWithMe" || type == "shareWithMe-1") {
      if (auth?.access == "admin" || auth?.access == "owner") {
        setOpenCollab(true);
        setOpenRename(true);
      } else {
        setDropDownShow(false);
      }
    } else {
      setOpenCollab(true);
      setOpenRename(true);
      setOpenDelete(true);
    }

    if (type == "shareWithMe-1") {
      setIsChangeOwner(true);
    }
  }, []);

  useEffect(() => {
    setCheckStatus(isCheckboxChecked);
  }, [isCheckboxChecked]);

  useEffect(() => {
    setFolderString(dataObj?.string_data);
  }, [dataObj]);

  const checkBoxAction = (e) => {
    setCheckStatus(e.target.checked ? true : false);
    checkboxCallback(dataObj, e);
  };

  const rename = () => {
    setRenameOpen(true);
  };

  const deleteFolder = () => {
    setDeleteOpen(true);
  };

  const collaborate = () => {
    setCollaborateOpen(true);
  };

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  useEffect(() => {
    setRenameOpen(false);
    setCollaborateOpen(false);
    setDeleteOpen(false);
  }, [snackbarObj?.snackbarOpen]);

  const styles = {
    width: "180px",
    position: "absolute",
    top: 28,
    right: 0,
    left: "unset",
    zIndex: 10,
    border: "1px solid #ddd",
    p: "4px 0",
    bgcolor: "#fff",
    boxShadow: "0 0.2rem 0.4rem rgb(0 0 0 / 10%)",
    borderRadius: 4,
  };

  const openDropDown = (e) => {
    setIsCheckedOne((prev) => !prev);
  };

  useEffect(() => {
    if (isCheckedOne) {
      setArrow("");
    } else {
      setArrow("aic-ai");
    }
  }, [isCheckedOne]);

  useEffect(() => {
    let side = typeParam;
    if (isCheckedOne) {
      addDeleteGetLocalStorage(STORAGE.FOLDER_DROPDOWN, { id: dataObj.id, type: side }, "add");
      dispatch(setChange());
    } else {
      addDeleteGetLocalStorage(STORAGE.FOLDER_DROPDOWN, { id: dataObj.id, type: side }, "remove");
      dispatch(setChange());
    }
  }, [isCheckedOne]);

  const Folder = () => {
    return (
      <>
        <Boxed
          display={"flex"}
          alignItems={"center"}
          onClick={() => {
            callbackFolderData(dataObj);
            handleDropDown();
          }}
          onMouseOver={callDetails}
          justifyContent={"space-between"}
          className="folder-main-holder"
          onDoubleClick={openDropDown}
        >
          <Boxed display={"flex"} alignItems={"center"} justifyContent={"flex-start"} className="arrow-icon-box">
            <Button className={`arrow-icon ai-clicked ${arrow}`} onClick={openDropDown}>
              <img src={dropArrowIcon} alt="" />
            </Button>
          </Boxed>

          <Boxed
            display={"flex"}
            flex={"auto"}
            alignItems={"flex-start"}
            justifyContent={"space-between"}
            className={`right-side-box ${rsbClicked}`}
          >
            <Boxed display={"flex"} alignItems={"center"} className="folder-box">
              <img src={folderIcon} alt="" />
              <Body
                className={"text-truncate width135"}
                text={dataObj.folder_name}
                color={Colors.nightPurple}
                padding={"0px 0px 0px 8px"}
              />
            </Boxed>
            <Boxed className="three-dotts-inmystr-holder">
              {dropDownShow ? (
                <>
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <Boxed style={{ position: "relative" }}>
                      <button className="three-dotts-inmystr-box" id="demo2" type="button" onClick={handleClick}>
                        <img src={threeDottesIcon} alt="" />
                      </button>
                      {open ? (
                        <Box sx={styles} className="three-dotts-inmystr-box-open" id="demo1">
                          {isChangeOwner ? (
                            <>
                              <MenuItem display={"flex"} alignItems={"center"}>
                                <Body
                                  onClick={() => {
                                    setChangeOwnerModal(true);
                                  }}
                                  text={strings.changeOwner}
                                  color={Colors.nightPurple}
                                  padding={"0px 0px 0px 8px"}
                                />
                              </MenuItem>
                            </>
                          ) : null}

                          {openRename ? (
                            <>
                              <MenuItem display={"flex"} alignItems={"center"}>
                                <Body
                                  onClick={rename}
                                  text={strings.rename}
                                  color={Colors.nightPurple}
                                  padding={"0px 0px 0px 8px"}
                                />
                              </MenuItem>
                            </>
                          ) : null}

                          {openCollab ? (
                            <>
                              <MenuItem display={"flex"} alignItems={"center"}>
                                <Body
                                  onClick={collaborate}
                                  text={strings.collaborate}
                                  color={Colors.nightPurple}
                                  padding={"0px 0px 0px 8px"}
                                />
                              </MenuItem>
                            </>
                          ) : null}

                          {openDelete ? (
                            <>
                              <MenuItem display={"flex"} alignItems={"center"}>
                                <Body
                                  onClick={deleteFolder}
                                  text={strings.delete}
                                  color={Colors.nightPurple}
                                  padding={"0px 0px 0px 8px"}
                                />
                              </MenuItem>
                            </>
                          ) : null}
                        </Box>
                      ) : null}
                    </Boxed>
                  </ClickAwayListener>
                </>
              ) : null}
            </Boxed>
            {hoverActive ? (
              <div className={`hovermenu`}>
                <MenuItem display={"flex"} alignItems={"center"}>
                  <img src={stringIcon} alt="" />
                  <LargeSmallSemiBold
                    // text={folderDetail?.folder_name}
                    text={
                      String(folderDetail?.folder_name).length >= 16
                        ? String(folderDetail?.folder_name).substring(0, 15) + "..."
                        : folderDetail?.folder_name
                    }
                    title={folderDetail?.folder_name}
                    color={Colors.nightGray}
                    padding={"0px 0px 0px 8px"}
                  />
                </MenuItem>
                <MenuItem display={"flex"} alignItems={"center"}>
                  <img src={ownerIcon} alt="" />
                  <LargeSmall
                    // text={`Owner: ${folderDetail?.owner}`}
                    text={`Owner: ${
                      String(folderDetail?.owner).length >= 10
                        ? `${String(folderDetail?.owner).substring(0, 9)}....`
                        : `${folderDetail?.owner}`
                    }`}
                    color={Colors.nightGray}
                    padding={"0px 0px 0px 8px"}
                    title={String(folderDetail?.owner)}
                  />
                </MenuItem>
                <MenuItem display={"flex"} alignItems={"center"}>
                  <img src={membersIcon} alt="" />
                  <LargeSmall
                    text={`${folderDetail?.total_share} other members`}
                    color={Colors.nightGray}
                    padding={"0px 0px 0px 8px"}
                  />
                </MenuItem>
                <MenuItem display={"flex"} alignItems={"center"}>
                  <img src={editAccessIcon} alt="" />
                  <LargeSmall
                    text={folderDetail?.permission == 0 ? "Edit Access " : "View Access"}
                    color={Colors.nightGray}
                    padding={"0px 0px 0px 10px"}
                  />
                </MenuItem>
              </div>
            ) : null}
          </Boxed>

          {type == "shareWithMe" || type == "shareWithMe-1" ? (
            isEditChecked ? (
              <>
                <Boxed className="" marin={"0 0 0 8px"}>
                  <Checkbox checked={true} disabled {...label} />
                </Boxed>
              </>
            ) : null
          ) : null}

          {type == "myString" ? (
            isEditChecked ? (
              enableCheckbox ? (
                <>
                  <Boxed className="" margin={"0 0 0 8px"}>
                    <Checkbox checked={checkStatus} onChange={(e) => checkBoxAction(e)} {...label} />
                  </Boxed>
                </>
              ) : (
                <>
                  <Boxed className="" margin={"0 0 0 8px"}>
                    <Checkbox checked={true} disabled {...label} />
                  </Boxed>
                </>
              )
            ) : null
          ) : null}
        </Boxed>
        <Boxed>
          <Collapse in={isCheckedOne}>
            {folderString.length ? (
              folderString.map((obj, ind) => {
                let isChecked = false;

                stringAllSelection.forEach((obj1) => {
                  if (obj?.id == obj1?.id) {
                    isChecked = true;
                  }
                });

                let checkDataAvailable = false;

                let findData = autoRichData.find((a) => a.string_id == obj.id);
                if (findData !== undefined) {
                  checkDataAvailable = findData?.isDataAvailable;
                }

                return (
                  <Strings
                    isAutoRichDataAvailable={checkDataAvailable}
                    key={ind}
                    dataObj={obj}
                    props={props}
                    callback={(data) => {
                      if (typingStatus == "release") {
                        callback(data);
                      } else {
                        dispatch(
                          setSnackbar({
                            snackbarMessage: strings.YouWereTooFast,
                            snackbarOpen: true,
                            snackbarState: "error",
                          })
                        );
                      }
                    }}
                    callGetFun={(bool, type) => {
                      getFolderDataCallback(true, type);
                    }}
                    type={type}
                    smClicked={currentActiveString?.id == obj.id ? "sm-clicked" : ""}
                    handleDropDown={handleDropDown}
                    userInfoForChangeOwner={userInfoForChangeOwner}
                    isCheckboxChecked={checkStatus ? true : isChecked}
                    checkboxCallback={checkboxCallbackString}
                    isEditChecked={isEditCheckedString}
                    enableCheckbox={enableCheckboxString}
                    currentActiveString={currentActiveString}
                    folderId={folderId}
                  />
                );
              })
            ) : (
              <Strings key={0} dataObj={{ id: 0, string_name: "No String Found " }} props={() => {}} />
            )}
          </Collapse>
        </Boxed>

        {changeOwnerModal ? (
          <ChangeStringOwner
            closePopup={() => {
              setChangeOwnerModal(false);
            }}
            userId={auth?.user_id}
            stringFolderData={dataObj}
            type={"folder"}
            role={auth?.access}
            callback={() => {
              getFolderDataCallback(true, "changeOwner");
            }}
            userInfoForChangeOwner={userInfoForChangeOwner}
          />
        ) : null}

        {renameOpen ? (
          <RenameFolder
            closePopup={() => setRenameOpen(false)}
            folderName={dataObj.folder_name}
            id={dataObj.id}
            callback={() => {
              getFolderDataCallback(true, "renameFolder");
            }}
          />
        ) : null}

        {deleteOpen ? (
          <DeleteFolderContent
            closePopup={() => setDeleteOpen(false)}
            folderName={dataObj.folder_name}
            id={dataObj.id}
            successCallback={(bool) => {
              if (bool) {
                getFolderDataCallback(true, "deleteFolder");
              }
            }}
            countFolderCollaborator={dataObj?.total_share}
          />
        ) : null}
        {collaborateOpen ? (
          <LetsCollaborate
            closePopup={(param) => {
              setCollaborateOpen(false);
              if (param == "success") {
                getFolderDataCallback(true, "dontChange");
              }
            }}
            folderOrStringName={dataObj.folder_name}
            folderOrStringId={dataObj.id}
            type={"folder"}
          />
        ) : null}
      </>
    );
  };

  return (
    <>
      <Folder />
    </>
  );
};

export default ShowingFolder;
