// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  // eslint-disable-next-line no-restricted-globals
  self.onmessage = (event) => {
    let { string } = event?.data;

    if (string) {
      const getOpenClose = (str) => {
        let O = [],
          isQuo = false;
        let C = [];

        for (let i = 0; i < str.length; i++) {
          if (str.charAt(i) === "(" && i === 0) O.push(i);
          if (str.charAt(i) === ")" && i === 0) C.push(i);

          if (str.charAt(i) === '"' && i === 0) isQuo = true;

          if (i > 0) {
            if (str.charAt(i) === '"') isQuo = !isQuo;

            if (!isQuo) {
              if (str.charAt(i) === "(") {
                O.push(i);
              } else if (str.charAt(i) === ")") {
                C.push(i);
                if (O.length > 1) {
                  C.pop();
                  O.pop();
                } else if (O.length === 1) {
                  C.pop();
                  O.pop();
                }
              }
            }
          }
        }
        return { O, C };
      };

      const isEvenDoubleQuotes = (index, string) => {
        let dq = 0;
        let subString = string.substring(0, index);
        dq = (subString.match(/"/g) || "").length;
        if (dq % 2 === 1) {
          return false;
        }
        return true;
      };

      const hasWhiteSpace = (s) => {
        return /\s/.test(s);
      };

      const duplicateOperators = (str) => {
        let regx =
          /(andand)|(and\s+and)|(notnot)|(not\s+not)|(oror)|(or\s+or)|(andnot)|(and\s+not)|(andor)|(and\s+or)|(andnot)|(and\s+not)|(notor)|(not\s+or)|(notand)|(not\s+and)|(ornot)|(or\s+not)|(orand)|(or\s+and)/g;
        let match;
        let index = [];
        while ((match = regx.exec(str.toLowerCase())) != null) {
          let s = match[0];

          if (isEvenDoubleQuotes(match.index, str)) {
            index.push({
              start: match.index,
              end: match.index + s.length,
            });
          }
        }

        return index;
      };

      const checkInitialAndEndAndNotOr = (str) => {
        let includeAndOr = ["and", "or"];
        let includeAndOrNot = ["not", "or", "and"];
        let string = String(str).substring(0, 3);
        let newS = str.trim();
        let string1 = newS.length > 4 ? String(newS).substring(newS.length - 3, newS.length) : "";

        let a = [];

        includeAndOr.forEach((obj, ind) => {
          if (obj === string.trim()) {
            a.push({
              type: obj,
              start: 0,
              end: obj == "and" ? 3 : 2,
              at: "start",
            });
          }
        });

        includeAndOrNot.forEach((obj, ind) => {
          if (obj === string1.trim()) {
            a.push({
              type: obj,
              start: obj === "and" || obj === "not" ? str.trim().length - 4 : str.trim().length - 3,
              end: str.length,
              at: "end",
            });
          }
        });

        return a;
      };

      const missingOperator = (str) => {
        str = str.toLowerCase();
        let rx = /"(.*?)"/g;
        let result;
        let allQouteindexs = [];
        let allQouteLastindexs = [];
        while ((result = rx.exec(str)) !== null) {
          allQouteindexs.push(result.index);
          allQouteLastindexs.push(Number(result.index) + result[0].length);
        }
        /**
         * Check Missing Opetator
         */
        let i = 0;
        let newStr;
        let totalChar;
        let startIndex;
        let allOp = ["and", "or", "not"];
        let AllData = [];
        let prevIn = 0;
        let chatAtLastIndex = 0;
        const openBrackets = ["(", "{", "[", ")"];
        while ((result = rx.exec(str)) !== null) {
          if (i < allQouteindexs.length - 1) {
            startIndex = allQouteindexs[i] + result[0].length;
            totalChar = allQouteindexs[i + 1] - startIndex;
            newStr = str.substr(allQouteindexs[i] + result[0].length, totalChar);
            newStr = newStr.replace(/[()]/g, "").trim();
            if (allOp.includes(newStr) == false) {
              prevIn = result.index;
              chatAtLastIndex = Number(allQouteLastindexs[i + 1]);
              if (prevIn > 0) {
                let bracketFirstExist = 0;
                let bracketSecoundExist = 0;
                if (openBrackets.includes(str.charAt(prevIn - 1))) {
                  prevIn = prevIn - 1;
                  bracketFirstExist = 1;
                }
                if (openBrackets.includes(str.charAt(chatAtLastIndex))) {
                  chatAtLastIndex = chatAtLastIndex;
                  bracketSecoundExist = 1;
                }
                if (bracketSecoundExist !== bracketFirstExist && bracketFirstExist == 1) {
                  prevIn = prevIn + 1;
                }
              }
              AllData.push([prevIn, chatAtLastIndex]);
            }
          }
          i++;
        }
        return AllData;
      };

      const ifOnlyQuotes = (str) => {
        let regx = /("")/g;
        let match;

        let index = [];
        while ((match = regx.exec(str)) !== null) {
          index.push({
            start: match.index,
            end: match.index + 2,
          });
        }

        return index;
      };

      const findOpeningAndClosing = (str) => {
        const getQuotes = (str) => {
          let quote = {
            count: 0,
            index: [],
          };

          for (let i = 0; i < str.length; i++) {
            if (str.charAt(i) === '"') {
              quote = {
                ...quote,
                count: quote.count + 1,
                index: [...quote.index, i],
              };
            }
          }
          return { quote };
        };

        let quotes = "";
        let quotesCount = 0;

        if (str.indexOf('"') > -1) {
          let data = getQuotes(str);
          quotes = data.quote.index;
          quotesCount = data.quote.count;
        }

        if ((str.indexOf("(") > -1 || str.indexOf(")") > -1) && (quotes.length === 0 || quotes.length % 2 === 0)) {
          let obj = getOpenClose(str);

          return {
            opening: obj.O,
            closing: obj.C,
          };
        } else {
          return {
            opening: [],
            closing: [],
          };
        }
      };

      const missingQuotationMark = (str) => {
        let str1 = String(str + " AND ");
        let strArr = [...str1];
        let length = strArr.length;

        let newString = "";
        let indexArr = [];
        let finalA = [];

        let a = [];

        if (length) {
          strArr.forEach((char, index) => {
            let a = strArr[index] ? strArr[index] : null;
            let b = strArr[index + 1] ? strArr[index + 1] : null;
            let c = strArr[index + 2] ? strArr[index + 2] : null;
            let d = strArr[index + 3] ? strArr[index + 3] : null;
            let e = strArr[index + 4] ? strArr[index + 4] : null;

            let conditionAnd = hasWhiteSpace(a) && b == "A" && c == "N" && d == "D";
            let conditionNot = hasWhiteSpace(a) && b == "N" && c == "O" && d == "T";
            let conditionOr = hasWhiteSpace(a) && b == "O" && c == "R";

            if (conditionAnd || conditionNot || conditionOr) {
              finalA.push({
                string: newString,
                index: indexArr,
              });
              newString = "";
              indexArr = [];
            } else {
              newString += strArr[index];
              indexArr.push(index);
            }
          });
        }

        if (finalA.length) {
          for (let j = 0; j < finalA.length; j++) {
            let index = finalA[j].index;

            if (index.length) {
              let quotes = 0;
              let ind = 0;
              let firstIndex = 0;
              index.forEach((key, ins) => {
                let a = strArr[key];
                if (ins == 0) {
                  firstIndex = key;
                }

                if (index.length - 1 == ins) {
                  ind = key;
                }

                if (a == '"') {
                  quotes += 1;
                }

                if (quotes % 2 == 0) {
                  quotes = 2;
                }
              });

              if (quotes != 2) {
                a.push({
                  start: firstIndex,
                  end: ind,
                });

                break;
              }
            }
          }
        }

        return a;
      };

      const ifThereIsNoQuotation = (str) => {
        let regEx = /.*?\s+(AND|OR|NOT)/g;
        let text = String(str + " AND");

        let match;
        let final = [];
        while ((match = regEx.exec(text)) != null) {
          let str = String(match[0]);
          let indOf = str.indexOf('"');
          if (indOf == -1) {
            final.push({
              start: match.index,
              end: match.index + str.length,
            });
          }
        }
        return final;
      };

      const errors = (str1) => {
        let str = String(str1).toLowerCase();
        let duplicate = duplicateOperators(str);
        let checkInitial = checkInitialAndEndAndNotOr(str);
        const missingOperate = missingOperator(str);
        const onlyQuotes = ifOnlyQuotes(str);
        const openingClosingPara = findOpeningAndClosing(str);
        const missingQuotation = missingQuotationMark(str1); //taking str1 for capital letter purpose
        const noQuotes = ifThereIsNoQuotation(str1);

        return {
          duplicateOperator: duplicate, //return array
          checkInitial: checkInitial, //return array
          missingOperator: missingOperate, //return array
          ifOnlyQuotes: onlyQuotes, //return array
          openingClosingPara: openingClosingPara, //return array
          missingQuotation: missingQuotation, //return array
          noQuotes: noQuotes,
        };
      };

      postMessage(errors(string));
    }
  };
};
