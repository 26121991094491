import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import * as Colors from "../../Styles-Elements/Colors";
import { Subheading, Subtitle, LargeBody, Body } from "../../Styles-Elements/Labels";
import { Textfield } from "../../Styles-Elements/Inputs";
import { ClearButton, SecondaryRoundedButton } from "../../Styles-Elements/Buttons";
import booHappy from "../../image-assets/boo-happy.png";
import dropdownIconLight from "../../image-assets/dropdown-icon-light.svg";
import user1 from "../../image-assets/user1.png";
import { Boxed } from "../../Styles-Elements/Box";
import { ClickAwayListener } from "@material-ui/core";
// Importing localized strings
const strings = require('../../localisation_en.json')
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    dropdownmenu: {
        position: "absolute",
        top: 55,
        right: 0,
        left: 1,
        zIndex: 1,
        border: "none",
        boxShadow:
            "0px 0px 15px -3px rgba(0, 0, 0, 0.1), 0px 5px 6px -2px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
        padding: "0",
        borderRadius: "6px",
        backgroundColor: "#fff",
    },
}));
const SaveNewStringPopup = (props) => {
    const classes = useStyles();
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [show, setShow] = useState(false);
    const handleClickAway1 = () => {
        setOpen1(false);
    };
    const handleClickAway2 = () => {
        setOpen2(false);
    };
    const handleClickAway3 = () => {
        setOpen3(false);
    };
    const handleClick = () => {
        setShow(!show);
        setOpen1((prev) => !prev);
        setOpen2((prev) => !prev);
        setOpen3((prev) => !prev);
    };

    return (
        <div className="container">
            <div className="container-modal large-modal">
                <div className="popup-boo-container positive" style={{ width: '116px', height: '116px' }}>
                    <img alt="" src={booHappy} width={116} height={116} />
                </div>
                <div className="container-modal-mid">
                    <Subtitle text={strings.letsSaveThisAsANewString} color={Colors.nightPurple} textAlign={"center"} padding={"24px 0"} />
                    <div className="mid-grid">
                        <div>
                            <Subheading text={strings.nameOfTheString} color={Colors.nightPurple} padding={"6px 0 8px 0"} />
                            <Textfield placeholder={strings.enterANameForYourString} />
                        </div>
                        <div>
                            <Subheading text={strings.selectAUser} color={Colors.nightPurple} padding={"6px 0 8px 0"} />
                            <div className="ks-drop-block">
                                <ClickAwayListener onClickAway={handleClickAway1}>
                                    <div className={classes.root}>
                                        <button className="dropdown-holder-style" onClick={handleClick}>
                                            <LargeBody text={strings.myFoldersDefault} />
                                            <img src={dropdownIconLight} className="dropdown-icon-holder" alt="" />
                                        </button>
                                        {open1 ? (
                                            <div className={classes.dropdownmenu}>
                                                <div className="other-drop-menu">
                                                    <div className="odm-drop-lists">
                                                        <div style={{ position: "relative", zIndex: "2" }}>
                                                            <div className="when-fv-ohtr-scroll">
                                                                <div className="odm-drop-list grid-item-flex">
                                                                    <Boxed
                                                                        display={"flex"}
                                                                        alignItems={"center"}
                                                                    >
                                                                        <img style={{ height: "35px", width: "35px" }} src={user1} className={'users-avtar'} alt="" />
                                                                        <Body
                                                                            text={strings.myFoldersDefault}
                                                                            textAlign={"center"}
                                                                            color={Colors.nightGray}
                                                                            padding={"0"}
                                                                            margin={"0 12px 0 12px"}
                                                                        />
                                                                    </Boxed>
                                                                </div>
                                                                <div className="odm-drop-list grid-item-flex">
                                                                    <Boxed
                                                                        display={"flex"}
                                                                        alignItems={"center"}
                                                                    >
                                                                        <img style={{ height: "35px", width: "35px" }} src={user1} className={'users-avtar'} alt="" />
                                                                        <Body
                                                                            text={'Buzz Lightyear'}
                                                                            textAlign={"center"}
                                                                            color={Colors.nightGray}
                                                                            padding={"0"}
                                                                            margin={"0 12px 0 12px"}
                                                                        />
                                                                    </Boxed>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </ClickAwayListener>
                            </div>
                        </div>
                        <div>
                            <Subheading text={strings.selectStringType} color={Colors.nightPurple} padding={"6px 0 8px 0"} />
                            <div className="ks-drop-block">
                                <ClickAwayListener onClickAway={handleClickAway3}>
                                    <div className={classes.root}>
                                        <button className="dropdown-holder-style" onClick={handleClick}>
                                            <LargeBody text={strings.keywordString} color={Colors.nightGray} />
                                            <img src={dropdownIconLight} className="dropdown-icon-holder" alt="" />
                                        </button>
                                        {open3 ? (
                                            <div className={classes.dropdownmenu}>
                                                <div className="other-drop-menu">
                                                    <div className="odm-drop-lists">
                                                        <div style={{ position: "relative", zIndex: "2" }}>
                                                            <div className="when-fv-ohtr-scroll">
                                                                <div
                                                                    className="odm-drop-list grid-item-flex"
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    <Body text={strings.keywordString} color={Colors.nightGray} />
                                                                </div>
                                                                <div
                                                                    className="odm-drop-list grid-item-flex"
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    <Body text={strings.companyString} color={Colors.nightGray} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </ClickAwayListener>
                            </div>
                        </div>
                        <div>
                            <Subheading text={strings.selectAFolder} color={Colors.nightPurple} padding={"6px 0 8px 0"} />
                            <div className="ks-drop-block">
                                <ClickAwayListener onClickAway={handleClickAway2}>
                                    <div className={classes.root}>
                                        <button className="dropdown-holder-style" onClick={handleClick}>
                                            <LargeBody text={strings.selectAFolder} color={Colors.nightGray} />
                                            <img src={dropdownIconLight} className="dropdown-icon-holder" alt="" />
                                        </button>
                                        {open2 ? (
                                            <div className={classes.dropdownmenu}>
                                                <div className="other-drop-menu">
                                                    <div className="odm-drop-lists">
                                                        <div style={{ position: "relative", zIndex: "2" }}>
                                                            <div className="when-fv-ohtr-scroll">
                                                                <div
                                                                    className="odm-drop-list grid-item-flex"
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    <Body text={'Folder A'} color={Colors.nightGray} />
                                                                </div>
                                                                <div
                                                                    className="odm-drop-list grid-item-flex"
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    <Body text={'Folder B'} color={Colors.nightGray} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </ClickAwayListener>
                            </div>
                        </div>
                    </div>
                    <Boxed className="popup-btn-group" padding={'30px 0 0 0'} margin={'auto'}>
                        <SecondaryRoundedButton disable="disabled" text={strings.create} margin={'24px 0 8px 0'} />
                        <ClearButton text={strings.cancel} onClick={() => props.closePopup()} />
                    </Boxed>
                </div>
            </div>
        </div>
    );
};
export default SaveNewStringPopup;