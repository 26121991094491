import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import 'antd/dist/antd.css'
import * as Colors from '../Styles-Elements/Colors'
import {
  Subheading,
  Subtitle,
  LargeBody,
} from '../Styles-Elements/Labels'
import { Textfield, InputWrapper } from '../Styles-Elements/Inputs'
import {
  ClearButton,
  MainRoundedButton,
} from '../Styles-Elements/Buttons'
import booNeutral from '../image-assets/boo-neutral.png'
import visibilityIcon from '../image-assets/visibilityicon.svg'

import { REQUEST } from '../Helpers/Enums'
import Scrollbar from 'react-smooth-scrollbar'
import OpenEye from '../image-assets/Eye.svg'
import focus from '../GlobalModule/focus'
import closePopUpByKey from '../GlobalModule/closePopUpByKey'
import { tooledUpLocalData } from '../redux/reducers/getTooledupData'
import { useDispatch } from 'react-redux'
import { setSnackbar } from '../redux/reducers/snackbar'
import globalRequest from '../GlobalModule/globalRequest'
import { stringData } from '../redux/reducers/localString'
import { changeLoader } from '../redux/reducers/loader'

const ChangePassword = (props) => {

  const auth = useSelector(tooledUpLocalData);
  const dispatch = useDispatch();
  const strings = useSelector(stringData);

  const [showVisibilityIconForExist, setShowVisibilityIconForExist] = useState(
    true,
  )
  const [showVisibilityIconForNew, setShowVisibilityIconForNew] = useState(true)
  const [inputFieldEntry, setInputFieldEntry] = useState({
    new: '',
    exist: '',
    newPwd: '',
  })
  const [showExistPassword, setShowExistPassword] = useState('Password')
  const [showNewPassword, setShowNewPassword] = useState('Password')
  const handleInputChange = (e, clear) => {
    if (clear !== true) {
      setInputFieldEntry({
        ...inputFieldEntry,
        [e.currentTarget.name]: e.currentTarget.value,
      })
    } else {
      setInputFieldEntry({})
    }
  }

  const changePassword = async (e) => {
    e.preventDefault()
    if (inputFieldEntry.exist == null && inputFieldEntry.new == null) {
      dispatch(setSnackbar({
        snackbarMessage: strings.enterPassword,
        snackbarOpen: true,
        snackbarState: 'error'
      }))
    } else if (inputFieldEntry.exist == null || inputFieldEntry.exist == '') {

      dispatch(setSnackbar({
        snackbarMessage: strings.enterExistingPassword,
        snackbarOpen: true,
        snackbarState: 'error'
      }))

    } else if (inputFieldEntry.new == null || inputFieldEntry.new == '') {

      dispatch(setSnackbar({
        snackbarMessage: strings.enterNewPassword,
        snackbarOpen: true,
        snackbarState: 'error'
      }))
    } else if (validateChangePwdInputs()) {
      // Send request
      let user_id
      if (auth?.user_id) {
        user_id = auth?.user_id
      }
      let formData = new FormData()
      formData.append('user_id', user_id)
      formData.append('old_password', inputFieldEntry.exist)
      formData.append('new_password', inputFieldEntry.new)

      dispatch(changeLoader(true));
      const response = await globalRequest("post", REQUEST.CHANGE_PASSWORD_REQUEST, formData, {}, true);
      let res = response.data;

      if (res.status == 1) {

        setInputFieldEntry({ ...inputFieldEntry, exist: '', new: '' })
        dispatch(setSnackbar({
          snackbarMessage: res.message,
          snackbarOpen: true,
          snackbarState: 'success'
        }))
        props.callback()

      } else if (res.status == 0) {
        handleInputChange(null, true)
        setInputFieldEntry({ ...inputFieldEntry, exist: '', new: '' })
        dispatch(setSnackbar({
          snackbarMessage: res.message,
          snackbarOpen: true,
          snackbarState: 'error'
        }))

      } else {
        handleInputChange(null, true)
        setInputFieldEntry({ ...inputFieldEntry, exist: '', new: '' })
        dispatch(setSnackbar({
          snackbarMessage: strings.invalidExistingPassword,
          snackbarOpen: true,
          snackbarState: 'error'
        }))
      }
      dispatch(changeLoader(false));

    }
  }

  const validPasswordCheck = (pass) => {
    let lowerCase = /[a-z]/g
    let upperCase = /[A-Z]/g
    let number = /[0-9]/g
    let specialChar = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g

    if (
      pass.match(lowerCase) &&
      pass.match(upperCase) &&
      pass.match(number) &&
      pass.match(specialChar) &&
      pass.length >= 7
    ) {
      return true
    } else {
      return false
    }
  }

  function validateChangePwdInputs() {
    if (validPasswordCheck(inputFieldEntry.new) === false) {
      dispatch(setSnackbar({
        snackbarMessage: strings.thePasswordMustHave,
        snackbarOpen: true,
        snackbarState: 'success'
      }))
      return false
    } else {
      return true
    }
  }

  useEffect(() => {
    focus('exist')
    closePopUpByKey(props.closePopup)
  }, [])

  return (
    <div className="container">
      <div className="container-modal">
        <div className="popup-boo-container neutral">
          <img src={booNeutral} width={120} height={120} />
        </div>
        <Scrollbar className="container-modal-mid">
          <Subtitle
            text={strings.changeYourPassword}
            color={Colors.nightPurple}
            textAlign={'center'}
            padding={'24px 0'}
          />
          <LargeBody
            text={strings.enterYourPasswordBelowAndYouAreGoodToGo}
            textAlign={'center'}
            color={Colors.darkGray}
            padding={'0 0 16px 0'}
          />
          <Subheading
            text={strings.existingPassword}
            color={Colors.nightPurple}
            padding={'0 0 8px 0'}
          />
          <form onSubmit={changePassword}>
            <InputWrapper>
              <Textfield
                onChange={(e) => handleInputChange(e)}
                value={inputFieldEntry.exist}
                type={showExistPassword}
                name={'exist'}
                placeholder={strings.enterYourExistingPassword}
                autoFocus={true}
                id={'exist'}
                required={true}
              />
              {showVisibilityIconForExist ? (
                <img
                  src={visibilityIcon}
                  className="closeeyeicon"
                  onClick={() => {
                    setShowVisibilityIconForExist(!showVisibilityIconForExist)
                    setShowExistPassword(
                      showExistPassword == 'Password' ? 'text' : 'Password',
                    )
                  }}
                  style={{ marginTop: '0px' }}
                />
              ) : (
                <img
                  src={OpenEye}
                  className="openeyeicon"
                  onClick={() => {
                    setShowVisibilityIconForExist(!showVisibilityIconForExist)
                    setShowExistPassword(
                      showExistPassword == 'Password' ? 'text' : 'Password',
                    )
                  }}
                  style={{ marginTop: '0px' }}
                />
              )}
            </InputWrapper>
            <Subheading
              text={strings.newPassword}
              color={Colors.nightPurple}
              padding={'16px 0 8px 0'}
            />
            <InputWrapper>
              <Textfield
                onChange={(e) => handleInputChange(e)}
                value={inputFieldEntry.new}
                type={showNewPassword}
                name={'new'}
                id="new"
                placeholder={strings.enterANewPassword}
                require={true}
              />
              {showVisibilityIconForNew ? (
                <img
                  src={visibilityIcon}
                  className="closeeyeicon"
                  onClick={() => {
                    setShowVisibilityIconForNew(!showVisibilityIconForNew)
                    setShowNewPassword(
                      showNewPassword == 'Password' ? 'text' : 'Password',
                    )
                  }}
                  style={{ marginTop: '0px' }}
                />
              ) : (
                <img
                  src={OpenEye}
                  className="openeyeicon"
                  onClick={() => {
                    setShowVisibilityIconForNew(!showVisibilityIconForNew)
                    setShowNewPassword(
                      showNewPassword == 'Password' ? 'text' : 'Password',
                    )
                  }}
                  style={{ marginTop: '0px' }}
                />
              )}
            </InputWrapper>
            <MainRoundedButton
              text={strings.changePassword}
              margin={'24px 0 8px 0'}
              type="submit"
            />
          </form>
          <ClearButton
            text={strings.cancel}
            onClick={() => {
              props.closePopup()
              setInputFieldEntry({
                ...inputFieldEntry,
                exist: '',
                new: '',
              })
            }}
          />
        </Scrollbar>
      </div>
    </div>
  )
}

export default ChangePassword
