import * as Colors from "../../Styles-Elements/Colors";
import { LargeBody, Subtitle, Subheading } from "../../Styles-Elements/Labels";
import { CreateButton } from "../../Styles-Elements/Buttons";
import comingSoon from "../../image-assets/coming-soon.svg"
import typoGenerator from "../../image-assets/typo-generator.svg"
import "./Gadgets.css";
import TypoGenerator from "./TypoGenerator";
// Importing localized strings
const strings = require("../../localisation_en.json");
const Gadgets = (props) => {

  return (
    <>
      <div className="gadgets-holder">
        <Subtitle
          text={strings.gadgets}
          color={Colors.nightPurple}
          padding={"0 0 32px 0"}
        />
        <div className="gadgets-white-box gadgets-grid">
          <div className="gadget-boxes">
            <img src={typoGenerator} alt="" />
            <Subheading
              text={strings.typoGenerator}
              color={'#423069'}
              textAlign={'center'}
              padding={"28px 0 14px 0"}
            />
            <LargeBody
              text={strings.leverageTheTypoGeneratorToFindCandidatesThatHaveBeenHidingInPlainSight}
              color={Colors.darkPurpleGray}
              fontWeight={'400'}
              textAlign={'center'}
              padding={"0 0 32px 0"}
            />
            <CreateButton
              text={strings.letsGo}
              color={Colors.mainLight}
              className={"gadgets-btn"}
            />
          </div>
          <div className="gadget-boxes">
            <img src={comingSoon} alt="" />
            <Subheading
              text={strings.comingSoon}
              color={'#423069'}
              textAlign={'center'}
              padding={"28px 0 14px 0"}
            />
            <LargeBody
              text={strings.aNewGadgetIsInDevelopmentYouWillBeUpdatedWhenSafetyChecksAreComplete}
              color={Colors.darkPurpleGray}
              fontWeight={'400'}
              textAlign={'center'}
              padding={"0 0 32px 0"}
            />
            <CreateButton
              text={strings.letsGo}
              color={Colors.mainLight}
              className={"gadgets-btn"}
            />
          </div>
          <div className="gadget-boxes">
            <img src={comingSoon} alt="" />
            <Subheading
              text={strings.comingSoon}
              color={'#423069'}
              textAlign={'center'}
              padding={"28px 0 14px 0"}
            />
            <LargeBody
              text={strings.aNewGadgetIsInDevelopmentYouWillBeUpdatedWhenSafetyChecksAreComplete}
              color={Colors.darkPurpleGray}
              fontWeight={'400'}
              textAlign={'center'}
              padding={"0 0 32px 0"}
            />
            <CreateButton
              text={strings.letsGo}
              color={Colors.mainLight}
              className={"gadgets-btn"}
            />
          </div>
          <div className="gadget-boxes">
            <img src={comingSoon} alt="" />
            <Subheading
              text={strings.comingSoon}
              color={'#423069'}
              textAlign={'center'}
              padding={"28px 0 14px 0"}
            />
            <LargeBody
              text={strings.aNewGadgetIsInDevelopmentYouWillBeUpdatedWhenSafetyChecksAreComplete}
              color={Colors.darkPurpleGray}
              fontWeight={'400'}
              textAlign={'center'}
              padding={"0 0 32px 0"}
            />
            <CreateButton
              text={strings.letsGo}
              color={Colors.mainLight}
              className={"gadgets-btn"}
            />
          </div>
          <div className="gadget-boxes">
            <img src={comingSoon} alt="" />
            <Subheading
              text={strings.comingSoon}
              color={'#423069'}
              textAlign={'center'}
              padding={"28px 0 14px 0"}
            />
            <LargeBody
              text={strings.aNewGadgetIsInDevelopmentYouWillBeUpdatedWhenSafetyChecksAreComplete}
              color={Colors.darkPurpleGray}
              fontWeight={'400'}
              textAlign={'center'}
              padding={"0 0 32px 0"}
            />
            <CreateButton
              text={strings.letsGo}
              color={Colors.mainLight}
              className={"gadgets-btn"}
            />
          </div>
          <div className="gadget-boxes">
            <img src={comingSoon} alt="" />
            <Subheading
              text={strings.comingSoon}
              color={'#423069'}
              textAlign={'center'}
              padding={"28px 0 14px 0"}
            />
            <LargeBody
              text={strings.aNewGadgetIsInDevelopmentYouWillBeUpdatedWhenSafetyChecksAreComplete}
              color={Colors.darkPurpleGray}
              fontWeight={'400'}
              textAlign={'center'}
              padding={"0 0 32px 0"}
            />
            <CreateButton
              text={strings.letsGo}
              color={Colors.mainLight}
              className={"gadgets-btn"}
            />
          </div>
        </div>
      </div>
      {/* <TypoGenerator /> */}
    </>
  );
};
export default Gadgets;