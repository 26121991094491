import { createSlice } from "@reduxjs/toolkit";

export const autoEnrichBulb = createSlice({
  name: "autoEnrichBulb",
  initialState: {
value: {
      isClicked: false,
      isFoundValue: false,
    },
  },
  reducers: {
    changeAutoEnrichBulb: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { changeAutoEnrichBulb } = autoEnrichBulb.actions;
export const currentAutoEnrichBulb = (state) => state.autoEnrichBulb.value;
export default autoEnrichBulb.reducer;
