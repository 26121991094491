import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import * as Colors from "../Styles-Elements/Colors";
import Scrollbar from "react-smooth-scrollbar";
import { Subtitle, Body } from "../Styles-Elements/Labels";
import { InputWrapper, TextSearchfield } from "../Styles-Elements/Inputs";
import { ClearButton, MainRoundedButton } from "../Styles-Elements/Buttons";
import booNeutral from "../image-assets/boo-neutral.png";
import searchIcon from "../image-assets/searchicon.svg";

import { API_TEAM, BASE_URL } from "../Helpers/Enums";
// Material UI for the snackbar
// import Box from "@material-ui/core/Box";
import Radio from "@material-ui/core/Radio";
import booHappy from "../image-assets/boo-happy.png";
import globalRequest from "../GlobalModule/globalRequest";
import changeOwnerShipString from "../GlobalModule/changeOwnerShipString";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../redux/reducers/snackbar";
import { changeLoader } from "../redux/reducers/loader";
import { Boxed } from "../Styles-Elements/Box";

const strings = require("../localisation_en.json");

/*
   @props - userId - @param
   @props - stringFolderData @obj
   @props - type @param
   @props - role @param
   @props - userInfoForChangeOwner @param 
   @props - callback @param 
*/

const ChangeStringOwner = (props) => {
  const dispatch = useDispatch();

  const [allUser, setAllUser] = useState([]);
  const [allUserForSearch, setAllUserForSearch] = useState([]);
  const [id, setId] = useState(0); //this is the transfer id
  const [userId, setUserId] = useState(0);

  useEffect(() => {
    if (props.userId != 0) {
      setUserId(props.userId);
    }
  }, [props.userId]);

  useEffect(() => {
    if (userId) {
      getAllUser(userId);
    }
  }, [userId]);

  const getAllUser = async (userId) => {
    //user,admin,owner
    // dispatch(changeLoader(true));
    let res = await globalRequest(
      "get",
      API_TEAM.GET_ALL_TEAM_USER,
      {},
      {
        params: {
          user_id: userId,
        },
      },
      true
    );
    if (res.data.status == 1) {
      setAllUser(res.data.data);
      setAllUserForSearch(res.data.data);
    }

    // dispatch(changeLoader(false));
  };

  const radioButton = (id) => {
    setId(id);
  };

  const changeOwnerShip = async () => {
    if (id == 0) {
      dispatch(
        setSnackbar({
          snackbarMessage: "Please select user first.",
          snackbarOpen: true,
          snackbarState: "error",
        })
      );
    } else {
      dispatch(changeLoader(true));
      let response = await changeOwnerShipString(props.type, props.stringFolderData?.id, id, props.role, userId);
      let data = response?.data;
      if (data.status == 1) {
        props.callback();
        props.closePopup();
        dispatch(
          setSnackbar({
            snackbarMessage: data.message,
            snackbarOpen: true,
            snackbarState: "success",
          })
        );
      } else {
        dispatch(
          setSnackbar({
            snackbarMessage: data.message,
            snackbarOpen: true,
            snackbarState: "error",
          })
        );
      }
      dispatch(changeLoader(false));
    }
  };

  const search = (e) => {
    try {
      let v = String(e.target.value).toLowerCase();
      if (v == "") {
        setAllUser(allUserForSearch);
      } else {
        let condition = new RegExp(v, "i");
        const users = allUser.filter((e) => {
          if (condition.test(String(e.full_name).toLowerCase())) {
            return e;
          } else if (condition.test(String(e.email_id).toLocaleLowerCase())) {
            return e;
          }
        });
        setAllUser(users);
      }
    } catch (e) {}
  };

  return (
    <div className="container">
      <div className="container-modal">
        <div className="popup-boo-container neutral">
          <img src={booNeutral} width={120} height={120} />
        </div>
        <Scrollbar className="container-modal-mid">
          <Subtitle
            text={`Change ${props.type} owner`}
            color={Colors.nightPurple}
            textAlign={"center"}
            padding={"24px 0 24px"}
          />

          <div className="popup-selection--box">
            <div>
              <Boxed
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                style={{ padding: "0px 4px 16px 4px" }}
              >
                <Body text={strings.selectNewOwner} textAlign={"left"} color={Colors.nightGray} padding={"0px 0 0 0"} />
              </Boxed>
            </div>
            <InputWrapper>
              <TextSearchfield
                placeholder={strings.startTypingAName}
                backgroundColor={Colors.whiteGray}
                onChange={search}
              />
              <button className="searchbtn">
                <img src={searchIcon} className="" />
              </button>
            </InputWrapper>

            <Scrollbar className="popup-scroll-box">
              {allUser.map((row) => {
                let profileUrl = null;
                if (row.profile_image) {
                  profileUrl = `${BASE_URL}uploads/profile/${row.id}/${row.profile_image}`;
                } else {
                  if (row.first_name == "") {
                    profileUrl = booHappy;
                  } else {
                    profileUrl = "nameImage";
                  }
                }

                return (
                  <>
                    <Boxed display={"flex"} alignItems={"center"} justifyContent={"space-between"} margin={"0 0 4px 0"}>
                      <Boxed display={"flex"} alignItems={"center"}>
                        {profileUrl == "nameImage" ? (
                          <>
                            <b className="sidebar-profile-char">
                              {String(row.first_name).charAt(0)} {String(row.last_name).charAt(0)}
                            </b>
                          </>
                        ) : (
                          <>
                            <img
                              style={{
                                height: "50px",
                                width: "50px",
                                borderRadius: "40px",
                              }}
                              src={profileUrl}
                            />
                          </>
                        )}

                        <Body
                          text={
                            row.first_name == "" || row.first_name == " "
                              ? row.email_id
                              : `${row?.first_name} ${row.last_name}`
                          }
                          textAlign={"center"}
                          color={Colors.nightPurple}
                          padding={"0"}
                          margin={"0 12px 0 12px"}
                        />
                      </Boxed>
                      {id == row.id ? (
                        <Radio name="radio-button-demo" checked={true} />
                      ) : (
                        <Radio name="radio-button-demo" onClick={() => radioButton(row.id)} checked={false} />
                      )}
                    </Boxed>
                  </>
                );
              })}
            </Scrollbar>
          </div>

          <Body
            text={`By pressing "Change Owner" below, you will transfer the string ownership from ${
              props?.userInfoForChangeOwner?.firstName == "" || props?.userInfoForChangeOwner?.firstName == " "
                ? props?.userInfoForChangeOwner?.emailId
                : props?.userInfoForChangeOwner.firstName + " " + props?.userInfoForChangeOwner.lastName
            } to the new user.`}
            textAlign={"center"}
            color={"#7A7A7A"}
            padding={"20px 4px 0px 4px"}
          />

          <Body
            text={strings.existingCollaboratorsWillContinueToHave}
            textAlign={"center"}
            color={"#7A7A7A"}
            padding={"32px 0 0px 0"}
          />
          <MainRoundedButton onClick={changeOwnerShip} text={"Change Owner"} margin={"24px 0 8px 0"} />
          <ClearButton text={strings.cancel} onClick={() => props.closePopup()} />
        </Scrollbar>
      </div>
    </div>
  );
};

export default ChangeStringOwner;
