import React, { useEffect } from "react";
import { Switch, Route, Router, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { history } from "./Helpers/history";
import LoginSignup from "./pages/login-signup/LoginSignup";
import MainApp from "./pages/main-app/MainApp";
import SignupInvited from "./pages/signup-invited/SignupInvited";
import ReactGA from "react-ga";
import Snackbars from "./GlobalModule/Snackbars";
import { setSnackbar, snackObj } from "./redux/reducers/snackbar";
import { changeLoader, currentLoader } from "./redux/reducers/loader";
import { useSelector } from "react-redux";
import VerificationCode from "./pages/VerificationCode/VerificationCode";
import addDeleteGetLocalStorage from "./GlobalModule/addDeleteGetLocalStorage";
import { BASE_URL, REQUEST, STORAGE } from "./Helpers/Enums";
import { setData, tooledUpLocalData } from "./redux/reducers/getTooledupData";
import globalRequest from "./GlobalModule/globalRequest";
import socket from "./socket";
import { changeStringId } from "./redux/reducers/stringId";
import ProgressBar from "./ProgressBar";
import { INTERCOM_APP_ID } from "./BaseUrl";
import "./Common.css";
import "./App.css";
import "./AppResponsive.css";
import { currentTrial } from "./redux/reducers/getTrial";
// tab_fill values
// 1.select plan
// 2.signup-details
// 3.checkout
// 4.onboarding
// 5.main-app

function App(props) {
  const snackbarObj = useSelector(snackObj);
  const loading = useSelector(currentLoader);
  const dispatch = useDispatch();
  const auth = useSelector(tooledUpLocalData);
  const trialData = useSelector(currentTrial);

  useEffect(() => {
    if (Object.keys(auth).length !== 0) {
      if (auth?.firstname && auth?.lastname) {
        let page = window.location.href;
        let url = page.split("/");
        let pages = url.at(url.length - 1);
        if (pages == "main-app") {
          let createdAt = new Date(auth?.created_at);
          let unixTimeStamp = createdAt.getTime() / 1000;

          window.intercomSettings = {
            api_base: "https://api-iam.intercom.io",
            app_id: INTERCOM_APP_ID,
            name: `${auth?.firstname} ${auth?.lastname}`,
            first_name: auth?.firstname,
            user_id: auth?.user_id,
            email: auth?.email,
            Companies: auth?.company,
            plan_type:
              trialData?.status === "payment_accepted"
                ? "Paid"
                : trialData?.status === "trial_completed"
                ? "FT Expired"
                : trialData?.status === "trial"
                ? "FT Active"
                : "FT Expired",
            user_type: auth?.access,
            created_at: unixTimeStamp,
          };

          (function () {
            var w = window;
            var ic = w.Intercom;
            if (typeof ic === "function") {
              ic("reattach_activator");
              ic("update", w.intercomSettings);
            } else {
              var d = document;
              var i = function () {
                i.c(arguments);
              };
              i.q = [];
              i.c = function (args) {
                i.q.push(args);
              };
              w.Intercom = i;
              var l = function () {
                var s = d.createElement("script");
                s.type = "text/javascript";
                s.async = true;
                s.src = `https://widget.intercom.io/widget/${INTERCOM_APP_ID}`;
                var x = d.getElementsByTagName("script")[0];
                x.parentNode.insertBefore(s, x);
              };
              if (document.readyState === "complete") {
                l();
              } else if (w.attachEvent) {
                w.attachEvent("onload", l);
              } else {
                w.addEventListener("load", l, false);
              }
            }
          })();
        }
      }
    }
  }, [auth, trialData]);

  ReactGA.initialize("UA-205871864-1");

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    socket.on("disconnect", (reason) => {
      console.log("disconnect :  ", reason);
    });
    socket.on("error", (err) => {
      console.log("err ", err);
    });
  }, []);

  useEffect(() => {
    logout();
  }, []);

  const logout = async () => {
    const res = await globalRequest(
      "get",
      BASE_URL + "api/auth/check_email_status",
      {},
      {
        params: {
          user_id: auth?.user_id,
        },
      },
      true
    );
    let data = res.data;
    if (data.status == 0) {
      handleLogout();
    }
  };

  const handleLogout = async () => {
    try {
      dispatch(changeLoader(true));
      let userId = auth?.user_id;
      let formData = new FormData();
      formData.append("user_id", userId);
      formData.append("type", "web");
      localStorage.removeItem(STORAGE.TOOLED_UP_LOCAL_DATA);
      dispatch(setData());
      history.push("/");
      dispatch(changeLoader(false));
    } catch (e) {}
  };

  useEffect(() => {
    socket.on("getuser_access", (data) => {
      let userId = data?.id;
      let access = data?.access;
      if (auth?.user_id == userId) {
        const ds = {
          ...auth,
          access: access,
        };
        addDeleteGetLocalStorage(STORAGE.TOOLED_UP_LOCAL_DATA, ds, "add", "single");
        dispatch(setData());
        window.location.reload();
      }
    });
  }, []);

  const Collab = () => {
    let params = useParams();
    let type = params.type;
    let stringId = params.string_id;

    useEffect(() => {
      addDeleteGetLocalStorage(STORAGE.STRING_ID, stringId, "add", "single");
      dispatch(changeStringId(params?.string_id));
      history.push(`/main-app?type=${type}`);
    }, []);

    return (
      <>
        <div></div>
      </>
    );
  };

  useEffect(() => {
    if (snackbarObj?.snackbarOpen) {
      setTimeout(() => {
        dispatch(
          setSnackbar({
            ...snackbarObj,
            snackbarOpen: false,
          })
        );
      }, 2000);
    }
  }, [snackbarObj?.snackbarOpen]);

  return (
    <>
      <Router history={history}>
        <Switch>
          <Route
            exact
            path="/"
            render={() => {
              let isEmpty = Object.keys(auth).length === 0;
              if (isEmpty) {
                return <LoginSignup />;
              } else {
                return history.push("/main-app");
              }
            }}
          />
          <Route path={"/otp-verification"} component={VerificationCode} />
          <Route path="/collab/:type/:string_id" component={Collab} />
          <Route
            path="/signup-invited"
            render={(prop) => {
              return <SignupInvited {...prop} />;
            }}
          />
          <Route path="/main-app" component={MainApp} />
        </Switch>
      </Router>

      <ProgressBar loading={loading} />

      <Snackbars
        snackBarMessage={snackbarObj?.snackbarMessage}
        snackbarOpen={snackbarObj?.snackbarOpen}
        snackBarState={snackbarObj?.snackbarState}
      />
    </>
  );
}

export default App;
