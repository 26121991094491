import * as Colors from "../../Styles-Elements/Colors";
import { Subtitle } from "../../Styles-Elements/Labels";
import "./Gadgets.css";
import { Boxed } from "../../Styles-Elements/Box";
import { MainRoundedButton } from "../../Styles-Elements/Buttons";
// Importing localised strings
const strings = require("../../localisation_en.json");
const GadgetsBreadcrumb = (props) => {

    return (
        <Boxed display={'flex'} alignItems={'flex-start'} justifyContent={'space-between'}>
            <Boxed display={'flex'}>
                <Subtitle
                    text={strings.gadgets}
                    color={Colors.nightPurple}
                    padding={"0 0 32px 0"}
                />
                <Subtitle
                    text={'|'}
                    color={Colors.nightPurple}
                    padding={"0 4px 32px 4px"}
                />
                <Subtitle
                    text={strings.typoGenerator}
                    color={Colors.nightPurple}
                    padding={"0 0 32px 0"}
                />
            </Boxed>
            <MainRoundedButton
                text={strings.backToGadgets}
                borderRadius={'4px'}
                padding={"8px 16px"}
                width={'auto'}
                className={'breadcrumb-btn'}
            />
        </Boxed>
    );
};
export default GadgetsBreadcrumb;