import React from "react";
import "antd/dist/antd.css";
import { Body, Subtitle } from "../Styles-Elements/Labels";
import booNuetral from "../image-assets/boo-neutral.png";
import { ClearButton, CustomRoundedButton } from "../Styles-Elements/Buttons";
import * as Colors from "../Styles-Elements/Colors";
import strings from "../localisation_en.json";

const UseCreditDialog = ({ closePopup, type = "autoBuild", submit, creditData = {} }) => {
  let build = creditData?.auto_build;
  let enrich = creditData?.auto_enrich;
  let translate = creditData?.translations;

  let firstMessage =
    type === "autoBuildBack"
      ? "If you make any changes to the “Build” form and click “Go”, you’ll use an extra build credit."
      : type === "autoBuild"
      ? "One token from your free trial will be deducted upon using the Build feature"
      : type === "autoEnrich"
      ? "Tokens from your free trial will be deducted upon using the Enrich feature."
      : "Tokens from your free trial will be deducted upon using the Translate feature.";

  return (
    <div className="container">
      <div className="container-modal">
        <div className="popup-boo-container neutral">
          <img src={booNuetral} width={120} height={120} alt="" />
        </div>
        <div className="container-modal-mid">
          <Subtitle
            text={"Do you want to continue?"}
            color={Colors.nightPurple}
            textAlign={"center"}
            padding={"24px 0 16px 0"}
          />
          <Body
            text={firstMessage}
            textAlign={"center"}
            color={"#7A7A7A"}
            padding={"20px 4px 0px 4px"}
            style={{ fontSize: "18px" }}
          />

          <Body
            text={`You have ${
              type === "autoBuildBack"
                ? `${build} Build `
                : type === "autoBuild"
                ? `${build} Build `
                : type === "autoEnrich"
                ? `${enrich} Enrich `
                : `${translate} Translate `
            } tokens remaining.`}
            textAlign={"center"}
            color={Colors.darkPurple}
            padding={"20px 4px 0px 4px"}
            style={{ fontSize: "18px" }}
          />

          <CustomRoundedButton
            text={"Let's Go"}
            margin={"24px 0 8px 0"}
            color={Colors.white}
            backgroundColor={Colors.darkPurple}
            onClick={submit}
          />
          <ClearButton text={strings.cancel} onClick={() => closePopup()} />
        </div>
      </div>
    </div>
  );
};

export default UseCreditDialog;
