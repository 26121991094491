import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "antd/dist/antd.css";
import * as Colors from "../Styles-Elements/Colors";
import { Subtitle, Subheading } from "../Styles-Elements/Labels";
import { Textfield } from "../Styles-Elements/Inputs";
import { ClearButton, SecondaryRoundedButton } from "../Styles-Elements/Buttons";
import booHappy from "../image-assets/boo-happy.png";

import Scrollbar from "react-smooth-scrollbar";
import { KEYWORD_STRING } from "../Helpers/Enums";
import focus from "../GlobalModule/focus";
import closePopUpByKey from "../GlobalModule/closePopUpByKey";
import { tooledUpLocalData } from "../redux/reducers/getTooledupData";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../redux/reducers/snackbar";
import globalRequest from "../GlobalModule/globalRequest";
import { changeLoader } from "../redux/reducers/loader";
import { currentSidebar } from "../redux/reducers/sidebar";

const strings = require("../localisation_en.json");

const RenameFolder = (props) => {
  const auth = useSelector(tooledUpLocalData);
  const dispatch = useDispatch();
  const typeParam = useSelector(currentSidebar);

  const [name, setName] = useState("");
  let userId = auth?.user_id;
  let id = props.id; //folder id

  useEffect(() => {
    if (props.folderName) {
      setName(props.folderName);
    }
  }, [props.folderName]);

  const submitRequest = async (e) => {
    e.preventDefault();

    if (name == "") {
      dispatch(
        setSnackbar({
          snackbarMessage: "folder cannot be empty",
          snackbarOpen: true,
          snackbarState: "error",
        })
      );
    } else {
      dispatch(changeLoader(true));
      let url = KEYWORD_STRING.UPDATE_FOLDER_NAME;
      let body = {
        team_id: auth?.team_id,
        folder_name: name,
        user_id: userId,
        folder_id: id,
        string_type: typeParam,
      };

      const response = await globalRequest("put", url, body, {}, true);
      let data = response.data;
      if (data.status == 1) {
        dispatch(
          setSnackbar({
            snackbarMessage: data.message,
            snackbarOpen: true,
            snackbarState: "success",
          })
        );
        props.callback(true);
      } else {
        dispatch(
          setSnackbar({
            snackbarMessage: data.message,
            snackbarOpen: true,
            snackbarState: "error",
          })
        );
      }
      dispatch(changeLoader(false));
    }
  };

  useEffect(() => {
    focus("rename1");
    closePopUpByKey(props.closePopup);
  }, []);

  return (
    <div className="container">
      <div className="container-modal ">
        <div className="popup-boo-container positive">
          <img src={booHappy} width={120} height={120} />
        </div>
        <Scrollbar className="container-modal-mid">
          <Subtitle
            text={strings.LetsRenameYourFolder}
            color={Colors.nightPurple}
            textAlign={"center"}
            padding={"24px 0"}
          />
          <Subheading text={strings.nameOfTheFolder} color={Colors.nightPurple} padding={"16px 0 8px 0"} />
          <form onSubmit={submitRequest}>
            <Textfield
              required={true}
              id="rename1"
              onChange={(e) => setName(e.target.value)}
              value={name}
              placeholder={strings.enterANameForYourString}
            />
            <SecondaryRoundedButton text={strings.save} margin={"24px 0 8px 0"} type="submit" />
          </form>
          <ClearButton text={strings.cancel} onClick={() => props.closePopup()} />
        </Scrollbar>
      </div>
    </div>
  );
};

export default RenameFolder;
