import React, { Component } from "react";

import styled from "styled-components/macro";

import * as FontStyles from "./FontStyles";

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.flexDirection ? props.flexDirection : "row")};
  flex-wrap: ${(props) => props.flexWrap};
  align-items: ${(props) => (props.alignItems ? props.alignItems : "center")};
  justify-content: ${(props) => props.justifyContent};
  white-space: ${(props) => props.whiteSpace};
  max-height: ${(props) => props.maxHeight};
  overflow-x: ${(props) => props.overflowX};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  transition: transform 0.2s ease-in-out;
  &::after {
    content: "";
  }

  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 1000px) {
    padding: ${(props) => props.mobilePadding};
  }
`;

LabelWrapper.defaultProps = {
  flexWrap: "wrap",
  justifyContent: "flex-start",
  whiteSpace: "normal",
  overflowX: "visible",
};

const LabelProvider = styled.div`
  font-family: ${(props) => (props.fontFamily ? props.fontFamily : FontStyles.getFontFamily(props.theme))};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : FontStyles.getFontWeight(props.theme))};
  font-size: ${(props) => (props.fontSize ? props.fontSize : FontStyles.getFontSize(props.theme))};
  font-style: ${(props) => props.fontStyle};
  text-align: ${(props) => props.textAlign};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  flex-grow: ${(props) => props.flexGrow};
  color: ${(props) => props.color};
  line-height: ${(props) => props.lineHeight};
  letter-spacing: ${(props) => props.letterSpacing};
  border-bottom: ${(props) => props.borderBottom};
  cursor: ${(props) => props.cursor};
  text-decoration: ${(props) => props.textDecoration};
  text-transform: ${(props) => props.textTransform};
  user-select: ${(props) => props.userSelect};
  text-overflow: ${(props) => props.ellipsis};
  overflow: ${(props) => props.hidden};
  classname: ${(props) => props.className};
`;

// @media (max-width: 1000px) {
//   font-size: ${props => props.theme === FontStyles.title1Large ? '40px' : ''};
// }

LabelProvider.defaultProps = {
  theme: FontStyles.body,
  textAlign: "left",
  padding: "initial",
  margin: "initial",
  flexGrow: 0,
  color: "black",
  lineHeight: "normal",
  letterSpacing: "normal",
  borderBottom: "medium none color",
  textDecoration: "none currentcolor solid",
};

/**
 * Represents a div with
 * font size: 60px
 * font name: Chewy
 * font weight: Medium
 */
export function LargeHeading(props) {
  return (
    <LabelProvider
      theme={FontStyles.largeHeading}
      textAlign={props.textAlign}
      padding={props.padding}
      margin={props.margin}
      flexGrow={props.flexGrow}
      color={props.color}
      lineHeight={props.lineHeight}
      letterSpacing={props.letterSpacing}
      textDecoration={props.textDecoration}
      cursor={props.cursor}
      onClick={props.onClick}
    >
      {props.text}
    </LabelProvider>
  );
}

/**
 * Represents a div with
 * font size: 48px
 * font name: Poppins
 * font weight: Medium
 */
export function Heading1(props) {
  return (
    <LabelProvider
      theme={FontStyles.heading1}
      fontWeight={props.fontWeight}
      textAlign={props.textAlign}
      padding={props.padding}
      margin={props.margin}
      flexGrow={props.flexGrow}
      color={props.color}
      lineHeight={props.lineHeight}
      letterSpacing={props.letterSpacing}
      textDecoration={props.textDecoration}
      cursor={props.cursor}
      onClick={props.onClick}
    >
      {props.text}
    </LabelProvider>
  );
}

/**
 * Represents a div with
 * font size: 32px
 * font name: Poppins
 * font weight: Bold
 */
export function Heading2Bold(props) {
  return (
    <LabelProvider
      theme={FontStyles.heading2Bold}
      textAlign={props.textAlign}
      fontSize={props.fontSize}
      padding={props.padding}
      margin={props.margin}
      flexGrow={props.flexGrow}
      color={props.color}
      lineHeight={props.lineHeight}
      letterSpacing={props.letterSpacing}
      textDecoration={props.textDecoration}
      cursor={props.cursor}
      onClick={props.onClick}
    >
      {props.text}
    </LabelProvider>
  );
}

/**
 * Represents a div with
 * font size: 32px
 * font name: Poppins
 * font weight: Medium
 */
export function Heading2(props) {
  return (
    <LabelProvider
      theme={FontStyles.heading2}
      textAlign={props.textAlign}
      padding={props.padding}
      margin={props.margin}
      flexGrow={props.flexGrow}
      color={props.color}
      lineHeight={props.lineHeight}
      letterSpacing={props.letterSpacing}
      textDecoration={props.textDecoration}
      cursor={props.cursor}
      onClick={props.onClick}
    >
      {props.text}
    </LabelProvider>
  );
}

/**
 * Represents a div with
 * font size: 24px
 * font name: Poppins
 * font weight: Medium
 */
export function Heading3(props) {
  return (
    <LabelProvider
      theme={FontStyles.heading3}
      textAlign={props.textAlign}
      padding={props.padding}
      margin={props.margin}
      flexGrow={props.flexGrow}
      color={props.color}
      lineHeight={props.lineHeight}
      letterSpacing={props.letterSpacing}
      textDecoration={props.textDecoration}
      cursor={props.cursor}
      onClick={props.onClick}
    >
      {props.text}
    </LabelProvider>
  );
}

/**
 * Represents a div with
 * font size: 24px
 * font name: Poppins
 * font weight: SemiBold
 */
export function Heading3SemiBold(props) {
  return (
    <LabelProvider
      theme={FontStyles.heading3SemiBold}
      textAlign={props.textAlign}
      padding={props.padding}
      margin={props.margin}
      flexGrow={props.flexGrow}
      color={props.color}
      lineHeight={props.lineHeight}
      letterSpacing={props.letterSpacing}
      textDecoration={props.textDecoration}
      cursor={props.cursor}
      onClick={props.onClick}
    >
      {props.text}
    </LabelProvider>
  );
}

/**
 * Represents a div with
 * font size: 20px
 * font name: Poppins
 * font weight: Medium
 */
export function Subtitle(props) {
  return (
    <LabelProvider
      theme={FontStyles.subtitle}
      fontStyle={props.fontStyle}
      textAlign={props.textAlign}
      padding={props.padding}
      margin={props.margin}
      flexGrow={props.flexGrow}
      color={props.color}
      lineHeight={props.lineHeight}
      letterSpacing={props.letterSpacing}
      textDecoration={props.textDecoration}
      cursor={props.cursor}
      onClick={props.onClick}
      textOverflow={props.ellipsis}
      overflow={props.hidden}
    >
      {props.text}
    </LabelProvider>
  );
}

/**
 * Represents a div with
 * font size: 20px
 * font name: Poppins
 * font weight: SemiBold
 */
export function SubtitleSemiBold(props) {
  return (
    <LabelProvider
      theme={FontStyles.subtitleSemiBold}
      textAlign={props.textAlign}
      padding={props.padding}
      margin={props.margin}
      flexGrow={props.flexGrow}
      color={props.color}
      lineHeight={props.lineHeight}
      letterSpacing={props.letterSpacing}
      textDecoration={props.textDecoration}
      cursor={props.cursor}
      onClick={props.onClick}
    >
      {props.text}
    </LabelProvider>
  );
}

/**
 * Represents a div with
 * font size: 20px
 * font name: Poppins
 * font weight: Bold
 */
export function SubtitleBold(props) {
  return (
    <LabelProvider
      theme={FontStyles.subtitleBold}
      textAlign={props.textAlign}
      padding={props.padding}
      margin={props.margin}
      flexGrow={props.flexGrow}
      color={props.color}
      lineHeight={props.lineHeight}
      letterSpacing={props.letterSpacing}
      textDecoration={props.textDecoration}
      cursor={props.cursor}
      onClick={props.onClick}
    >
      {props.text}
    </LabelProvider>
  );
}

/**
 * Represents a div with
 * font size: 18px
 * font name: Poppins
 * font weight: Medium
 */
export function Subheading(props) {
  return (
    <LabelProvider
      theme={FontStyles.subheading}
      fontStyle={props.fontStyle}
      fontWeight={props.fontWeight}
      textAlign={props.textAlign}
      padding={props.padding}
      margin={props.margin}
      flexGrow={props.flexGrow}
      color={props.color}
      lineHeight={props.lineHeight}
      letterSpacing={props.letterSpacing}
      textDecoration={props.textDecoration}
      cursor={props.cursor}
      className={props.className}
      onClick={props.onClick}
    >
      {props.text}
    </LabelProvider>
  );
}

/**
 * Represents a div with
 * font size: 18px
 * font name: Poppins
 * font weight: Bold
 */
export function SubheadingBold(props) {
  return (
    <LabelProvider
      theme={FontStyles.subheadingBold}
      fontWeight={props.fontWeight}
      textAlign={props.textAlign}
      padding={props.padding}
      margin={props.margin}
      flexGrow={props.flexGrow}
      color={props.color}
      lineHeight={props.lineHeight}
      letterSpacing={props.letterSpacing}
      textDecoration={props.textDecoration}
      textTransform={props.textTransform}
      cursor={props.cursor}
      onClick={props.onClick}
    >
      <span style={props.style}> {props.text}</span>
    </LabelProvider>
  );
}

/**
 * Represents a div with
 * font size: 16px
 * font name: Poppins
 * font weight: Medium
 */
export function LargeBody(props) {
  return (
    <LabelProvider
      theme={FontStyles.largeBody}
      fontWeight={props.fontWeight}
      textAlign={props.textAlign}
      padding={props.padding}
      margin={props.margin}
      flexGrow={props.flexGrow}
      color={props.color}
      lineHeight={props.lineHeight}
      letterSpacing={props.letterSpacing}
      textDecoration={props.textDecoration}
      textTransform={props.textTransform}
      cursor={props.cursor}
      onClick={props.onClick}
      style={props.style}
    >
      {props.text}
    </LabelProvider>
  );
}

/**
 * Represents a div with
 * font size: 16px
 * font name: Poppins
 * font weight: Bold
 */
export function LargeBodyBold(props) {
  return (
    <LabelProvider
      theme={FontStyles.largeBodyBold}
      textAlign={props.textAlign}
      padding={props.padding}
      margin={props.margin}
      flexGrow={props.flexGrow}
      color={props.color}
      lineHeight={props.lineHeight}
      letterSpacing={props.letterSpacing}
      textDecoration={props.textDecoration}
      userSelect={props.userSelect}
      cursor={props.cursor}
      onClick={props.onClick}
    >
      {props.text}
    </LabelProvider>
  );
}

/**
 * Represents a div with
 * font size: 16px
 * font name: Poppins
 * font weight: SemiBold
 */
export function LargeBodySemiBold(props) {
  return (
    <LabelProvider
      theme={FontStyles.largeBodySemiBold}
      textAlign={props.textAlign}
      padding={props.padding}
      margin={props.margin}
      className={props.className}
      flexGrow={props.flexGrow}
      color={props.color}
      lineHeight={props.lineHeight}
      letterSpacing={props.letterSpacing}
      textDecoration={props.textDecoration}
      userSelect={props.userSelect}
      cursor={props.cursor}
      onClick={props.onClick}
    >
      {props.text}
    </LabelProvider>
  );
}

/**
 * Represents a div with
 * font size: 16px
 * font name: Poppins
 * font weight: Regular
 */
export function LargeBodyRegular(props) {
  return (
    <LabelProvider
      theme={FontStyles.largeBodyRegular}
      textAlign={props.textAlign}
      padding={props.padding}
      margin={props.margin}
      flexGrow={props.flexGrow}
      color={props.color}
      lineHeight={props.lineHeight}
      letterSpacing={props.letterSpacing}
      textDecoration={props.textDecoration}
      cursor={props.cursor}
      onClick={props.onClick}
    >
      {props.text}
    </LabelProvider>
  );
}

/**
 * Represents a div with
 * font size: 14px
 * font name: Poppins
 * font weight: Medium
 */
export function Body(props) {
  return (
    <LabelProvider
      theme={FontStyles.body}
      textAlign={props.textAlign}
      fontStyle={props.fontStyle}
      padding={props.padding}
      margin={props.margin}
      flexGrow={props.flexGrow}
      color={props.color}
      lineHeight={props.lineHeight}
      letterSpacing={props.letterSpacing}
      textDecoration={props.textDecoration}
      cursor={props.cursor}
      onClick={props.onClick}
      className={props.className}
      style={props.style}
    >
      {props.text} {props.htmlText}
    </LabelProvider>
  );
}

/**
 * Represents a div with
 * font size: 14px
 * font name: Poppins
 * font weight: Bold
 */
export function Bold(props) {
  return (
    <LabelProvider
      theme={FontStyles.bold}
      textAlign={props.textAlign}
      padding={props.padding}
      margin={props.margin}
      flexGrow={props.flexGrow}
      color={props.color}
      lineHeight={props.lineHeight}
      letterSpacing={props.letterSpacing}
      textDecoration={props.textDecoration}
      textTransform={props.textTransform}
      cursor={props.cursor}
      onClick={props.onClick}
    >
      {props.text}
    </LabelProvider>
  );
}

/**
 * Represents a div with
 * font size: 12px
 * font name: Poppins
 * font weight: Medium
 */
export function LargeSmall(props) {
  return (
    <LabelProvider
      theme={FontStyles.largeSmall}
      textAlign={props.textAlign}
      padding={props.padding}
      margin={props.margin}
      flexGrow={props.flexGrow}
      color={props.color}
      lineHeight={props.lineHeight}
      letterSpacing={props.letterSpacing}
      textDecoration={props.textDecoration}
      cursor={props.cursor}
      className={props.className}
      onClick={props.onClick}
      title={props.title}
    >
      {props.text}
    </LabelProvider>
  );
}

/**
 * Represents a div with
 * font size: 12px
 * font name: Poppins
 * font weight: SemiBold
 */
export function LargeSmallSemiBold(props) {
  return (
    <LabelProvider
      theme={FontStyles.largeSmallSemiBold}
      textAlign={props.textAlign}
      padding={props.padding}
      margin={props.margin}
      flexGrow={props.flexGrow}
      color={props.color}
      lineHeight={props.lineHeight}
      letterSpacing={props.letterSpacing}
      textDecoration={props.textDecoration}
      cursor={props.cursor}
      onClick={props.onClick}
      title={props.title}
    >
      {props.text}
    </LabelProvider>
  );
}

/**
 * Represents a div with
 * font size: 10px
 * font name: Poppins
 * font weight: Medium
 */
export function Small(props) {
  return (
    <LabelProvider
      theme={FontStyles.small}
      textAlign={props.textAlign}
      padding={props.padding}
      margin={props.margin}
      flexGrow={props.flexGrow}
      color={props.color}
      lineHeight={props.lineHeight}
      letterSpacing={props.letterSpacing}
      textDecoration={props.textDecoration}
      cursor={props.cursor}
      onClick={props.onClick}
    >
      {props.text}
    </LabelProvider>
  );
}

/**
 * Represents a div with
 * font size: 10px
 * font name: Poppins
 * font weight: SemiBold
 */
export function SmallSemiBold(props) {
  return (
    <LabelProvider
      theme={FontStyles.smallSemiBold}
      textAlign={props.textAlign}
      padding={props.padding}
      margin={props.margin}
      flexGrow={props.flexGrow}
      color={props.color}
      lineHeight={props.lineHeight}
      letterSpacing={props.letterSpacing}
      textDecoration={props.textDecoration}
      cursor={props.cursor}
      onClick={props.onClick}
    >
      {props.text}
    </LabelProvider>
  );
}
