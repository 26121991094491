import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import PropTypes from "prop-types";
import * as Colors from "../Styles-Elements/Colors";
import closeModalIcon from "../image-assets/modal-close-new.png";
import {
  LabelWrapper,
  LargeBody,
  Subtitle,
} from "../Styles-Elements/Labels";
import { MainRoundedButton } from "../Styles-Elements/Buttons";
import booHappy from "../image-assets/boo-happy.png";
import Scrollbar from "react-smooth-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import strings from "../localisation_en.json";
import { setSnackbar } from "../redux/reducers/snackbar";
import reportBugsApi from "./reportBugsApi";
import { changeLoader } from "../redux/reducers/loader";
import { tooledUpLocalData } from "../redux/reducers/getTooledupData";
import { Boxed } from "../Styles-Elements/Box";
import { TextAreafield } from "../Styles-Elements/Inputs";
import closePopUpByKey from "../GlobalModule/closePopUpByKey";
import './child.css'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


const ReportBug = ({ closePopup, string, selectedLanguage }) => {
  
  const dispatch = useDispatch();
  const auth = useSelector(tooledUpLocalData);
  const [text, setText] = useState("");

  useEffect(() => {
    closePopUpByKey(closePopup);
  }, []);

  const sendReport = () => {
    if (text) {
      dispatch(changeLoader(true));
      reportBugsApi({
        user_id: auth?.user_id,
        message: text,
        type: 0,
        error_message: text,
        input_string: string,
        output_language: selectedLanguage,
      })
        .then((res) => {
          let data = res.data;
          dispatch(
            setSnackbar({
              snackbarMessage: data?.message,
              snackbarOpen: true,
              snackbarState: data?.status ? "success" : "error",
            })
          );
          closePopup();
        })
        .catch(() => {
          dispatch(
            setSnackbar({
              snackbarMessage: "Something went wrong with the API ",
              snackbarOpen: true,
              snackbarState: "error",
            })
          );
        })
        .finally(() => {
          dispatch(changeLoader(false));
        });
    } else {
      dispatch(
        setSnackbar({
          snackbarMessage: "Please provide your specific comments and try again",
          snackbarOpen: true,
          snackbarState: "error",
        })
      );
    }
  };

  return (
    <div className="container translation-complete-page">
      <div className="container-modal width1000" style={{ width: "750px", padding: "40px 30px 30px 30px" }}>
        <img src={closeModalIcon} alt="" className="close-new-modal" onClick={() => closePopup()} />
        <div className="popup-boo-container positive">
          <img src={booHappy} width={120} height={120} />
        </div>
        <Scrollbar className="container-modal-mid">
          <Subtitle
            text={strings.reportTranslateProblem}
            color={Colors.nightPurple2}
            textAlign={"center"}
            padding={"0 0 15px 0"}
          />

          <LabelWrapper justifyContent={"center"} padding={"0 0 24px 0"}>
            <LargeBody text={strings.reportDesc1} color={Colors.darkGray} textAlign={"center"} margin={"15px 0px"} />
            <LargeBody text={strings.reportDesc2} color={Colors.darkGray} textAlign={"center"} padding={"0 0 0 4px"} />
          </LabelWrapper>

          <TextAreafield
            onChange={(e) => setText(e.target.value)}
            placeholder="Add comments here"
            style={{ height: "200px !important" }}
          />
          <div className="two-column-modal-40-60"></div>
          <Boxed textAlign={"center"}>
            <MainRoundedButton
              width={"200px"}
              className="border-radius5 report-button"
              text={strings.sendReport}
              margin={"20px 0 0 0"}
              onClick={sendReport}
            />
          </Boxed>
        </Scrollbar>
      </div>
    </div>
  );
};

export default ReportBug;
