import React, {  } from 'react';
import "antd/dist/antd.css";
import * as Colors from "../Styles-Elements/Colors";
import { Subtitle, LargeBody } from "../Styles-Elements/Labels";
import { ClearButton, CustomRoundedButton } from "../Styles-Elements/Buttons";
import booThinking from "../image-assets/boo-thinking.png";


// Material UI for the snackbar
import Scrollbar from "react-smooth-scrollbar";
// Importing localised strings
const strings = require("../localisation_en.json");


const RemoveAllCollaboration = (props) => {

    return (
        <div className="container">
            <div className="container-modal">
                <div className="popup-boo-container negative">
                    <img src={booThinking} width={120} height={120} />
                </div>
                <Scrollbar className="container-modal-mid">
                    <Subtitle
                        text={strings.removeAllCollaboration}
                        color={Colors.nightPurple}
                        textAlign={"center"}
                        padding={"24px 0"}
                    />
                    <LargeBody
                        text={strings.removeAllCollaborationDesc+" "+props.stringName+" string."}
                        textAlign={"center"}
                        color={Colors.darkGray}
                        padding={"0 0 16px 0"}
                    />
                    <CustomRoundedButton
                        text={"Remove All Collaborators"}
                        margin={"24px 0 8px 0"}
                        color={Colors.mainLight}
                        backgroundColor={Colors.lightRed}
                        onClick={props.handleClick}
                    />
                    <ClearButton text={strings.cancel} onClick={() => props.closePopup()} />
                </Scrollbar>

            </div>
        </div>
    );
}

export default RemoveAllCollaboration
