import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import * as Colors from "../Styles-Elements/Colors";
import Scrollbar from "react-smooth-scrollbar";
import { Subheading, Subtitle, Body } from "../Styles-Elements/Labels";
import {
  InputWrapper,
  TextSearchfield,
  Textfield,
} from "../Styles-Elements/Inputs";
import {
  ClearButton,
  MainRoundedButton,
} from "../Styles-Elements/Buttons";
import booNeutral from "../image-assets/boo-neutral.png";
import searchIcon from "../image-assets/searchicon.svg";
import usersAvtar from "../image-assets/user1.png";

import {
  BASE_URL,
  IMPORT_EXPORT_STRING,
  USER_TYPE,
} from "../Helpers/Enums";
// Material UI for the snackbar
// import Box from "@material-ui/core/Box";
import Radio from "@material-ui/core/Radio";
import { Grid } from "@material-ui/core";
import booHappy from "../image-assets/boo-happy.png";
import globalRequest from "../GlobalModule/globalRequest";
import Snackbars from "../GlobalModule/Snackbars";
import emailValidation from "../GlobalModule/emailValidation";
import Loaders from "../GlobalModule/Loaders";
import { useDispatch, useSelector } from "react-redux";
import { tooledUpLocalData } from "../redux/reducers/getTooledupData";
import { stringData } from "../redux/reducers/localString";
import { setSnackbar } from "../redux/reducers/snackbar";
import { changeLoader } from "../redux/reducers/loader";
import { Boxed } from "../Styles-Elements/Box";

const strings = require("../localisation_en.json");

/*
   @props - allUser - @array 
   @props - sbackbarObj - @object
   @props - transferUserData @object
   @props - callback  @callback 
*/

const Transfer = (props) => {
  const auth = useSelector(tooledUpLocalData);
  const strings = useSelector(stringData);
  const dispatch = useDispatch();

  const [allUser, setAllUser] = useState([]);
  const [allUserForSearch, setAllUserForSearch] = useState([]);
  const [id, setId] = useState(0); //this is the transfer id
  const [emailAddress, setEmailAddress] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [loader, setLoader] = useState(false);
  useEffect(() => {
    if (props.allUser) {
      setAllUser(props.allUser);
      setAllUserForSearch(props.allUser);
    }
  }, [props.allUser]);

  const [activeToggleOption, setActiveToggleOption] = useState(
    USER_TYPE.EXISTING_USER
  );

  const radioButton = (id) => {
    setId(id);
  };

  const transferData = async () => {
    if (activeToggleOption == USER_TYPE.EXISTING_USER) {
      if (id == 0) {
        dispatch(
          setSnackbar({
            snackbarMessage: "Please select user first",
            snackbarOpen: true,
            snackbarState: "error",
          })
        );
      } else {
        dispatch(changeLoader(true));
        let response = await globalRequest(
          "post",
          IMPORT_EXPORT_STRING.TRANSFER_DATA_EXISTING_USER,
          {
            user_id: auth?.user_id,
            transfer_user_id: props.transferUserData.id,
            new_user_id: id,
          },
          {},
          true
        );

        let data = response.data;
        if (data.status == 1) {
          dispatch(
            setSnackbar({
              snackbarMessage: data.message,
              snackbarOpen: true,
              snackbarState: "success",
            })
          );
          props.callback();
        } else {
          dispatch(
            setSnackbar({
              snackbarMessage: data.message,
              snackbarOpen: true,
              snackbarState: "error",
            })
          );
        }
        dispatch(changeLoader(false));
      }
    } else if (activeToggleOption == USER_TYPE.NEW_USER) {
      if (firstName == "") {
        dispatch(
          setSnackbar({
            snackbarMessage: "Please, enter the first name ",
            snackbarOpen: true,
            snackbarState: "error",
          })
        );
      } else if (lastName == "") {
        dispatch(
          setSnackbar({
            snackbarMessage: "Please, enter the last name ",
            snackbarOpen: true,
            snackbarState: "error",
          })
        );
      } else if (emailAddress == "") {
        dispatch(
          setSnackbar({
            snackbarMessage: "Please, enter the email ",
            snackbarOpen: true,
            snackbarState: "error",
          })
        );
      } else if (!emailValidation(emailAddress)) {
        dispatch(
          setSnackbar({
            snackbarMessage: "Please, enter the valid email  ",
            snackbarOpen: true,
            snackbarState: "error",
          })
        );
      } else {
        dispatch(changeLoader(true));
        let body = {
          user_id: auth?.user_id,
          team_id: auth?.team_id,
          email_id: emailAddress,
          first_name: firstName,
          last_name: lastName,
          transfer_user_id: props.transferUserData?.id,
        };

        let response = await globalRequest(
          "post",
          IMPORT_EXPORT_STRING.TRANSFER_DATA_NEW_USER,
          body,
          {},
          true
        );

        let data = response.data;
        if (data.status == 1) {
          dispatch(
            setSnackbar({
              snackbarMessage: data.message,
              snackbarOpen: true,
              snackbarState: "success",
            })
          );
          props.callback();
        } else {
          dispatch(
            setSnackbar({
              snackbarMessage: data.message,
              snackbarOpen: true,
              snackbarState: "error",
            })
          );
        }
        dispatch(changeLoader(false));
      }
    }
  };

  const search = (e) => {
    let v = String(e.target.value).toLowerCase();
    if (v == "") {
      setAllUser(allUserForSearch);
    } else {
      let condition = new RegExp(v, "i");
      const users = allUser.filter((e) => {
        if (condition.test(String(e.full_name).toLowerCase())) {
          return e;
        } else if (condition.test(String(e.email_id).toLocaleLowerCase())) {
          return e;
        }
      });
      setAllUser(users);
    }
  };

  const Bodys = () => {
    let name =
      props.transferUserData?.first_name == "" ||
      props.transferUserData?.first_name == " "
        ? props.transferUserData?.email_id
        : props.transferUserData?.first_name +
          " " +
          props.transferUserData?.last_name;

    return (
      <div>
        <Body
          text={`By pressing "Transfer" below, ${name}'s data will be transferred to the new owner. ${name}'s account will then be `}
          textAlign={"center"}
          color={"#7A7A7A"}
          padding={"20px 4px 0px 4px"}
          htmlText={<span style={{ color: "red" }}>permanently deleted.</span>}
        />
      </div>
    );
  };

  return (
    <div className="container">
      <div className="container-modal">
        <div className="popup-boo-container neutral">
          <img src={booNeutral} width={120} height={120} />
        </div>
        <Scrollbar className="container-modal-mid">
          <Subtitle
            text={`Transfer ${
              props.transferUserData?.first_name == "" ||
              props.transferUserData?.first_name == " "
                ? props.transferUserData?.email_id
                : props.transferUserData?.first_name +
                  " " +
                  props.transferUserData?.last_name
            }'s data to....`}
            color={Colors.nightPurple}
            textAlign={"center"}
            padding={"24px 0 24px"}
          />
          <div className="tab-manager-small">
            <div
              className={`tab-small ${
                activeToggleOption === USER_TYPE.EXISTING_USER
                  ? "selected-tab-small"
                  : ""
              }`}
              onClick={() => {
                setActiveToggleOption(USER_TYPE.EXISTING_USER);
              }}
            >
              <Subheading
                text={strings.anExistingUser}
                color={
                  activeToggleOption === USER_TYPE.EXISTING_USER
                    ? Colors.darkPurple
                    : Colors.darkGray
                }
                textAlign={"center"}
              />
            </div>
            <div
              className={`tab-small ${
                activeToggleOption === USER_TYPE.NEW_USER
                  ? "selected-tab-small"
                  : ""
              }`}
              onClick={() => {
                setActiveToggleOption(USER_TYPE.NEW_USER);
              }}
            >
              <Subheading
                text={strings.aNewUser}
                color={
                  activeToggleOption === USER_TYPE.NEW_USER
                    ? Colors.darkPurple
                    : Colors.darkGray
                }
                textAlign={"center"}
              />
            </div>
          </div>
          {activeToggleOption == USER_TYPE.EXISTING_USER ? (
            <div className="popup-selection--box">
              <div>
                <Boxed
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  style={{ padding: "0px 4px 16px 4px" }}
                >
                  <Body
                    text={strings.selectNewOwner}
                    textAlign={"left"}
                    color={Colors.nightGray}
                    padding={"0px 0 0 0"}
                  />
                </Boxed>
              </div>
              <InputWrapper>
                <TextSearchfield
                  placeholder={strings.startTypingAName}
                  backgroundColor={Colors.whiteGray}
                  onChange={search}
                />
                <button className="searchbtn">
                  <img src={searchIcon} className="" />
                </button>
              </InputWrapper>

              <Scrollbar className="popup-scroll-box">
                {allUser.map((row) => {
                  let profileUrl = null;
                  if (row.profile_image) {
                    profileUrl = `${BASE_URL}uploads/profile/${row.id}/${row.profile_image}`;
                  } else {
                    if (row.first_name == "") {
                      profileUrl = booHappy;
                    } else {
                      profileUrl = "nameImage";
                    }
                  }

                  if (
                    (row.access == "owner" && row.id != auth?.user_id) ||
                    row.id == props.transferUserData.id
                  ) {
                  } else {
                    return (
                      <>
                        <Boxed
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                          margin={"0 0 4px 0"}
                        >
                          <Boxed display={"flex"} alignItems={"center"}>
                            {/* <img src={usersAvtar} className="users-avtar" /> */}
                            {profileUrl == "nameImage" ? (
                              <>
                                <b className="sidebar-profile-char">
                                  {String(row.first_name).charAt(0)}{" "}
                                  {String(row.last_name).charAt(0)}
                                </b>
                              </>
                            ) : (
                              <>
                                <img
                                  style={{
                                    height: "50px",
                                    width: "50px",
                                    borderRadius: "40px",
                                  }}
                                  src={profileUrl}
                                />
                              </>
                            )}

                            <Body
                              text={
                                row.first_name == "" || row.first_name == " "
                                  ? row.email_id
                                  : `${row?.first_name} ${row.last_name}`
                              }
                              textAlign={"center"}
                              color={Colors.nightPurple}
                              padding={"0"}
                              margin={"0 12px 0 12px"}
                            />
                          </Boxed>
                          {id == row.id ? (
                            <Radio name="radio-button-demo" checked={true} />
                          ) : (
                            <Radio
                              name="radio-button-demo"
                              onClick={() => radioButton(row.id)}
                              checked={false}
                            />
                          )}
                        </Boxed>
                      </>
                    );
                  }
                })}
              </Scrollbar>
            </div>
          ) : null}
          {activeToggleOption == USER_TYPE.NEW_USER ? (
            <div className="fields-box">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Subheading
                    text={strings.newUserEmailID}
                    color={Colors.nightPurple}
                    padding={"8px 0 8px 0"}
                  />
                  <Textfield
                    value={emailAddress}
                    onChange={(e) => setEmailAddress(e.target.value)}
                    placeholder={strings.enterTheEmailAddressForYourTeamMember}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Subheading
                    text={strings.firstName}
                    color={Colors.nightPurple}
                    padding={"8px 0 8px 0"}
                  />
                  <Textfield
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    placeholder={strings.enterFirstName}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Subheading
                    text={strings.lastName}
                    color={Colors.nightPurple}
                    padding={"8px 0 8px 0"}
                  />
                  <Textfield
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    placeholder={strings.enterLastName}
                  />
                </Grid>
              </Grid>
            </div>
          ) : null}

          {activeToggleOption == USER_TYPE.NEW_USER ? <Bodys /> : null}

          {activeToggleOption == USER_TYPE.EXISTING_USER ? <Bodys /> : null}

          {/* <Body
            text={strings.existingCollaboratorsWillContinueToHave}
            textAlign={"center"}
            color={"#7A7A7A"}
            padding={"32px 0 0px 0"}
          /> */}
          <MainRoundedButton
            onClick={transferData}
            text={strings.transfer}
            margin={"24px 0 8px 0"}
          />
          <ClearButton
            text={strings.cancel}
            onClick={() => props.closePopup()}
          />
        </Scrollbar>
      </div>
      {loader ? <Loaders /> : null}
    </div>
  );
};

export default Transfer;
