import { createSlice } from "@reduxjs/toolkit";

export const autoBuildValues = createSlice({
  name: "autoBuildValues",
  initialState: {
    value: {
      targetJobTitle: "",
      targetIndustry: "",
      roleCoreFunction: "",
      criticalRequirement1: "",
      requirement2: "",
      requirement3: "",
      isApiCall: false,
      string: "",
      isDecontruct: false,
      words: [],
      includeWord: [],
    },
  },
  reducers: {
    changeAutoBuildValue: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { changeAutoBuildValue } = autoBuildValues.actions;
export const currentAutoBuildValue = (state) => state.autoBuildValues.value;
export default autoBuildValues.reducer;
