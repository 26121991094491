import React from "react";
import headerLogo from "../../image-assets/header-logo.png";
import * as Colors from "../Colors";
import { Body, Subtitle, Heading2Bold, Subheading } from "../Labels";
import exitIcon from "../../image-assets/exit-icon.svg";
// import Box from "@material-ui/core/Box";
import "./Header.css";
import { STORAGE } from "../../Helpers/Enums";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { setData } from "../../redux/reducers/getTooledupData";
import { Boxed } from "../Box";

const strings = require("../../localisation_en.json");

const Header = () => {
  let path = window.location.pathname;
  let history = useHistory();
  let dispatch = useDispatch();

  const logout = () => {
    localStorage.removeItem(STORAGE.VIEW_TYPE);
    localStorage.removeItem(STORAGE.TOOLED_UP_LOCAL_DATA);
    dispatch(setData());
    history.push("/");
  };

  return (
    <div className="heading">
      <div className="headin-inner">
        <img height={44} src={headerLogo} alt="" />
        {path == "/" ? null : (
          <Boxed
            display={"flex"}
            alignItems={"center"}
            cursor={"pointer"}
            onClick={logout}
            style={{ cursor: "pointer" }}
          >
            <Body text={strings.logout} color={Colors.nightPurple} padding={"0 12px 0 0"} />
            <img src={exitIcon} width={20} className={"filter-night-purple"} alt="" />
          </Boxed>
        )}
      </div>
    </div>
  );
};

export default Header;
