import { createSlice } from '@reduxjs/toolkit'
import { SIDEBAR_STATE } from '../../Helpers/Enums';

export const pagesChange = createSlice({
    name: 'pagesChange',
    initialState: {
        value: SIDEBAR_STATE.COMPANY_STRINGS,
    },
    reducers: {
        changePage: (state, action) => {
            state.value = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { changePage } = pagesChange.actions
export const currentPage = (state) => state.pagesChange.value;
export default pagesChange.reducer
