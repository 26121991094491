import { createSlice } from "@reduxjs/toolkit";

export const openInvite = createSlice({
  name: "openInvite",
  initialState: {
    value: false,
  },
  reducers: {
    changeOpenInvite: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { changeOpenInvite } = openInvite.actions;
export const currentOpenInvite = (state) => state.openInvite.value;
export default openInvite.reducer;
