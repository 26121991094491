import { useState, useEffect, useRef } from "react";
import * as Colors from "../../../Styles-Elements/Colors";
import { LargeBody, LargeSmall, LargeSmallSemiBold, Subtitle } from "../../../Styles-Elements/Labels";
import { BorderedRoundedButton, MainRoundedButton } from "../../../Styles-Elements/Buttons";
import "./RequirementsGather.css";
import strings from "../../../localisation_en.json";
import booSurprisedImg from "../../image-assets/boo-surprised.png";
import playerPlayCircleIcon from "../../image-assets/player-play-circle.svg";
import infoIcon from "../../image-assets/info.svg";
import { Boxed } from "../../../Styles-Elements/Box";
import { Textfield } from "../../../Styles-Elements/Inputs";
import * as React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import LearnMore from "../../../dialogs/LearnMore";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../../redux/reducers/snackbar";
import { changeLoader } from "../../../redux/reducers/loader";
import globalRequest from "../../../GlobalModule/globalRequest";
import { AUTO_BUILD } from "../../../Helpers/Enums";
import { changeAutoBuildValue, currentAutoBuildValue } from "../../../redux/reducers/autoBuildValues";
import socket from "../../../socket";
import { tooledUpLocalData } from "../../../redux/reducers/getTooledupData";
import getCredit from "../../../GlobalModule/getCredit";
import { changeCredit, currentCredit } from "../../../redux/reducers/getCredit";
import { changeTrialConfirm, currentTrialConfirm } from "../../../redux/reducers/trialConfirm";
import { currentTrial } from "../../../redux/reducers/getTrial";
import UseCreditDialog from "../../../dialogs/UseCreditDialog";

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#FEFEFE",
      color: "rgba(55, 55, 55, 1)",
      maxWidth: 232,
      fontSize: theme.typography.pxToRem(12),
      padding: "15px 15px",
      borderRadius: "6px",
      boxShadow:
        "0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 5px 6px -2px rgba(0, 0, 0, 0.05), 0px 0px 15px -3px rgb(0, 0, 0, 0.10)",
    },
  })
);

let timer = null;

const RequirementsGather = ({ goBack, goForward, stringId }) => {
  const [learnMore, setLearnMore] = useState(false);
  const dispatch = useDispatch();
  const autoBuildVal = useSelector(currentAutoBuildValue);
  const auth = useSelector(tooledUpLocalData);
  const trialData = useSelector(currentTrial);
  const creditData = useSelector(currentCredit);

  const [errors, setErrors] = useState({
    jobTitle: false,
    jobIndustry: false,
    roleCoreFunction: false,
    criticalRequirement1: false,
    requirement2: false,
    requirement3: false,
  });

  const [buttonClicked, setButtonClicked] = useState(false);
  const [openUseCredit, setOpenUseCredit] = useState(false);

  const getId = (id) => {
    return document.getElementById(id);
  };

  const callApi = () => {
    setButtonClicked(true);
    if (autoBuildVal?.isApiCall) {
      goForward(autoBuildVal?.string, autoBuildVal?.words, autoBuildVal?.includeWord);
    } else {
      if (
        autoBuildVal?.targetJobTitle &&
        autoBuildVal?.targetIndustry &&
        autoBuildVal?.roleCoreFunction &&
        autoBuildVal?.criticalRequirement1
      ) {
        dispatch(changeLoader(true));
        globalRequest("post", AUTO_BUILD.AUTO_BUILD_OPEN_AI, autoBuildVal, {}, true)
          .then((res) => {
            let data = res.data;
            if (data.ack == 1) {
              let datas = data.data;
              let string = datas.string;
              let includedWord = datas.includedWord;
              goForward(string, includedWord);
              dispatch(
                changeAutoBuildValue({
                  ...autoBuildVal,
                  isApiCall: true,
                  string: string,
                })
              );
            } else {
              dispatch(
                setSnackbar({
                  snackbarMessage: data.msg,
                  snackbarOpen: true,
                  snackbarState: "error",
                })
              );
            }
          })
          .catch((err) => {
            let msg = err.response.body.msg;
            dispatch(
              setSnackbar({
                snackbarMessage: msg,
                snackbarOpen: true,
                snackbarState: "error",
              })
            );
          })
          .finally(async () => {
            dispatch(changeLoader(false));
            let data = await getCredit(auth?.id);
            dispatch(changeCredit(data));
          });
      }
    }
  };

  const typingCallback = (stringId) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      socket.emit("auto-build-typing", {
        string_id: stringId,
        typing_user_id: auth?.user_id,
        typing_status: "typing",
      });
    }, 500);
  };

  return (
    <>
      <div className="requirement-gather-holder">
        <Boxed display={"flex"} alignItems={"center"} justifyContent={"space-between"} style={{ width: "100%" }}>
          <div className="popup-boo-container positive" style={{ margin: "0", width: "80px", height: "80px" }}>
            <img src={booSurprisedImg} width="80" height="80" alt="" />
          </div>
          <Boxed margin={"0 0 0 80px"}>
            <Subtitle textAlign={"center"} text={strings.requirements} color={Colors.nightGray} />
            <Subtitle textAlign={"center"} text={strings.gatheringTime} color={Colors.nightGray} />
          </Boxed>
          <Boxed display={"flex"} justifyContent={"center"} style={{ gap: "32px" }}>
            <BorderedRoundedButton
              img={<img src={playerPlayCircleIcon} alt="" style={{ marginLeft: "10px" }} className="play-circle" />}
              text={strings.learnMore}
              className={"darkpurple-outline-btn width-auto"}
              margin={"0"}
              onClick={() => setLearnMore(true)}
            />
          </Boxed>
        </Boxed>
        <Boxed display={"flex"} flexDirection={"column"} gap={"0px"}>
          <Boxed display={"flex"} alignItems={"flex-start"} gap={"70px"}>
            <Boxed width={"100%"}>
              <Boxed display={"flex"} alignItems={"flex-start"} justifyContent={"space-between"}>
                <LargeBody text={strings.targetJobTitle} color={Colors.darkPurple} padding={"0 0 8px 0"} />
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <LargeSmallSemiBold
                        text={"Example Target Job Titles:"}
                        color={Colors.nightGray}
                        padding={"0 0 4px 0"}
                      />
                      <ul className="toolt-ul">
                        <li>Accountant</li>
                        <li>Sales Manager</li>
                        <li>Embedded Software Engineer</li>
                        <li>Cyber Security Manager</li>
                      </ul>
                    </React.Fragment>
                  }
                  placement="left-end"
                >
                  <img src={infoIcon} className="info-icon" alt="" />
                </HtmlTooltip>
              </Boxed>
              <div className="input-group">
                <Textfield
                  placeholder={strings.enterJobTitle}
                  type={"text"}
                  required={true}
                  error={String(autoBuildVal?.targetJobTitle).split(/\s/g).length > 5 ? true : false}
                  value={autoBuildVal?.targetJobTitle}
                  id={"targetJob"}
                  onKeyDown={(e) => {
                    let active = document.activeElement;
                    let id = active.id;
                    if (e.shiftKey && e.key === "Tab") {
                      e.preventDefault();
                    } else if (e.key == "Tab") {
                      if (id == "targetJob") {
                        getId("targetIndustry").focus();
                        e.preventDefault();
                      }
                    }
                  }}
                  onChange={(e) => {
                    let value = e.target.value;
                    typingCallback(stringId);
                    let split = String(value).split(" ");
                    if (split.length > 5) {
                      setErrors({
                        ...errors,
                        jobTitle: true,
                      });
                    } else {
                      dispatch(
                        changeAutoBuildValue({
                          ...autoBuildVal,
                          targetJobTitle: value,
                          isApiCall: false,
                          string: "",
                        })
                      );
                      setErrors({
                        ...errors,
                        jobTitle: false,
                      });
                    }
                  }}
                  className={`input-error  ${errors.jobTitle ? "focus" : ""}`}
                />

                {buttonClicked ? (
                  autoBuildVal?.targetJobTitle ? null : (
                    <small className="input-error-text">{strings.thisFieldIsMandatory}</small>
                  )
                ) : null}
                {errors?.jobTitle ? <small className="input-error-text">{strings.max5word}</small> : null}
              </div>
            </Boxed>
            <Boxed width={"100%"}>
              <Boxed display={"flex"} alignItems={"flex-start"} justifyContent={"space-between"}>
                <LargeBody text={strings.criticalRequirement1} color={Colors.darkPurple} padding={"0 0 8px 0"} />
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <LargeSmallSemiBold
                        text={"Critical Requirement"}
                        color={Colors.nightGray}
                        padding={"0 0 4px 0"}
                      />
                      <LargeSmall
                        text={
                          "For example, this could be a specific skill, framework, product focus, software tool, qualification, certification, standard etc."
                        }
                        color={Colors.nightGray}
                        padding={"0 0 0px 0"}
                      />
                    </React.Fragment>
                  }
                  placement="left-end"
                >
                  <img src={infoIcon} className="info-icon" alt="" />
                </HtmlTooltip>
              </Boxed>
              <div className="input-group">
                <Textfield
                  placeholder={strings.enterCriticalRequirement1}
                  type={"text"}
                  required={true}
                  maxLength={100}
                  className={`input-error  ${errors.criticalRequirement1 ? "focus" : ""}`}
                  onKeyDown={(e) => {
                    let active = document.activeElement;
                    let id = active.id;
                    if (e.shiftKey && e.key === "Tab") {
                      if (id == "criticalRequirement") {
                        getId("roleCoreFunction").focus();
                        e.preventDefault();
                      }
                      e.preventDefault();
                    } else if (e.key == "Tab") {
                      if (id == "criticalRequirement") {
                        getId("requirement2").focus();
                        e.preventDefault();
                      }
                    }
                  }}
                  error={autoBuildVal?.criticalRequirement1 ? true : false}
                  value={autoBuildVal?.criticalRequirement1}
                  id={"criticalRequirement"}
                  onChange={(e) => {
                    let value = e.target.value;
                    typingCallback(stringId);
                    dispatch(
                      changeAutoBuildValue({
                        ...autoBuildVal,
                        criticalRequirement1: value,
                        isApiCall: false,
                        string: "",
                        words: [],
                      })
                    );
                    setErrors({
                      ...errors,
                      criticalRequirement1: false,
                    });
                  }}
                />
                {buttonClicked ? (
                  autoBuildVal?.criticalRequirement1 ? null : (
                    <small className="input-error-text">{strings.thisFieldIsMandatory}</small>
                  )
                ) : null}
                {errors?.criticalRequirement1 ? <small className="input-error-text">{strings.max2word}</small> : null}
              </div>
            </Boxed>
          </Boxed>
          <Boxed display={"flex"} alignItems={"flex-start"} gap={"70px"}>
            <Boxed width={"100%"}>
              <Boxed display={"flex"} alignItems={"flex-start"} justifyContent={"space-between"}>
                <LargeBody text={strings.targetIndustry} color={Colors.darkPurple} padding={"0 0 8px 0"} />
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <LargeSmallSemiBold
                        text={"Example Target Industries:"}
                        color={Colors.nightGray}
                        padding={"0 0 4px 0"}
                      />
                      <ul className="toolt-ul">
                        <li>Automotive</li>
                        <li>Financial Services</li>
                        <li>Fintech</li>
                        <li>Industrial Automation</li>
                      </ul>
                    </React.Fragment>
                  }
                  placement="left-end"
                >
                  <img src={infoIcon} className="info-icon" alt="" />
                </HtmlTooltip>
              </Boxed>
              <div className="input-group">
                <Textfield
                  placeholder={strings.enterTheTargetIndustry}
                  type={"text"}
                  required={true}
                  maxLength={100}
                  className={`input-error  ${errors.targetIndustry ? "focus" : ""}`}
                  error={autoBuildVal?.targetIndustry ? true : false}
                  value={autoBuildVal?.targetIndustry}
                  id={"targetIndustry"}
                  onKeyDown={(e) => {
                    let active = document.activeElement;
                    let id = active.id;
                    if (e.shiftKey && e.key === "Tab") {
                      if (id == "targetIndustry") {
                        getId("targetJob").focus();
                        e.preventDefault();
                      }
                      e.preventDefault();
                    } else if (e.key == "Tab") {
                      if (id == "targetIndustry") {
                        getId("roleCoreFunction").focus();
                        e.preventDefault();
                      }
                    }
                  }}
                  onChange={(e) => {
                    let value = e.target.value;
                    typingCallback(stringId);
                    dispatch(
                      changeAutoBuildValue({
                        ...autoBuildVal,
                        targetIndustry: value,
                        isApiCall: false,
                        string: "",
                      })
                    );
                    setErrors({
                      ...errors,
                      targetIndustry: false,
                    });
                  }}
                />
                {buttonClicked ? (
                  autoBuildVal?.targetIndustry ? null : (
                    <small className="input-error-text">{strings.thisFieldIsMandatory}</small>
                  )
                ) : null}
                {errors?.targetIndustry ? <small className="input-error-text">{strings.max2word}</small> : null}
              </div>
            </Boxed>
            <Boxed width={"100%"}>
              <Boxed display={"flex"} alignItems={"flex-start"} justifyContent={"space-between"}>
                <LargeBody text={strings.requirement2Optional} color={Colors.darkPurple} padding={"0 0 8px 0"} />
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <LargeSmallSemiBold
                        text={"Requirement 2 (Optional)"}
                        color={Colors.nightGray}
                        padding={"0 0 4px 0"}
                      />
                      <LargeSmall
                        text={
                          "This could be an essential or desirable skill, framework, product focus, tool, qualification, certification, standard etc."
                        }
                        color={Colors.nightGray}
                        padding={"0 0 0px 0"}
                      />
                    </React.Fragment>
                  }
                  placement="left-end"
                >
                  <img src={infoIcon} className="info-icon" alt="" />
                </HtmlTooltip>
              </Boxed>
              <div className="input-group">
                <Textfield
                  placeholder={strings.enterRequirement2}
                  type={"text"}
                  required={true}
                  maxLength={100}
                  value={autoBuildVal?.requirement2}
                  className={`input-error  ${errors.requirement2 ? "focus" : ""}`}
                  id={"requirement2"}
                  onKeyDown={(e) => {
                    let active = document.activeElement;
                    let id = active.id;
                    if (e.shiftKey && e.key === "Tab") {
                      if (id == "requirement2") {
                        getId("criticalRequirement").focus();
                        e.preventDefault();
                      }
                      e.preventDefault();
                    } else if (e.key == "Tab") {
                      if (id == "requirement2") {
                        getId("requirement3").focus();
                        e.preventDefault();
                      }
                    }
                  }}
                  onChange={(e) => {
                    let value = e.target.value;
                    typingCallback(stringId);
                    dispatch(
                      changeAutoBuildValue({
                        ...autoBuildVal,
                        requirement2: value,
                        isApiCall: false,
                        string: "",
                      })
                    );
                    setErrors({
                      ...errors,
                      requirement2: false,
                    });
                  }}
                />
                {errors?.requirement2 ? <small className="input-error-text">{strings.max2word}</small> : null}
              </div>
            </Boxed>
          </Boxed>
          <Boxed display={"flex"} alignItems={"flex-start"} gap={"70px"}>
            <Boxed width={"100%"}>
              <Boxed display={"flex"} alignItems={"flex-start"} justifyContent={"space-between"}>
                <LargeBody text={strings.theRolesCoreFunction} color={Colors.darkPurple} padding={"0 0 8px 0"} />
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <LargeSmallSemiBold
                        text={"The Role’s Core Function"}
                        color={Colors.nightGray}
                        padding={"0 0 4px 0"}
                      />
                      <LargeSmall
                        text={
                          "For example, the core function of a Project Manager would be “Project Management”. The core function of an Accountant, would be “Accounting”."
                        }
                        color={Colors.nightGray}
                        padding={"0 0 0px 0"}
                      />
                    </React.Fragment>
                  }
                  placement="left-end"
                >
                  <img src={infoIcon} className="info-icon" alt="" />
                </HtmlTooltip>
              </Boxed>
              <div className="input-group">
                <Textfield
                  placeholder={strings.enterCoreFunction}
                  type={"text"}
                  required={true}
                  maxLength={100}
                  error={autoBuildVal?.roleCoreFunction ? true : false}
                  value={autoBuildVal?.roleCoreFunction}
                  id={"roleCoreFunction"}
                  onKeyDown={(e) => {
                    let active = document.activeElement;
                    let id = active.id;
                    if (e.shiftKey && e.key === "Tab") {
                      if (id == "roleCoreFunction") {
                        getId("targetIndustry").focus();
                        e.preventDefault();
                      }
                      e.preventDefault();
                    } else if (e.key == "Tab") {
                      if (id == "roleCoreFunction") {
                        getId("criticalRequirement").focus();
                        e.preventDefault();
                      }
                    }
                  }}
                  onChange={(e) => {
                    let value = e.target.value;
                    typingCallback(stringId);
                    let split = String(value).split(" ");
                    dispatch(
                      changeAutoBuildValue({
                        ...autoBuildVal,
                        roleCoreFunction: value,
                        isApiCall: false,
                        string: "",
                      })
                    );
                    setErrors({
                      ...errors,
                      roleCoreFunction: false,
                    });
                  }}
                  className={`input-error  ${errors.roleCoreFunction ? "focus" : ""}`}
                />
                {buttonClicked ? (
                  autoBuildVal?.roleCoreFunction ? null : (
                    <small className="input-error-text">{strings.thisFieldIsMandatory}</small>
                  )
                ) : null}
                {errors?.roleCoreFunction ? <small className="input-error-text">{strings.max2word}</small> : null}
              </div>
            </Boxed>
            <Boxed width={"100%"}>
              <Boxed display={"flex"} alignItems={"flex-start"} justifyContent={"space-between"}>
                <LargeBody text={strings.requirement3Optional} color={Colors.darkPurple} padding={"0 0 8px 0"} />
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <LargeSmallSemiBold
                        text={"Requirement 3 (Optional)"}
                        color={Colors.nightGray}
                        padding={"0 0 4px 0"}
                      />
                      <LargeSmall
                        text={
                          "This could be an essential or desirable skill, framework, product focus, tool, qualification, certification, standard etc."
                        }
                        color={Colors.nightGray}
                        padding={"0 0 0px 0"}
                      />
                    </React.Fragment>
                  }
                  placement="left-end"
                >
                  <img src={infoIcon} className="info-icon" alt="" />
                </HtmlTooltip>
              </Boxed>
              <div className="input-group">
                <Textfield
                  placeholder={strings.enterRequirement3}
                  type={"text"}
                  required={true}
                  maxLength={100}
                  className={`input-error  ${errors.requirement3 ? "focus" : ""}`}
                  value={autoBuildVal?.requirement3}
                  id={"requirement3"}
                  onKeyDown={(e) => {
                    let active = document.activeElement;
                    let id = active.id;
                    if (e.shiftKey && e.key === "Tab") {
                      if (id == "requirement3") {
                        getId("requirement2").focus();
                        e.preventDefault();
                      }
                      e.preventDefault();
                    } else if (e.key == "Tab") {
                      if (id == "requirement3") {
                        getId("go").focus();
                        e.preventDefault();
                      }
                    }
                  }}
                  onChange={(e) => {
                    let value = e.target.value;
                    typingCallback(stringId);
                    dispatch(
                      changeAutoBuildValue({
                        ...autoBuildVal,
                        requirement3: value,
                        isApiCall: false,
                        string: "",
                      })
                    );
                    setErrors({
                      ...errors,
                      requirement3: false,
                    });
                  }}
                />
                {errors?.requirement3 ? <small className="input-error-text">{strings.max2word}</small> : null}
              </div>
            </Boxed>
          </Boxed>
        </Boxed>
        <Boxed display={"flex"} justifyContent={"center"} margin={"12px 0 0 0"} gap={"24px"}>
          <BorderedRoundedButton
            onClick={() => goBack()}
            text={strings.back}
            className={"sharpgreen-outline-btn max-w-200"}
            margin={"0"}
          />
          <MainRoundedButton
            onClick={() => {
              if (trialData?.status === "trial") {
                setButtonClicked(true);
                if (autoBuildVal?.isApiCall) {
                  goForward(autoBuildVal?.string, autoBuildVal?.words, autoBuildVal?.includeWord);
                } else {
                  if (
                    autoBuildVal?.targetJobTitle &&
                    autoBuildVal?.targetIndustry &&
                    autoBuildVal?.roleCoreFunction &&
                    autoBuildVal?.criticalRequirement1
                  ) {
                    setOpenUseCredit(true);
                  }
                }
              } else {
                callApi();
              }
            }}
            text={strings.go}
            className={"sharpgreen-fill-btn max-w-200"}
            margin={"0"}
            id={"go"}
          />
        </Boxed>
      </div>
      {learnMore ? <LearnMore closePopup={() => setLearnMore(false)} /> : null}

      {openUseCredit ? (
        <UseCreditDialog
          closePopup={() => {
            setOpenUseCredit(false);
          }}
          type={"autoBuild"}
          creditData={creditData}
          submit={() => {
            setOpenUseCredit(false);
            callApi();
          }}
        />
      ) : null}
    </>
  );
};
export default RequirementsGather;
