import { useState } from 'react';
import './SignupInvited.css';
import booInvitedWelcome from '../../image-assets/Boo-Invited-Welcome.png'
import Header from '../../Styles-Elements/Heading/Header';
import { Heading2Bold, Subheading, LargeBody } from '../../Styles-Elements/Labels';
import { MainRoundedButton } from '../../Styles-Elements/Buttons';
import { Textfield } from '../../Styles-Elements/Inputs';
import * as Colors from '../../Styles-Elements/Colors'
// Importing localised strings
const strings = require('../../localisation_en.json')
function SignupInvited(props) {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  function signupUser() {

  }

  return (
    <div className="page-background">
      <Header />
      <div className="grid-container">
        <div className="grid-header">
          <Heading2Bold text={strings.welcomeToTooledUpRaccoons}
            color={Colors.nightPurple}
            textAlign={"center"}
          />
          <LargeBody text={strings.meetBooTheBooleanNinjaWhoWillAssistYou}
            color={Colors.nightPurple}
            textAlign={"center"} />
        </div>
        <div className="grid-item">
          <img src={booInvitedWelcome} className="signup-booinvitedwel" alt='' />
        </div>
        <div className="grid-item">
          <Subheading text={strings.firstName}
            color={Colors.nightPurple}
            padding={'0 0 8px 0'} />
          <Textfield placeholder={strings.enterYourFirstName}
            onChange={(event) => setFirstName(event.target.value)} />
        </div>
        <div className="grid-item">
          <Subheading text={strings.lastName}
            color={Colors.nightPurple}
            padding={'16px 0 8px 0'} />
          <Textfield placeholder={strings.enterYourLastName}
            onChange={(event) => setLastName(event.target.value)} />
        </div>
        <div className="grid-item">
          <Subheading text={strings.email}
            color={Colors.nightPurple}
            padding={'16px 0 8px 0'} />
          <Textfield placeholder={strings.enterYourEmail}
            onChange={(event) => setEmail(event.target.value)} />
        </div>
        <div className="grid-item">
          <Subheading text={strings.phoneNumber}
            color={Colors.nightPurple}
            padding={'16px 0 8px 0'} />
          <Textfield placeholder={strings.enterYourPhoneNumber}
            onChange={(event) => setPhoneNumber(event.target.value)} />
        </div>
        <div className="grid-item">
          <MainRoundedButton text={strings.confirmDetails}
            margin={'24px 0 16px 0'}
            onClick={() => signupUser()} />
        </div>
      </div>
    </div>)
}

export default SignupInvited;