import { createSlice } from "@reduxjs/toolkit";

export const onTranslate = createSlice({
  name: "onTranslate",
  initialState: {
    value: false,
  },
  reducers: {
    changeOnTranslate: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { changeOnTranslate } = onTranslate.actions;
export const currentOnTranslate = (state) => state.onTranslate.value;
export default onTranslate.reducer;
