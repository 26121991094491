const validPasswordCheck = (password) => {
    let lowerCase = /[a-z]/g;
    let upperCase = /[A-Z]/g;
    let number = /[0-9]/g;
    let specialChar = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g;

    if (
        password.match(lowerCase) &&
        password.match(upperCase) &&
        password.match(number) &&
        password.match(specialChar) &&
        password.length >= 8
    ) {
        return true;
    } else {
        return false;
    }
};

export default validPasswordCheck;