import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "antd/dist/antd.css";
import { makeStyles } from "@material-ui/core/styles";
import * as Colors from "../Styles-Elements/Colors";
import { Subtitle, Subheading, Body, LargeBody } from "../Styles-Elements/Labels";
import { ClearButton, MainRoundedButton } from "../Styles-Elements/Buttons";

import booHappy from "../image-assets/boo-happy.png";
import dropdownIconLight from "../image-assets/dropdown-icon-light.svg";

// Material UI for the snackbar
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

// import Box from "@material-ui/core/Box";
import CreateFolder from "./CreateFolder";
import getFolder from "../apis/getFolder";
import { KEYWORD_STRING, NEW_API } from "../Helpers/Enums";
import CollabFolderAndMove from "./CollabFolderAndMove";
import closePopUpByKey from "../GlobalModule/closePopUpByKey";
import { tooledUpLocalData } from "../redux/reducers/getTooledupData";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../redux/reducers/snackbar";
import globalRequest from "../GlobalModule/globalRequest";
import { currentSidebar } from "../redux/reducers/sidebar";
import ShowProfile from "../GlobalModule/ShowProfile";
import { Boxed } from "../Styles-Elements/Box";
import { Autocomplete, TextField } from "@mui/material";

// Importing localised strings
const strings = require("../localisation_en.json");

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  dropdownmenu: {
    position: "absolute",
    top: 55,
    right: 0,
    left: 1,
    zIndex: 1,
    border: "none",
    boxShadow:
      "0px 0px 15px -3px rgba(0, 0, 0, 0.1), 0px 5px 6px -2px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
    padding: "0",
    borderRadius: "6px",
    backgroundColor: "#fff",
  },
}));

const MoveStringToFolder = (props) => {
  const stringId = props.stringId;
  const type = props.type;

  const auth = useSelector(tooledUpLocalData);
  const sidebar = useSelector(currentSidebar);
  const dispatch = useDispatch();

  const [openFolder, setOpenFolder] = useState(false);
  const classes = useStyles();
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [show, setShow] = useState(false);
  const [folder, setFolder] = useState([]);
  const [allFolderData, setAllFolderData] = useState([]);
  const [selectedFolderName, setSelectedFolderName] = useState(strings.selectAFolder);
  const [folderForRequest, setFolderForRequest] = useState("");
  const [folderIdForRequest, setFolderIdForRequest] = useState(0);
  const [createdId, setCreatedId] = useState(0);
  const [openPopUp, setOpenPopUp] = useState(false);

  const [userName, setUserName] = useState(strings.myFoldersDefault);
  const [userId, setUserId] = useState(0);
  const [userData, setUserData] = useState([]);

  const [selectedFolder, setSelectedFolder] = useState({
    id: 0,
    folder_name: "Select a folder",
  });
  const [selectedUser, setSelectedUser] = useState({
    name: strings.myFoldersDefault,
    id: 0,
  });

  useEffect(() => {
    closePopUpByKey(props.closePopup);
  }, []);

  const submitRequest = async () => {
    if (folderForRequest == "") {
      dispatch(
        setSnackbar({
          snackbarMessage: "Folder name cannot be empty.",
          snackbarOpen: true,
          snackbarState: "error",
        })
      );
    } else {
      const userIds = auth.access == "user" ? auth?.user_id : userId;
      const response = await globalRequest(
        "post",
        KEYWORD_STRING.MOVE_STRING,
        {
          type: type,
          user_id: userIds,
          folder_id: folderIdForRequest,
          created_by: createdId,
          string_id: stringId,
        },
        {},
        true
      );

      if (response.data.status == 1) {
        dispatch(
          setSnackbar({
            snackbarMessage: response.data.message,
            snackbarOpen: true,
            snackbarState: "success",
          })
        );
        localStorage.removeItem("string");
        props.closePopup();
        props.callback(true, folderIdForRequest);
      } else {
        dispatch(
          setSnackbar({
            snackbarMessage: response.data.message,
            snackbarOpen: true,
            snackbarState: "error",
          })
        );
      }
    }
  };

  const getFolderData = () => {
    getFolder(auth?.user_id, (response) => {
      let data = response.data;
      if (data.status == 1) {
        let datas = data?.data.sort((a, b) => {
          let a1 = String(a.folder_name).toLowerCase();
          let b1 = String(b.folder_name).toLowerCase();
          if (a1 < b1) {
            return -1;
          }
          return 1;
        });

        setFolder(datas);
        setAllFolderData(datas);
      }
    });
  };

  useEffect(() => {
    getFolderData();
  }, []);

  const handleClickAway1 = () => {
    setOpen1(false);
  };
  const handleClickAway2 = () => {
    setOpen2(false);
  };
  const handleClickAway3 = () => {
    setOpen3(false);
  };

  const handleClick = () => {
    setShow(!show);
    setOpen1((prev) => !prev);
    setOpen2((prev) => !prev);
    setOpen3((prev) => !prev);
  };

  const folderOpen = () => {
    setOpenFolder(true);
  };

  const callPopUp = () => {
    if (folderForRequest == "") {
      dispatch(
        setSnackbar({
          snackbarMessage: "Folder name cannot be empty.",
          snackbarOpen: true,
          snackbarState: "error",
        })
      );
    } else {
      setOpenPopUp(true);
    }
  };

  const callUserData = async () => {
    let response = await globalRequest(
      "get",
      NEW_API.FOLDER_USER_LIST,
      {},
      {
        params: {
          user_id: auth.user_id,
          team_id: auth.team_id,
          string_type: sidebar,
        },
      },
      true
    );

    let data = response.data;
    if (data.status == 1) {
      let mainData = data.data;
      setUserData(mainData);
    }
  };

  useEffect(() => {
    callUserData();
  }, []);

  useEffect(() => {
    if (userId != 0) {
      callFolder();
      setFolderForRequest("");
      setFolderIdForRequest(0);
      setSelectedFolderName(strings.selectAFolder);
    }
  }, [userId]);

  const callFolder = async () => {
    const response = await globalRequest(
      "get",
      NEW_API.FOLDER_LIST,
      {},
      {
        params: {
          user_id: userId,
          string_type: sidebar,
        },
      },
      true
    );
    let data = response.data;
    if (data.status == 1) {
      setFolder(data.data);
    }
  };

  return (
    <div className="container">
      <div className="container-modal">
        <div className="popup-boo-container neutral">
          <img alt="" src={booHappy} width={120} height={120} />
        </div>
        <Boxed className="container-modal-mid" style={{ overflowY: "hidden" }}>
          {auth?.access == "user" ? (
            <>
              <Subtitle
                text={strings.moveYourStringToAFolder}
                color={Colors.nightPurple}
                textAlign={"center"}
                padding={"24px 0 16px"}
              />

              <Subheading text={strings.folderToAddYourStringTo} color={Colors.nightPurple} padding={"16px 0 8px 0"} />
              <div className="msf-dropd1">
                <div className="ks-drop-block">
                  <Autocomplete
                    options={folder}
                    getOptionLabel={(option) => option.folder_name}
                    onChange={(event, value) => {
                      if (value !== null) {
                        setFolderForRequest(value.folder_name);
                        setSelectedFolderName(value.folder_name);
                        setFolderIdForRequest(value.id);
                        setCreatedId(value.created_by);
                        setSelectedFolder(value);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        style={{
                          fontSize: "19px",
                        }}
                      />
                    )}
                    value={selectedFolder}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <Subheading text={strings.selectAUser} color={Colors.nightPurple} padding={"16px 0 8px 0"} />
              <div className="msf-dropd1">
                <div className="ks-drop-block">
                  <Autocomplete
                    options={userData}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => {
                      if (value !== null) {
                        setUserName(value.name ? value.name : value.email_id);
                        setUserId(value.id);
                        setSelectedUser({
                          name: value.name ? value.name : value.email_id,
                          id: value.id,
                        });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        style={{
                          fontSize: "19px",
                        }}
                      />
                    )}
                    value={selectedUser}
                  />
                </div>
              </div>
              <Subheading text={strings.selectAFolder} color={Colors.nightPurple} padding={"16px 0 8px 0"} />
              <div className="msf-dropd1">
                <div className="ks-drop-block">
                  <Autocomplete
                    options={folder}
                    getOptionLabel={(option) => option.folder_name}
                    onChange={(event, value) => {
                      if (value !== null) {
                        setFolderForRequest(value.folder_name);
                        setSelectedFolderName(value.folder_name);
                        setFolderIdForRequest(value.id);
                        setCreatedId(value.created_by);
                        setSelectedFolder(value);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        style={{
                          fontSize: "19px",
                        }}
                      />
                    )}
                    value={selectedFolder}
                  />
                </div>
              </div>
            </>
          )}

          <MainRoundedButton
            text={strings.moveString}
            margin={"24px 0 8px 0"}
            // onClick={() => submitRequest()}
            onClick={createdId == auth?.user_id ? submitRequest : callPopUp}
          />
          <ClearButton text={strings.cancel} onClick={() => props.closePopup()} />
        </Boxed>
      </div>

      {openFolder ? (
        <CreateFolder
          closePopup={() => setOpenFolder(false)}
          callback={(bool) => {
            if (bool) {
              getFolderData();
            }
          }}
        />
      ) : null}

      {openPopUp ? (
        <CollabFolderAndMove
          stringName={props.stringName}
          closePopup={() => setOpenPopUp(false)}
          submitRequest={submitRequest}
        />
      ) : null}
    </div>
  );
};

export default MoveStringToFolder;
