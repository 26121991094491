import { MAIN_TEXT, MISSING_OPERATOR_ERROR } from "../Helpers/Enums";

const showError = (parent, data, isErrorCallback, errMsgCallback) => {
  const {
    checkInitial,
    duplicateOperator,
    ifOnlyQuotes,
    missingOperator,
    missingQuotation,
    openingClosingPara,
    noQuotes,
  } = data;

  let id = parent.id;
  let zeroIndex = `${MAIN_TEXT}-firstid`;

  const error = (start, end) => {
    for (let i = start; i < end; i++) {
      try {
        parent.children[i].className = "error";
      } catch (e) {}
    }
  };

  if (missingQuotation.length) {
    isErrorCallback(true);
    errMsgCallback(MISSING_OPERATOR_ERROR.QUOTATION);
    missingQuotation.forEach((obj) => {
      error(obj.start, obj.start + 10);
    });
  } else if (duplicateOperator.length) {
    isErrorCallback(true);
    errMsgCallback(MISSING_OPERATOR_ERROR.DUPLICATE);
    duplicateOperator.forEach((obj) => {
      error(obj.start, obj.end);
    });
  } else if (ifOnlyQuotes.length) {
    errMsgCallback(MISSING_OPERATOR_ERROR.ONLY_QUOTES);
    isErrorCallback(true);
    ifOnlyQuotes.forEach((obj) => {
      error(obj.start - 4, obj.end + 4);
    });
  } else if (missingOperator.length) {
    isErrorCallback(true);
    errMsgCallback(MISSING_OPERATOR_ERROR.OPERATOR);
    missingOperator.forEach((obj) => {
      let start = obj[0];
      let end = obj[1];
      error(start, end);
    });
  } else if (openingClosingPara.opening.length) {
    isErrorCallback(true);
    errMsgCallback(MISSING_OPERATOR_ERROR.CLOSE_PARA);
    openingClosingPara.opening.forEach((obj) => {
      let start = obj;
      let end = obj + 10;
      error(start, end);
    });
  } else if (openingClosingPara.closing.length) {
    isErrorCallback(true);
    errMsgCallback(MISSING_OPERATOR_ERROR.OPEN_PARA);
    openingClosingPara.closing.forEach((obj) => {
      let start = obj - 10;
      let end = obj;
      error(start, end);
    });
  } else if (checkInitial.length) {
    if (id != zeroIndex) {
      isErrorCallback(true);
      checkInitial.forEach((obj, ind) => {
        if (ind === 0) {
          let msg = "";
          if (obj?.fors == "deconstruct") {
            msg = MISSING_OPERATOR_ERROR.INITIAL_AND_NOT;
          } else {
            if (obj.at === "start") {
              msg =
                obj.type == "and"
                  ? MISSING_OPERATOR_ERROR.CANNOT_START_OPERATOR.AND
                  : MISSING_OPERATOR_ERROR.CANNOT_START_OPERATOR.OR;
            } else if (obj.at === "end") {
              msg =
                obj.type == "and"
                  ? MISSING_OPERATOR_ERROR.CANNOT_END_OPERATOR.AND
                  : obj.type == "not"
                  ? MISSING_OPERATOR_ERROR.CANNOT_END_OPERATOR.NOT
                  : MISSING_OPERATOR_ERROR.CANNOT_END_OPERATOR.OR;
            }
          }
          errMsgCallback(msg);
        }
        error(obj.start, obj.end);
      });
    }
  } else if (noQuotes.length) {
    isErrorCallback(true);
    errMsgCallback(MISSING_OPERATOR_ERROR.QUOTATION);
    noQuotes.forEach((ind, index) => {
      let start = ind.start;
      let end = ind.end;
      error(start, end);
    });
  } else {
    isErrorCallback(false);
    errMsgCallback("");
  }
};

export default showError;
