import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import { Subtitle, LargeBody, Subheading } from "../Styles-Elements/Labels";
import { ClearButton } from "../Styles-Elements/Buttons";
import "antd/dist/antd.css";
import * as Colors from "../Styles-Elements/Colors";
import booThinking from "../image-assets/boo-happy.png";
import dropdownIconLight from "../image-assets/dropdown-icon-light.svg";
import { CustomRoundedButton } from "../Styles-Elements/Buttons";
import { LabelWrapper } from "../Styles-Elements/Labels";
import { makeStyles } from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../redux/reducers/snackbar";
import { tooledUpLocalData } from "../redux/reducers/getTooledupData";
import { NEW_MODULE_API } from "../Helpers/Enums";
import globalRequest from "../GlobalModule/globalRequest";
import { changeLoader } from "../redux/reducers/loader";
import { changeAlert, currentAlert } from "../redux/reducers/enrichAlert";

const strings = require("../localisation_en.json");
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  root: {
    position: "relative",
  },
  dropdown: {
    position: "absolute",
    top: 35,
    right: 0,
    left: 0,
    zIndex: 10,
    border: "none",
    padding: "0",
    backgroundColor: "transparent",
  },
  dropdownmenu: {
    position: "absolute",
    bottom: 55,
    right: 0,
    left: 0,
    zIndex: 10,
    border: "none",
    boxShadow:
      "0px 0px 15px -3px rgba(0, 0, 0, 0.1), 0px 5px 6px -2px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
    padding: "8px 16px 0px 16px",
    borderRadius: "6px",
    backgroundColor: "#fff",
    overflowX: "hidden",
    overflowY: "auto",
    height: "180px",
  },
  dropdownicons: {
    width: "190px",
    position: "absolute",
    top: 40,
    right: 0,
    left: 0,
    zIndex: 1,
    border: "none",
    boxShadow:
      "0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
    borderRadius: "6px",
    backgroundColor: "#fff",
    padding: "4px",
  },
}));

const KeywordAlertFrequency = ({ closePopup }) => {
  const classes = useStyles();
  const [open2, setOpen2] = React.useState(false);
  const alerts = ["Never", "Instant", "Daily", "Weekly", "Monthly"];
  const [alert, setAlert] = useState("");
  const dispatch = useDispatch();
  const auth = useSelector(tooledUpLocalData);
  const alertValue = useSelector(currentAlert);

  const handleClick2 = () => {
    setOpen2((prev) => !prev);
  };

  const handleClickAway2 = () => {
    setOpen2(false);
  };

  useEffect(() => {
    if (alertValue) {
      setAlert(alertValue);
    }
  }, [alertValue]);

  const submitAlert = async () => {
    if (alert) {
      dispatch(changeLoader(true));
      let res = await globalRequest(
        "post",
        NEW_MODULE_API.UPDATE_ENRICH_ALERT,
        { userId: auth?.id, alert: alert },
        {},
        true
      );
      let data = res.data;
      if (data?.ack === 1) {
        dispatch(changeAlert(alert));
      }
      dispatch(
        setSnackbar({
          snackbarMessage: data.msg,
          snackbarOpen: true,
          snackbarState: data?.ack === 1 ? "success" : "error",
        })
      );
      dispatch(changeLoader(false));
      closePopup();
    } else {
      dispatch(
        setSnackbar({
          snackbarMessage: "Alert should not be empty in order to continue",
          snackbarOpen: true,
          snackbarState: "error",
        })
      );
    }
  };

  return (
    <div className="container">
      <div className="container-modal">
        <div className="popup-boo-container positive">
          <img src={booThinking} width={120} height={120} alt="" />
        </div>
        <div className="container-modal-mid">
          <Subtitle
            text={strings.keywordAlertFrequency}
            color={Colors.nightPurple}
            textAlign={"center"}
            padding={"24px 0 16px 0"}
          />
          <LabelWrapper display={"flex"} justifyContent={"center"} padding={"0 0 32px 0"}>
            <LargeBody
              text={strings.wantToBeNotifiedWhenWeHaveIdentifiedNewKeywordSuggestionsForYou}
              color={Colors.darkGray}
              textAlign={"center"}
              padding={"0 0 0px 0"}
            />
          </LabelWrapper>
          <Subheading text={strings.selectAlertFrequency} color={"#423069"} padding={"0 0 8px 0"} />
          <div>
            <ClickAwayListener onClickAway={handleClickAway2}>
              <div className={classes.root}>
                <button className="dropdown-holder-style" onClick={handleClick2}>
                  <LargeBody text={alert ? alert : "Select Alert"} />
                  <img src={dropdownIconLight} className="dropdown-icon-holder" alt="" />
                </button>
                {open2 ? (
                  <div className={classes.dropdownmenu}>
                    {alerts.map((obj, ind) => {
                      return (
                        <div
                          onClick={() => {
                            setAlert(obj);
                            setOpen2(false);
                          }}
                          key={ind}
                          className="ks-drop-select-value"
                          style={{ fontFamily: "Poppins" }}
                        >
                          {obj}
                        </div>
                      );
                    })}
                  </div>
                ) : null}
              </div>
            </ClickAwayListener>
          </div>
          <CustomRoundedButton
            text={strings.confirm}
            margin={"24px 0 8px 0"}
            color={Colors.nightPurple}
            backgroundColor={Colors.sharpGreen}
            onClick={submitAlert}
          />
          <ClearButton text={strings.cancel} onClick={() => closePopup()} />
        </div>
      </div>
    </div>
  );
};

export default KeywordAlertFrequency;
