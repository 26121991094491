import { useState, useEffect } from 'react';
import 'antd/dist/antd.css'
import * as Colors from '../Styles-Elements/Colors'
import {
  Subheading,
  Subtitle,
  LargeBody,
  LabelWrapper,
  LargeBodyBold,
} from '../Styles-Elements/Labels'
import booNegative from '../image-assets/boo-negative.png'
import { TextAreafield } from '../Styles-Elements/Inputs';
import { ClearButton, CustomRoundedButton } from '../Styles-Elements/Buttons';
import { REQUEST, ACTION_TYPE } from '../Helpers/Enums'
import Scrollbar from 'react-smooth-scrollbar'
import closePopUpByKey from '../GlobalModule/closePopUpByKey'
import { useDispatch, useSelector } from 'react-redux';
import { tooledUpLocalData } from '../redux/reducers/getTooledupData';
import { setSnackbar } from '../redux/reducers/snackbar';
import globalRequest from '../GlobalModule/globalRequest';
import { changeLoader } from '../redux/reducers/loader';
import moment from 'moment';
import { planDetails } from '../redux/reducers/getPlanDetail';

// Importing localised strings
const strings = require('../localisation_en.json')

const CancelSubscriptionRenewal = (props) => {

  const auth = useSelector(tooledUpLocalData);
  const dispatch = useDispatch();
  const planDetail = useSelector(planDetails);
  const billingDate = moment(planDetail?.end_date).format("DD MMM YYYY")
  

  // console.log("plan de ", planDetail);


  const [inputFieldEntry, setInputFieldEntry] = useState({
    feedback: '',
  })
  const handleInputChange = (e, clear) => {
    if (clear !== true) {
      setInputFieldEntry({
        ...inputFieldEntry,
        [e.currentTarget.name]: e.currentTarget.value,
      })
    } else {
      setInputFieldEntry({})
    }
  }

  const handleCancelSubscription = async (e) => {
    e.preventDefault()
    if (inputFieldEntry.feedback !== '') {
      let formData = new FormData()
      formData.append('user_id', auth?.user_id)
      formData.append('type', 1)
      formData.append('feedback', inputFieldEntry.feedback)

      let url = REQUEST.CANCEL_REQUEST

      dispatch(changeLoader(true));
      let response = await globalRequest("put", url, formData, {}, true);
      let data = response.data;

      if (data.status == 1) {
        setInputFieldEntry({
          ...inputFieldEntry,
          feedback: '',
        })

        props.setCancelPlan(data?.data?.cancel_request)
        dispatch(setSnackbar({
          snackbarMessage: data.message,
          snackbarOpen: true,
          snackbarState: 'success'
        }))
        dispatch(changeLoader(false));
      } else {
        dispatch(setSnackbar({
          snackbarMessage: data.message,
          snackbarOpen: true,
          snackbarState: 'error'
        }))
        dispatch(changeLoader(false));
      }


    } else {

      dispatch(setSnackbar({
        snackbarMessage: 'Please enter your feedback',
        snackbarOpen: true,
        snackbarState: 'error'
      }))
    }
  }

  useEffect(() => {
    closePopUpByKey(props.closePopup)
  }, [])

  return (
    <div className="container">
      <div className="container-modal">
        <div className="popup-boo-container negative">
          <img src={booNegative} width={120} height={120} alt="" />
        </div>
        <Scrollbar className="container-modal-mid">
          <Subtitle
            text={strings.areYouSure}
            color={Colors.nightPurple}
            textAlign={'center'}
            padding={'20px 0 16px 0'}
          />
          <LabelWrapper display={'flex'} justifyContent={'center'} padding={'0 0 32px 0'}>
            <LargeBody
              text={strings.byCancellingYourSubscriptionRenewalYour}
              color={Colors.darkGray}
              padding={'0 0 0px 0'}
            />
            <LargeBody
              text={strings.accountWillBeClosedOn}
              color={Colors.darkGray}
              padding={'0 0 0px 0'}
            />
            <LargeBodyBold
              text={billingDate}
              color={Colors.darkGray}
              padding={'0 0 0px 4px'}
            />
          </LabelWrapper>
          <LargeBody
            text={strings.PleaseEnsureThatYouExportAllUserDataBeforeBefore + ' ' + billingDate}
            color={Colors.darkGray}
            padding={'0 0 16px 0'}
            textAlign={'center'}
          />
          <Subheading
            text={strings.feedback}
            color={Colors.nightPurple}
            padding={'0 0 8px 0'}
          />
          <TextAreafield
            name={'feedback'}
            value={inputFieldEntry.feedback}
            onChange={(e) => handleInputChange(e)}
            placeholder={strings.pleaseEnterAnyFeedbackToHelpUsImprove}
            multiline
            rows={4}
          />
          <CustomRoundedButton
            text={strings.cancelSubscriptionRenewal}
            margin={'24px 0 8px 0'}
            padding={'11px 12px'}
            color={Colors.mainLight}
            backgroundColor={Colors.lightRed}
            onClick={(e) => handleCancelSubscription(e)}
          />
          <ClearButton text={strings.cancel} onClick={() => props.closePopup()} />
        </Scrollbar>
      </div>
    </div>
  )
}

export default CancelSubscriptionRenewal;
