import isEvenDoubleQuotes from "./isEvenDoubleQuotes";
import bracketReshuffeling from "./bracketReshuffle";
import getIndicesOf from "./getIndicesOf";
import colorTheString__ from "./colorTheString__";

const colorTheString = (str, parent) => {
  let strArr = String(str).toLowerCase().split("");
  let globalLength = strArr.length;

  let smallStr = String(str).toLowerCase();

  if (globalLength) {
    let andIndexes = [];
    let orIndexes = [];
    let notIndexes = [];

    if (smallStr.indexOf("and") > -1) andIndexes = getIndicesOf("and", smallStr, 3);
    if (smallStr.indexOf("or") > -1) orIndexes = getIndicesOf("or", smallStr, 2);
    if (smallStr.indexOf("not") > -1) notIndexes = getIndicesOf("not", smallStr, 3);

    colorTheString__(andIndexes, notIndexes, orIndexes, parent);
    bracketReshuffeling(parent);
  }
};

export default colorTheString;
