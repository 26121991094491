import React, { useEffect, useState } from "react";

import { Heading3, Subheading } from "../../Styles-Elements/Labels";
import * as Colors from "../../Styles-Elements/Colors";

import plusIcon from "../../image-assets/plus-icon.svg";
import minusIcon from "../../image-assets/minus-icon.svg";
import { useSelector } from "react-redux";
import { planDetails } from "../../redux/reducers/getPlanDetail";


const strings = require("../../localisation_en.json");

const UserNumberCounter = (props) => {

  const planDetail = useSelector(planDetails);
  const totalNumber = planDetail?.total_user
  const noLessThanTotalUser = (props.numberOfUsers < totalNumber)
  const equal = (props.numberOfUsers == totalNumber);
  const condition = (props.isStyle ? equal ? null : noLessThanTotalUser ? 'decrease--bg' : 'increase--bg' : null)

  const handleCounterValueChange = (e) => {

    let maxUser = 100000;
    let value = e.target.value;

    if (value && value > 0) {
      (/^(?:[0-9]+|\d+)$/.test(value) || value == "") &&
        parseInt(value) <= maxUser &&
        props.setNumberOfUsers(value);

    } else if (value !== "0") {
      (/^(?:[0-9]+|\d+)$/.test(value) || value == "") &&
        props.setNumberOfUsers(value);
    }
  };


  return (
    <div className="payment-summary-detail">
      <Subheading
        text={strings.numberOfUsers}
        color={Colors.nightPurple}
        padding={"6px 0 0 0"}
      />
      {/* please add these classes (increase--bg , decrease--bg) for increase and decrease bg color */}
      <div className={`counter-holder ${condition}`}
      >
        <button
          className={
            props.cancelRequest == 1
              ? "counter-button-disable"
              : "counter-button"
          }
          onClick={() =>
            props.numberOfUsers >= 2
              ? props.setNumberOfUsers(props.numberOfUsers - 1)
              : props.setNumberOfUsers(1)
          }
          disabled={props.cancelRequest == 1}
        >
          <img src={minusIcon} width={16} disabled={props.cancelRequest == 1} />
        </button>
        <input
          value={props.numberOfUsers}
          onChange={(e) => {
            handleCounterValueChange(e);
          }}
          className={
            props.cancelRequest == 1
              ? "user-count-input-disable"
              : "user-count-input"
          }
          style={props.isStyle ? noLessThanTotalUser ? { backgroundColor: '#f4aaaa' } : null : null}
          disabled={props.cancelRequest == 1}
          
        />

        <button
          className={
            props.cancelRequest == 1
              ? "counter-button-disable"
              : "counter-button"
          }
          onClick={() =>
            props.setNumberOfUsers(JSON.parse(props.numberOfUsers) + 1)
          }
          disabled={props.cancelRequest == 1 || props.numberOfUsers === ""}
        >
          <img src={plusIcon} width={16} />
        </button>
      </div>
    </div>
  );
}

export default UserNumberCounter;
