import * as Colors from "../Styles-Elements/Colors";
import { Body } from "../Styles-Elements/Labels";
import React from "react";
import { Boxed } from "../Styles-Elements/Box";
import userTypingImg from "../image-assets/boo-happy.png";
import strings from '../localisation_en.json'
import eyeIcon from "../image-assets/Eye.svg";

const TypingView = ({ socketData = {}, type = "typing" }) => {
  return (
    <>
      <div className="copy-to-clipboard-holder bordrtop">
        <Boxed
          display="flex"
          alignItems="center"
          justifyContent={"start"}
          style={{ padding: "10px" }}
          className="for-mainKeyword m-for-mainKeyword"
        >
          {type === "view" ? (
            <>
              <div className="typing-white-chips">
                <img
                  style={{
                    height: "25px",
                    width: "25px",
                    borderRadius: "50px",
                  }}
                  src={eyeIcon}
                  className="user-icon"
                  alt=""
                />
                <span contentEditable={false}>{strings.viewOnlyMode}</span>
              </div>
            </>
          ) : (
            <button className="view-only-button extbgmainlight">
              <img
                style={{
                  height: "25px",
                  width: "25px",
                  borderRadius: "50%",
                }}
                src={socketData?.img_path ? socketData?.img_path : userTypingImg}
                className="gbg-icon"
                alt=""
              />
              <Body
                text={`${socketData?.name} is typing ...`}
                color={Colors.nightGray}
                padding={"0px"}
                margin={"0px 0px 0px 8px"}
              />
            </button>
          )}
        </Boxed>
      </div>
    </>
  );
};
export default TypingView;
