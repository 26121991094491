import hasWhiteSpace from "./hasWhiteSpace";

const createSpan = (string) => {
  let strArr = [...string];
  let length = strArr.length;
  let html = "";

  if (length) {
    strArr.forEach((char) => {
      if (hasWhiteSpace(char)) {
        html += `<span>&nbsp;</span>`;
      } else {
        html += `<span>${char}</span>`;
      }
    });
  }

  return html;
};

export default createSpan;
