import * as Colors from "../../../Styles-Elements/Colors";
import { LargeBody, Subtitle } from "../../../Styles-Elements/Labels";
import { BorderedRoundedButton, MainRoundedButton } from "../../../Styles-Elements/Buttons";
import "./NewStringCreation.css";
import strings from "../../../localisation_en.json";
import booGradientImg from "../../image-assets/boo-gradient.png";
import aiBuilderWhiteIcon from "../../image-assets/ai-builder-icon-white.png";
import scratchStartIcon from "../../image-assets/scratch-start-icon.png";
import lockWhiteIcon from "../../image-assets/lock-white.svg";
import { Boxed } from "../../../Styles-Elements/Box";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { currentTrial } from "../../../redux/reducers/getTrial";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { currentCredit } from "../../../redux/reducers/getCredit";
import { setSnackbar } from "../../../redux/reducers/snackbar";
import AiUpgradeTooltip from "../../../GlobalModule/AiUpgradeTooltip";
import ManagementPlanSelection from "../../../dialogs/ManagementPlanSelection";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "#373737",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.25)",
    fontSize: 14,
    fontWeight: 500,
    padding: "12px 16px",
    borderRadius: "4px",
    fontFamily: "Poppins",
  },
}))(Tooltip);

const NewStringCreation = ({ clickCallback }) => {
  const trialData = useSelector(currentTrial);
  const creditData = useSelector(currentCredit);
  const dispatch = useDispatch();
  const [autoBuildOpen, setAutoBuildOpen] = useState(false);
  const [manualOpen, setManualOpen] = useState(false);
  const [openPayment, setOpenPayment] = useState(false);

  const LockUp = ({ onUpgrade, text = strings.autoBuildBeta }) => {
    return (
      <Boxed
        className="nsc-parent-tooltip"
        onClick={() => {
          setAutoBuildOpen(false);
          setManualOpen(false);
        }}
      >
        <BorderedRoundedButton
          text={text}
          className={"red-fill-btn rfb"}
          margin={"0"}
          img={
            <img
              src={lockWhiteIcon}
              alt="ai Builder"
              style={{
                width: "28px",
                height: "28px",
                position: "absolute",
                left: "16px",
                top: "24px",
              }}
            />
          }
        />
        <AiUpgradeTooltip onUpgrade={onUpgrade} />
      </Boxed>
    );
  };

  return (
    <>
      <div className="string-creation-holder">
        <div className="string-creation-box">
          <div className="positive" style={{ margin: "0 auto", width: "100px", height: "100px" }}>
            <img src={booGradientImg} width="100" height="100" alt="" />
          </div>
          <Subtitle textAlign={"center"} text={strings.needHelpGetting} color={Colors.nightGray} />
          <Boxed display={"flex"} justifyContent={"center"} style={{ gap: "32px" }}>
            {autoBuildOpen ? (
              <LockUp
                onUpgrade={() => {
                  setOpenPayment(true);
                  setAutoBuildOpen(false);
                  setManualOpen(false);
                }}
              />
            ) : (
              <BorderedRoundedButton
                onClick={() => {
                  if (trialData?.status == "trial" && creditData.auto_build == 0) {
                    setAutoBuildOpen(true);
                  } else {
                    if (trialData?.checkPay) {
                      clickCallback("auto-build");
                    } else {
                      setAutoBuildOpen(true);
                    }
                  }
                }}
                text={strings.autoBuildBeta}
                className={"darkpurple-fill-btn dfb"}
                margin={"0"}
                img={
                  <img
                    src={aiBuilderWhiteIcon}
                    alt="ai Builder"
                    style={{ width: "28px", height: "28px", position: "absolute", left: "16px", top: "24px" }}
                  />
                }
              />
            )}

            {manualOpen ? (
              <LockUp
                text={strings.startFromScratch}
                onUpgrade={() => {
                  setOpenPayment(true);
                  setAutoBuildOpen(false);
                  setManualOpen(false);
                }}
              />
            ) : (
              <BorderedRoundedButton
                onClick={() => {
                  if (trialData?.status == "trial" && creditData.auto_build == 0) {
                    clickCallback("manual");
                  } else {
                    if (trialData?.checkPay) {
                      clickCallback("manual");
                    } else {
                      setManualOpen(true);
                    }
                  }
                }}
                img={
                  <img
                    src={scratchStartIcon}
                    alt="scratch Start"
                    style={{
                      width: "28px",
                      height: "28px",
                      position: "absolute",
                      left: "16px",
                      top: "24px",
                    }}
                  />
                }
                text={strings.startFromScratch}
                className={"darkpurple-outline-btn dob-outline"}
                margin={"0"}
              />
            )}
          </Boxed>
        </div>
      </div>
      {openPayment ? <ManagementPlanSelection closePopup={() => setOpenPayment(false)} /> : null}
    </>
  );
};
export default NewStringCreation;
