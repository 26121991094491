const convertIntoHtml = (str) => {

  let convertHtml = String(str)
    .replace(/"/g, '<span class="quoteColor">"</span>')
    .replace(/\(/g, '<span  class="open-breackets-color-translate">(</span>')
    .replace(/\)/g, '<span  class="open-breackets-color-translate">)</span>')
    .replace(/AND/g, '<span class="and-color-translate">AND</span>')
    .replace(/NOT/g, '<span class="not-color-translate">NOT</span>')
    .replace(/OR/g, '<span class="or-color-translate">OR</span>')

  return convertHtml;
};

export default convertIntoHtml;
