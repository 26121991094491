import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import "antd/dist/antd.css";
import * as Colors from "../Styles-Elements/Colors";
import { Subheading, Subtitle, Body } from "../Styles-Elements/Labels";
import { Textfield } from "../Styles-Elements/Inputs";
import {
  ClearButton,
  MainRoundedButton,
} from "../Styles-Elements/Buttons";
import booNeutral from "../image-assets/boo-neutral.png";

import { REQUEST, ACTION_TYPE, STORAGE } from "../Helpers/Enums";
// Material UI for the snackbar
import Scrollbar from "react-smooth-scrollbar";
import defaultUploadIcon from "../image-assets/default-upload-icon.svg";
import closePopUpByKey from "../GlobalModule/closePopUpByKey";
import focus from "../GlobalModule/focus";
import {
  setData,
  tooledUpLocalData,
} from "../redux/reducers/getTooledupData";
import { setSnackbar } from "../redux/reducers/snackbar";
import globalRequest from "../GlobalModule/globalRequest";
import addDeleteGetLocalStorage from "../GlobalModule/addDeleteGetLocalStorage";

// Importing localised strings
const strings = require("../localisation_en.json");

function ProfileDetailPopUp(props) {
  const auth = useSelector(tooledUpLocalData);
  const dispatch = useDispatch();

  const [inputFieldEntry, setInputFieldEntry] = useState({
    new: "",
    exist: "",
    newPwd: "",
    email: "",
    firstName: auth?.firstname,
    lastName: auth?.lastname,
  });
  const [base64Image, setBase64Image] = useState(null);
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    focus("firstname");
    closePopUpByKey(props.closePopup);
  }, []);

  const handleInputChange = (e, clear) => {
    if (clear !== true) {
      setInputFieldEntry({
        ...inputFieldEntry,
        [e.currentTarget.name]: e.currentTarget.value,
      });
    } else {
      setInputFieldEntry({});
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  const onImageChange = async ({ fileList }) => {
   
    if (fileList.length > 0) {
      const base64 = await getBase64(
        fileList[fileList.length - 1]?.originFileObj
      );
      // console.log(base64, 'base 64')
      setBase64Image(base64);
      // console.log(base64Image, 'base 64 image');
    } else {
      setBase64Image(null);
    }

    setFileList(fileList);
  };

  const ChangeProfileDetail = async (e) => {
    e.preventDefault();
    if (inputFieldEntry.firstName == "" && inputFieldEntry.lastName == "") {
      dispatch(
        setSnackbar({
          snackbarMessage: strings.enterProfileDetail,
          snackbarOpen: true,
          snackbarState: "error",
        })
      );
    } else if (
      inputFieldEntry.firstName == null ||
      inputFieldEntry.firstName == ""
    ) {
      dispatch(
        setSnackbar({
          snackbarMessage: strings.enterFirstName,
          snackbarOpen: true,
          snackbarState: "error",
        })
      );
    } else if (
      inputFieldEntry.lastName == null ||
      inputFieldEntry.lastName == ""
    ) {
      dispatch(
        setSnackbar({
          snackbarMessage: strings.enterLastName,
          snackbarOpen: true,
          snackbarState: "error",
        })
      );
    } else {
      // Send request
      let user_id;
      let user_token;
      if (auth?.user_id) {
        user_id = auth?.user_id;
        user_token = auth?.token;
      }
      let formData = new FormData();
      // console.log("sss");
      formData.append("user_id", user_id);
      formData.append("first_name", inputFieldEntry.firstName);
      formData.append("last_name", inputFieldEntry.lastName);
      formData.append("profile_image", base64Image);

      const response = await globalRequest(
        "put",
        REQUEST.PROFILE_UPDATE_REQUEST,
        formData,
        {},
        true
      );

      const res = response.data;

      if (res.status == 1) {
        dispatch(
          setSnackbar({
            snackbarMessage: res.message,
            snackbarOpen: true,
            snackbarState: "success",
          })
        );

        let a = {
          ...auth,
          firstname: res.data.first_name,
          lastname: res.data.last_name,
          profile_image: res.data.profile_image,
          img_path: res.data.img_path,
        };
        addDeleteGetLocalStorage(
          STORAGE.TOOLED_UP_LOCAL_DATA,
          a,
          "add",
          "single"
        );
        dispatch(setData());

        props.closePopup();
      } else {
        dispatch(
          setSnackbar({
            snackbarMessage: res.message,
            snackbarOpen: true,
            snackbarState: "error",
          })
        );
      }
    }
  };

  return (
    <div className="container">
      <div className="container-modal">
        <div className="popup-boo-container neutral">
          <img src={booNeutral} width={120} height={120} />
        </div>
        <Scrollbar className="container-modal-mid">
          <Subtitle
            text={strings.changeYourProfileDetails}
            color={Colors.nightPurple}
            textAlign={"center"}
            padding={"24px 0 16px"}
          />
          <div
            className="profile-upload-holder"
            style={{ textAlign: "center" }}
          >
            <div className="profile-upload-holder22">
              <ImgCrop grid style={{ borderRadius: "50%" }}>
                <Upload
                  listStyle={{ borderRadius: "50%" }}
                  listType="picture-card"
                  showUploadList={false}
                  onChange={onImageChange}
                >
                  {base64Image ? (
                    <img src={base64Image} className="profile-upload-icon" />
                  ) : auth?.img_path !== "" ? (
                    <img src={auth?.img_path} className="profile-upload-icon" />
                  ) : (
                    <div className="profile-upload-icon">
                      <img src={defaultUploadIcon} />
                    </div>
                  )}
                </Upload>
              </ImgCrop>
            </div>
            {auth?.profile_image !== "" ? (
              <div className="abc">
                <ImgCrop>
                  <Upload
                    listType="picture-card"
                    showUploadList={false}
                    onChange={onImageChange}
                  >
                    <label htmlFor="contained-button-file">
                      <Body
                        text={strings.uploadNewImage}
                        textAlign={"center"}
                        color={Colors.darkPurple}
                        padding={"0"}
                      />
                    </label>
                  </Upload>
                </ImgCrop>
              </div>
            ) : null}
          </div>
          <Subheading
            text={strings.firstName}
            color={Colors.nightPurple}
            padding={"0 0 8px 0"}
          />
          <Textfield
            name={"firstName"}
            value={inputFieldEntry.firstName}
            onChange={(e) => handleInputChange(e)}
            placeholder={strings.firstName}
            margin={"0px auto 24px auto"}
            width={"99%"}
            id="firstname"
          />
          <Subheading
            text={strings.lastName}
            color={Colors.nightPurple}
            padding={"0 0 8px 0"}
          />
          <Textfield
            name={"lastName"}
            value={inputFieldEntry.lastName}
            onChange={(e) => handleInputChange(e)}
            placeholder={strings.lastName}
            margin={"0px auto 24px auto"}
            width={"99%"}
          />
          <MainRoundedButton
            text={strings.saveDetails}
            margin={"24px 0 8px 0"}
            onClick={(e) => ChangeProfileDetail(e)}
          />
          <ClearButton
            text={strings.cancel}
            onClick={() => {
              setInputFieldEntry({
                new: "",
                exist: "",
                firstName: auth?.firstname,
                lastName: auth?.lastname,
              });
              props.closePopup();
              setBase64Image("");
            }}
          />
        </Scrollbar>
      </div>
    </div>
  );
}

export default ProfileDetailPopUp;
