import { API_TRANSLATION, REQUEST, STORAGE } from "../../src/Helpers/Enums";
import addDeleteGetLocalStorage from "../GlobalModule/addDeleteGetLocalStorage";
export const glossaryFileDataByLanguage = async (input) => {
    let language=input?.target_language;
    let auth=input?.auth;
    let sourceLang=input?.source_language;
    let json;
    const dataToken = addDeleteGetLocalStorage(
        STORAGE.TOOLED_UP_LOCAL_DATA,
        {},
        "get",
        "single"
    );
    try {
        json = JSON.parse(dataToken);
    } catch (e) {
        json = {};
    }
    let authorization = json?.token;
    let team_id=auth?.team_id==0 ? auth?.user_id : auth?.team_id;
    let Inputs = {
        language: language,
        team_id:team_id,
        source_lanuage:sourceLang,
        type:input?.type
    }
    let results = {};
    let response = await fetch(API_TRANSLATION.READ_GLOSSARY_FILE, {
        method: "POST",
        headers: {
            "Authorization": "Bearer ",  // Modified
            "Content-Type": "application/json",
            "api_key": REQUEST.API_KEY,
            "authorization": authorization
        },
        body: JSON.stringify(Inputs),
    })
        .then((response) => {
            return response.json()
        })
        .then((jsonr) => {
            if (jsonr?.error) {
                results = {
                    status: 0,
                    message: jsonr?.error?.message || 'Technical Error'
                }
            } else {
                if (jsonr?.status == 1) {
                    results = {
                        status: 1,
                        language: language,
                        total_row: jsonr?.data?.totalrow,
                        data: jsonr?.data?.translate_data
                    }
                } else {
                    results = {
                        status: 0,
                        message: 'failed to load file.'
                    }
                }
            }
        })
        .catch(error => {
            results = {
                status: 0,
                message: error?.message || 'Technical Error'
            }
        }); 
    return results; 
}

export const saveDataInGlossary = async (input) => {
    let auth=input?.auth;
    let team_id=auth?.team_id==0 ? auth?.user_id : auth?.team_id;
    let Inputs = {
        translated_words: input?.ready_to_save,
        team_id:team_id,
        source_lanuage:input?.source_language,
        type:input?.type
    }
    let json;
    const dataToken = addDeleteGetLocalStorage(
        STORAGE.TOOLED_UP_LOCAL_DATA,
        {},
        "get",
        "single"
    );
    try {
        json = JSON.parse(dataToken);
    } catch (e) {
        json = {};
    }
    let authorization = json?.token;
    let response = await fetch(API_TRANSLATION.SAVE_GLOSSARY_FILE, {
        method: "POST",
        headers: {
            "Authorization": "Bearer ",  // Modified
            "Content-Type": "application/json",
            "authorization":authorization,
            "api_key": REQUEST.API_KEY,
        },
        body: JSON.stringify(Inputs),
    })
    .then((response) => {
        return response.json()
    })
    .then((jsonr) => {
       
    })
    .catch(error => {
       
    });
   // let result = await response.json();
}