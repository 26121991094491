import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import * as Colors from "../../Styles-Elements/Colors";
import { LargeBody, Subtitle, LargeSmall, Heading2Bold, Body } from "../../Styles-Elements/Labels";
import searchIcon from "../../image-assets/searchicon.svg";
import "./Dashboard.css";
import { InputWrapper, TextSearchfield } from "../../Styles-Elements/Inputs";
import { Boxed } from "../../Styles-Elements/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import { useCallback } from "react";
import globalRequest from "../../GlobalModule/globalRequest";
import { BASE_URL, NEW_API } from "../../Helpers/Enums";
import { useSelector } from "react-redux";
import { tooledUpLocalData } from "../../redux/reducers/getTooledupData";
import booNuetral from "../../image-assets/boo-neutral.png";
const strings = require("../../localisation_en.json");

const createData = (name, academy_progress, total_company, total_keyword, total_chr_translate, last_interaction) => {
  return { name, academy_progress, total_company, total_keyword, total_chr_translate, last_interaction };
};
const rows = [createData()];
const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: (
      <Boxed display={"flex"} justifyContent={"flex-start"} textAlign={"center"}>
        {strings.raccoon}
        <br />
        {strings.user}
      </Boxed>
    ),
  },
  {
    id: "academy_progress",
    numeric: true,
    disablePadding: false,
    label: (
      <Boxed display={"flex"} justifyContent={"center"} textAlign={"center"}>
        {strings.academy} <br /> {strings.progressPercent}
      </Boxed>
    ),
  },
  {
    id: "total_company",
    numeric: true,
    disablePadding: false,
    label: (
      <Boxed display={"flex"} justifyContent={"center"} textAlign={"center"}>
        {strings.noCompany} <br /> {strings.strings}
      </Boxed>
    ),
  },
  {
    id: "total_keyword",
    numeric: true,
    disablePadding: false,
    label: (
      <Boxed display={"flex"} justifyContent={"center"} textAlign={"center"}>
        {strings.noKeyword} <br /> {strings.strings}
      </Boxed>
    ),
  },
  {
    id: "total_chr_translate",
    numeric: true,
    disablePadding: false,
    label: (
      <Boxed display={"flex"} justifyContent={"center"} textAlign={"center"}>
        {strings.characters} <br /> {strings.translated}
      </Boxed>
    ),
  },
  {
    id: "last_interaction",
    numeric: true,
    disablePadding: false,
    label: (
      <Boxed display={"flex"} justifyContent={"center"} textAlign={"center"}>
        {strings.lastTech} <br /> {strings.interaction}
      </Boxed>
    ),
  },
];

const EnhancedTableHead = (props) => {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell,ind) => (
          <TableCell
            key={ind}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>{order === "desc" ? "" : ""}</span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  dropdownmenu: {
    position: "absolute",
    top: 55,
    right: 0,
    left: 1,
    zIndex: 1,
    border: "none",
    boxShadow:
      "0px 0px 15px -3px rgba(0, 0, 0, 0.1), 0px 5px 6px -2px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
    padding: "0",
    borderRadius: "6px",
    backgroundColor: "#fff",
  },
}));
const Dashboard = () => {
  const auth = useSelector(tooledUpLocalData);
  const [progressbar_percentage, setProgressbar_percentage] = useState("100%");
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [teamOverviewData, setTeamOverviewData] = useState({});
  const [individualOverviewData, setIndividualOverviewData] = useState([]);
  const [individualOverviewDataSearch, setIndividualOverviewDataSearch] = useState([]);
  const [type, setType] = useState("first_name");

  const teamOverview = useCallback(async () => {
    globalRequest(
      "get",
      NEW_API.TEAM_OVERVIEW,
      {},
      {
        params: {
          team_id: auth?.team_id,
          user_id: auth?.user_id,
        },
      },
      true
    )
      .then((res) => {
        let data = res.data;
        if (data.status == 1) {
          setTeamOverviewData(data.data);
        }
      })
      .catch((err) => {
        console.log("catch ", err);
      });
  });

  const individualOverview = useCallback(async () => {
    globalRequest(
      "get",
      NEW_API.INDIVIDUAL_OVERVIEW,
      {},
      {
        params: {
          team_id: auth?.team_id,
          user_id: auth?.user_id,
          type: type,
          order: order,
        },
      },
      true
    )
      .then((res) => {
        let data = res.data;
        if (data.status == 1) {
          setIndividualOverviewData(data?.data);
          setIndividualOverviewDataSearch(data?.data);
        }
      })
      .catch((err) => {
        console.log("catch ", err);
      });
  });

  useEffect(() => {
    teamOverview();
    individualOverview();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const GetTableRow = ({ obj, ind }) => {
    let progressColor = "red";
    let progressCount = obj?.academy_progress;

    if (progressCount >= 50 && progressCount <= 99) {
      progressColor = "orange";
    } else if (progressCount == 100) {
      progressColor = "green";
    }

    let userImg = obj?.profile_image;
    let pro = "";
    if (userImg) {
      pro = `${BASE_URL}uploads/profile/${obj.id}/${obj.profile_image}`;
    } else {
      pro = booNuetral;
    }

    return (
      <>
        <TableRow key={ind} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
          <TableCell component="th" scope="row">
            <Boxed display={"flex"} alignItems={"center"}>
              <img src={pro} className="table-user-img" alt="" />
              <Body
                text={obj?.name == "" || obj?.name == " " ? "No name" : obj?.name}
                color={Colors.nightGray}
                textAlign={"center"}
                fontWeight={"500"}
                padding={"0 0 0px 20px"}
              />
            </Boxed>
          </TableCell>
          <TableCell style={{padding:'10px 28px 10px 10px'}}>
            <div className="progress-bar bar-new-layout">
              <div
                className="progress-inner inner-new-layout"
                style={{ width: `${obj?.academy_progress <= 20 ? 20 : obj?.academy_progress}%`, backgroundColor: progressColor }}
              >
                {obj?.academy_progress}%
              </div>
            </div>
          </TableCell>
          <TableCell>
            <Body
              text={obj?.total_company}
              color={Colors.darkPurpleGray}
              textAlign={"center"}
              fontWeight={"500"}
              padding={"0 18px 0px 0"}
            />
          </TableCell>
          <TableCell>
            <Body
              text={obj?.total_keyword}
              color={Colors.darkPurpleGray}
              textAlign={"center"}
              fontWeight={"500"}
              padding={"0 18px 0px 0"}
            />
          </TableCell>
          <TableCell>
            <Body
              text={obj?.total_chr_translate}
              color={Colors.darkPurpleGray}
              textAlign={"center"}
              fontWeight={"500"}
              padding={"0 18px 0px 0"}
            />
          </TableCell>
          <TableCell>
            <Body
              text={String(obj?.last_interaction).replace(/-/g, "/")}
              color={Colors.darkPurpleGray}
              textAlign={"center"}
              fontWeight={"500"}
              padding={"0 18px 0px 0"}
            />
          </TableCell>
        </TableRow>
      </>
    );
  };

  const search = (event) => {
    let value = event.target.value;
    let a = individualOverviewData;
    let condition = new RegExp(value.toLowerCase(), "i");
    const a1 = a.filter((e) => {
      if (condition.test(String(e.name).toLowerCase())) {
        return e;
      }
    });

    if (event.code == "Backspace") {
      setIndividualOverviewData(individualOverviewDataSearch);
    } else {
      if (value) {
        setIndividualOverviewData(a1);
      } else {
        setIndividualOverviewData(individualOverviewDataSearch);
      }
    }
  };

  return (
    <>
      <div className="dashboard-holder">
        <Subtitle text={strings.dashboard} color={Colors.nightPurple} padding={"0 0 32px 0"} />
        <div className="dashboard-white-box">
          <LargeBody text={strings.teamOverview} color={Colors.nightPurple} fontWeight={"500"} padding={"0 0 20px 0"} />
          <div className="dashboard-boxes-block">
            <div className="dashboard-box">
              <LargeSmall
                text={strings.academyProgress}
                color={Colors.nightGray}
                textAlign={"center"}
                fontWeight={"500"}
                padding={"0 0 12px 0"}
              />
              <Heading2Bold
                text={`${teamOverviewData.academy_progress ? teamOverviewData.academy_progress : 0}%`}
                color={Colors.darkPurple}
                textAlign={"center"}
                padding={"0 0 12px 0"}
              />
              <div className="bottom-line-decor"></div>
            </div>
            <div className="dashboard-box">
              <LargeSmall
                text={strings.noCompanyStrings}
                color={Colors.nightGray}
                textAlign={"center"}
                fontWeight={"500"}
                padding={"0 0 12px 0"}
              />
              <Heading2Bold
                text={teamOverviewData?.total_company}
                color={Colors.darkPurple}
                textAlign={"center"}
                padding={"0 0 12px 0"}
              />
              <div className="bottom-line-decor"></div>
            </div>
            <div className="dashboard-box">
              <LargeSmall
                text={strings.noKeywordStrings}
                color={Colors.nightGray}
                textAlign={"center"}
                fontWeight={"500"}
                padding={"0 0 12px 0"}
              />
              <Heading2Bold
                text={teamOverviewData.total_keyword ? teamOverviewData.total_keyword : "0"}
                color={Colors.darkPurple}
                textAlign={"center"}
                padding={"0 0 12px 0"}
              />
              <div className="bottom-line-decor"></div>
            </div>
            <div className="dashboard-box">
              <LargeSmall
                text={strings.charactersTranslated}
                color={Colors.nightGray}
                textAlign={"center"}
                fontWeight={"500"}
                padding={"0 0 12px 0"}
              />
              <Heading2Bold
                text={teamOverviewData.total_chr_translate ? teamOverviewData.total_chr_translate : "0"}
                color={Colors.darkPurple}
                textAlign={"center"}
                padding={"0 0 12px 0"}
              />
              <div className="bottom-line-decor"></div>
            </div>
            <div className="dashboard-box">
              <LargeSmall
                text={strings.lastTechInteraction}
                color={Colors.nightGray}
                textAlign={"center"}
                fontWeight={"500"}
                padding={"0 0 12px 0"}
              />
              <Heading2Bold
                text={
                  teamOverviewData.last_interaction
                    ? String(teamOverviewData?.last_interaction).replace(/-/g, "/")
                    : "0"
                }
                color={Colors.darkPurple}
                textAlign={"center"}
                padding={"0 0 12px 0"}
              />
              <div className="bottom-line-decor"></div>
            </div>
          </div>
          <div className="individual-table-block">
            <Boxed display={"flex"} alignItems={"center"} justifyContent={"space-between"} className="searching-block">
              <LargeBody
                text={strings.individualOverview}
                color={Colors.nightPurple}
                fontWeight={"500"}
                padding={"0 0 0px 0"}
              />
              <Boxed className="" display={"flex"} alignItems={"center"}>
                <InputWrapper>
                  <TextSearchfield
                    placeholder={strings.searchUsers}
                    backgroundColor={Colors.whiteGray}
                    onKeyUp={search}
                  />
                  <button className="searchbtn">
                    <img src={searchIcon} className="" alt="" />
                  </button>
                </InputWrapper>
              </Boxed>
            </Boxed>
            <div className="table-holder">
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table
                    stickyHeader
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={dense ? "small" : "medium"}
                    aria-label="sticky table"
                  >
                    <EnhancedTableHead
                      classes={classes}
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={individualOverviewData.length}
                    />
                    <TableBody>
                      {stableSort(individualOverviewData, getComparator(order, orderBy))
                        ?.map((obj, ind) => {
                          return <GetTableRow  key={ind} obj={obj} ind={ind} />;
                        })}
                      {/* {emptyRows > 0 && (
                        <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )} */}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Dashboard;
