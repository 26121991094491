import * as Colors from "../Styles-Elements/Colors";
import { Subtitle } from "../Styles-Elements/Labels";
import { MainRoundedButton } from "../Styles-Elements/Buttons";
import Scrollbar from "react-smooth-scrollbar";
import booNeutral from "../image-assets/boo-neutral.png";
import closeModalIcon from "../image-assets/modal-close-new.png";
import { Boxed } from "../Styles-Elements/Box";
import { LEARN_MORE_VIDEO_URL } from "../Helpers/Enums";
import { useRef } from "react";

const strings = require("../localisation_en.json");
const LearnMore = ({ closePopup, message, submit }) => {
  const iframeVideo = useRef();

  const requestFullScreen = () => {
    let videoFrame = iframeVideo.current;

    if (videoFrame.requestFullscreen) {
      videoFrame.requestFullscreen();
    } else if (videoFrame.mozRequestFullScreen) {
      videoFrame.mozRequestFullScreen();
    } else if (videoFrame.webkitRequestFullscreen) {
      videoFrame.webkitRequestFullscreen();
    } else if (videoFrame.msRequestFullscreen) {
      videoFrame.msRequestFullscreen();
    }
  };

  return (
    <div className="container">
      <div className="container-modal width1000" style={{ width: "750px", padding: "40px 30px 30px 30px" }}>
        <img src={closeModalIcon} alt="" className="close-new-modal" onClick={() => closePopup()} />
        <div className="popup-boo-container positive">
          <img src={booNeutral} width={120} height={120} alt="" />
        </div>
        <Scrollbar className="container-modal-mid">
          <Subtitle
            text={strings.leverageAutoBuildLikeABoss}
            color={Colors.darkPurple}
            textAlign={"center"}
            padding={"0 0 20px 0"}
          />
          <Boxed display={"flex"} alignItems={"center"} justifyContent={"center"} className="video-box-popup">
            <iframe
              src="https://www.veed.io/embed/4765bad3-0fb5-40b7-8fc3-9df7340c1cbf?watermark=0&color=purple&sharing=0&title=0"
              width="744"
              height="504"
              frameBorder="0"
              title="Leverage Auto-Build Like a Boss"
              // webkitallowfullscreen
              // mozallowfullscreen
              // allowfullscreen
            ></iframe>
          </Boxed>
          <div className="">
            <MainRoundedButton
              margin={"15px 0 0 0"}
              className="darkpurple-fill-btn width-100"
              text={strings.Close}
              onClick={closePopup}
            />
          </div>
        </Scrollbar>
      </div>
    </div>
  );
};
export default LearnMore;
