// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  // eslint-disable-next-line no-restricted-globals
  self.onmessage = (event) => {
    let { string } = event?.data;

    const findAllAndOrNotInsideQuotation = (str) => {

      str = String(str).toLowerCase();

      let regex = /(\w+\s+and\s+\w+)|(\w+\s+and")|(\w+\s+and\s+")|("\s+and\s+\w+")|("and)/g;
      let regex1 = /(\w+\s+not\s+\w+)|(\w+\s+not")|(\w+\s+not\s+")|("\s+not\s+\w+")|("not)/g;
      let regex2 = /(\w+\s+or\s+\w+)|(\w+\s+or")|(\w+\s+or\s+")|("\s+or\s+\w+")|("or)/g;

      let match;
      let match1;
      let match2;

      let and = [];
      let or = [];
      let not = [];

      while ((match = regex.exec(str)) != null) {
        let start = match.index;
        let word = match[0];

        let andIndex = word.indexOf("and");

        if (andIndex != -1) {
          and.push(start + andIndex);
        }
      }

      while ((match1 = regex1.exec(str)) != null) {
        let start = match1.index;
        let word = match1[0];

        let notIndex = word.indexOf("not");

        if (notIndex != -1) {
          not.push(start + notIndex);
        }
      }

      while ((match2 = regex2.exec(str)) != null) {
        let start = match2.index;
        let word = match2[0];

        let orIndex = word.indexOf("or");

        if (orIndex != -1) {
          or.push(start + orIndex);
        }
      }

      return {
        and: and,
        not: not,
        or: or,
      };
    };

    let find = findAllAndOrNotInsideQuotation(string);
  
    postMessage({
      findAllAndOrNotInsideQuotation: find
    });
  };
};
