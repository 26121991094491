import * as Colors from "../Styles-Elements/Colors";
import { Body, Heading3, LargeBody } from "../Styles-Elements/Labels";
import { BorderedRoundedButton, MainRoundedButton } from "../Styles-Elements/Buttons";
import closeWhite from "../image-assets/close-white.png";
import booGradient from "../image-assets/boo-gradient.png";
import powerIcon from "../image-assets/power.png";
import { Boxed } from "../Styles-Elements/Box";
import Scrollbar from "react-smooth-scrollbar";
import greenLine from "../image-assets/green-auto-build.png";
import pikBlb from "../image-assets/pik-blb.png";
import purpleGlobe from "../image-assets/purple-globe.png";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import strings from "../localisation_en.json";
import { useEffect, useState } from "react";
import globalRequest from "../GlobalModule/globalRequest";
import { BASE_URL, NEW_MODULE_API, SIDEBAR_STATE, SIDEBAR_STATE_FOR_LOCAL_STORAGE, STORAGE } from "../Helpers/Enums";
import { useDispatch, useSelector } from "react-redux";
import { tooledUpLocalData } from "../redux/reducers/getTooledupData";
import { currentTrial } from "../redux/reducers/getTrial";
import formatDate from "../GlobalModule/formatDate";
import { currentCredit } from "../redux/reducers/getCredit";
import { changePage } from "../redux/reducers/pagesChange";
import addDeleteGetLocalStorage from "../GlobalModule/addDeleteGetLocalStorage";
import { changeSidebar } from "../redux/reducers/sidebar";
import { changeOpenInvite } from "../redux/reducers/openInvite";
import booHappy from "../image-assets/boo-happy.png";

function createData(username, build, enrich, translate) {
  return { username, build, enrich, translate };
}

const CreditManagementModal = ({ closePopup, upgradeDisplay }) => {
  const [data, setData] = useState([]);
  const auth = useSelector(tooledUpLocalData);
  const trialData = useSelector(currentTrial);
  const creditData = useSelector(currentCredit);
  const dispatch = useDispatch();
  const [isExpire, setIsExpire] = useState(false);

  useEffect(() => {
    let createdAt = new Date();
    let expireOn = new Date(trialData?.expire_on);

    if (createdAt > expireOn) {
      setIsExpire(true);
    }
  }, [trialData]);

  const getUserCredit = async () => {
    let response = await globalRequest(
      "get",
      NEW_MODULE_API.GET_ALL_CREDIT(auth?.access === "owner" ? auth?.user_id : auth?.team_id),
      {},
      {},
      true
    );
    let datas = response.data;
    if (datas.ack === 1) {
      if (auth?.access === "owner") {
        setData(datas.data);
      } else {
        setData(datas.data.filter((obj) => obj.user_id !== auth?.user_id));
      }
    }
  };

  useEffect(() => {
    getUserCredit();
  }, []);

  const OwnerTable = () => {
    let profileUrl = null;
    if (auth.profile_image) {
      profileUrl = `${BASE_URL}uploads/profile/${auth.user_id}/${auth.profile_image}`;
    } else {
      if (auth.firstname == "") {
        profileUrl = booHappy;
      } else {
        profileUrl = "nameImage";
      }
    }

    return (
      <TableRow
        key={"owner"}
        sx={{
          "&:last-child td, &:last-child th": {
            border: 0,
          },
        }}
      >
        <TableCell component="th" scope="row">
          <Boxed display={"flex"} alignItems={"center"}>
            {profileUrl == "nameImage" ? (
              <>
                <b className="sidebar-profile-char">
                  {String(auth.firstname).charAt(0)} {String(auth.lastname).charAt(0)}
                </b>
              </>
            ) : (
              <>
                <img
                  style={{
                    width: "45px",
                  }}
                  src={profileUrl}
                  alt=""
                />
              </>
            )}
            <Body
              text={auth.firstname === "" ? auth.email : `${auth.firstname} ${auth.lastname}`}
              color={Colors.nightGray}
              textAlign={"center"}
              fontWeight={"500"}
              padding={"0 0 0px 20px"}
            />
          </Boxed>
        </TableCell>
        <TableCell align="center">
          <span
            style={{
              color: "#00E4A2",
              fontWeight: "500",
              fontSize: "14px",
            }}
          >
            {creditData.auto_build}
          </span>
        </TableCell>
        <TableCell align="center">
          <span
            style={{
              color: "#FF66C4",
              fontWeight: "500",
              fontSize: "14px",
            }}
          >
            {creditData.auto_enrich}
          </span>
        </TableCell>
        <TableCell align="center">
          <span
            style={{
              color: "#4A0FA3",
              fontWeight: "500",
              fontSize: "14px",
            }}
          >
            {creditData.translations}
          </span>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      <div className="container">
        <div className="container-modal ftam">
          <img src={closeWhite} alt="" className="p-white-close" onClick={() => closePopup()} />
          <Scrollbar className="container-modal-mid">
            <Boxed className="credit-management-holder">
              <div className="trail-account-left" style={{ width: "100%", padding: "32px 40px" }}>
                <div>
                  <Boxed display={"flex"} alignItems={"center"} justifyContent={"space-between"} margin={"0 0 20px 0"}>
                    <Heading3 text={strings.freeTrialTokensRemaining} color={Colors.darkPurple} padding={"0 0 0px 0"} />
                    {auth?.access === "user" ? null : (
                      <MainRoundedButton
                        text={strings.inviteTeam}
                        color={Colors.mainLight}
                        className={"upgrade-now-btn"}
                        onClick={() => {
                          dispatch(changePage(SIDEBAR_STATE.MANAGE_USERS));
                          addDeleteGetLocalStorage(
                            STORAGE.NEW_SIDE_BAR,
                            SIDEBAR_STATE_FOR_LOCAL_STORAGE.MANAGE_USERS,
                            "add",
                            "single"
                          );
                          dispatch(changeSidebar());
                          dispatch(changeOpenInvite(true));
                          closePopup();
                        }}
                      />
                    )}
                  </Boxed>
                  <Boxed
                    className="tokens-remaining"
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <LargeBody text={strings.myTokensRemaining} color={Colors.nightGray} padding={"0 8px 0px 0"} />
                    <Boxed display={"flex"} alignItems={"center"} gap={"10px"}>
                      <Boxed display={"flex"} alignItems={"center"} className="token-count-no-box">
                        <img src={greenLine} alt="" style={{ height: "20px" }} />
                        <LargeBody
                          text={creditData?.auto_build}
                          fontWeight={"600"}
                          color={Colors.sharpGreen}
                          padding={"0 0px 0px 8px"}
                        />
                      </Boxed>
                      <Boxed display={"flex"} alignItems={"center"} className="token-count-no-box">
                        <img src={pikBlb} alt="" />
                        <LargeBody
                          text={creditData?.auto_enrich}
                          fontWeight={"600"}
                          color={"#FF66C4"}
                          padding={"0 0px 0px 8px"}
                        />
                      </Boxed>
                      <Boxed display={"flex"} alignItems={"center"} className="token-count-no-box">
                        <img src={purpleGlobe} alt="" />
                        <LargeBody
                          text={creditData?.translations}
                          fontWeight={"600"}
                          color={Colors.darkPurple}
                          padding={"0 0px 0px 8px"}
                        />
                      </Boxed>
                    </Boxed>
                  </Boxed>
                  <div>
                    <div className="table-holder">
                      <Paper sx={{ width: "100%" }}>
                        <TableContainer component={Paper}>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>User Name</TableCell>
                                <TableCell align="center">Build</TableCell>
                                <TableCell align="center">Enrich</TableCell>
                                <TableCell align="center">Translate</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <OwnerTable />
                              {data.length
                                ? data.map((row, ind) => {
                                    let profileUrl = null;
                                    if (row.profile_image) {
                                      profileUrl = `${BASE_URL}uploads/profile/${row.user_id}/${row.profile_image}`;
                                    } else {
                                      if (row.first_name == "") {
                                        profileUrl = booHappy;
                                      } else {
                                        profileUrl = "nameImage";
                                      }
                                    }

                                    return (
                                      <TableRow
                                        key={ind}
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <TableCell component="th" scope="row">
                                          <Boxed display={"flex"} alignItems={"center"}>
                                            {profileUrl == "nameImage" ? (
                                              <>
                                                <b className="sidebar-profile-char">
                                                  {String(row.first_name).charAt(0)} {String(row.last_name).charAt(0)}
                                                </b>
                                              </>
                                            ) : (
                                              <>
                                                <img
                                                  style={{
                                                    width: "45px",
                                                  }}
                                                  src={profileUrl}
                                                  alt=""
                                                />
                                              </>
                                            )}
                                            <Body
                                              text={
                                                row.first_name === ""
                                                  ? row.email_id
                                                  : `${row.first_name} ${row.last_name}`
                                              }
                                              color={Colors.nightGray}
                                              textAlign={"center"}
                                              fontWeight={"500"}
                                              padding={"0 0 0px 20px"}
                                            />
                                          </Boxed>
                                        </TableCell>
                                        <TableCell align="center">
                                          <span
                                            style={{
                                              color: "#00E4A2",
                                              fontWeight: "500",
                                              fontSize: "14px",
                                            }}
                                          >
                                            {row.auto_build}
                                          </span>
                                        </TableCell>
                                        <TableCell align="center">
                                          <span
                                            style={{
                                              color: "#FF66C4",
                                              fontWeight: "500",
                                              fontSize: "14px",
                                            }}
                                          >
                                            {row.auto_enrich}
                                          </span>
                                        </TableCell>
                                        <TableCell align="center">
                                          <span
                                            style={{
                                              color: "#4A0FA3",
                                              fontWeight: "500",
                                              fontSize: "14px",
                                            }}
                                          >
                                            {row.translations}
                                          </span>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })
                                : null}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                    </div>
                  </div>
                </div>
              </div>
              <div className="trail-account-right" style={{ width: "100%" }}>
                <Boxed display={"flex"} justifyContent={"center"} margin={"40px 0 0 0"}>
                  <img src={booGradient} className="boo-g-200" alt="" />
                </Boxed>
                <LargeBody
                  text={"Your 7-day free trial expires soon"}
                  textAlign={"center"}
                  fontWeight={"600"}
                  color={Colors.mainLight}
                  padding={"16px 0 0px 0"}
                />
                <Boxed
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  gap={"24px"}
                  margin={"48px 0px 0px 0px"}
                >
                  <div className="Bordered-white">
                    {isExpire ? "Expired" : "Expires"} on <strong>{formatDate(trialData?.expire_on)}</strong>
                  </div>
                  {auth?.access === "owner" ? (
                    <BorderedRoundedButton
                      text={strings.upgradeNow}
                      img={<img src={powerIcon} style={{ marginLeft: "16px" }} alt="" />}
                      color={Colors.mainLight}
                      className={"create-button-green height48 Bordered-green"}
                      onClick={upgradeDisplay}
                    />
                  ) : null}
                </Boxed>
              </div>
            </Boxed>
          </Scrollbar>
        </div>
      </div>
    </>
  );
};
export default CreditManagementModal;
