import { useState } from "react";
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import * as Colors from "../../Styles-Elements/Colors";
import * as FontStyles from "../../Styles-Elements/FontStyles";
import { Subheading, Body } from "../../Styles-Elements/Labels";
import { CreateButton } from "../../Styles-Elements/Buttons";
import saveImg from "../../image-assets/save.svg"
import copyImg from "../../image-assets/copy.svg"
import circleX from "../../image-assets/circle-x.svg"
import checkedGreen from "../../image-assets/checked-green.svg"
import "./Gadgets.css";
import { Boxed } from "../../Styles-Elements/Box";
import { CustomRoundedButton } from "../../Styles-Elements/Buttons";
import GadgetsBreadcrumb from "./GadgetsBreadcrumb";
import { InputWrapper, Textfield } from "../../Styles-Elements/Inputs";
import SaveNewStringPopup from "./SaveNewStringPopup";
// Importing localized strings
const strings = require("../../localisation_en.json");
const marks = [
    {
        value: 0,
    },
    {
        value: 50,
    },
    {
        value: 100,
    },
];
const IOSSlider = styled(Slider)(({ theme }) => ({
    color: theme.palette.mode === 'dark' ? '#D9D9D9' : '#D9D9D9',
    height: 3,
    padding: '11px 0',
    '& .MuiSlider-thumb': {
        height: 30,
        width: 30,
        backgroundColor: '#4A0FA3',
        boxShadow: 'none !important',
        border: '7px solid #00E4A2',
        '&:focus, &:hover, &.Mui-active': {
            outline: ' 1px solid #4A0FA3',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                boxShadow: 'none',
            },
        },
    },
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-rail': {
        opacity: 1,
        backgroundColor: '#D9D9D9',
    },
    '& .MuiSlider-mark': {
        backgroundColor: '#bfbfbf',
        height: 25,
        width: 3,
        '&.MuiSlider-markActive': {
            opacity: 1,
            backgroundColor: 'currentColor',
        },
    },
}));
const TypoGenerator = (props) => {
    const [saveNewStringPopupOpen, setSaveNewStringPopupOpen] = useState(false);
    const openSaveNewStringPopup = () => {
        setSaveNewStringPopupOpen(true);
    };
    return (
        <>
            <div className="gadgets-holder">
                <GadgetsBreadcrumb />
                <div className="gadgets-white-box typo-generator-grid">
                    <div>
                        <Subheading
                            text={strings.selectGeneratorPowerLevel}
                            color={'#423069'}
                            padding={"0px 0 20px 0"}
                        />
                        <div className="range-slider-block">
                            <Boxed display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                <Body
                                    text={strings.normal}
                                    color={Colors.darkPurpleGray}
                                    padding={"0px 0 10px 0"}
                                />
                                <Body
                                    text={strings.high}
                                    color={Colors.darkPurpleGray}
                                    padding={"0px 0 10px 16px"}
                                />
                                <Body
                                    text={strings.overdrive}
                                    color={Colors.darkPurpleGray}
                                    padding={"0px 0 10px 0"}
                                />
                            </Boxed>
                            <IOSSlider
                                aria-label="ios slider"
                                defaultValue={100}
                                marks={marks}
                                valueLabelDisplay="off"
                            />
                        </div>
                        <Subheading
                            text={strings.typeAKeywordPhraseHitEnter}
                            color={'#423069'}
                            padding={"0px 0 20px 0"}
                        />
                        <div className="generator-Search-block">
                            <InputWrapper>
                                <Textfield
                                    placeholder={strings.startTypingHere}
                                    margin={"0"}
                                    padding={"12px 130px 12px 16px"}
                                />
                                <CustomRoundedButton
                                    theme={FontStyles.largeBody}
                                    fontWeight={'600'}
                                    text={strings.highlightAdd}
                                    backgroundColor={Colors.sharpGreen}
                                    color={Colors.mainLight}
                                    padding={"10px 24px"}
                                    margin={"0 0"}
                                    borderRadius={"0px 4px 4px 0px"}
                                    className={'search-btn'}
                                />
                            </InputWrapper>
                        </div>
                        <div className="chips-generate-block">
                            <div className="chips-block">
                                <span className="chips">{strings.softwareEngineer} <img className="chips-circle" src={circleX} alt="" /></span>
                                <span className="chips">sw engineer <img className="chips-circle" src={circleX} alt="" /></span>
                                <span className="chips">firmware engineer <img className="chips-circle" src={circleX} alt="" /></span>
                                <span className="chips">fw engineer <img className="chips-circle" src={circleX} alt="" /></span>
                                <span className="chips click">fw engineer <img className="chips-circle" src={checkedGreen} alt="" /></span>
                            </div>
                            <div className="chips-btns-block">
                                <Boxed display={'flex'} alignItems={'center'}>
                                    <CreateButton
                                        text={strings.generate}
                                        color={Colors.mainLight}
                                        className={"chips-btn-first"}
                                    />
                                    <CreateButton
                                        text={strings.clear}
                                        color={Colors.mainLight}
                                        className={"chips-btn-second"}
                                    />
                                </Boxed>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Subheading
                            text={strings.output}
                            color={'#423069'}
                            padding={"0px 0 20px 0"}
                        />
                        <div className="editor-wrapper">
                            <div className="editor-content-wrap">
                                "software engineer" OR "oftware engineer" OR "sftware engineer" OR "sotware engineer" OR "sofware engineer" OR "softare engineer" OR "softwre engineer" OR "softwae engineer" OR "softwar engineer" OR "software ngineer" OR "software egineer" OR "software enineer" OR "software engneer" OR "software engieer" OR "software enginer" OR "software enginer" OR "software enginee" OR "ssoftware engineer" OR "sooftware engineer" OR "sofftware engineer" OR "softtware engineer" OR "softwware engineer" OR "softwaare engineer" OR "softwarre engineer" OR "softwaree engineer" OR "software eengineer" OR "software enngineer" OR "software enggineer" OR "software engiineer" OR "software enginneer" OR "software engineeer" OR "software engineeer" OR "software engineerr" OR "osftware engineer" OR "sfotware engineer" OR "sotfware engineer" OR "sofwtare engineer" OR "softawre engineer" OR "softwrae engineer" OR "softwaer engineer" OR "softwar eengineer" OR "software negineer" OR "software egnineer" OR "software enigneer" OR "software engnieer" OR "software engiener" OR "software enginere" OR "softwareengineer" OR "aoftware engineer" OR "woftware engineer"
                            </div>
                            <Boxed display={'flex'} alignItems={'center'} justifyContent={'space-between'} className="editor-controls-wrap">
                                <Boxed display={'flex'} alignItems={'center'} className="control-btns" onClick={openSaveNewStringPopup}>
                                    <img src={saveImg} alt="" />
                                    <Body
                                        text={strings.save}
                                        color={Colors.darkPurple}
                                        padding={"0px 0 0px 8px"}
                                    />
                                </Boxed>
                                <Boxed display={'flex'} alignItems={'center'} className="control-btns">
                                    <img src={copyImg} alt="" />
                                    <Body
                                        text={strings.copy}
                                        color={Colors.darkPurple}
                                        padding={"0px 0 0px 8px"}
                                    />
                                </Boxed>
                                <Boxed display={'flex'} alignItems={'center'} className="control-btn-fill">
                                    <Body
                                        text={'14578'}
                                        color={Colors.darkPurple}
                                        padding={"5px 10px"}
                                    />
                                </Boxed>
                            </Boxed>
                        </div>
                    </div>
                </div>
            </div>
            {saveNewStringPopupOpen ? (
                <SaveNewStringPopup
                    closePopup={() => {
                        setSaveNewStringPopupOpen(false);
                    }}
                    popupIsOpen={true}
                />
            ) : null}
        </>
    );
};
export default TypoGenerator;