import React, { useEffect } from "react";
import "antd/dist/antd.css";
import { Subtitle } from "../Styles-Elements/Labels";
import { ClearButton } from "../Styles-Elements/Buttons";
import closePopUpByKey from "../GlobalModule/closePopUpByKey";
import "antd/dist/antd.css";
import * as Colors from "../Styles-Elements/Colors";
import booThinking from "../image-assets/boo-thinking.png";
import { CustomRoundedButton } from "../Styles-Elements/Buttons";

const AlertBox = ({ closePopup, message, submit, submitButtonText = "Yes", closeButtonText = "No" }) => {
  useEffect(() => {
    closePopUpByKey(closePopup);
  }, []);

  return (
    <div className="container">
      <div className="container-modal">
        <div className="popup-boo-container positive">
          <img src={booThinking} width={120} height={120} alt="" />
        </div>
        <div className="container-modal-mid">
          <Subtitle text={message} color={Colors.nightPurple} textAlign={"center"} padding={"24px 0 16px 0"} />
          <CustomRoundedButton
            onClick={submit}
            text={submitButtonText}
            margin={"24px 0 8px 0"}
            color={Colors.nightPurple}
            backgroundColor={Colors.sharpGreen}
          />
          <ClearButton text={closeButtonText} onClick={() => closePopup()} />
        </div>
      </div>
    </div>
  );
};

export default AlertBox;
