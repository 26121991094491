const masculineFeminine = [
  {
    language: "Afrikaans",
    masculine: "mannelik",
    feminine: "vroulik",
  },
  {
    language: "Albanian",
    masculine: "mashkulin",
    feminine: "femëror",
  },
  {
    language: "Arabic",
    masculine: "ذكري",
    feminine: "أنثوي",
  },
  {
    language: "Armenian",
    masculine: "արական",
    feminine: "իգական",
  },
  {
    language: "Assamese",
    masculine: "পুরুষলিঙ্গ",
    feminine: "মহিলা",
  },
  {
    language: "Azerbaijani",
    masculine: "kişi",
    feminine: "qadın",
  },
  {
    language: "Basque",
    masculine: "gizonezkoa",
    feminine: "emakumezkoa",
  },
  {
    language: "Belarusian",
    masculine: "мужчынскі",
    feminine: "жаночы",
  },
  {
    language: "Bengali",
    masculine: "পুরুষ",
    feminine: "মহিলা",
  },
  {
    language: "Bosnian",
    masculine: "muški",
    feminine: "ženski",
  },
  {
    language: "Bulgarian",
    masculine: "мъжки",
    feminine: "женски",
  },
  {
    language: "Burmese",
    masculine: "အမျိုးသား",
    feminine: "အမျိုးသမီး",
  },
  {
    language: "Catalan",
    masculine: "masculí",
    feminine: "femení",
  },
  {
    language: "Chinese Simplified",
    masculine: "男性",
    feminine: "女性",
  },
  {
    language: "Chinese Traditional",
    masculine: "男性",
    feminine: "女性",
  },
  {
    language: "Croatian",
    masculine: "muški",
    feminine: "ženski",
  },
  {
    language: "Czech",
    masculine: "mužský",
    feminine: "ženský",
  },
  {
    language: "Danish",
    masculine: "maskulin",
    feminine: "feminin",
  },
  {
    language: "Dutch",
    masculine: "mannelijk",
    feminine: "vrouwelijk",
  },
  {
    language: "English",
    masculine: "masculine",
    feminine: "feminine",
  },
  {
    language: "Esperanto",
    masculine: "vira",
    feminine: "virina",
  },
  {
    language: "Estonian",
    masculine: "meessoost",
    feminine: "naissoost",
  },
  {
    language: "Filipino",
    masculine: "Tagalog",
    feminine: "lalaki",
  },
  {
    language: "Finnish",
    masculine: "maskuliininen",
    feminine: "feminiininen",
  },
  {
    language: "French",
    masculine: "masculin",
    feminine: "féminin",
  },
  {
    language: "Galician",
    masculine: "masculino",
    feminine: "feminino",
  },
  {
    language: "Georgian",
    masculine: "მამაკაცი",
    feminine: "ქალი",
  },
  {
    language: "German",
    masculine: "männlich",
    feminine: "weiblich",
  },
  {
    language: "Greek",
    masculine: "αρσενικός",
    feminine: "θηλυκός",
  },
  {
    language: "Gujarati",
    masculine: "પુરુષ",
    feminine: "સ્ત્રી",
  },
  {
    language: "Haitian Creole",
    masculine: "maskilin",
    feminine: "feminin",
  },
  {
    language: "Hausa",
    masculine: "daga,jinsi",
    feminine: "mace",
  },
  {
    language: "Hebrew",
    masculine: "זכר",
    feminine: "נקבה",
  },
  {
    language: "Hindi",
    masculine: "पुरुष",
    feminine: "महिला",
  },
  {
    language: "Hmong",
    masculine: "neeg,siab",
    feminine: "niam",
  },
  {
    language: "Hungarian",
    masculine: "hímnemű",
    feminine: "nőnemű",
  },
  {
    language: "Icelandic",
    masculine: "karlkyns",
    feminine: "kvenkyns",
  },
  {
    language: "Igbo",
    masculine: "otu,nwoke",
    feminine: "nwanyị",
  },
  {
    language: "Indonesian",
    masculine: "maskulin",
    feminine: "feminin",
  },
  {
    language: "Irish",
    masculine: "firinscneach",
    feminine: "baininscneach",
  },
  {
    language: "Italian",
    masculine: "maschile",
    feminine: "femminile",
  },
  {
    language: "Japanese",
    masculine: "男性",
    feminine: "女性",
  },
  {
    language: "Javanese",
    masculine: "lanang",
    feminine: "wadon",
  },
  {
    language: "Kannada",
    masculine: "ಗಂಡು",
    feminine: "ಹೆಣ್ಣು",
  },
  {
    language: "Kazakh",
    masculine: "ер",
    feminine: "әйел",
  },
  {
    language: "Khmer",
    masculine: "បុរស",
    feminine: "ស្រី",
  },
  {
    language: "Korean",
    masculine: "남성의",
    feminine: "여성의",
  },
  {
    language: "Kurdish",
    masculine: "Sorani",
    feminine: "مرد",
  },
  {
    language: "Kurdish",
    masculine: "Sorani",
    feminine: "مێ",
  },
  {
    language: "Kyrgyz",
    masculine: "эркек",
    feminine: "аял",
  },
  {
    language: "Kyrgyz",
    masculine: "эркек",
    feminine: "аял",
  },
  {
    language: "Lao",
    masculine: "ຜູ້ຊາຍ",
    feminine: "ຜູ້ຍິງ",
  },
  {
    language: "Latvian",
    masculine: "vīriešu",
    feminine: "sieviešu",
  },
  {
    language: "Lithuanian",
    masculine: "vyras",
    feminine: "moteriškas",
  },
  {
    language: "Luxembourgish",
    masculine: "maskulin",
    feminine: "féminin",
  },
  {
    language: "Macedonian",
    masculine: "машки",
    feminine: "женски",
  },
  {
    language: "Maori",
    masculine: "tāne",
    feminine: "wahine",
  },
  {
    language: "Malay",
    masculine: "lelaki",
    feminine: "perempuan",
  },
  {
    language: "Malayalam",
    masculine: "പുരുഷൻ",
    feminine: "സ്ത്രീ",
  },
  {
    language: "Malagasy",
    masculine: "mizaka,ny,vehivavy",
    feminine: "vehivavy",
  },
  {
    language: "Maltese",
    masculine: "maskil",
    feminine: "feminil",
  },
  {
    language: "Marathi",
    masculine: "पुरुष",
    feminine: "स्त्री",
  },
  {
    language: "Mongolian",
    masculine: "эрэгтэй",
    feminine: "эмэгтэй",
  },
  {
    language: "Nepali",
    masculine: "पुरुष",
    feminine: "महिला",
  },
  {
    language: "Norwegian",
    masculine: "maskulin",
    feminine: "feminin",
  },
  {
    language: "Oriya",
    masculine: "ପୁରୁଷ",
    feminine: "ମହିଳା",
  },
  {
    language: "Oromo",
    masculine: "goota",
    feminine: "foolla",
  },
  {
    language: "Pashto",
    masculine: "نر",
    feminine: "ښځه",
  },
  {
    language: "Persian",
    masculine: "Farsi",
    feminine: "مردانه",
    feminine: "زنانه",
  },
  {
    language: "Polish",
    masculine: "męski",
    feminine: "żeński",
  },
  {
    language: "Portuguese",
    masculine: "masculino",
    feminine: "feminino",
  },
  {
    language: "Punjabi",
    masculine: "ਮਰਦਾਨਾ",
    feminine: "ਔਰਤਾਂ",
  },
  {
    language: "Romanian",
    masculine: "masculin",
    feminine: "feminin",
  },
  {
    language: "Russian",
    masculine: "мужской",
    feminine: "женский",
  },
  {
    language: "Samoan",
    masculine: "tama",
    feminine: "fafine",
  },
  {
    language: "Scots Gaelic",
    masculine: "fear",
    feminine: "boireannach",
  },
  {
    language: "Sesotho",
    masculine: "botona",
    feminine: "motsoalle",
  },
  {
    language: "Shona",
    masculine: "mukomana",
    feminine: "mukadzi",
  },
  {
    language: "Sindhi",
    masculine: "مرداني",
    feminine: "عورت",
  },
  {
    language: "Sinhala",
    masculine: "පුරුවුව",
    feminine: "ගැහැණු",
  },
  {
    language: "Slovak",
    masculine: "mužský",
    feminine: "ženský",
  },
  {
    language: "Slovenian",
    masculine: "moški",
    feminine: "ženski",
  },
  {
    language: "Somali",
    masculine: "maskuulin",
    feminine: "haweeney",
  },
  {
    language: "Spanish",
    masculine: "masculino",
    feminine: "femenino",
  },
  {
    language: "Sundanese",
    masculine: "lalaki",
    feminine: "wuragaté",
  },
  {
    language: "Swahili",
    masculine: "kiume",
    feminine: "kike",
  },
  {
    language: "Swedish",
    masculine: "maskulin",
    feminine: "feminin",
  },
  {
    language: "Tagalog",
    masculine: "lalaki",
    feminine: "babae",
  },
  {
    language: "Tajik",
    masculine: "мардонана",
    feminine: "занона",
  },
  {
    language: "Tamil",
    masculine: "ஆண்",
    feminine: "பெண்",
  },
  {
    language: "Tatar",
    masculine: "ер",
    feminine: "кадын",
  },
  {
    language: "Telugu",
    masculine: "పురుషుడు",
    feminine: "స్త్రీ",
  },
  {
    language: "Thai",
    masculine: "ผู้ชาย",
    feminine: "ผู้หญิง",
  },
  {
    language: "Tigrinya",
    masculine: "ሰብ",
    feminine: "ሴት",
  },
  {
    language: "Tongan",
    masculine: "tāne",
    feminine: "fefa'atai",
  },
  {
    language: "Turkish",
    masculine: "erkek",
    feminine: "kadın",
  },
  {
    language: "Turkmen",
    masculine: "erkek",
    feminine: "zenan",
  },
  {
    language: "Ukrainian",
    masculine: "чоловічий",
    feminine: "жіночий",
  },
  {
    language: "Urdu",
    masculine: "مردانہ",
    feminine: "عورتانہ",
  },
  {
    language: "Uzbek",
    masculine: "erkak",
    feminine: "ayol",
  },
  {
    language: "Vietnamese",
    masculine: "nam",
    feminine: "nữ",
  },
  {
    language: "Welsh",
    masculine: "gwrywaidd",
    feminine: "benywaidd",
  },
  {
    language: "Wolof",
    masculine: "jalang",
    feminine: "noy",
  },
  {
    language: "Xhosa",
    masculine: "inkwenkwe",
    feminine: "umfazi",
  },
  {
    language: "Yiddish",
    masculine: "מאַסקולין",
    feminine: "ווייַבלעך",
  },
  {
    language: "Yoruba",
    masculine: "okunrin",
    feminine: "obinrin",
  },
  {
    language: "Zulu",
    masculine: "okuninana",
    feminine: "okushadile",
  },
];

export default masculineFeminine;
