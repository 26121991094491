import { BASE_URL, NEW_API } from "../Helpers/Enums";
import globalRequest from "./globalRequest";

const updateLastInteraction = async (userId = 0) => {
  let url = NEW_API.LAST_INTERACTION;
  let response = await globalRequest(
    "post",
    url,
    {
      user_id: userId,
    },
    {},
    true
  );
  return response;
};

export default updateLastInteraction;
