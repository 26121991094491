import { REQUEST } from "../Helpers/Enums"
import globalRequest from "./globalRequest"

const getCurrentPlan = async (userId) => {

    const response = await globalRequest("get", REQUEST.GETPLAN_REQUEST, {}, {
        params: {
            user_id: userId
        }
    }, true);

    return response;

}
export default getCurrentPlan