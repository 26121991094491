import React, { useState, useEffect } from 'react'
import 'antd/dist/antd.css'
import * as Colors from '../Styles-Elements/Colors'
import { Subheading, Subtitle } from '../Styles-Elements/Labels'
import { Textfield } from '../Styles-Elements/Inputs'
import {
  ClearButton,
  SecondaryRoundedButton,
} from '../Styles-Elements/Buttons'
import booHappy from '../image-assets/boo-happy.png'
import { isEmpty } from 'lodash'

import Scrollbar from 'react-smooth-scrollbar'
import axios from 'axios'
import Snackbars from '../GlobalModule/Snackbars'
import { KEYWORD_STRING } from '../Helpers/Enums'
import { useDispatch, useSelector } from 'react-redux'
import { tooledUpLocalData } from '../redux/reducers/getTooledupData'
import focus from '../GlobalModule/focus'
import closePopUpByKey from '../GlobalModule/closePopUpByKey'
import { setSnackbar } from '../redux/reducers/snackbar'
import globalRequest from '../GlobalModule/globalRequest'
import { changeLoader } from '../redux/reducers/loader'
const strings = require('../localisation_en.json')

const RenameString = (props) => {

  const stringId = props.stringId
  const auth = useSelector(tooledUpLocalData);
  const dispatch = useDispatch();
  const [renameString, setRenameString] = useState(props.stringName)

  const handleRenameCreatedStringName = async (e) => {
    e.preventDefault()
    if (renameString == '') {
      dispatch(setSnackbar({
        snackbarMessage: 'String cannot be empty.',
        snackbarOpen: true,
        snackbarState: 'error',
      }))
    } else {

      dispatch(changeLoader(true));

      const response = await globalRequest("put", KEYWORD_STRING.RENAME_STRING, {
        user_id: auth?.user_id,
        string_id: props.stringId,
        string_name: renameString,
      }, {}, true);

      if (response.data.status == 1) {

        dispatch(setSnackbar({
          snackbarMessage: response.data.message,
          snackbarOpen: true,
          snackbarState: 'success',
        }));
        
        props.callback(renameString,true)

      } else {
        dispatch(setSnackbar({
          snackbarMessage: response.data.message,
          snackbarOpen: true,
          snackbarState: 'error',
        }))
      }

      dispatch(changeLoader(false));
    }
  }

  useEffect(() => {
    focus('rename')
    closePopUpByKey(props.closePopup)
  }, [])

  return (
    <div className="container">
      <div className="container-modal">
        <div className="popup-boo-container positive">
          <img src={booHappy} width={120} height={120} />
        </div>
        <Scrollbar className="container-modal-mid">
          <Subtitle
            text={strings.letsRenameYourString}
            color={Colors.nightPurple}
            textAlign={'center'}
            padding={'24px 0'}
          />
          <Subheading
            text={strings.nameOfTheString}
            color={Colors.nightPurple}
            padding={'16px 0 8px 0'}
          />
          <form onSubmit={handleRenameCreatedStringName}>
            <Textfield
              placeholder={strings.enterANameForYourString}
              value={renameString}
              onChange={(e) => setRenameString(e.target.value)}
              require={true}
              id="rename"
            />
            <SecondaryRoundedButton
              text={strings.save}
              margin={'24px 0 8px 0'}
              type="submit"
            />
          </form>
          <ClearButton
            text={strings.cancel}
            onClick={() => props.closePopup()}
          />
        </Scrollbar>
      </div>
    </div>
  )
}

export default RenameString
