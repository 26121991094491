import { useState, useEffect } from "react";
import * as Colors from "../../Styles-Elements/Colors";
import * as FontStyles from "../../Styles-Elements/FontStyles";
import { LargeBody, Subtitle, Subheading, Body } from "../../Styles-Elements/Labels";
import { ClearButton, SecondaryRoundedButton } from "../../Styles-Elements/Buttons";
import { IMPORT_EXPORT_STRING, STRING_TYPE, BASE_URL } from "../../Helpers/Enums";
import Dropzone from "react-dropzone";
import fileIcon from "../../image-assets/fileicon.svg";
import importIcon from "../../image-assets/import-icon.svg";
import addDocumentIcon from "../../image-assets/add-document-icon.svg";
import crossIcon from "../../image-assets/closepopupicon.svg";
import Spinner from "../../image-assets/spinner.gif";
// import { Box } from "@material-ui/core";
import { Boxed } from "../../Styles-Elements/Box";
import "./ImportStrings.css";
import globalRequest from "../../GlobalModule/globalRequest";
import { useDispatch, useSelector } from "react-redux";
import { tooledUpLocalData } from "../../redux/reducers/getTooledupData";
import { changeLoader } from "../../redux/reducers/loader";
import { setSnackbar } from "../../redux/reducers/snackbar";
import { currentTrial } from "../../redux/reducers/getTrial";
// Importing localised strings
const strings = require("../../localisation_en.json");
const ImportStrings = (props) => {
  const auth = useSelector(tooledUpLocalData);
  const trialData = useSelector(currentTrial);
  const dispatch = useDispatch();
  const [activeToggleOption, setActiveToggleOption] = useState(STRING_TYPE.COMPANY_STRING);
  const [fileName, setFileName] = useState("");
  const [fileContent, setFileContent] = useState("Drag and drop or select .CSV file");
  const [fileImportContent, setFileImportContent] = useState("");
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupType, setPopupType] = useState("");
  const [uploadImage, setUploadImage] = useState(Spinner);
  const [uploadingColor, setUploadingColor] = useState(Colors.darkGray);
  useEffect(() => {
    if (fileName.name != undefined) {
      setTimeout(() => {
        setUploadImage(crossIcon);
        setUploadingColor(Colors.black);
      }, 3000);
    }
  }, [fileName]);

  async function handleDrop([file]) {
    setUploadImage(Spinner);
    setFileName(file);
    setFileContent("Choose a different file to upload.");
    var reader = new FileReader();
    reader.onload = () => {
      const binaryStr = reader.result;
      setFileImportContent(binaryStr);
    };
    reader.readAsText(file);
  }

  const time = () => {
    let d = new Date();

    const getPmAm = () => {
      if (d.getHours() >= 12) {
        return "PM";
      } else {
        return "AM";
      }
    };

    const month = () => {
      let mon = d.getMonth() + 1;
      if (mon <= 9) {
        return `0${mon}`;
      } else {
        return mon;
      }
    };

    const date = () => {
      let da = d.getDate();
      if (da <= 9) {
        return `0${da}`;
      } else {
        return da;
      }
    };

    const minute = () => {
      let da = d.getMinutes();
      if (da <= 9) {
        return `0${da}`;
      } else {
        return da;
      }
    };
    const hour = () => {
      let da = d.getHours();
      if (da <= 9) {
        return `0${da}`;
      } else {
        return da;
      }
    };

    let d1 = `${hour()}:${minute()} ${getPmAm()} ${date()}/${month()}/${d.getFullYear()}`;
    return d1;
  };

  const cross = () => {
    setFileContent("Drag and drop or select .CSV file");
    setFileName("");
    setUploadImage(Spinner);
    setUploadingColor(Colors.darkGray);
  };

  const importData = async () => {
    if (fileName) {
      dispatch(changeLoader(true));
      let formData = new FormData();
      formData.append("string_type", activeToggleOption == "KeywordString" ? "keyword" : "company");
      formData.append("user_id", auth?.user_id);
      formData.append("team_id", auth?.team_id);
      formData.append("import_file", fileName);

      const f = await globalRequest("post", IMPORT_EXPORT_STRING.IMPORT_STRING, formData, {}, true);
      let data = f.data;
      if (data.status == 1) {
        dispatch(
          setSnackbar({
            snackbarMessage: data.message,
            snackbarOpen: true,
            snackbarState: "success",
          })
        );
        setFileContent("Drag and drop or select .CSV file");
        setFileName("");
      } else {
        dispatch(
          setSnackbar({
            snackbarMessage: data.message,
            snackbarOpen: true,
            snackbarState: "error",
          })
        );
      }

      dispatch(changeLoader(false));
    } else {
      dispatch(
        setSnackbar({
          snackbarMessage: "Please select file first.",
          snackbarOpen: true,
          snackbarState: "error",
        })
      );
    }
  };

  const downloadCsv = () => {
    let link = "";
    if (activeToggleOption == STRING_TYPE.KEYWORD_STRING) {
      link = `${BASE_URL}uploads/keyword_string.csv`;
    } else if (activeToggleOption == STRING_TYPE.COMPANY_STRING) {
      link = `${BASE_URL}uploads/company_string.csv`;
    }
    let element = document.createElement("a");
    element.setAttribute("href", link);
    element.click();
  };

  return (
    <div className="settings-holder">
      <Subtitle text={strings.importYourStrings} color={Colors.nightPurple} padding={"0 0 24px 0"} />
      <div className="settings-grid import-strings-grid">
        <div className="settings-subscription-holder isg-ssh">
          <Subheading text={strings.stringType} color={"#423069"} padding={"0 0 16px 0"} />
          <div className="tab-manager">
            <div
              className={`tab ${activeToggleOption === STRING_TYPE.COMPANY_STRING ? "selected-tab" : ""}`}
              onClick={() => {
                setActiveToggleOption(STRING_TYPE.COMPANY_STRING);
                setFileContent("Drag and drop or select .CSV file");
                setFileName("");
                setUploadingColor(Colors.darkGray);
              }}
            >
              <LargeBody
                text={strings.companyStrings}
                color={activeToggleOption === STRING_TYPE.COMPANY_STRING ? Colors.darkPurple : Colors.darkGray}
                textAlign={"center"}
              />
            </div>
            <div
              className={`tab ${activeToggleOption === STRING_TYPE.KEYWORD_STRING ? "selected-tab" : ""}`}
              onClick={() => {
                setActiveToggleOption(STRING_TYPE.KEYWORD_STRING);
                setFileContent("Drag and drop or select .CSV file");
                setFileName("");
                setUploadingColor(Colors.darkGray);
              }}
            >
              <LargeBody
                text={strings.keywordStrings}
                color={activeToggleOption === STRING_TYPE.KEYWORD_STRING ? Colors.darkPurple : Colors.darkGray}
                textAlign={"center"}
              />
            </div>
          </div>
          <div className="dropzone-field">
            <Dropzone onDrop={(acceptedFiles) => handleDrop(acceptedFiles)}>
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} accept=".csv" type="file" />
                  <div className="file-field__dropzone">
                    <div className="file-field__status"></div>
                    <div className="file-field__text">
                      <img src={addDocumentIcon} style={{ fill: Colors.lightGray }} alt="" />
                      <Body text={fileContent} color={Colors.lightGray} padding={"2px 0 0 8px"} />
                    </div>
                  </div>
                </div>
              )}
            </Dropzone>
          </div>
          {fileName ? (
            <div className="thumbnail-upload-holder grid-item-flex tuh-uploaded">
              <Boxed display={"flex"} alignItems={"center"}>
                <img src={fileIcon} className="upload-icon" alt="" />
                <div style={{ paddingLeft: "16px" }}>
                  <LargeBody text={fileName?.name} color={uploadingColor} />
                  {uploadingColor == Colors.darkGray ? null : (
                    <Body text={`Uploaded: ${time()}`} color={Colors.darkGray} />
                  )}
                </div>
              </Boxed>
              <img src={uploadImage} style={{ height: "41px" }} onClick={cross} className="cursor-p" alt="" />
            </div>
          ) : null}
        </div>
        <div className="settings-detail-holder isg-sdh">
          <Subheading text={strings.supportedFormats} color={Colors.nightPurple} />
          <Body text={strings.youCanUseCSVFormat} color={Colors.darkGray} padding={"8px 0 0 0"} />
          <Subheading text={strings.importantNotes} color={Colors.nightPurple} padding={"24px 0 0 0"} />
          <Body text={strings.duplicatesWillBeVerified} color={Colors.darkGray} padding={"8px 0 0 0"} />
          <Body text={strings.firstRowOfTheGivenFileWillBeTreated} color={Colors.darkGray} padding={"8px 0 0 0"} />
          <Body text={strings.pleaseEnsureYourFileSizeDoesNotExceed} color={Colors.darkGray} padding={"8px 0 0 0"} />
          <Body text={strings.whileCheckingForDuplicateRecords} color={Colors.darkGray} padding={"8px 0 0 0"} />
        </div>
        <div className="onboarding-import-button-holder isg-oibh1">
          <Subheading text={strings.downloadImportTemplate} color={Colors.nightPurple} padding={"24px 0 0 0"} />
          <Body text={strings.noteYouWillHaveToCreateADocument} color={Colors.darkGray} padding={"8px 0 0 0"} />
          <div className="import-existing-csv-holder">
            <img src={importIcon} alt="" />
            <ClearButton
              theme={FontStyles.body}
              onClick={downloadCsv}
              text={
                activeToggleOption === STRING_TYPE.COMPANY_STRING
                  ? strings.downloadCompanyStringCSVTemplate
                  : strings.downloadKeywordStringCSVTemplate
              }
              textAlign={"left"}
              color={Colors.darkPurple}
            />
          </div>
        </div>
        <div className="onboarding-import-button-holder isg-oibh">
          <div></div>
          {trialData?.checkPay ? (
            <SecondaryRoundedButton onClick={importData} text={strings.import} width={"164px"} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ImportStrings;
