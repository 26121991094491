import { createSlice } from '@reduxjs/toolkit'

export const trialConfirm = createSlice({
  name: 'trialConfirm',
  initialState: {
    value: {
        open : false,
        type : "autoBuild",
        isSubmit : false
    },
  },
  reducers: {
    changeTrialConfirm: (state, action) => {
      state.value = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { changeTrialConfirm } = trialConfirm.actions
export const currentTrialConfirm = (state) => state.trialConfirm.value;
export default trialConfirm.reducer
