import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "antd/dist/antd.css";
import * as Colors from "../Styles-Elements/Colors";
import { Subheading, Subtitle, LargeBody, Body } from "../Styles-Elements/Labels";
import { Textfield } from "../Styles-Elements/Inputs";
import { isEmpty } from "lodash";
import { ClearButton, SecondaryRoundedButton } from "../Styles-Elements/Buttons";
import booHappy from "../image-assets/boo-happy.png";
import dropdownIconLight from "../image-assets/dropdown-icon-light.svg";

import { REQUEST, STORAGE } from "../Helpers/Enums";
import useDropdownMenu from "react-accessible-dropdown-menu-hook";
import getFolder from "../apis/getFolder";
import CollabFolderAndMove from "./CollabFolderAndMove";
import focus from "../GlobalModule/focus";
// Material UI for the snackbar
// import Box from '@material-ui/core/Box'
import globalRequest from "../GlobalModule/globalRequest";
import encryptDcrypt from "../GlobalModule/encryptDcrypt";
import addDeleteGetLocalStorage from "../GlobalModule/addDeleteGetLocalStorage";

import { useSelector, useDispatch } from "react-redux";
import { stringData } from "../redux/reducers/localString";
import { tooledUpLocalData } from "../redux/reducers/getTooledupData";
import { setSnackbar } from "../redux/reducers/snackbar";
import { changeLoader } from "../redux/reducers/loader";
import { currentSidebar } from "../redux/reducers/sidebar";
import { setChange } from "../redux/reducers/getLocalData";
import { Boxed } from "../Styles-Elements/Box";
import { Autocomplete, TextField } from "@mui/material";

const AddString = (props) => {
  const strings = useSelector(stringData);
  const auth = useSelector(tooledUpLocalData);
  const typeParam = useSelector(currentSidebar);
  const dispatch = useDispatch();

  const [stringName, setStringName] = useState("");
  const { buttonProps, itemProps, isOpen, setIsOpen } = useDropdownMenu(3);
  const [folderStringType, setfolderStringType] = useState("My Strings (Default)");
  const [folderId, setFolderId] = useState(0);
  const [createdBy, setCreatedBy] = useState(0);

  const [folder, setFolder] = useState([]);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [selectedString, setSelectedString] = useState({
    id: 0,
    folder_name: "My Strings (Default)",
    created_by: 0,
  });

  useEffect(() => {
    getFolder(auth.user_id, (response) => {
      let data = response.data;
      if (data.status == 1) {
        let sort = data.data.sort((a, b) => {
          if (a.folder_name < b.folder_name) {
            return -1;
          } else {
            return 1;
          }
        });
        let a = [
          {
            folder_name: folderStringType,
            id: 0,
            created_by: 0,
          },
          ...sort,
        ];
        setFolder(a);
      }
    });
  }, []);

  let history = useHistory();

  const handleCreateString = async (e) => {
    e.preventDefault();

    if (!isEmpty(stringName)) {
      if (stringName.length > 2) {
        let userId = auth?.user_id ? auth.user_id : "";
        let stingType = typeParam;
        let teamId = auth?.team_id;

        let formData = new FormData();
        formData.append("user_id", userId);
        formData.append("string_type", stingType);
        formData.append("string_name", stringName);
        formData.append("team_id", teamId);

        if (folderId != 0) {
          formData.append("folder_id", folderId);
          formData.append("created_by", createdBy);
        }

        dispatch(changeLoader(true));
        let response = await globalRequest("post", REQUEST.CREATE_STRING_NAME_REQUEST, formData, {}, true);

        let res = response.data;
        if (res.status == 1) {
          if (folderId != 0) {
            addDeleteGetLocalStorage(STORAGE.FOLDER_DROPDOWN, { id: folderId, type: stingType }, "add");
            dispatch(setChange());
          }

          dispatch(
            setSnackbar({
              snackbarMessage: res.message,
              snackbarState: "success",
              snackbarOpen: true,
            })
          );

          props.callback(true, res);
        } else if (res.status == 0) {
          dispatch(
            setSnackbar({
              snackbarMessage: res.message,
              snackbarState: "error",
              snackbarOpen: true,
            })
          );
        } else {
          dispatch(
            setSnackbar({
              snackbarMessage: res.message,
              snackbarState: "error",
              snackbarOpen: true,
            })
          );
        }
      } else {
        dispatch(
          setSnackbar({
            snackbarMessage: "String Field should be greater than 3",
            snackbarState: "error",
            snackbarOpen: true,
          })
        );
      }

      dispatch(changeLoader(false));
    } else {
      dispatch(
        setSnackbar({
          snackbarMessage: "String Field Should Not Be Emtpy",
          snackbarState: "error",
          snackbarOpen: true,
        })
      );
    }
  };

  const callPopUp = (e) => {
    e.preventDefault();
    if (stringName == "") {
      dispatch(
        setSnackbar({
          snackbarMessage: "String Field Should Not Be Emtpy",
          snackbarState: "error",
          snackbarOpen: true,
        })
      );
    } else {
      setOpenPopUp(true);
    }
  };

  useEffect(() => {
    focus("string");
    window.addEventListener("keyup", (e) => {
      if (e.keyCode == 27) {
        props.closePopup();
      }
    });
  }, []);

  useEffect(() => {
    if (typeParam == "keyword") {
      let folderData = addDeleteGetLocalStorage(STORAGE.FOLDER_DATA_KEYWORD, {}, "get", "single");
      if (folderData) {
        try {
          let obj = JSON.parse(folderData);
          if (obj?.user_id === auth?.user_id) {
            setfolderStringType(obj.folder_name);
            setFolderId(obj.id);
            setCreatedBy(obj.created_by);
            setIsOpen(false);
            setSelectedString(obj);
          }
        } catch (e) {}
      }
    } else {
      let folderData = addDeleteGetLocalStorage(STORAGE.FOLDER_DATA_COMPANY, {}, "get", "single");
      if (folderData) {
        try {
          let obj = JSON.parse(folderData);
          if (obj?.user_id === auth?.user_id) {
            setfolderStringType(obj.folder_name);
            setFolderId(obj.id);
            setCreatedBy(obj.created_by);
            setIsOpen(false);
            setSelectedString(obj);
          }
        } catch (e) {}
      }
    }
  }, []);

  return (
    <div className="container">
      <div className="container-modal">
        <div className="popup-boo-container positive">
          <img src={booHappy} width={120} height={120} />
        </div>
        <Boxed className="container-modal-mid" style={{ overflowY: "hidden", paddingLeft: "4px", paddingRight: "4px" }}>
          <Subtitle text={strings.letsAddAString} color={Colors.nightPurple} textAlign={"center"} padding={"24px 0"} />
          <form onSubmit={folderId != 0 && createdBy != auth.user_id ? callPopUp : handleCreateString}>
            <Subheading text={strings.nameOfTheString} color={Colors.nightPurple} padding={"16px 0 8px 0"} />
            <Textfield
              placeholder={strings.enterANameForYourString}
              onChange={(e) => setStringName(e.target.value.trim())}
              id={"string"}
              required={true}
            />
            <Subheading text={strings.folderToAddYourStringTo} color={Colors.nightPurple} padding={"16px 0 8px 0"} />
            <div style={{ position: "relative" }}>
              <Autocomplete
                options={folder}
                getOptionLabel={(option) => option.folder_name}
                onChange={(event, value) => {
                  if (value !== null) {
                    setSelectedString(value);
                    setfolderStringType(value.folder_name);
                    setFolderId(value.id);
                    setCreatedBy(value.created_by);
                    setIsOpen(false);

                    let obj = {
                      ...value,
                      user_id: auth?.user_id,
                    };

                    if (typeParam == "keyword") {
                      addDeleteGetLocalStorage(STORAGE.FOLDER_DATA_KEYWORD, obj, "add", "single");
                    } else {
                      addDeleteGetLocalStorage(STORAGE.FOLDER_DATA_COMPANY, obj, "add", "single");
                    }
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{
                      fontSize: "19px",
                    }}
                  />
                )}
                value={selectedString}
              />
            </div>
            <SecondaryRoundedButton text={strings.create} margin={"24px 0 8px 0"} type={"submit"} />
          </form>
          <ClearButton text={strings.cancel} onClick={() => props.closePopup()} />
        </Boxed>

        {openPopUp ? (
          <CollabFolderAndMove
            stringName={stringName}
            closePopup={() => setOpenPopUp(false)}
            submitRequest={handleCreateString}
          />
        ) : null}
      </div>
    </div>
  );
};

export default AddString;
