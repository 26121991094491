import encryptDcrypt from "../../GlobalModule/encryptDcrypt";
import globalRequest from "../../GlobalModule/globalRequest";
import { BASE_URL } from "../../Helpers/Enums";

export const getLastActiveString = async (userId, type) => {
  // let l = await globalRequest("get", BASE_URL + "api/string/get_laststring", {}, {
  //     params: {
  //         user_id: userId,
  //         string_type: type
  //     }
  // }, true);
  // let data = l.data;

  // if (data.status == 1) {

  //     // console.log("get ", l.data.data);

  //     let isEmpty = Object.keys(data.data).length == 0;
  //     if (isEmpty) {
  //         return {};
  //     } else {
  //         let json = data.data
  //         return json
  //     }
  // } else {
  //     return {};
  // }
  return {};
};

export const postLastActiveString = async (userId, stringId, type, stringBool) => {
  // console.log("type ---- ", type);

  let json = {
    string_id: stringId,
    user_id: userId,
    string_type: type,
    string_boolean: JSON.stringify(stringBool),
  };
  // console.log("post ", json);
  let l = await globalRequest("post", BASE_URL + "api/string/action_laststring", json, {}, true);
};
