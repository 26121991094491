import globalRequest from "../GlobalModule/globalRequest";
import { NEW_API } from "../Helpers/Enums";

const reportBugsApi = (body = {}) => {
  return new Promise((resolve, reject) => {
    globalRequest("post", NEW_API.BUG_REPORT, body, {}, true)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default reportBugsApi;
