import 'antd/dist/antd.css'
import * as Colors from '../Styles-Elements/Colors'
import { Subtitle, LargeBody } from '../Styles-Elements/Labels'
import { ClearButton, CustomRoundedButton } from '../Styles-Elements/Buttons'
import booThinking from '../image-assets/boo-thinking.png'
import React, { useEffect } from 'react'


// Material UI for the snackbar
import Scrollbar from 'react-smooth-scrollbar'
import closePopUpByKey from '../GlobalModule/closePopUpByKey'
import { tooledUpLocalData } from '../redux/reducers/getTooledupData'
// Importing localised strings
const strings = require('../localisation_en.json')

const CollabFolderAndMove = (props) => {
  
  useEffect(() => {
    closePopUpByKey(props.closePopup)
  }, [])

  return (
    <div className="container">
      <div className="container-modal">
        <div className="popup-boo-container negative">
          <img src={booThinking} width={120} height={120} />
        </div>
        <Scrollbar className="container-modal-mid">
          <Subtitle
            text={'Are you sure.'}
            color={Colors.nightPurple}
            textAlign={'center'}
            padding={'24px 0'}
          />
          <LargeBody
            text={
              strings.whileCollabStringCreateDesc +
              ` ${props.stringName} string`
            }
            textAlign={'center'}
            color={Colors.darkGray}
            padding={'0 0 16px 0'}
          />
          <CustomRoundedButton
            text={'Proceed'}
            margin={'24px 0 8px 0'}
            color={Colors.mainLight}
            onClick={props.submitRequest}
            backgroundColor={Colors.lightRed}
          />
          <ClearButton
            text={strings.cancel}
            onClick={() => props.closePopup()}
          />
        </Scrollbar>
      </div>
    </div>
  )
}

export default CollabFolderAndMove;
