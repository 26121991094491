import { KEYWORD_STRING } from "../Helpers/Enums";
import globalRequest from "./globalRequest";

const changeOwnerShipString = async (stringFolderType, stringFolderId, newOwnerId, role, userId) => {

    const response = await globalRequest("post", KEYWORD_STRING.CHANGE_OWNERSHIP_STRING, {
        type: stringFolderType,
        id: stringFolderId,
        new_owner_id: newOwnerId,
        role: role,
        user_id: userId
    }, {}, true);

    return response

}

export default changeOwnerShipString;