import { createSlice } from "@reduxjs/toolkit";

export const socketUserData = createSlice({
  name: "socketUserData",
  initialState: {
    value: {},
  },
  reducers: {
    changeSocketUserData: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { changeSocketUserData } = socketUserData.actions;
export const currentSocketUserData = (state) => state.socketUserData.value;
export default socketUserData.reducer;
