const setCursorPosition = (index, id) => {
  let ind = index;
  let parent = document.getElementById(id);

  try {
    let range = document.createRange();
    let sel = document.getSelection();
    range.setStart(parent, ind);
    range.collapse(true);
    sel.removeAllRanges();
    sel.addRange(range);
    parent.focus();

  } catch (e) {
    // console.log("exception ",e);
  }
};

export default setCursorPosition;
