import { createSlice } from "@reduxjs/toolkit";
import { ON_TRIAL_STATUS } from "../../Helpers/Enums";

export const getTrial = createSlice({
  name: "getTrial",
  initialState: {
    value: {},
  },
  reducers: {
    changeTrial: (state, action) => {
      let obj = action.payload;
      let checkPay = obj?.status === ON_TRIAL_STATUS.PAYMENT_ACCEPTED || obj?.status === ON_TRIAL_STATUS.TRIAL;
      state.value = {
        ...obj,
        checkPay: checkPay,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeTrial } = getTrial.actions;
export const currentTrial = (state) => state.getTrial.value;
export default getTrial.reducer;
