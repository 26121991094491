import createAllNode from "./createAllNode";

const addSpaceLogic = (str) => {
  let strArr = [...str];
  let strLength = strArr.length;

  const indexes = [];

  if (strLength) {
    strArr.forEach((char, index) => {
      const first = strArr[index] ? strArr[index].toLowerCase() : undefined;
      const second = strArr[index + 1] ? strArr[index + 1].toLowerCase() : undefined;
      const third = strArr[index + 2]
        ? strArr[index + 2].toLowerCase()
        : undefined;
      const fourth = strArr[index + 3]
        ? strArr[index + 3].toLowerCase()
        : undefined;
      const five = strArr[index + 4]
        ? strArr[index + 4].toLowerCase()
        : undefined;
        
      //add Space Logics
      if (
        (first == ")" &&
          second == "a" &&
          third == "n" &&
          fourth == "d" &&
          five == " ") ||
        (first == ")" &&
          second == "n" &&
          third == "o" &&
          fourth == "t" &&
          five == " ")
      ) {
        indexes.push({
          index: index,
        });
      } else if (
        (first == '"' &&
          second == "a" &&
          third == "n" &&
          fourth == "d" &&
          five == " ") ||
        (first == '"' &&
          second == "n" &&
          third == "o" &&
          fourth == "t" &&
          five == " ")
      ) {
        indexes.push({
          index: index,
        });
      } else if (
        first == '"' &&
        second == "o" &&
        third == "r" &&
        fourth == " "
      ) {
        indexes.push({
          index: index,
        });
      } else if (
        first == ")" &&
        second == "o" &&
        third == "r" &&
        fourth == " "
      ) {
        indexes.push({
          index: index,
        });
      } else if (
        (first == ")" &&
          second == "a" &&
          third == "n" &&
          fourth == "d" &&
          five == "(") ||
        (first == ")" &&
          second == "n" &&
          third == "o" &&
          fourth == "t" &&
          five == "(") ||
        (first == ")" &&
          second == "a" &&
          third == "n" &&
          fourth == "d" &&
          five == '"') ||
        (first == ")" &&
          second == "n" &&
          third == "o" &&
          fourth == "t" &&
          five == '"')
      ) {
        indexes.push({
          index: index,
        });
      } else if (
        (first == '"' &&
          second == "a" &&
          third == "n" &&
          fourth == "d" &&
          five == "(") ||
        (first == '"' &&
          second == "n" &&
          third == "o" &&
          fourth == "t" &&
          five == "(") ||
        (first == '"' &&
          second == "a" &&
          third == "n" &&
          fourth == "d" &&
          five == '"') ||
        (first == '"' &&
          second == "n" &&
          third == "o" &&
          fourth == "t" &&
          five == '"')
      ) {
        indexes.push({
          index: index,
        });
      } else if (
        (first == ")" && second == "o" && third == "r" && fourth == "(") ||
        (first == ")" && second == "o" && third == "r" && fourth == '"')
      ) {
        indexes.push({
          index: index,
        });
      } else if (
        (first == '"' && second == "o" && third == "r" && fourth == "(") ||
        (first == '"' && second == "o" && third == "r" && fourth == '"')
      ) {
        indexes.push({
          index: index,
        });
      }
    });
  }

  return indexes;
};

export default addSpaceLogic;
