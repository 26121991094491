import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import OpenEye from "../../image-assets/Eye.svg";
import "./LoginSignup.css";
import { MainRoundedButton } from "../../Styles-Elements/Buttons";
import { Textfield, InputWrapper } from "../../Styles-Elements/Inputs";
import Header from "../../Styles-Elements/Heading/Header";
import {
  Heading2Bold,
  Subheading,
  LargeBody,
  Body,
  LabelWrapper,
  Bold,
  LargeBodyBold,
  Heading3,
  Heading3SemiBold,
} from "../../Styles-Elements/Labels";
import * as Colors from "../../Styles-Elements/Colors";
import { openLink } from "../../Helpers/Auxiliary";
import { REQUEST, SIDEBAR_STATE, STORAGE } from "../../Helpers/Enums";
import booLanding from "../../image-assets/Boo-Landing.png";
import rayImg from "../../image-assets/Ray.png";
import booImg from "../../image-assets/Boo.png";
import visibilityIcon from "../../image-assets/visibilityicon.svg";
import stars5Icon from "../../image-assets/5stars.svg";
import teamMemberImg from "../../image-assets/team1.png";
import latestLogoIcon from "../../image-assets/latest-logo.png";
import MuiAlert from "@material-ui/lab/Alert";
import validPasswordCheck from "../../GlobalModule/validPasswordCheck";
import { setSnackbar } from "../../redux/reducers/snackbar";
import { stringData } from "../../redux/reducers/localString";
import { useSelector, useDispatch } from "react-redux";
import globalRequest from "../../GlobalModule/globalRequest";
import addDeleteGetLocalStorage from "../../GlobalModule/addDeleteGetLocalStorage";
import { changeLoader } from "../../redux/reducers/loader";
import { setData } from "../../redux/reducers/getTooledupData";
import ForgetPassword from "../../dialogs/ForgetPassword";
import ResetPasswordPopUp from "../../dialogs/ResetPassword";
import { changeSidebar } from "../../redux/reducers/sidebar";
import { currentLocalValue, setChange } from "../../redux/reducers/getLocalData";
import { Boxed } from "../../Styles-Elements/Box";
const LoginSignup = (props) => {
  let strings = useSelector(stringData);
  let dispatch = useDispatch();
  const history = useHistory();
  // console.log(window.location.href)
  const [loginState, setLoginState] = useState(true);
  const [showPassword, setShowPassword] = useState("Password");
  const [showVisibilityIcon, setShowVisibilityIcon] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupType, setPopupType] = useState("");
  const [openReset, setOpenReset] = useState(false);
  const [openForgot, setOpenForgot] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const getLocalData = useSelector(currentLocalValue);

  const loginSignUp = async (type = "login") => {
    let side = addDeleteGetLocalStorage(STORAGE.NEW_SIDE_BAR, {}, "get", "single");
    let sidebar = side ? side : "dashboard";
    const body = {
      email: email,
    };

    if (type == "signUp") {
      if (validateInputs()) {
        dispatch(changeLoader(true));

        let response = await globalRequest("post", REQUEST.SIGNUP_OTP_REQUEST, body, {}, false);
        let data = response.data;
        if (data?.status == 1) {
          addDeleteGetLocalStorage(STORAGE.TEMPORARY_DATA, { email: email, password: password }, "add", "single");

          if (getLocalData?.first_login !== "no") {
            addDeleteGetLocalStorage(STORAGE.FIRST_LOGIN, sidebar, "add", "single");
            dispatch(setChange());
          }

          history.push("/otp-verification");
        } else {
          dispatch(
            setSnackbar({
              snackbarMessage: data.message,
              snackbarOpen: true,
              snackbarState: "error",
            })
          );
        }
        dispatch(changeLoader(false));
      }
    } else {
      body.password = password;
      body.type = "web";

      dispatch(changeLoader(true));
      let response = await globalRequest("post", REQUEST.LOGIN_REQUEST, body, {}, false);
      let data = response.data;
      if (data?.status == 1) {
        addDeleteGetLocalStorage(STORAGE.TOOLED_UP_LOCAL_DATA, data.data, "add", "single");
        dispatch(setData());
        dispatch(
          setSnackbar({
            snackbarMessage: data?.message,
            snackbarState: "success",
            snackbarOpen: true,
          })
        );

        addDeleteGetLocalStorage(STORAGE.NEW_SIDE_BAR, sidebar, "add", "single");
        dispatch(changeSidebar());

        let da = data.data;

        addDeleteGetLocalStorage(
          STORAGE.USER_DATA,
          { id: da.user_id, email_id: da.email, user_cancel: da.user_cancel },
          "add",
          "single"
        );
        dispatch(setChange());

        setTimeout(() => {
          history.push("/");
        }, 1500);
      } else {
        dispatch(
          setSnackbar({
            snackbarMessage: data?.message,
            snackbarState: "error",
            snackbarOpen: true,
          })
        );
      }

      dispatch(changeLoader(false));
    }
  };

  function openForgotPasswordPopup() {
    setOpenForgot(true);
  }
  function openResetPasswordPopup() {
    setOpenReset(true);
  }

  useEffect(() => {
    let url = new URL(window.location.href);
    let code = url.searchParams.get("code");
    let user_id = url.searchParams.get("user_id");
    if (code && user_id) {
      openResetPasswordPopup();
    }
  }, []);

  const rejectionEmail = async () => {
    var url = new URL(window.location.href);
    var email = url.searchParams.get("email");
    if (email) {
      let formData = new FormData();
      formData.append("email", email);

      const response = await globalRequest("post", REQUEST.REJECT_DOMAIN_REQUEST, formData, {}, false);
      let res = response.data;

      if (res.status == 1) {
        dispatch(
          setSnackbar({
            snackbarMessage: "You have successfully rejected the email ",
            snackbarOpen: true,
            snackbarState: "success",
          })
        );
      } else if (res.status == 0) {
        dispatch(
          setSnackbar({
            snackbarMessage: strings.message,
            snackbarOpen: true,
            snackbarState: "error",
          })
        );
      } else {
        dispatch(
          setSnackbar({
            snackbarMessage: res.message,
            snackbarOpen: true,
            snackbarState: "error",
          })
        );
      }
    }
  };

  useEffect(() => {
    rejectionEmail();
  }, []);

  function openSaasAgreement() {
    openLink("https://www.tooledupraccoons.com/saas-agreement/");
  }

  function openTermsOfService() {
    openLink("https://www.tooledupraccoons.com/terms-and-conditions");
  }

  function openPrivacyNotice() {
    openLink("https://www.tooledupraccoons.com/privacy-policy/");
  }

  // Snackbar functions
  function validateEmail(email) {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    // loginState ? loginUser(e) : signupUser(e);
    loginSignUp(loginState ? "login" : "signUp");
  };

  // Validates inputs, if they are false, block login/signup
  function validateInputs() {
    if (email === "" && password === "") {
      dispatch(
        setSnackbar({
          snackbarMessage: strings.allFieldsMustBeFilled,
          snackbarOpen: true,
          snackbarState: strings.error,
        })
      );
      return false;
    } else if (validateEmail(email) === false) {
      dispatch(
        setSnackbar({
          snackbarMessage: strings.enterAValidEmail,
          snackbarOpen: true,
          snackbarState: strings.error,
        })
      );
      return false;
    } else if (validPasswordCheck(password) === false) {
      dispatch(
        setSnackbar({
          snackbarMessage: strings.thePasswordMustHave,
          snackbarOpen: true,
          snackbarState: strings.error,
        })
      );
      return false;
    } else {
      return true;
    }
  }

  return (
    <div className="page-background" style={{ minHeight: "100%", backgroundColor: "#fff" }}>
      {/* <Header /> */}
      <form onSubmit={handleSubmit} className="" style={{ marginBottom: "0px" }}>
        <Boxed display={"flex"} style={{ height: "100vh" }}>
          <div className="login-signup-left">
            <div className="">
              <Boxed className="logo-box" textAlign={"center"} margin={"0 0 50px 0"}>
                <img height={114} src={latestLogoIcon} className="login-logo" alt="" />
              </Boxed>
              <Boxed className="login-head-box" margin={"0 0 40px 0"}>
                <Heading3
                  text={loginState ? strings.loginTo : strings.signUpTo}
                  color={Colors.darkPurple}
                  textAlign={"center"}
                />
                <Heading3SemiBold text={strings.tooledUpRaccoons} color={Colors.darkPurple} textAlign={"center"} />
              </Boxed>
              <Boxed>
                <div className="grid-item">
                  <Subheading text={strings.email} color={Colors.nightPurple} padding={"0 0 8px 0"} />
                  <Textfield
                    placeholder={strings.enterYourEmail}
                    value={email}
                    type={"email"}
                    required={true}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </div>
                <div className="grid-item-flex gif-dd4">
                  <Subheading text={strings.password} color={Colors.nightPurple} padding={"16px 0 8px 0"} />
                  {loginState ? (
                    <LargeBody
                      text={strings.forgotPassword}
                      color={Colors.darkPurple}
                      padding={"17px 0 8px 0"}
                      cursor={"pointer"}
                      onClick={() => openForgotPasswordPopup()}
                    />
                  ) : (
                    <div></div>
                  )}
                </div>
                <div className="grid-item">
                  <InputWrapper>
                    <Textfield
                      placeholder={strings.enterYourPassword}
                      type={showPassword}
                      value={password}
                      required={true}
                      onChange={(event) => setPassword(event.target.value)}
                      padding={"12px 52px 12px 16px"}
                    />
                    {showVisibilityIcon ? (
                      <img
                        src={visibilityIcon}
                        className="closeeyeicon"
                        onClick={() => {
                          setShowVisibilityIcon(!showVisibilityIcon);
                          setShowPassword(showPassword == "Password" ? "text" : "Password");
                        }}
                        alt=""
                      />
                    ) : (
                      <img
                        src={OpenEye}
                        className="openeyeicon"
                        onClick={() => {
                          setShowVisibilityIcon(!showVisibilityIcon);
                          setShowPassword(showPassword == "Password" ? "text" : "Password");
                        }}
                        alt=""
                      />
                    )}
                  </InputWrapper>
                </div>
                <div className="grid-item">
                  <MainRoundedButton
                    text={loginState ? strings.logIn : strings.startdayfreetrial}
                    margin={"24px 0 24px 0"}
                    type={"submit"}
                  />
                </div>
                <div className="grid-item">
                  <LabelWrapper justifyContent={"center"}>
                    <LargeBody
                      text={loginState ? strings.needAnAccount : strings.haveAnAccount}
                      color={Colors.darkGray}
                      textAlign={"center"}
                      padding={"0 2px 0 0"}
                    />
                    <LargeBodyBold
                      text={loginState ? strings.signUp : strings.logIn}
                      userSelect={"none"}
                      color={Colors.darkPurple}
                      textAlign={"center"}
                      padding={"0 0 0 2px"}
                      cursor={"pointer"}
                      onClick={() => setLoginState(!loginState)}
                    />
                  </LabelWrapper>
                </div>
                <br />

                <div className="grid-item">
                  <LabelWrapper justifyContent={"center"}>
                    <Body
                      text={loginState ? strings.byLoggingInYouAgreeToThe : strings.bySigningUpYouAgreeToThe}
                      color={Colors.darkGray}
                      padding={"0 2px 0 0"}
                    />
                    <Bold
                      text={strings.saasAgreement + ","}
                      color={Colors.darkGray}
                      padding={"0 2px"}
                      cursor={"pointer"}
                      onClick={() => openSaasAgreement()}
                    />
                    <Bold
                      text={strings.termsOfService}
                      color={Colors.darkGray}
                      padding={"0 2px"}
                      cursor={"pointer"}
                      onClick={() => openTermsOfService()}
                    />
                    <Body text={strings.and} color={Colors.darkGray} padding={"0 2px"} />
                    <Bold
                      text={strings.privacyNotice}
                      color={Colors.darkGray}
                      padding={"0 2px"}
                      cursor={"pointer"}
                      onClick={() => openPrivacyNotice()}
                    />
                  </LabelWrapper>
                </div>
              </Boxed>
            </div>
          </div>
          <div className="login-signup-right">
            <img
              src={stars5Icon}
              alt=""
              className="width116"
              style={{ margin: "auto", marginTop: "0px", marginBottom: "50px" }}
            />
            <Boxed className="first-box">
              <Subheading
                text={strings.canNotImagineMyDirectSourcingLifeWithoutIt}
                color={Colors.mainLight}
                textAlign={"center"}
                fontStyle={"italic"}
                padding={"0 0px 50px 0px"}
              />
              <div style={{ textAlign: "center", marginBottom: "50px" }}>
                <img src={teamMemberImg} alt="" className="team-user-icon" style={{ margin: "auto" }} />
              </div>
              <LargeBody
                text={"Nathan Zimmerman"}
                color={Colors.mainLight}
                fontWeight={"600"}
                textAlign={"center"}
                padding={"0 0px 4px 0px"}
                className=""
              />
              <Body
                text={"Senior Partner, High Country Search"}
                color={Colors.mainLight}
                textAlign={"center"}
                padding={"0 0px 0px 0px"}
                className=""
              />
            </Boxed>
          </div>
        </Boxed>
      </form>

      {openForgot ? <ForgetPassword closePopup={() => setOpenForgot(false)} /> : null}

      {openReset ? <ResetPasswordPopUp closePopup={() => setOpenReset(false)} /> : null}
    </div>
  );
};

export default LoginSignup;
