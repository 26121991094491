import { Checkbox, Grid } from "@material-ui/core";
import "../advanceEditor.css";
import * as Colors from "../../Styles-Elements/Colors";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { currentString } from "../../redux/reducers/string";
import logicToSeperateString from "./logicToSeperateString";
import getAllWordWithOperator from "./getAllWordWithOperator";
import convertIntoHtml from "../../editor/convertIntoHtml";
import strings from "../../localisation_en.json";
import { Boxed } from "../../Styles-Elements/Box";
import { LargeSmall } from "../../Styles-Elements/Labels";
import removeAllSpaces from "../../editor/removeAllSpaces";

const AdvanceViewCopyEditor = ({ selectedData, onlyView = false, str = "" }) => {
  const selector = useSelector(currentString);
  const string = str == "" ? selector : str;
  const [selectedWord, setSelectedWord] = useState([]);
  const [wordArray, setWordArray] = useState([]);
  const [wordArrayWithoutOperator, setWordArrayWithoutOperator] = useState([]);

  useEffect(() => {
    if (string) {
      let words = logicToSeperateString(string);
      let word = getAllWordWithOperator(words);
      let word1 = [];
      word.forEach((obj, ind) => {
        word1.push({
          ind: ind,
          string: removeAllSpaces(obj.string),
        });
      });
      setWordArray(word1);
      setSelectedWord(word1);
      let word2 = [];
      word.forEach((obj, ind) => {
        let str = String(obj.string);
        let threeStr = String(str.substring(0, 4)).toLowerCase();
        let wo = str.substring(4, str.length);
        if (threeStr === "not ") {
          wo = str;
        }
        word2.push({
          string: removeAllSpaces(wo),
          ind: ind,
        });
      });
      setWordArrayWithoutOperator(word2);
    }
  }, [string]);

  useEffect(() => {
    if (selectedWord.length) {
      selectedData(selectedWord);
    } else {
      selectedData([
        {
          ind: 0,
          string: " ",
        },
      ]);
    }
  }, [selectedWord]);

  const ListComponent = ({ string, ind }) => {
    let strs = string;
    let checkPrevious = null;

    for (let i = 0; i < ind; i++) {
      checkPrevious = selectedWord.find((a) => a.ind == i);
      if (checkPrevious !== undefined) {
        break;
      }
    }

    let isStrike = selectedWord.find((a) => a.ind == ind);

    if (checkPrevious == undefined) {
      let f = wordArrayWithoutOperator.find((a) => a.ind == ind);
      if (ind !== 0) {
        if (isStrike) {
          if (selectedWord.length !== wordArrayWithoutOperator.length) {
            strs = f.string;
          }
        }
      }
    }

    return (
      <>
        <Grid container className="textInputKeyword" style={{ padding: "0px !important" }}>
          {/* Left column (95%) */}
          <Grid item xs={onlyView ? 12 : 11}>
            {isStrike !== undefined ? (
              <div dangerouslySetInnerHTML={{ __html: convertIntoHtml(strs) }}></div>
            ) : (
              <div dangerouslySetInnerHTML={{ __html: strs }} className={"line-through"}></div>
            )}
          </Grid>
          {/* Right column (5%) */}
          {onlyView ? null : (
            <Grid item xs={1} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Checkbox
                checked={isStrike !== undefined ? true : false}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedWord((word) => [
                      ...word,
                      {
                        string: string,
                        ind: ind,
                      },
                    ]);
                  } else {
                    let filter = selectedWord.filter((a) => a.ind !== ind);
                    setSelectedWord(filter);
                  }
                }}
                style={{ marginLeft: "25px" }}
              />
            </Grid>
          )}
        </Grid>
      </>
    );
  };

  return (
    <>
      {onlyView ? null : (
        <div
          className="sel-selall"
          style={{
            background: "white",
            height: "25px",
          }}
        >
          <Boxed
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            style={{
              position: "absolute",
              right: "8px",
              top: "8px",
              zIndex: 10,
            }}
          >
            <LargeSmall
              text={strings.selectAll}
              onClick={() => {
                setSelectedWord(wordArray);
              }}
              color={Colors.sharpGreen}
              className={`darkpurple-color-all`}
            />
            <LargeSmall text={"/"} color={Colors.sharpGreen} padding={"0px 8px"} />
            <LargeSmall
              text={strings.unselectAll}
              onClick={() => {
                setSelectedWord([]);
              }}
              color={Colors.sharpGreen}
              className={`darkpurple-color-all`}
            />
          </Boxed>
        </div>
      )}

      <div className="scroll-view" style={{ marginTop: onlyView ? null : "10px" }}>
        {wordArray.map((obj, ind) => {
          return <ListComponent key={ind} string={obj.string} ind={obj?.ind} />;
        })}
      </div>
    </>
  );
};

export default AdvanceViewCopyEditor;
