import { Box, Checkbox, ClickAwayListener } from "@material-ui/core";
import copy from "copy-to-clipboard";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import addAutoQuotesAndPara from "../../editor/addAutoQuotesAndPara";
import addSpaceLogic from "../../editor/addSpaceLogic";
import AllCharacterAndSymbols from "../../editor/AllCharacterAndSymbols";
import allError from "../../editor/allError";
import colorTheString from "../../editor/colorTheString";
import createSpan from "../../editor/createSpan";
import cursorPosition from "../../editor/cursorPosition";
import findSelectionIndexes from "../../editor/findSelectionIndex";
import hasWhiteSpace from "../../editor/hasWhiteSpace";
import isEvenDoubleQuotes from "../../editor/isEvenDoubleQuotes";
import removeAllSpaces from "../../editor/removeAllSpaces";
import removeColor from "../../editor/removeColor";
import replaceNewLine from "../../editor/replaceNewLine";
import setCursorPosition from "../../editor/setCursorPosition";
import setCursorPositionParent from "../../editor/setCursorPositionParent";
import CopySection from "../../GlobalModule/CopySection";
import globalRequest from "../../GlobalModule/globalRequest";
import randomNumber from "../../GlobalModule/randomNumber";
import removeSingleQuotation from "../../GlobalModule/removeSingleQuotation";
import replaceComma from "../../GlobalModule/replaceComma";
import updateLastInteraction from "../../GlobalModule/updateLastInteraction";
import { ADVANCE_KEYWORD_ID, KEYWORD_STRING, MAIN_TEXT } from "../../Helpers/Enums";
import userTypingImg from "../../image-assets/boo-happy.png";
import booNegative from "../../image-assets/boo-negative.png";
import comboBlackIcon from "../../image-assets/combo-black.svg";
import copyIcon from "../../image-assets/copyicon.svg";
import eyeIcon from "../../image-assets/Eye.svg";
import booOn from "../../image-assets/on.png";
import playerPlayCircleIcon from "../../image-assets/player-play-circle.svg";
import restoreIcon from "../../image-assets/restore.svg";
import strings from "../../localisation_en.json";
import { getLastActiveString, postLastActiveString } from "../../pages/keyword-strings/postGetLastActiveString";
import { tooledUpLocalData } from "../../redux/reducers/getTooledupData";
import { setSnackbar } from "../../redux/reducers/snackbar";
import socket from "../../socket";
import { Boxed } from "../../Styles-Elements/Box";
import * as Colors from "../../Styles-Elements/Colors";
import { Body, LargeSmall } from "../../Styles-Elements/Labels";
import AdvanceViewCopyEditor from "./AdvanceViewCopyEditor";
import getAllWordWithOperator from "./getAllWordWithOperator";
import logicToSeperateString from "./logicToSeperateString";

import "./../advanceEditor.css";
import { currentTrial } from "../../redux/reducers/getTrial";
import TypingView from "../../GlobalModule/TypingView";

let saveTimer = null;
let colorTimer = null;
let backspaceTimer = null;
const randomId = parseInt(Math.random() * 9999 + 100);

const AdvanceKeyword = ({
  currentActiveString,
  isLast = false,
  typingStatus,
  wsResponse,
  revisionCallback,
  wordArray,
  currentStringCallback,
  reloadWordArray,
  isDeletedString,
  errorBool,
  booTheBoolean,
  saveHit,
  isShareWithMe,
}) => {
  const id = ADVANCE_KEYWORD_ID;

  const dispatch = useDispatch();
  const auth = useSelector(tooledUpLocalData);
  const trialData = useSelector(currentTrial);
  const [socketData, setSocketData] = useState({});
  const [collaborationStatus, setCollaborationStatus] = useState(false);
  const [permission, setPermission] = useState(0);
  const [isContentEnable, setIsContentEnable] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [backspaceRepeat, setBackspaceRepeat] = useState(false);
  const [textCount, setTextCount] = useState(0);
  const [combos, setCombos] = useState(false);
  const [includeQuotation, setIncludeQuotation] = useState(true);
  const [selectedWord, setSelectedWord] = useState([]);
  const [typing, setTyping] = useState("");

  const getId = (ids = id) => {
    return document.getElementById(ids);
  };

  let isErrorCallback = (bool) => {
    errorBool(bool);
  };

  const errMsgCallback = (msg) => {
    if (msg !== "") {
      setErrorMsg(msg);
    }
  };

  const rearrangeHtml = () => {
    let id = getDivFromList().id;
    let pos = cursorPosition();
    let parent = getId();

    parent.childNodes.forEach((nodes) => {
      try {
        let text = nodes.textContent;
        let ids = nodes.id;
        let remove = removeAllSpaces(text).replace(/and"/g, `AND "`);
        nodes.innerHTML = createSpan(removeAllSpaces(remove));
        try {
          colorTheString(remove, getId(ids));
          allError(remove, getId(ids), true, isErrorCallback, errMsgCallback);
        } catch (e) {}

        if (id == ids) {
          setCursorPosition(pos, id);
        }
      } catch (e) {}
    });

    setTextCount(String(getId().innerText).trim().length);
  };

  useEffect(() => {
    rearrangeHtml();
  }, [saveHit]);

  const getHtml = (array) => {
    if (array.length) {
      let div = "";
      array.forEach((word, index) => {
        if (word.string !== "undefined" && word.string && word.string !== "null") {
          let html = createSpan(removeAllSpaces(word.string));
          let d = `<div class="textInputKeyword" id="${MAIN_TEXT}-${
            index == 0 ? "firstid" : randomNumber()
          }">${html}</div>`;
          div += d;
        }
      });
      getId().innerHTML = "";
      getId().innerHTML = div;
      setTimeout(() => {
        applyFeature("single");
      }, 200);
    }
  };

  useEffect(() => {
    if (wordArray.length) {
      getHtml(wordArray);
    } else {
      getId(id).innerHTML = "";
    }
  }, [wordArray]);

  const callingGetToggle = async (createdBy, stringId) => {
    let params = {
      user_id: createdBy,
      string_id: stringId,
    };

    let res = await globalRequest(
      "get",
      KEYWORD_STRING.STRING_TOGGLE_GET,
      {},
      {
        params: params,
      },
      true
    );

    if (res?.data?.status == 1) {
      let data = res?.data?.data;
      setCollaborationStatus(data.collaborate_toggle == 1 ? true : false);
    }
  };

  useEffect(() => {
    let obj = Object.keys(currentActiveString).length !== 0;
    if (obj) {
      setErrorMsg("");

      if (currentActiveString?.type == "restore" && currentActiveString?.index != 0) {
        setIsContentEnable(false);
      } else {
        setIsContentEnable(true);
      }
      setTextCount(
        String(currentActiveString?.string_boolean ? currentActiveString?.string_boolean : "").trim().length
      );
      callingGetToggle(auth?.user_id, currentActiveString?.id);
      setPermission(currentActiveString?.string_detail?.permission);
    }
  }, [currentActiveString]);

  useEffect(() => {
    if (auth?.access == "user") {
      if (currentActiveString?.type == "restore" && currentActiveString?.index != 0) {
        setIsContentEnable(false);
      } else {
        if (auth.user_id == currentActiveString?.string_detail?.created_by) {
          setIsContentEnable(true);
        } else {
          setIsContentEnable(collaborationStatus);
        }
      }
    } else if (auth?.access == "admin" || auth?.access == "owner") {
      if (currentActiveString?.type == "restore" && currentActiveString?.index != 0) {
        setIsContentEnable(false);
      } else {
        setIsContentEnable(true);
      }
    }
  }, [collaborationStatus]);

  const updateStringInStorage = async (textInput) => {
    let i = await getLastActiveString(auth?.user_id, "keyword");
    let isEmpty = Object.keys(i).length == 0;
    if (!isEmpty) {
      try {
        let obj = {
          ...i,
          string_boolean: textInput,
        };
        await postLastActiveString(auth?.user_id, currentActiveString?.id, "keyword", obj);
      } catch (e) {
        console.log(" e ", e);
      }
    }
  };

  const saved = (status) => {
    typingStatus(status);
    setTyping(status);

    let data = {
      string_id: currentActiveString?.id,
      user_id: currentActiveString?.string_detail?.created_by,
      typing_user_id: auth?.user_id,
      status: status,
      name: auth?.firstname + " " + auth?.lastname,
      browser_type: "web",
      string_type: "keyword",
      random_id: randomId,
    };

    if (status == "release") {
      try {
        let text = String(getId().innerText);
        let string = replaceNewLine(text);
        data.string_boolean = string;
        updateStringInStorage(string);
        socket.emit("poststring", data);
        setTimeout(() => {
          socket.emit("auto-enrich", {
            stringData: [{ string_id: currentActiveString?.id }],
            userId: auth?.user_id,
            type: "single",
          });
        }, 1000);
        wsResponse(true);
      } catch (e) {
        // console.log("e ", e);
      }
    } else if (status == "typing") {
      socket.emit("poststring", data);
    }
  };

  const getString = (str) => {
    let words = logicToSeperateString(str);
    let word = getAllWordWithOperator(words);
    getHtml(word);
  };

  useEffect(() => {
    let data = socketData;

    if (data?.string_type == "keyword") {
      if (
        data.browser_type == "chrome" &&
        data.typing_user_id1 == auth?.user_id &&
        data.string_id == currentActiveString?.id &&
        data.status == ""
      ) {
        htmlRender(data);
      }

      if (
        data.browser_type == "web" &&
        data.status == "" &&
        data.string_id == currentActiveString?.id &&
        data.typing_user_id1 == auth?.user_id &&
        data.random_id !== randomId
      ) {
        htmlRender(data);
      }

      if (data.status == "" && data.string_id == currentActiveString?.id && data.typing_user_id1 != auth?.user_id) {
        htmlRender(data);
      }
    }
  }, [socketData]);

  const htmlRender = (data) => {
    try {
      let str = replaceComma(data.string_boolean);
      setTextCount(String(str).trim().length);
      currentStringCallback();
      getString(str);
    } catch (e) {}
  };

  useEffect(() => {
    socket.on("poststring", (data) => {
      setSocketData(data);
    });
  }, []);

  const copyTextToClip = () => {
    var text = String(getId().innerText);
    let text1 = replaceNewLine(text);
    if (!combos) {
      rearrangeHtml();
    }
    updateLastInteraction(auth?.user_id);

    let finalText = "";

    if (selectedWord.length) {
      let st = "";
      selectedWord.forEach((obj, ind) => {
        if (ind == 0) {
          let str = String(obj?.string).substring(0, 3);
          if (str == "AND" || str == "NOT") {
            let s = String(obj?.string);
            st += `${s.substring(4, s.length)} `;
          } else {
            st += `${obj?.string} `;
          }
        } else {
          st += `${obj?.string} `;
        }
      });
      finalText = st;
    } else {
      finalText = text1;
    }

    if (!includeQuotation) {
      let te = removeSingleQuotation(finalText);
      finalText = te;
    }

    copy(finalText);

    dispatch(
      setSnackbar({
        snackbarOpen: true,
        snackbarMessage: "String successfully copied",
        snackbarState: "success",
      })
    );
  };

  const copySelected = () => {
    try {
      let text = window.getSelection();
      let f = text.getRangeAt(0).toString();
      let removeSpace = removeAllSpaces(f);
      updateLastInteraction(auth?.user_id);

      if (text == "") {
        copyTextToClip();
      } else {
        copy(removeSpace);
        window.getSelection().removeAllRanges();
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: "String successfully copied",
            snackbarState: "success",
          })
        );
      }
    } catch (e) {}
  };

  const cutSelected = () => {
    let text = window.getSelection().getRangeAt(0).toString();
    copy(text);
    removeSelected();
    dispatch(
      setSnackbar({
        snackbarOpen: true,
        snackbarMessage: "String successfully copied",
        snackbarState: "success",
      })
    );
  };

  const selectTextToClip = () => {};

  const removeColorFromStrings = () => {
    setErrorMsg("");
    errorBool(false);
    window.$(`#${id} span`).removeClass("error underline missingQuotes right-error");
  };

  const checkEndOpearator = (parent, id) => {
    let text = String(parent.innerText).toLowerCase();
    let andNot = text.slice(-3).trim();
    if (andNot === "and" || andNot === "not") {
      callSeparator();
    }
  };

  const callSeparator = () => {
    let parent = getId();
    let cursorId = getDivFromList().id;
    let arr = parent.children;
    let length = arr.length;
    if (length) {
      for (let i = 0; i < length; i++) {
        try {
          const id = arr[i].id;
          let str = arr[i].textContent;
          if (id == cursorId) {
            operatorSeperator(str, i, id);
            break;
          }
        } catch (e) {}
      }
    }
  };

  useEffect(() => {
    if (!errorMsg) {
      callSeparator();
    }
  }, [errorMsg]);

  const applyFeature = (type = "multiple") => {
    try {
      removeColorFromStrings();
      let parent = getId();
      let cursorId = getDivFromList().id;
      if (type == "single") {
        parent.childNodes.forEach((no) => {
          try {
            const id = no.id;
            let str = no.textContent;

            checkEndOpearator(getId(id), id);
            colorTheString(str, getId(id));
            allError(str, getId(id), true, isErrorCallback, errMsgCallback);
          } catch (e) {}
        });
      } else {
        let arr = parent.children;
        let length = arr.length;
        if (length) {
          parent.childNodes.forEach((nodes) => {
            try {
              const id = nodes.id;
              let str = nodes.textContent;

              if (id == cursorId) {
                checkEndOpearator(getId(id), id);
              }

              allError(str, getId(id), true, isErrorCallback, errMsgCallback);
            } catch (e) {}
          });
        }
      }
    } catch (e) {}
  };

  const createDiv = (divPosition, operator = "") => {
    let pos = cursorPosition();
    let parent = getId();
    let ids = `${MAIN_TEXT}-${randomNumber()}`;

    let div = document.createElement("div");
    div.className = "textInputKeyword";
    div.id = ids;

    if (operator != "") {
      if (operator == "and") {
        div.innerHTML = `<span>A</span><span >N</span><span >D</span>`;
      } else if (operator == "not") {
        div.innerHTML = `<span >N</span><span >O</span><span >T</span>`;
      } else {
        div.innerHTML = `<span >&nbsp;</span>`;
      }
    } else {
      div.innerHTML = `<span >&nbsp;</span>`;
    }

    if (parent.children[divPosition - 1]) {
      parent.children[divPosition - 1].insertAdjacentElement("afterEnd", div);
    } else {
      parent.children[divPosition].insertAdjacentElement("beforebegin", div);
    }

    if (operator != "") {
      div.focus();
      setCursorPositionParent(3, div);
    } else {
      div.focus();
      setCursorPositionParent(0, div);
    }
  };

  const createNode = (ch) => {
    let pos = cursorPosition();
    let position = pos + 1;
    let parent = getId();

    if (parent.hasChildNodes()) {
      const div = getDivFromList();

      let span = document.createElement("span");

      if (ch === " ") {
        span.innerHTML = "&nbsp;";
      } else {
        span.innerText = ch;
      }
      if (div.children[pos - 1]) {
        div.children[pos - 1].insertAdjacentElement("afterEnd", span);
      } else {
        div.children[pos].insertAdjacentElement("beforebegin", span);
      }
      setCursorPositionParent(position, div);
    } else {
      let div = document.createElement("div");

      div.className = "textInputKeyword";
      div.id = `${MAIN_TEXT}-firstid`;
      let span = document.createElement("span");

      if (ch === " ") {
        span.innerHTML = "&nbsp;";
      } else {
        span.innerText = ch;
      }
      div.appendChild(span);
      parent.appendChild(div);
      setCursorPositionParent(position, parent);
    }
  };

  const removeSelected = () => {
    try {
      let selection = window.getSelection();
      if (selection && selection.toString() != "") {
        let range = selection.getRangeAt(0).toString();
        let word = range;

        let pos = cursorPosition();
        let id = getDivFromList().id;
        let parent = getId(id);
        let text = String(parent.innerText);

        if (text.length == word.length) {
          try {
            getId().removeChild(parent);
          } catch (e) {}
        } else {
          let find = findSelectionIndexes(text, pos, word);
          let s = find.startIndex;
          let e = find.endIndex;

          let index = e;
          let f = () => {
            index--;
            if (index == s) {
              try {
                getId(id).children[s].remove();
              } catch (e) {}
            } else {
              try {
                getId(id).children[index].remove();
              } catch (e) {}
              f();
            }
          };

          f();
          setCursorPositionParent(s, parent);
        }
      }
    } catch (e) {}
  };

  const removeElement = () => {
    try {
      let list = getDivFromList();
      let parent = getId(list.id);
      let childrens = parent.children;
      let pos = cursorPosition();
      let str = String(parent.innerText);

      if (pos <= 1 && str.length <= 1) {
        getId().removeChild(parent);
      } else {
        if (childrens.length > 0 && pos != 0) {
          let elem = childrens[pos - 1];
          parent.removeChild(elem);
        }
      }
    } catch (e) {
      // console.log("e ", e);
    }
  };

  const getDivFromList = () => {
    try {
      let selection = window.getSelection().getRangeAt(0);
      let div = selection.commonAncestorContainer.parentElement.parentNode;
      return div;
    } catch (e) {
      return {};
    }
  };

  useEffect(() => {
    if (backspaceRepeat) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: strings.backspaceHoldError,
          snackbarState: "error",
        })
      );
    }
    clearTimeout(backspaceTimer);
    backspaceTimer = setTimeout(() => {
      setBackspaceRepeat(false);
    }, 4000);
  }, [backspaceRepeat]);

  const detectKey = (e) => {
    let cKey = 67;
    let arr = AllCharacterAndSymbols;
    let stopQuotes = false;
    let isCreateNodeRunning = false;
    let isArrow = false;

    setTextCount(String(getId().innerText).trim().length);

    e.preventDefault();
    let selection = document.getSelection();

    if ((e.ctrlKey || e.metaKey) && e.keyCode === 86) {
      e.preventDefault();
      return false;
    }

    if ((e.keyCode >= 112 && e.keyCode <= 123) || e.key === "Meta" || e.key === "ContextMenu") {
      e.preventDefault();
      return false;
    }

    if ((e.ctrlKey || e.metaKey) && e.keyCode === cKey) {
      e.preventDefault();
      copySelected();
      return true;
    }

    if ((e.ctrlKey || e.metaKey) && e.keyCode === 88) {
      e.preventDefault();
      cutSelected();
      return true;
    }

    if ((e.ctrlKey || e.metaKey) && e.keyCode === 65) {
      selectTextToClip();
      e.preventDefault();
      return false;
    }

    if ((e.keyCode >= 112 && e.keyCode <= 123) || e.key === "Meta" || e.key === "ContextMenu") {
      e.preventDefault();
      return false;
    }

    if ((e.keyCode >= 65 && e.keyCode <= 95) || (e.keyCode >= 108 && e.keyCode <= 126)) {
      createNode(e.key);
      isCreateNodeRunning = true;
    } else if (e.key == "Backspace") {
      let id = getDivFromList().id;
      if (e.repeat) {
        setBackspaceRepeat(true);
        return;
      } else {
        let text = window.getSelection().getRangeAt(0).toString();
        if (text != "") {
          removeSelected();
        } else {
          removeElement();
          try {
            removeColor(String(getId(id).innerText), getId(id));
          } catch (e) {}
        }
      }
    } else if (e.key == "Delete") {
      if (e.repeat) {
        return false;
      } else {
        let text = window.getSelection().getRangeAt(0).toString();

        if (text != "") {
          removeSelected();
        } else {
          removeByDelete();
          removeColor(String(getId().innerText), getId());
        }
      }
    } else if (e.code == "ArrowLeft") {
      selection.modify("move", "left", "character", "granularity");
      stopQuotes = true;
      isArrow = true;
    } else if (e.code == "ArrowRight") {
      selection.modify("move", "right", "character", "granularity");
      stopQuotes = true;
      isArrow = true;
    } else if (e.code == "ArrowUp") {
      selection.modify("move", "left", "line", "granularity");
      getId().scrollBy(0, -25);
      stopQuotes = true;
      isArrow = true;
    } else if (e.code == "ArrowDown") {
      selection.modify("move", "right", "line", "granularity");
      getId().scrollBy(0, 25);
      stopQuotes = true;
      isArrow = true;
    } else {
    }

    if (e.key) {
      if ((e.ctrlKey || e.metaKey) && e.keyCode === 65) {
      } else if (e.ctrlKey) {
      } else {
        try {
          let text = window.getSelection().getRangeAt(0).toString();
          if (text) {
            removeSelected();
          }
        } catch (e) {}
      }
    }

    if (e.key == "Enter") {
      let id = getDivFromList().id;

      let parent = getId();
      if (parent.children.length) {
        parent.childNodes.forEach((nodes, index) => {
          if (nodes.id == id) {
            createDiv(index + 1);
          }
        });
      }
    }

    if (/\s/.test(e.key)) {
      try {
        let id = getDivFromList().id;
        clearTimeout(colorTimer);
        colorTimer = setTimeout(() => {
          try {
            let str = String(getId(id).innerText);
            colorTheString(str, getId(id));
          } catch (e) {}
        }, 100);
      } catch (e) {}
    }

    arr.forEach((char) => {
      if (e.key == char) {
        createNode(e.key);
        isCreateNodeRunning = true;
      }
    });

    if (!e.ctrlKey) {
      if (!stopQuotes) {
        let pos = cursorPosition();
        applyAutoQuotesAndSpace(pos, isCreateNodeRunning);
      }
    }

    if (!isArrow) {
      applyFeature();
    }

    currentStringCallback();
  };

  const operatorSeperator = (str, divIndex, id) => {
    try {
      let strArr = [...str.toLowerCase()];
      let length = str.length;
      let counter = 0;
      let operator = "";
      let leftPara = 0;
      let rightPara = 0;
      if (length) {
        let i = 0;
        let func = (is) => {
          setTimeout(() => {
            let char = strArr[is];
            let ind = is;

            if (char == ")") {
              leftPara += 1;
            }
            if (char == "(") {
              rightPara += 1;
            }
            if (leftPara == rightPara) {
              if (isEvenDoubleQuotes(ind, str)) {
                let a = strArr[ind] ? strArr[ind] : undefined;
                let b = strArr[ind + 1] ? strArr[ind + 1] : undefined;
                let c = strArr[ind + 2] ? strArr[ind + 2] : undefined;
                const conditionAnd = a == "a" && b == "n" && c == "d";
                const conditionNot = a == "n" && b == "o" && c == "t";
                if (conditionAnd || conditionNot) {
                  counter += 1;
                } else {
                  func(i);
                  i++;
                }
                if (conditionAnd) {
                  operator = "and";
                }
                if (conditionNot) {
                  operator = "not";
                }
              }
            }
          }, 0);
        };
        func(i);

        strArr.forEach((char, ind) => {
          if (char == ")") {
            leftPara += 1;
          }
          if (char == "(") {
            rightPara += 1;
          }
          if (leftPara == rightPara) {
            if (isEvenDoubleQuotes(ind, str)) {
              let a = strArr[ind] ? strArr[ind] : undefined;
              let b = strArr[ind + 1] ? strArr[ind + 1] : undefined;
              let c = strArr[ind + 2] ? strArr[ind + 2] : undefined;
              const conditionAnd = a == "a" && b == "n" && c == "d";
              const conditionNot = a == "n" && b == "o" && c == "t";
              if (conditionAnd || conditionNot) {
                counter += 1;
              }
              if (conditionAnd) {
                operator = "and";
              }
              if (conditionNot) {
                operator = "not";
              }
            }
          }
        });
        let count = 1;
        if (divIndex !== 0) {
          count = 2;
        }
        if (counter >= count) {
          let s = String(str);
          let strArr = [...s];

          let last = strArr[str.length - 1] ? strArr[str.length - 1].toLowerCase() : undefined;
          let lastOne = strArr[str.length - 2] ? strArr[str.length - 2].toLowerCase() : undefined;
          let lastTwo = strArr[str.length - 3] ? strArr[str.length - 3].toLowerCase() : undefined;

          let conditionAnd = lastTwo == "a" && lastOne == "n" && last == "d";
          let conditionNot = lastTwo == "n" && lastOne == "o" && last == "t";

          if (conditionAnd || conditionNot) {
            let end = str.length;
            let start = str.length - 3;
            let index = end;
            let f = () => {
              index--;
              if (index == start) {
                try {
                  getId(id).children[start].remove();
                } catch (e) {}
              } else {
                try {
                  getId(id).children[index].remove();
                } catch (e) {}
                f();
              }
            };
            f();
            createDiv(divIndex + 1, operator);
            applyFeature("single");
          } else {
            if (counter === count) {
              try {
                let str = String(getId().innerText);
                reloadWordArray(str);
              } catch (e) {}
            }
          }
        }
      }
    } catch (e) {}
  };

  const addNodesInBetween = (index, ch, first = false) => {
    try {
      let id = getDivFromList().id;
      let parent = getId(id);
      const node = document.createElement("span");

      if (ch === " ") {
        node.innerHTML = "&nbsp;";
      } else {
        node.innerText = ch;
      }

      if (first) {
        parent.children[index].insertAdjacentElement("beforebegin", node);
      } else {
        parent.children[index].insertAdjacentElement("afterEnd", node);
      }
    } catch (e) {
      // console.log("exception ---- ", e);
    }
  };

  const applyAutoQuotesAndSpace = (pos, isCreateNodeRunning = false) => {
    try {
      let id = getDivFromList().id;
      let str = String(getId(id).innerText);
      let strArr = [...str];

      let isPos = false;
      let inde = [];

      if (strArr.length) {
        if (isCreateNodeRunning) {
          if (booTheBoolean) {
            let indexs = addAutoQuotesAndPara(str);
            let indexes = addSpaceLogic(str);

            if (indexs.length) {
              indexs.forEach((objs) => {
                let ind = objs.index;
                let fors = objs.fors;
                let bool = objs.bool;
                if (fors == "quotes") {
                  let a = strArr[ind] ? strArr[ind].toLowerCase() : undefined;
                  let b = strArr[ind + 1] ? strArr[ind + 1].toLowerCase() : undefined;
                  let c = strArr[ind + 2] ? strArr[ind + 2].toLowerCase() : undefined;

                  if (ind === 0) {
                    if ((a == "a" && b == "n" && c == "d") || (a == "n" && b == "o" && c == "t")) {
                      addNodesInBetween(ind + 4, '"', bool);
                    } else {
                      addNodesInBetween(ind, '"', bool);
                    }
                  } else {
                    addNodesInBetween(ind, '"', bool);
                  }
                  isPos = true;
                  inde.push(ind);
                } else if (fors == "space") {
                  // addNodesInBetween(ind, " ", bool);
                }
              });
            } else if (indexes.length) {
              // indexes.forEach((ind) => {
              //   let index = ind.index;
              //   addNodesInBetween(index, " ", false);
              // });
            }
          }
        }

        let first = inde[0] ? inde[0] : undefined;
        let second = inde[1] ? inde[1] : undefined;

        if (isPos) {
          applyFeature();
        }
        if (first + 1 == second) {
          if (isPos) {
            let positions = pos;
            setCursorPosition(positions + 1, id);
          }
        }
      }
    } catch (e) {}
  };

  const removeByDelete = () => {
    try {
      let id = getDivFromList().id;
      let parent = getId(id);
      let strLength = String(parent.innerText).length;
      let childrens = parent.children;
      let pos = cursorPosition();
      let str = String(parent.innerText);

      if (pos <= 1 && str.length <= 1) {
        getId().removeChild(parent);
      } else {
        if (childrens.length > 0 && strLength !== pos) {
          let elem = childrens[pos];
          parent.removeChild(elem);
        }
      }
    } catch (e) {}
  };

  const permissions = () => {
    let r =
      (auth?.access == "owner" && !isShareWithMe) ||
      (auth?.access == "admin" && !isShareWithMe) ||
      (auth?.access == "user" && permission == 0 && !isShareWithMe) ||
      (auth?.access == "owner" && isShareWithMe) ||
      (auth?.access == "admin" && isShareWithMe) ||
      (auth?.access == "user" && isShareWithMe)
        ? socketData?.status == ""
          ? null
          : socketData?.status == "typing" &&
            auth?.user_id != socketData.typing_user_id &&
            socketData?.typing_user_id != 0 &&
            socketData?.string_id == currentActiveString?.id
          ? true
          : false
        : true;

    return r;
  };

  const revisionHistory = async () => {
    let res = await globalRequest(
      "post",
      KEYWORD_STRING.REVISION_HISTORY,
      {
        user_id: auth?.user_id,
        revision_id: currentActiveString?.revision_id,
      },
      {},
      true
    );

    if (res.data.status == 1) {
      let datas = { ...currentActiveString };
      setIsContentEnable(true);
      revisionCallback(datas);
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: res.data.message,
          snackbarState: "success",
        })
      );
    } else {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: res.data.message,
          snackbarState: "error",
        })
      );
    }
  };

  const [isActive, setIsActive] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
    setIsActive(!isActive);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const styles = {
    position: "absolute",
    width: 220,
    top: "40px",
    right: "0%",
    transform: "translate(0%, 0%)",
    zIndex: 50,
    borderRadius: "6px",
    p: "14px 16px",
    bgcolor: "#FEFEFE",
    boxShadow:
      "0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 5px 6px -2px rgba(0, 0, 0, 0.05), 0px 0px 15px -3px rgba(0, 0, 0, 0.10)",
  };

  const putQuotesOnLast = () => {
    try {
      let domId = getDivFromList().id;
      let str = String(`${getId(domId).innerText}`);
      let split = str.split(/AND|NOT|OR/).pop();
      let lastIndex = str.lastIndexOf(split);
      let globalLength = str.length;

      let isCharAvail = /[a-zA-Z0-9]/.test(split);

      if (isCharAvail) {
        let arr = [...split.trim()];
        let lastIndex2 = arr.length;
        let plus = 1;

        if (lastIndex + 1 == globalLength || lastIndex == globalLength) {
          return false;
        } else {
          if (arr[0] != '"' && arr[0] != "(" && arr[arr.length - 1] != '"' && arr[arr.length - 1] != ")") {
            if (lastIndex == 0) {
              plus = 0;
            }
            addNodesInBetween(lastIndex == 0 ? lastIndex : lastIndex, '"', lastIndex == 0 ? true : false);
            addNodesInBetween(lastIndex + lastIndex2 + plus, '"');
            applyFeature();

            let selection = window.getSelection();
            let ancestor = selection.getRangeAt(0).commonAncestorContainer;
            let parentElement = ancestor.parentNode.parentElement;
            let id = parentElement.id;

            if (id == domId) {
              setCursorPosition(lastIndex + lastIndex2 + (plus == 0 ? 7 : 3), domId);
            }
          }

          currentStringCallback();
          setTextCount(String(getId().innerText).trim().length);

          return true;
        }
      }
    } catch (e) {}
  };

  const selectedData = (data) => {
    if (data.length) {
      let sort = data.sort((a, b) => a.ind - b.ind);
      setSelectedWord(sort);
      let count = sort.map((a) => String(a.string).trim().length);
      setTextCount(count.reduce((partialSum, a) => partialSum + a));
    }
  };

  return (
    <>
      <div className="mainKeyword">
        <div className={isDeletedString ? "add-gray-bg" : `${permissions() ? "add-gray-bg" : ""}`}>
          {isDeletedString ? (
            <div className="no-string-data">
              <img src={booNegative} className="boo-sad-img" alt="" />
              <Body
                text={strings.UnfortunatelyThestringownerhasremovedyourcollaborationaccess_text}
                color={Colors.darkPurple}
              />
            </div>
          ) : null}

          <div
            onContextMenu={(e) => {
              e.preventDefault();
              return false;
            }}
            style={{ display: combos ? "none" : "block" }}
            onKeyDown={(e) => {
              if (trialData?.checkPay) {
                detectKey(e);
                saved("typing");
                clearTimeout(saveTimer);
                saveTimer = setTimeout(() => {
                  putQuotesOnLast();
                  saved("release");
                }, 3000);
              } else {
                e.preventDefault();
                dispatch(
                  setSnackbar({
                    snackbarMessage: strings.dontHaveActivePlan,
                    snackbarOpen: true,
                    snackbarState: "error",
                  })
                );
                return false;
              }
            }}
            spellCheck={false}
            contentEditable={isDeletedString ? false : permissions() ? false : isContentEnable}
            className={`scroll-view`}
            id={id}
            onPaste={(e) => {
              e.preventDefault();
            }}
            placeholder={"Start typing your Boolean search here..."}
          ></div>

          {combos ? <AdvanceViewCopyEditor selectedData={selectedData} /> : null}

          {auth?.access == "user" && permission == 1 && !isShareWithMe ? (
            <TypingView socketData={{}} type="view" />
          ) : socketData?.status == "typing" &&
            auth?.user_id != socketData.typing_user_id &&
            socketData?.typing_user_id != 0 &&
            socketData.string_id == currentActiveString?.id ? (
            <TypingView socketData={socketData} type="typing" />
          ) : (
            <CopySection
              currentActiveString={currentActiveString}
              revisionHistory={revisionHistory}
              copyTextToClip={copyTextToClip}
              comboCallback={(value) => {
                setCombos(value);
              }}
              textCount={textCount}
              includeQuotationCallback={(value) => {
                setIncludeQuotation(value);
              }}
              isTranslateModule={false}
              typingStatus={typing}
            />
          )}

          <div
            className="new-error-msg"
            style={isContentEnable && errorMsg ? { visibility: "visible" } : { visibility: "hidden" }}
          >
            <img alt="" style={{ height: "36px", marginRight: "auto", marginLeft: 10 }} src={booOn} />
            <div className="new-error-msg-content"> {errorMsg} </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdvanceKeyword;
