import io from "socket.io-client";
import { socketUrl } from "./BaseUrl";

let connectionOptions = {
  transports: ["websocket"],
  pingInterval: 1000 * 60 * 10,
  pingTimeout: 1000 * 60 * 7,
};

let socket = io(socketUrl,connectionOptions);

export default socket;
