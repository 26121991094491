import { createSlice } from '@reduxjs/toolkit'

export const lessonData = createSlice({
    name: 'lessonData',
    initialState: {
        value: null,
    },
    reducers: {
        changeLessonData: (state, action) => {
            state.value = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { changeLessonData } = lessonData.actions
export const currentLesson = (state) => state.lessonData.value;
export default lessonData.reducer
