import { NEW_MODULE_API } from "../Helpers/Enums";
import globalRequest from "./globalRequest";

const getCredit = async (userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await globalRequest("get", NEW_MODULE_API.GET_CREDIT(userId), {}, {}, true);
      let data = response.data;
      if (data.status === 1) {
        resolve(data.data);
      }
    } catch (e) {
      resolve({});
    }
  });
};
export default getCredit;
