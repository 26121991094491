import { withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Tooltip from "@material-ui/core/Tooltip";
import copy from "copy-to-clipboard";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Scrollbar from "react-smooth-scrollbar";

import AdvanceViewCopyEditor from "../advance-editor/keyword/AdvanceViewCopyEditor";
import replaceNewLine from "../editor/replaceNewLine";
import closePopUpByKey from "../GlobalModule/closePopUpByKey";
import CopySection from "../GlobalModule/CopySection";
import removeSingleQuotation from "../GlobalModule/removeSingleQuotation";
import { KEYWORD_VIEW_TYPE } from "../Helpers/Enums";
import booHappy from "../image-assets/boo-happy.png";
import BugRed from "../image-assets/bug-red.svg";
import BugWhite from "../image-assets/bug-white.svg";
import closeModalIcon from "../image-assets/modal-close-new.png";
import newCopyIcon from "../image-assets/new-copy-icon.svg";
import stopRedIcon from "../image-assets/stop-icon-red.svg";
import stopIcon from "../image-assets/stop-icon.svg";
import strings from "../localisation_en.json";
import { tooledUpLocalData } from "../redux/reducers/getTooledupData";
import { setSnackbar } from "../redux/reducers/snackbar";
import { Boxed } from "../Styles-Elements/Box";
import { MainRoundedButton } from "../Styles-Elements/Buttons";
import * as Colors from "../Styles-Elements/Colors";
import { Body, Subtitle } from "../Styles-Elements/Labels";
import { calculatePercentage } from "./GlobalFunction";
import { getColordString } from "./GlobalFunction";
import ReportBugs from "./ReportBug";
import updateCount from "./updateCount";
import "antd/dist/antd.css";
import { changeCredit } from "../redux/reducers/getCredit";
import getCredit from "../GlobalModule/getCredit";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "#373737",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.25)",
    fontSize: 14,
    fontWeight: 500,
    padding: "12px 16px",
    borderRadius: "4px",
    fontFamily: "Poppins",
  },
}))(Tooltip);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TranslationComplete = ({ closePopup, translatedData, openTranslate, selectedLanguage }) => {
  const dispatch = useDispatch();
  const auth = useSelector(tooledUpLocalData);
  const [value, setValue] = useState(1);
  const [orgString, setOrgString] = useState("");
  const [textCount, setTextCount] = useState(0);
  const refForText = useRef();
  const refForText1 = useRef();
  const [isSource, setIsSource] = useState(false);
  const [translatedString, setTranslatedString] = useState("");
  const [isBug, setIsBug] = useState(false);
  const [bugImageChange, setBugImageChange] = useState(false);

  const [combos, setCombos] = useState(false);
  const [selectedWord, setSelectedWord] = useState([]);
  const [includeQuotation, setIncludeQuotation] = useState(true);
  const [string, setString] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setCombos(false);
  };

  useEffect(() => {
    setTimeout(() => {
      if (value == 0) {
        setTextCount(String(refForText1.current.innerText).length);
      } else {
        setTextCount(String(refForText.current.innerText).length);
      }
    }, 700);
  }, []);

  useEffect(() => {
    if (value == 0) {
      setTextCount(String(refForText1.current.innerText).length);
    } else {
      setTextCount(String(refForText.current.innerText).length);
    }
  }, [value]);

  useEffect(() => {
    let str = isSource ? translatedData?.transalted_string_without_source : translatedData?.translated_string;
    setTranslatedString(str);
    let s = String(str).replace(/(<([^>]+)>)/gi, "");
    setString(s);
  }, [isSource]);

  useEffect(() => {
    (async () => {
      setOrgString(await getColordString(translatedData?.laststring));
    })();

    if (translatedData) {
      (async () => {
        await updateCount(translatedData?.totalCount, auth);
        let data = await getCredit(auth?.id);
        dispatch(changeCredit(data));
      })();
    }
  }, [translatedData]);

  useEffect(() => {
    closePopUpByKey(closePopup);
  }, []);

  const selectedData = (data) => {
    if (data.length) {
      let sort = data.sort((a, b) => a.ind - b.ind);
      setSelectedWord(sort);
      let count = sort.map((a) => String(a.string).trim().length);
      setTextCount(count.reduce((partialSum, a) => partialSum + a));
    }
  };

  const copyTextToClip = () => {
    var text = "";

    if (value == 0) {
      text = translatedData?.laststring;
    } else {
      text = string;
    }

    let text1 = replaceNewLine(text);
    let finalText = "";

    if (selectedWord.length) {
      let st = "";
      selectedWord.forEach((obj, ind) => {
        if (ind == 0) {
          let str = String(obj?.string).substring(0, 3);
          if (str == "AND" || str == "NOT") {
            let s = String(obj?.string);
            st += `${s.substring(4, s.length)} `;
          } else {
            st += `${obj?.string} `;
          }
        } else {
          st += `${obj?.string} `;
        }
      });
      finalText = st;
    } else {
      finalText = text1;
    }

    if (!includeQuotation) {
      let te = removeSingleQuotation(finalText);
      finalText = te;
    }

    copy(finalText);

    dispatch(
      setSnackbar({
        snackbarOpen: true,
        snackbarMessage: "String successfully copied",
        snackbarState: "success",
      })
    );
  };

  return (
    <>
      {isBug ? (
        <ReportBugs
          closePopup={() => {
            setIsBug(false);
            setBugImageChange(false);
          }}
          string={translatedData?.laststring}
          selectedLanguage={selectedLanguage}
        />
      ) : (
        <div className="container translation-complete-page">
          <div className="container-modal width1000" style={{ width: "750px", padding: "40px 30px 30px 30px" }}>
            <img src={closeModalIcon} alt="" className="close-new-modal" onClick={() => closePopup()} />
            <div className="popup-boo-container positive">
              <img src={booHappy} width={120} height={120} />
            </div>
            <Scrollbar className="container-modal-mid">
              <Subtitle
                text={strings.TranslationComplete}
                color={Colors.nightPurple2}
                textAlign={"center"}
                padding={"0 0 15px 0"}
              />
              <div className="two-column-modal-40-60">
                <div className="modal-tabs translate-complete-tab">
                  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label={strings.original_string} {...a11yProps(0)} />
                    <Tab label={strings.Translated_string} {...a11yProps(1)} />
                  </Tabs>
                  <TabPanel value={value} index={0}>
                    <div className="ext-textarea-holder">
                      <div className="table">
                        <div className="table__textarea-div">
                          {combos ? (
                            <AdvanceViewCopyEditor selectedData={selectedData} str={translatedData?.laststring} />
                          ) : (
                            <section ref={refForText1} className="table__textarea translate-complete-textarea">
                              <div dangerouslySetInnerHTML={{ __html: orgString }}></div>
                            </section>
                          )}

                          <CopySection
                            currentActiveString={{ string: "" }}
                            revisionHistory={() => {}}
                            copyTextToClip={copyTextToClip}
                            comboCallback={(value) => {
                              setCombos(value);
                              if (!value) {
                                setSelectedWord([]);
                              }
                            }}
                            textCount={textCount}
                            includeQuotationCallback={(value) => {
                              setIncludeQuotation(value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <div className="ext-textarea-holder">
                      <div className="table">
                        <div className="table__textarea-div">
                          {combos ? (
                            <AdvanceViewCopyEditor selectedData={selectedData} str={string} />
                          ) : (
                            <section ref={refForText} className="table__textarea translate-complete-textarea">
                              <div dangerouslySetInnerHTML={{ __html: translatedString }}></div>
                            </section>
                          )}

                          <div>
                            <LightTooltip
                              display={"flex"}
                              alignItems={"start"}
                              style={{
                                cursor: "pointer",
                                backgroundColor: "#F5F6F9",
                                height: "auto",
                                padding: "6px 8px",
                                borderRadius: "5px",
                                position: "absolute",
                                left: 0,
                                marginLeft: "18px",
                                marginTop: "20px",
                              }}
                              onClick={() => {
                                setIsBug(!isBug);
                              }}
                              className={bugImageChange ? "bug-icon" : "new-icon"}
                              title={bugImageChange ? "Report Problem" : ""}
                            >
                              <img
                                onMouseOver={() => {
                                  setBugImageChange(true);
                                }}
                                onMouseOut={() => {
                                  setBugImageChange(false);
                                }}
                                src={bugImageChange ? BugRed : BugWhite}
                                className=""
                                alt=""
                              />
                            </LightTooltip>

                            <CopySection
                              currentActiveString={{ string: "" }}
                              revisionHistory={() => {}}
                              copyTextToClip={copyTextToClip}
                              comboCallback={(value) => {
                                setCombos(value);
                                if (!value) {
                                  setSelectedWord([]);
                                }
                              }}
                              textCount={textCount}
                              includeQuotationCallback={(value) => {
                                setIncludeQuotation(value);
                              }}
                              isTranslation={true}
                              isSourceCallback={(value) => {
                                setIsSource(value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                </div>
              </div>
              <div className="new-btns-group">
                <MainRoundedButton
                  onClick={openTranslate}
                  className="border-radius5 new-back-btn"
                  text={strings.back}
                  margin={"20px 0 0 0"}
                />
                <MainRoundedButton
                  className="border-radius5 new-close-btn"
                  text={strings.Close}
                  margin={"20px 0 0 0"}
                  onClick={() => closePopup()}
                />
              </div>
            </Scrollbar>
          </div>
        </div>
      )}
    </>
  );
};

export default TranslationComplete;
