const removeSingleQuotation = (str) => {
  let string = String(str);
  let expression = /(".*?")/g;
  let match;
  let words = [];
  while ((match = expression.exec(string)) !== null) {
    let word = String(match[0]);
    let str1 = word.split(/\s/g);
    let str2 = word.replace(/"/g, "");
    let findHyphen = word.indexOf("-");

    if (findHyphen == -1) {
      if (str1.length == 1) {
        string = string.replace(str1, str2);
      }
    }
  }

  return string;
};

export default removeSingleQuotation;
