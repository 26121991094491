import React, { useState, useEffect } from "react";
import * as Colors from "../../Styles-Elements/Colors";
import { LargeBody, LargeSmall, Subtitle, Subheading, Small, Body, LabelWrapper } from "../../Styles-Elements/Labels";
import { ClearButton, CustomRoundedButton } from "../../Styles-Elements/Buttons";
import { Textfield } from "../../Styles-Elements/Inputs";
import { API_TEAM, MANAGE_TEAM, STORAGE } from "../../Helpers/Enums";
import { INVITE_TYPE } from "../../Helpers/Enums";
import "./ManageUsers.css";
import { makeStyles } from "@material-ui/core/styles";
import binIcon from "../../image-assets/binicon.svg";
import exportIcon from "../../image-assets/exporticon.svg";
import biPersonPlusIcon from "../../image-assets/bi_person-plus.svg";
import deleteIcon from "../../image-assets/deleteicon.svg";
import ownerIcon from "../../image-assets/ownericon.svg";
import muDropIcon from "../../image-assets/mu-dropicon.svg";
import arrowRightIcon from "../../image-assets/arrow-right.svg";
import star from "../../image-assets/start.svg";
import user from "../../image-assets/bx_bxs-user.svg";
import resendInviteImg from "../../image-assets/email-black.svg";
import check from "../../image-assets/check.svg";
import booHappy from "../../image-assets/boo-happy.png";
import { Boxed } from "../../Styles-Elements/Box";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import booNeutral from "../../image-assets/boo-neutral.png";
import addDocumentIcon from "../../image-assets/add-document-icon.svg";
import fileIcon from "../../image-assets/fileicon.svg";
import clrossIcon from "../../image-assets/closepopupicon.svg";
import Scrollbar from "react-smooth-scrollbar";
import { MainRoundedButton } from "../../Styles-Elements/Buttons";
import Dropzone from "react-dropzone";
import moment from "moment";
import { BASE_URL } from "./../../Helpers/Enums";
import booSurprised from "../../image-assets/boo-surprised.png";
import { Bold } from "../../Styles-Elements/Labels";
import globalRequest from "../../GlobalModule/globalRequest";
import Transfer from "../../dialogs/Transfer";
import { useDispatch, useSelector } from "react-redux";
import { stringData } from "../../redux/reducers/localString";
import { setData, tooledUpLocalData } from "../../redux/reducers/getTooledupData";
import { setSnackbar } from "../../redux/reducers/snackbar";
import { changeLoader } from "../../redux/reducers/loader";
import addDeleteGetLocalStorage from "../../GlobalModule/addDeleteGetLocalStorage";
import { currentTrial } from "../../redux/reducers/getTrial";
import { changeOpenInvite, currentOpenInvite } from "../../redux/reducers/openInvite";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}
const rows = [createData("Cupcake", 305, 3.7, 67, 4.3)];
const headCells = [
  { id: "name", numeric: false, disablePadding: true, label: "Name" },
  { id: "Role", numeric: true, disablePadding: false, label: "Role" },
  {
    id: "Last Interation",
    numeric: true,
    disablePadding: false,
    label: "Last Interaction",
  },
  { id: "carbs", numeric: true, disablePadding: false, label: "" },
  { id: "protein", numeric: true, disablePadding: false, label: "" },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const ManageTeam = () => {
  const strings = useSelector(stringData);
  const auth = useSelector(tooledUpLocalData);
  const trialData = useSelector(currentTrial);
  const dispatch = useDispatch();
  const [sidebar, setSideBar] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupType, setPopupType] = useState("");
  const [activeToggleOption, setActiveToggleOption] = useState(INVITE_TYPE.INVITE_BY_EMAIL);
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [isDisplay, setIsDisplay] = useState(false);
  const [statusDisplay, setStatusDisplay] = useState(false);
  const [seatRemain, setSeatRemain] = useState("(0 seat remaining)");
  const [allUser, setAllUser] = useState([]);
  const [dropDownNumber, setDropDownNumber] = useState(0);
  const [visible, setVisible] = useState(false);
  const [anotherUserId, setAnotherUserId] = useState(0);
  const [anotherUserStatus, setAnotherUserStatus] = useState("");
  const [currentUserStatus, setCurrentUserStatus] = useState("");
  const [isInviteSend, setIsInviteSend] = useState(false);
  const [allUserExportIdEmail, setAllUserExportIdEmail] = useState([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [removeEmail, setRemoveEmail] = useState("");
  const [removeName, setRemoveName] = useState("");
  const [singleUserData, setSingleUserData] = useState({});
  const [teamMateDisplay, setTeamMateDisplay] = useState(false);
  const [thisUserId, setThisUserId] = useState(null);
  const [transferOpen, setTransferOpen] = useState(false);
  const [transferUserData, setTransferUserData] = useState({});
  let userId = auth?.user_id;
  let emailId = auth?.email;
  let teamId = auth?.team_id;

  let [accessArr, setAccessArr] = useState([
    {
      name: "User",
      icon: user,
      access: "user",
    },
    {
      name: "Admin",
      icon: star,
      access: "admin",
    },
    {
      name: "Owner",
      icon: ownerIcon,
      access: "owner",
    },
  ]);

  let openInviteData = useSelector(currentOpenInvite);

  const [fileName, setFileName] = useState(null);
  const [fileTitle, setFileTitle] = useState("Drag and drop or select .CSV file");
  const [fileImportContent, setFileImportContent] = useState(null);
  const [uploadTime, setUploadTime] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      if (openInviteData) {
        openInviteNewTeammembers();
      }
    }, 100);
  }, [openInviteData]);

  useEffect(() => {
    if (!isDisplay) {
      dispatch(changeOpenInvite(false));
    }
  }, [isDisplay]);

  useEffect(() => {
    if (auth.access !== "owner") {
      let filter = accessArr.filter((a) => a.access != "owner");
      setAccessArr(filter);
    }
  }, [auth]);

  const downloadFile = (file) => {
    let element = document.createElement("a");
    element.setAttribute("href", file);
    element.click();
  };

  useEffect(() => {
    document.body.addEventListener(
      "click",
      () => {
        setVisible(false);
        setDropDownNumber(0);
      },
      true
    );
  }, []);

  const getRemailUser = async () => {
    const res = await globalRequest(
      "get",
      API_TEAM.GET_REMAIN_USER,
      {},
      {
        params: {
          user_id: userId,
          team_id: teamId,
        },
      },
      true
    );

    if (res?.data?.status == 1) {
      setSeatRemain(
        `(${res.data.data.remaining_user} ${res.data.data.remaining_user >= 2 ? "seats" : "seat"} remaining)`
      );
    }
  };

  const exportData = async () => {
    if (allUserExportIdEmail.length) {
      dispatch(changeLoader(true));

      let data = {
        user_id: userId,
        all_user_id: [],
        email_id: [],
      };

      allUserExportIdEmail.forEach((obj) => {
        data.all_user_id.push(obj.id);
        data.email_id.push(obj.email_id);
      });

      const response = await globalRequest("post", MANAGE_TEAM.export_data, data, {}, true);

      if (response?.data?.status == 1) {
        let file = response.data.data.file;
        if (file != undefined) {
          // saveAs(file, null);
          // window.open(file, "_blank");

          downloadFile(file);

          dispatch(
            setSnackbar({
              snackbarMessage: "Your file is downloading.",
              snackbarOpen: true,
              snackbarState: "success",
            })
          );
        } else {
          dispatch(
            setSnackbar({
              snackbarMessage: "No data available to export",
              snackbarOpen: true,
              snackbarState: "error",
            })
          );
        }
      } else {
        dispatch(
          setSnackbar({
            snackbarMessage: "Something went wrong while calling API",
            snackbarOpen: true,
            snackbarState: "error",
          })
        );
      }

      dispatch(changeLoader(false));
    } else {
      dispatch(
        setSnackbar({
          snackbarMessage: "Please select at least one user.",
          snackbarOpen: true,
          snackbarState: "error",
        })
      );
    }
  };

  const deleteUser = async () => {
    if (allUserExportIdEmail.length) {
      let data = {
        user_id: userId,
        deleteuser_id: [],
        email_id: [],
      };

      allUserExportIdEmail.forEach((obj) => {
        data.deleteuser_id.push(obj.id);
        data.email_id.push(obj.email_id);
      });

      dispatch(changeLoader(true));

      let response = await globalRequest("post", MANAGE_TEAM.delete_user, data, {}, true);

      if (response?.data?.status == 1) {
        dispatch(
          setSnackbar({
            snackbarMessage: response.data.message,
            snackbarOpen: true,
            snackbarState: "success",
          })
        );

        setConfirmOpen(false);
        getAllUser();
        getRemailUser();
        setAllUserExportIdEmail([]);

        let file = response?.data?.data?.file;
        if (file != undefined) {
          // saveAs(file, "keyword_and_company_string.zip");
          downloadFile(file);
        }
      } else {
        dispatch(
          setSnackbar({
            snackbarMessage: response.data.message,
            snackbarOpen: true,
            snackbarState: "error",
          })
        );
      }

      dispatch(changeLoader(false));
    } else {
      dispatch(
        setSnackbar({
          snackbarMessage: "Please select at least one user",
          snackbarOpen: true,
          snackbarState: "error",
        })
      );
    }
  };

  const submitRequest = async (e) => {
    e.preventDefault();

    let emails = e.target.elements.email.value;

    if (emails == "") {
      dispatch(
        setSnackbar({
          snackbarMessage: "Email Should not be empty",
          snackbarOpen: true,
          snackbarState: "error",
        })
      );
    } else {
      dispatch(changeLoader(true));

      let res = await globalRequest(
        "post",
        API_TEAM.INVITE_TEAMMATE,
        {
          email_id: emails,
          user_id: userId,
          team_id: teamId,
        },
        {},
        true
      );

      if (res?.data?.status == 1) {
        dispatch(
          setSnackbar({
            snackbarMessage: res.data.message,
            snackbarOpen: true,
            snackbarState: "success",
          })
        );

        setIsDisplay(false);
        setActiveToggleOption(INVITE_TYPE.INVITE_BY_EMAIL);
        setFileImportContent(null);
        setFileName(null);
        getRemailUser();
        getAllUser();
      } else {
        dispatch(
          setSnackbar({
            snackbarMessage: res.data.message,
            snackbarOpen: true,
            snackbarState: "error",
          })
        );
      }

      dispatch(changeLoader(false));
    }
  };

  useEffect(() => {
    getRemailUser();
  }, []);

  // Handling snackbar close

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      let newSelecteds = allUser.map((n) => (n.pass == "" && n.id != userId ? n.id : null));
      let a = [];
      newSelecteds.forEach((objs) => {
        if (objs != null) {
          allUser.forEach((obj1) => {
            if (obj1.id == objs) {
              a.push(obj1);
            }
          });
        }
      });
      setAllUserExportIdEmail(a);
      setSelected(newSelecteds);
    } else {
      setAllUserExportIdEmail([]);
      setSelected([]);
    }
  };

  const handleClick = (event, row) => {
    if (event.target.checked) {
      setAllUserExportIdEmail((allUserExportIdEmail) => [...allUserExportIdEmail, row]);
    }

    if (!event.target.checked) {
      const filter = allUserExportIdEmail.filter((obj) => obj.id != row.id);
      setAllUserExportIdEmail(filter);
    }

    const selectedIndex = selected.indexOf(row.id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  function openInviteNewTeammembers() {
    if (trialData?.checkPay) {
      setIsDisplay(true);
    } else {
      dispatch(
        setSnackbar({
          snackbarMessage: strings.dontHaveActivePlan,
          snackbarOpen: true,
          snackbarState: "error",
        })
      );
    }
  }

  async function handleDrop([file]) {
    setFileName(file.name);
    setUploadTime(new Date());
    var reader = new FileReader();
    reader.onload = () => {
      setFileImportContent(file);
    };
    reader.readAsText(file);
  }

  useEffect(() => {
    if (fileName != null) {
      setFileTitle("Choose a different file to upload");
    }
  }, [fileName]);

  const uploadCsv = async () => {
    if (fileImportContent != null) {
      let formData = new FormData();
      formData.append("team_id", teamId);
      formData.append("import_file", fileImportContent);
      formData.append("user_id", userId);
      formData.append("owner_email_id", emailId);

      dispatch(changeLoader(true));

      const response = await globalRequest("post", MANAGE_TEAM.upload_csv, formData, {}, true);

      let data = response.data;
      if (data.status == 1) {
        dispatch(
          setSnackbar({
            snackbarMessage: data.message,
            snackbarOpen: true,
            snackbarState: "success",
          })
        );

        setIsDisplay(false);
        setActiveToggleOption(INVITE_TYPE.INVITE_BY_EMAIL);
        setFileImportContent(null);
        setFileName(null);
        getAllUser();
        getRemailUser();
      } else {
        dispatch(
          setSnackbar({
            snackbarMessage: data.message,
            snackbarOpen: true,
            snackbarState: "error",
          })
        );
      }
      dispatch(changeLoader(false));
    } else {
      dispatch(
        setSnackbar({
          snackbarMessage: "Please select file first.",
          snackbarOpen: true,
          snackbarState: "error",
        })
      );
    }
  };

  const InviteTeamMate = () => {
    return (
      <div className="container">
        <div className="container-modal">
          <div className="popup-boo-container neutral">
            <img src={booNeutral} width={120} height={120} alt="" />
          </div>
          <Scrollbar className="container-modal-mid">
            <Subtitle
              text={strings.letsInviteNewMembers}
              color={Colors.nightPurple}
              textAlign={"center"}
              padding={"24px 0 16px"}
            />
            <div className="tab-manager-small">
              <div
                className={`tab-small ${
                  activeToggleOption === INVITE_TYPE.INVITE_BY_EMAIL ? "selected-tab-small" : ""
                }`}
                onClick={() => {
                  setActiveToggleOption(INVITE_TYPE.INVITE_BY_EMAIL);
                }}
              >
                <Subheading
                  text={strings.inviteByEmail}
                  color={activeToggleOption === INVITE_TYPE.INVITE_BY_EMAIL ? Colors.darkPurple : Colors.darkGray}
                  textAlign={"center"}
                />
              </div>
              <div
                className={`tab-small ${activeToggleOption === INVITE_TYPE.IMPORT_MEMBERS ? "selected-tab-small" : ""}`}
                onClick={() => {
                  setActiveToggleOption(INVITE_TYPE.IMPORT_MEMBERS);
                }}
              >
                <Subheading
                  text={strings.importMemebers}
                  color={activeToggleOption === INVITE_TYPE.IMPORT_MEMBERS ? Colors.darkPurple : Colors.darkGray}
                  textAlign={"center"}
                />
              </div>
            </div>

            {activeToggleOption == INVITE_TYPE.INVITE_BY_EMAIL ? (
              <>
                <form onSubmit={submitRequest}>
                  <Subheading text={strings.emailOfYourTeammate} color={Colors.nightPurple} padding={"0 0 8px 0"} />
                  <Textfield type="email" placeholder={strings.enterTheEmailOfYourTeammate} name="email" />
                  <MainRoundedButton type={"submit"} text={strings.inviteTeamMember} margin={"24px 0 8px 0"} />
                </form>
              </>
            ) : (
              <>
                <div className="dropzone-field df-height-small">
                  <Dropzone onDrop={(acceptedFiles) => handleDrop(acceptedFiles)}>
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} accept=".csv" type="file" />
                        <div className="file-field__dropzone">
                          <div className="file-field__status"></div>
                          <div className="file-field__text">
                            <img src={addDocumentIcon} style={{ fill: Colors.lightGray }} alt="" />

                            <Body text={fileTitle} color={Colors.lightGray} padding={"2px 0 0 8px"} />
                          </div>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </div>
                <div style={{ color: "grey" }}>
                  {strings.DownloadTheUploadTemplateHere} &nbsp;
                  <a
                    style={{
                      color: "#4A0FA3",
                      fontFamily: "Poppins",
                      fontWeight: "500",
                      textDecoration: "underline",
                    }}
                    href={MANAGE_TEAM.get_csv}
                    download
                  >
                    here{" "}
                  </a>
                </div>
                {fileImportContent != null ? (
                  <>
                    <div className="thumbnail-upload-holder grid-item-flex tuh-uploaded">
                      <Boxed display={"flex"} alignItems={"center"}>
                        <img src={fileIcon} className="upload-icon" alt="" />
                        <div style={{ paddingLeft: "16px" }}>
                          <LargeBody text={fileName} color={Colors.darkGray} />
                          {uploadTime != null ? (
                            <>
                              <Body
                                text={`Uploaded: ${uploadTime.getHours()}:${uploadTime.getMinutes()} ${
                                  uploadTime.getHours() >= 12 ? " PM" : " AM"
                                } ${uploadTime.getDate()}/${uploadTime.getMonth() + 1}/${uploadTime.getFullYear()}`}
                                color={Colors.darkGray}
                              />
                            </>
                          ) : null}
                        </div>
                      </Boxed>
                      <img
                        src={clrossIcon}
                        onClick={() => {
                          setFileName(null);
                          setFileTitle("Drag and drop or select .CSV file");
                          setFileImportContent(null);
                          setUploadTime(null);
                        }}
                        className="cursor-p"
                        alt=""
                      />
                    </div>
                  </>
                ) : null}

                <MainRoundedButton
                  type={"submit"}
                  onClick={() => uploadCsv()}
                  text={strings.inviteTeamMembers}
                  margin={"24px 0 8px 0"}
                />
              </>
            )}

            <ClearButton
              text={strings.cancel}
              onClick={() => {
                setIsDisplay(false);
                setActiveToggleOption(INVITE_TYPE.INVITE_BY_EMAIL);
                setFileImportContent(null);
                setFileName(null);
              }}
            />
          </Scrollbar>
        </div>
      </div>
    );
  };

  const deleteSingleUser = async () => {
    let data = {
      user_id: singleUserData.id,
      delete_user_id: singleUserData.id,
    };
    let response = await globalRequest("post", MANAGE_TEAM.delete_sing_user, data, {}, true);

    if (response.data.status == 1) {
      // const keyword = response.data.data.keyword;
      // const company = response.data.data.company;
      // if (keyword != "") {
      //   // saveAs(keyword, "keyword.csv");
      //   downloadFile(keyword);
      // }
      // if (company != "") {
      //   // saveAs(company, "company.csv");
      //   downloadFile(company);
      // }

      dispatch(
        setSnackbar({
          snackbarMessage: response.data.message,
          snackbarOpen: true,
          snackbarState: "success",
        })
      );
    } else {
      dispatch(
        setSnackbar({
          snackbarMessage: "Something went wrong while calling API",
          snackbarOpen: true,
          snackbarState: "error",
        })
      );
    }

    getAllUser();
    setTeamMateDisplay(false);
    getRemailUser();
  };

  const ChangeStatusPopUp = ({ isDisplay }) => {
    return (
      <div className="container" style={{ display: !isDisplay ? "none" : "" }}>
        <div className="container-modal">
          <div className={`popup-boo-container ${anotherUserStatus == "owner" ? "negative" : "neutral"}`}>
            <img src={anotherUserStatus == "owner" ? booSurprised : booNeutral} width={120} height={120} alt="" />
          </div>
          <Scrollbar className="container-modal-mid">
            <Subtitle
              text={
                anotherUserStatus == "owner"
                  ? strings.changeOwner1
                  : anotherUserStatus == "admin"
                  ? strings.youAreAboutToChangeThisUserToBeAnAdmin
                  : strings.youAreAboutToChangeThisAdminToBeAndUser
              }
              color={Colors.nightPurple}
              textAlign={"center"}
              padding={"24px 0 16px"}
            />
            <Body
              text={
                anotherUserStatus == "owner"
                  ? strings.changeOwnerDesc
                  : anotherUserStatus == "admin"
                  ? strings.onceYouConvertThisUserToAnAdminTheyWillHaveAFullOverviewAndControlOverManagingUsers
                  : strings.onceYouConvertThisAdminToUserTheyWillHaveAFullOverviewAndControlOverManagingUsers
              }
              textAlign={"center"}
              color={Colors.darkGray}
              padding={"0 0 24px"}
            />
            {anotherUserStatus == "owner" ? (
              <Body text={strings.changeOwnerDesc1} textAlign={"center"} color={Colors.darkGray} padding={"0 0 24px"} />
            ) : null}

            <MainRoundedButton
              text={anotherUserStatus == "owner" ? "Proceed" : `Make the ${currentUserStatus} to ${anotherUserStatus}`}
              margin={"24px 0 8px 0"}
              onClick={() => changeStatus(anotherUserId, anotherUserStatus)}
              style={anotherUserStatus == "owner" ? { backgroundColor: "#eb5757", color: "white" } : null}
            />
            <ClearButton text={"Cancel"} onClick={() => setStatusDisplay(false)} />
          </Scrollbar>
        </div>
      </div>
    );
  };

  useEffect(() => {
    getAllUser();
  }, []);

  const resendInvite = async (e, userid) => {
    let res = await globalRequest("post", API_TEAM.RESEND_INVITE, { user_id: userid }, {}, true);

    if (res?.data?.status == 1) {
      dispatch(
        setSnackbar({
          snackbarMessage: res.data.message,
          snackbarOpen: true,
          snackbarState: "success",
        })
      );

      setIsInviteSend(true);
      setThisUserId(userid);
    } else {
      dispatch(
        setSnackbar({
          snackbarMessage: res.data.message,
          snackbarOpen: true,
          snackbarState: "error",
        })
      );
    }
  };

  useEffect(() => {
    if (isInviteSend) {
      setTimeout(() => {
        setIsInviteSend(false);
      }, 5000);
    }
  }, [isInviteSend]);

  const getAllUser = async () => {
    //user,admin,owner
    dispatch(changeLoader(true));
    let res = await globalRequest(
      "get",
      API_TEAM.GET_ALL_TEAM_USER,
      {},
      {
        params: {
          user_id: userId,
        },
      },
      true
    );
    if (res.data.status == 1) {
      setAllUser(res.data.data);
    }
    dispatch(changeLoader(false));
  };

  const changeStatus = async (userId, statuses) => {
    if (trialData?.checkPay) {
      const res = await globalRequest(
        "put",
        API_TEAM.CHANGE_ROLE_STATUS,
        {
          user_id: userId,
          role: statuses,
        },
        {},
        true
      );

      let { status, data, message } = res.data;

      if (status == 1) {
        dispatch(
          setSnackbar({
            snackbarMessage: message,
            snackbarOpen: true,
            snackbarState: "success",
          })
        );

        if (Object.keys(data).length !== 0) {
          addDeleteGetLocalStorage(STORAGE.TOOLED_UP_LOCAL_DATA, data, "add", "single");
          dispatch(setData());
        }

        getRemailUser();
        setStatusDisplay(false);
      } else {
        dispatch(
          setSnackbar({
            snackbarMessage: message,
            snackbarOpen: true,
            snackbarState: "error",
          })
        );
      }
      setVisible(false);
      setDropDownNumber(0);
      getAllUser();
    } else {
      dispatch(
        setSnackbar({
          snackbarMessage: strings.dontHaveActivePlan,
          snackbarOpen: true,
          snackbarState: "error",
        })
      );
    }
  };

  const RemoveTheTeamMemberDialog = () => {
    return (
      <div className="container">
        <div className="container-modal">
          <div className="popup-boo-container negative">
            <img src={booSurprised} width={120} height={120} alt="" />
          </div>
          <Scrollbar className="container-modal-mid">
            <Subtitle
              text={strings.RemovingTeamMember}
              color={Colors.nightPurple}
              textAlign={"center"}
              padding={"24px 0 24px 0"}
            />

            <LabelWrapper justifyContent={"center"} padding={"0px 0px 0 0px"}>
              <Body text={"Are you sure you want to delete"} textAlign={"center"} color={Colors.darkGray}></Body>
              <Bold text={removeName} padding={"0 4px"} color={Colors.darkGray} />
              <Bold text={`(${removeEmail})`} padding={"0 4px"} color={Colors.darkGray} />
              <Body text={"from your team ?"} textAlign={"center"} color={Colors.darkGray}></Body>
            </LabelWrapper>

            <CustomRoundedButton
              text={strings.exportDataRemoveData}
              margin={"24px 0 8px 0"}
              color={Colors.mainLight}
              backgroundColor={Colors.lightRed}
              onClick={() => deleteSingleUser(singleUserData)}
            />
            <ClearButton text={strings.cancel} onClick={() => setTeamMateDisplay(false)} />
          </Scrollbar>
        </div>
      </div>
    );
  };

  const RemoveTheMultipleDialog = () => {
    return (
      <div className="container">
        <div className="container-modal">
          <div className="popup-boo-container negative">
            <img src={booSurprised} width={120} height={120} alt="" />
          </div>
          <Scrollbar className="container-modal-mid">
            <Subtitle
              text={strings.removingAllMember}
              color={Colors.nightPurple}
              textAlign={"center"}
              padding={"24px 0 24px 0"}
            />

            <LabelWrapper justifyContent={"center"} padding={"0px 0px 0 0px"}>
              <Body text={strings.removingAllMemberDesc} textAlign={"center"} color={Colors.darkGray}></Body>
            </LabelWrapper>

            <CustomRoundedButton
              text={strings.exportDataRemoveData}
              margin={"24px 0 8px 0"}
              color={Colors.mainLight}
              backgroundColor={Colors.lightRed}
              onClick={() => deleteUser()}
            />
            <ClearButton text={strings.cancel} onClick={() => setConfirmOpen(false)} />
          </Scrollbar>
        </div>
      </div>
    );
  };

  return (
    <div className="keywordstrings-holder rightside-holder">
      <Subtitle text={strings.manageYourTeam} color={Colors.nightPurple} padding={"0 0 24px 0"} />
      <div className="manageteam-grid">
        <div className="" style={{ display: "flex", flexDirection: "column" }}>
          <div className="grid-item-flex" style={{ marginBottom: "16px" }}>
            <Boxed display={"flex"} alignItems={"center"}>
              <Subheading text={strings.allUsers} color={Colors.darkPurple} margin={"0 8px 0 0"} />
              <Small text={seatRemain} color={Colors.darkestGray} margin={"0 8px 0 0"} />
            </Boxed>
            <Boxed display={"flex"} alignItems={"center"}>
              <Boxed display={"flex"} alignItems={"center"} margin={"0 0 0 24px"}>
                {auth?.access == "admin" || auth?.access == "owner" ? (
                  <>
                    <img src={biPersonPlusIcon} alt="" />
                    <LargeBody
                      text={strings.inviteNewTeammate}
                      color={Colors.darkPurple}
                      margin={"0 0 0 8px"}
                      cursor={"pointer"}
                      onClick={() => openInviteNewTeammembers()}
                    />
                  </>
                ) : null}
              </Boxed>
            </Boxed>
          </div>
          <div className="grid mu-table-holder mu-table-holder-scroll" style={{ flexGrow: "2" }}>
            <div className={classes.root}>
              <Paper className={classes.paper}>
                <TableContainer>
                  <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={dense ? "small" : "medium"}
                    aria-label="enhanced table"
                  >
                    <EnhancedTableHead
                      classes={classes}
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={allUser.length}
                    />
                    <TableBody>
                      {allUser.map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;

                        let profileUrl = null;
                        if (row.profile_image) {
                          profileUrl = `${BASE_URL}uploads/profile/${row.id}/${row.profile_image}`;
                        } else {
                          if (row.first_name == "") {
                            profileUrl = booHappy;
                          } else {
                            profileUrl = "nameImage";
                          }
                        }

                        if ((row.access == "owner" && row.id != userId) || row.id == userId) {
                          return (
                            <TableRow key={index}>
                              <TableCell padding="checkbox"></TableCell>

                              <TableCell component="th" id={labelId} scope="row" padding="none">
                                <LabelWrapper>
                                  {profileUrl == "nameImage" ? (
                                    <>
                                      <b className="sidebar-profile-char">
                                        {String(row.first_name).charAt(0)} {String(row.last_name).charAt(0)}
                                      </b>
                                    </>
                                  ) : (
                                    <>
                                      <img
                                        style={{
                                          height: "50px",
                                          width: "50px",
                                          borderRadius: "40px",
                                        }}
                                        src={profileUrl}
                                        alt=""
                                      />
                                    </>
                                  )}
                                  <Boxed>
                                    <Body
                                      text={`${row.first_name} ${row.last_name}`}
                                      color={Colors.darkPurple}
                                      margin={"0 0 0 16px"}
                                    />
                                    <LargeSmall text={row.email_id} color={Colors.darkestGray} margin={"0 0 0 16px"} />
                                  </Boxed>
                                </LabelWrapper>
                              </TableCell>
                              <TableCell>
                                <div className="owner-box" style={{ justifyContent: "center" }}>
                                  {row.access == "owner" ? (
                                    <>
                                      <img src={ownerIcon} alt="" />
                                      <LargeSmall text={strings.owner} color={Colors.nightGray} margin={"0 0 0 12px"} />
                                    </>
                                  ) : row.access == "admin" ? (
                                    <>
                                      <img src={star} alt="" />
                                      <LargeSmall text={"Admin"} color={Colors.nightGray} margin={"0 0 0 12px"} />
                                    </>
                                  ) : (
                                    <>
                                      <img src={user} alt="" />
                                      <LargeSmall text={"User"} color={Colors.nightGray} margin={"0 0 0 12px"} />
                                    </>
                                  )}
                                </div>
                              </TableCell>
                              <TableCell>
                                <LargeSmall
                                  text={moment(row.updated_on).format("dddd Do MMM, h:mm")}
                                  color={Colors.nightGray}
                                  margin={"0 0 0 0px"}
                                  textAlign={"center"}
                                />
                              </TableCell>

                              <TableCell></TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          );
                        }
                      })}

                      {allUser.map((row, index) => {
                        const isItemSelected = isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        let profileUrl = null;
                        if (row.profile_image) {
                          profileUrl = `${BASE_URL}uploads/profile/${row.id}/${row.profile_image}`;
                        } else {
                          if (row.first_name == "") {
                            profileUrl = booHappy;
                          } else {
                            profileUrl = "nameImage";
                          }
                        }

                        if (row.id == userId || (row.access == "owner" && row.id != userId)) {
                        } else {
                          return (
                            <TableRow
                              hover
                              onClick={(event) => (row.pass == "" ? handleClick(event, row) : "")}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.id}
                              selected={isItemSelected}
                              style={{
                                backgroundColor: row.pass == "" ? "white" : "#f4f4f4",
                              }}
                            >
                              {row.pass == "" ? (
                                <>
                                  <TableCell padding="checkbox">
                                    <Checkbox
                                      checked={isItemSelected}
                                      inputProps={{
                                        "aria-labelledby": labelId,
                                      }}
                                    />
                                  </TableCell>
                                </>
                              ) : (
                                <>
                                  <TableCell padding="checkbox"></TableCell>
                                </>
                              )}

                              <TableCell component="th" id={labelId} scope="row" padding="none">
                                <LabelWrapper>
                                  {profileUrl == "nameImage" ? (
                                    <>
                                      <b className="sidebar-profile-char">
                                        {String(row.first_name).charAt(0)} {String(row.last_name).charAt(0)}
                                      </b>
                                    </>
                                  ) : (
                                    <>
                                      <img
                                        style={{
                                          height: "50px",
                                          width: "50px",
                                          borderRadius: "40px",
                                        }}
                                        src={profileUrl}
                                        alt=""
                                      />
                                    </>
                                  )}

                                  <Boxed>
                                    <Body
                                      text={`${row.first_name} ${row.last_name}`}
                                      color={Colors.darkPurple}
                                      margin={"0 0 0 16px"}
                                    />
                                    <LargeSmall text={row.email_id} color={Colors.darkestGray} margin={"0 0 0 16px"} />
                                  </Boxed>
                                </LabelWrapper>
                              </TableCell>

                              <TableCell>
                                {row.status == "active" ? (
                                  <>
                                    <div className="manage-user-dropbox" style={{ position: "relative" }}>
                                      {(auth?.access == "owner" && row.id != auth?.user_id) ||
                                      (auth?.access == "admin" && row.id != auth?.user_id) ? (
                                        <>
                                          <button
                                            className="mu-dropdown-style"
                                            onClick={() => {
                                              if (visible) {
                                                setVisible(false);
                                                setDropDownNumber(0);
                                              } else {
                                                setVisible(true);
                                                setDropDownNumber(index);
                                              }
                                            }}
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            <img
                                              src={row.access == "admin" ? star : user}
                                              className="mudropdown-icon-holder"
                                              alt=""
                                            />
                                            <LargeSmall style={{ marginLeft: "10px" }} text={row.access} />
                                            <img src={muDropIcon} className="mudropdown-icon-holder" alt="" />
                                          </button>
                                          {/* start of drop down */}
                                          <div
                                            style={{ left: "0", right: "0" }}
                                            className={
                                              visible && dropDownNumber == index
                                                ? "dropdown-menu add-shadow visible"
                                                : "dropdown-menu add-shadow"
                                            }
                                            role="menu"
                                          >
                                            {accessArr
                                              .filter((a) => a.access !== row.access)
                                              .map((obj) => {
                                                return (
                                                  <div
                                                    onClick={() => {
                                                      setAnotherUserId(row.id);
                                                      setAnotherUserStatus(obj.access);
                                                      setCurrentUserStatus(row.access);
                                                      setStatusDisplay(true);
                                                    }}
                                                    className="owner-box"
                                                    style={{
                                                      backgroundColor: "white",
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    <img src={obj.icon} alt="" />
                                                    <Body text={obj.name} margin={"0 0 0 12px"} />
                                                  </div>
                                                );
                                              })}
                                          </div>
                                        </>
                                      ) : (
                                        <>{row.id != auth?.user_id ? <LargeSmall text={row.access} /> : null}</>
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="manage-user-dropbox" style={{ position: "relative" }}>
                                      <button
                                        className="mu-dropdown-style"
                                        style={{ textTransform: "capitalize" }}
                                        onClick={() => {
                                          if (visible) {
                                            setVisible(false);
                                            setDropDownNumber(0);
                                          } else {
                                            setVisible(true);
                                            setDropDownNumber(index);
                                          }
                                        }}
                                      >
                                        <LargeSmall
                                          style={{ marginLeft: "10px" }}
                                          text={"Paused"}
                                          color={Colors.lightRed}
                                        />
                                        <img src={muDropIcon} className="mudropdown-icon-holder" alt="" />
                                      </button>

                                      {/* start of drop down */}

                                      <div
                                        style={{ left: "0", right: "0" }}
                                        className={
                                          visible && dropDownNumber == index
                                            ? "dropdown-menu add-shadow visible"
                                            : "dropdown-menu add-shadow"
                                        }
                                        role="menu"
                                      >
                                        {accessArr.map((obj) => {
                                          return (
                                            <div
                                              onClick={() => {
                                                setAnotherUserId(row.id);
                                                setAnotherUserStatus(obj.access);
                                                setCurrentUserStatus(row.access);
                                                setStatusDisplay(true);
                                              }}
                                              className="owner-box"
                                              style={{
                                                backgroundColor: "white",
                                                cursor: "pointer",
                                              }}
                                            >
                                              <img src={obj.icon} alt="" />
                                              <Body text={obj.name} margin={"0 0 0 12px"} />
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </>
                                )}
                              </TableCell>

                              <TableCell>
                                <LargeSmall
                                  text={moment(row.updated_on).format("dddd Do MMM, h:mm")}
                                  color={Colors.nightGray}
                                  margin={"0 0 0 0px"}
                                  textAlign={"center"}
                                />
                              </TableCell>

                              {row.pass == "" ? (
                                <TableCell>
                                  <Boxed display={"flex"} alignItems={"center"} cursor={"pointer"}>
                                    <img src={arrowRightIcon} className="mu-filter-icon" alt="" />
                                    <LargeSmall
                                      text={strings.Transfer}
                                      color={Colors.nightGray}
                                      cursor={"pointer"}
                                      margin={"0 0 0 8px"}
                                      onClick={() => {
                                        setTransferOpen(true);
                                        setTransferUserData(row);
                                      }}
                                    />
                                  </Boxed>
                                </TableCell>
                              ) : isInviteSend && thisUserId == row.id ? (
                                <TableCell>
                                  <Boxed display={"flex"} alignItems={"center"} cursor={"pointer"}>
                                    <img
                                      src={check}
                                      className="mu-filter-icon"
                                      style={{
                                        cursor: "pointer",
                                        filter: "none",
                                      }}
                                      alt=""
                                    />{" "}
                                    <LargeSmall
                                      text={strings.inviteResent}
                                      color={Colors.nightGray}
                                      cursor={"pointer"}
                                      margin={"0 0 0 8px"}
                                    />
                                  </Boxed>
                                </TableCell>
                              ) : (
                                <TableCell>
                                  <Boxed
                                    cursor={"pointer"}
                                    onClick={(e) => resendInvite(e, row.id)}
                                    display={"flex"}
                                    alignItems={"center"}
                                  >
                                    <img src={resendInviteImg} className="mu-filter-icon" alt="" />
                                    <LargeSmall
                                      cursor={"pointer"}
                                      text={strings.inviteResend}
                                      color={Colors.nightGray}
                                      margin={"0 0 0 8px"}
                                    />
                                  </Boxed>
                                </TableCell>
                              )}

                              <TableCell>
                                {" "}
                                <Boxed
                                  style={{ cursor: "pointer" }}
                                  display={"flex"}
                                  alignItems={"center"}
                                  onClick={() => {
                                    setRemoveEmail(row.email_id);
                                    setRemoveName(row.first_name + " " + row.last_name);
                                    setSingleUserData(row);
                                    setTeamMateDisplay(true);
                                  }}
                                >
                                  <img
                                    src={deleteIcon}
                                    className="mu-filter-icon"
                                    style={{ width: "16px", height: "16px" }}
                                    alt=""
                                  />{" "}
                                  <LargeSmall text={strings.delete} color={Colors.nightGray} margin={"0 0 0 8px"} />
                                </Boxed>{" "}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      })}

                      {emptyRows > 0 && (
                        <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>
          </div>
          {auth?.access == "owner" || auth?.access == "admin" ? (
            allUserExportIdEmail.length ? (
              <>
                <div className="grid-item-flex selected-bottom-holder">
                  <LargeBody
                    text={parseInt(allUserExportIdEmail.length) + " " + strings.teamMembersSelected}
                    color={Colors.nightGray}
                  />
                  <Boxed display={"flex"} alignItems={"center"}>
                    {/* <div className="sb-button-holder" onClick={() => exportData()}>
                      <img src={exportIcon} className="sb-bh-icon" height={16} alt="" />
                      <LargeSmall
                        text={strings.exportData}
                        color={Colors.darkPurple}
                        margin={"0 0 0 8px"}
                        cursor={"pointer"}
                      />
                    </div> */}
                    <div className="sb-button-holder" onClick={() => setConfirmOpen(true)}>
                      <img src={binIcon} className="sb-bh-icon" height={16} alt="" />
                      <LargeSmall
                        text={strings.delete}
                        color={Colors.darkPurple}
                        margin={"0 0 0 8px"}
                        cursor={"pointer"}
                      />
                    </div>
                  </Boxed>
                </div>
              </>
            ) : null
          ) : null}
        </div>
      </div>

      {isDisplay ? <InviteTeamMate /> : null}

      <ChangeStatusPopUp isDisplay={statusDisplay} />

      {teamMateDisplay ? (
        <>
          <RemoveTheTeamMemberDialog isDisplay={teamMateDisplay} />
        </>
      ) : null}

      {confirmOpen ? (
        <>
          <RemoveTheMultipleDialog />
        </>
      ) : null}

      {transferOpen ? (
        <Transfer
          closePopup={() => setTransferOpen(false)}
          allUser={allUser}
          transferUserData={transferUserData}
          callback={() => {
            setTransferOpen(false);
            getAllUser();
          }}
        />
      ) : null}
    </div>
  );
};

export default ManageTeam;
