import React, { useState, useEffect } from "react";
import * as Colors from "../../Styles-Elements/Colors";
import { Body, LargeSmallSemiBold, LargeSmall } from "../../Styles-Elements/Labels";
import "./KeywordStrings.css";
import threeDottesIcon from "../../image-assets/three-dottes-icon.svg";
import Box from "@material-ui/core/Box";
import { Boxed } from "../../Styles-Elements/Box";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import LetsCollaborate from "../../dialogs/LetsCollaborate";
import MoveStringToFolder from "../../dialogs/MoveStringToFolder";
import RenameString from "../../dialogs/RenameString";
import DeleteStrings from "./../../dialogs/DeleteStrings";
import addDeleteGetLocalStorage from "./../../GlobalModule/addDeleteGetLocalStorage";
import stringIcon from "./../../image-assets/string-icons.svg";
import ownerIcon from "../../image-assets/owner-icon.svg";
import membersIcon from "../../image-assets/members-icon.svg";
import editAccessIcon from "../../image-assets/edit-access-icon.svg";
import { BASE_URL, KEYWORD_STRING, NEW_API, REQUEST, STORAGE } from "./../../Helpers/Enums";
import globalRequest from "../../GlobalModule/globalRequest";
import ChangeStringOwner from "../../dialogs/ChangeStringOwner";
import { useDispatch, useSelector } from "react-redux";
import { tooledUpLocalData } from "../../redux/reducers/getTooledupData";
import { setSnackbar, snackObj } from "../../redux/reducers/snackbar";
import { changeData } from "../../redux/reducers/letCollab";
import { setChange } from "../../redux/reducers/getLocalData";
import { postLastActiveString } from "./postGetLastActiveString";
import encryptDcrypt from "../../GlobalModule/encryptDcrypt";
import { changeLoader } from "../../redux/reducers/loader";
import { currentTrial } from "../../redux/reducers/getTrial";
const strings = require("../../localisation_en.json");
function Strings({
  dataObj,
  callback,
  callGetFun,
  isEditChecked,
  type,
  props,
  isCheckboxChecked = false,
  checkboxCallback, // callback
  enableCheckbox = true,
  smClicked = "",
  handleDropDown,
  userInfoForChangeOwner,
  currentActiveString,
  closeMenu = false,
  folderId, //callback
  isAutoRichDataAvailable = false,
}) {
  // const dataObj = props.dataObj;
  // const callback = props.callback;
  // const callGetFun = props.callGetFun;
  // const isEditChecked = props.isEditChecked;
  // const type = props.type;
  //string_type;
  //string_name'
  //string_boolean;
  //id
  const auth = useSelector(tooledUpLocalData);
  const trialData = useSelector(currentTrial);
  const snackbarObj = useSelector(snackObj);
  const dispatch = useDispatch();
  let id = dataObj.id;
  const label = { inputProps: { "aria-label": `Checkbox demo ${dataObj.id}` } };

  const [checkStatus, setCheckStatus] = useState(false);
  const [collaborateOpen, setCollaborateOpen] = useState(false);
  const [moveOpen, setMoveOpen] = useState(false);
  const [renameOpen, setRenameOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [dropDownShow, setDropDownShow] = useState(true);
  const [isRename, setIsRename] = useState(true);
  const [isDelete, setIsDelete] = useState(true);
  const [isMoved, setIsMoved] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(true);
  const [isCollabration, setIsCollabration] = useState(true);
  const [hoverActive, setHoverActive] = useState(false);
  const [hoverData, setHoverData] = useState({});
  const [isChangeOwner, setIsChangeOwner] = useState(false);
  const [changeOwnerModal, setChangeOwnerModal] = useState(false);

  const changeOwner = () => {
    setChangeOwnerModal(true);
  };

  const [stringDetail, setStringDetail] = useState([]);
  const [stringData, setStringData] = useState({});
  const [alreadyRun, setAlreadyRun] = useState(false);

  useEffect(() => {
    if (closeMenu) {
      setOpen(false);
    }
  }, [closeMenu]);

  const callDetails = async () => {
    if (!alreadyRun) {
      setAlreadyRun(true);
      const response = await globalRequest(
        "get",
        NEW_API.GET_DETAILS,
        {},
        {
          params: {
            string_id: dataObj.id,
            user_id: auth?.user_id,
            type: "string",
          },
        },
        true
      );

      let data = response.data;

      if (data.status == 1) {
        setStringDetail(data?.data);
        setHoverActive(true);
        setHoverData(data?.data);
      }
    }
  };

  const callString = async () => {
    if (currentActiveString?.id != dataObj?.id) {
      dispatch(changeLoader(true));

      let response = await globalRequest(
        "get",
        NEW_API.GET_SINGLE_STRING,
        {},
        {
          params: {
            user_id: auth?.user_id,
            string_id: dataObj?.id,
          },
        },
        true
      );

      let data = response.data;

      if (data.status == 1) {
        let data1 = data.data;
        callback(data1);
        await postLastActiveString(auth?.user_id, data1.id, data1.string_type, dataObj);
        setStringData(data1);
      }
      dispatch(changeLoader(false));
    }
  };

  useEffect(() => {
    if (type == "shareWithMe" || type == "shareWithMe-1") {
      if (auth?.access == "user") {
        //permission == 0 = edit
        //permission == 1 = view
        if (dataObj.permission == 0) {
          setDropDownShow(false);
          setIsRename(false);
          setIsDelete(false);
          setIsDuplicate(false);
          setIsCollabration(false);
        } else if (dataObj.permission == 1) {
          setDropDownShow(false);
          setIsRename(false);
          setIsDelete(false);
          setIsDuplicate(false);
          setIsCollabration(false);
        }
      } else if (auth?.access == "admin" || auth?.access == "owner") {
        setDropDownShow(true);
        setIsRename(true);
        setIsDelete(true);
        setIsCollabration(true);
        setIsDuplicate(true);
      }
    } else {
      setIsMoved(true);
    }

    if (type == "favorite") {
      setIsDuplicate(false);
    }

    if (type == "shareWithMe-1") {
      setIsChangeOwner(true);
    }
  }, [type]);

  const collaborate = () => {
    setCollaborateOpen(true);
  };
  const move = () => {
    setMoveOpen(true);
  };
  const rename = () => {
    setRenameOpen(true);
  };
  const deletes = () => {
    setDeleteOpen(true);
  };

  const checkBoxAction = (e) => {
    checkboxCallback(dataObj, e);
  };

  const handleClick = () => {
    setOpen((prev) => !prev);
    setHoverActive((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const styles = {
    width: "180px",
    position: "absolute",
    top: 28,
    right: 0,
    left: "unset",
    zIndex: 10,
    border: "1px solid #ddd",
    p: "4px 0",
    bgcolor: "#fff",
    boxShadow: "0 0.2rem 0.4rem rgb(0 0 0 / 10%)",
    borderRadius: 4,
  };
  useEffect(() => {
    setCheckStatus(isCheckboxChecked);
  }, [isCheckboxChecked]);

  const duplicate = async () => {
    const formData = new FormData();
    formData.append("user_id", auth?.user_id);
    formData.append("string_id", dataObj.id);
    let url = REQUEST.DUPLICATE_STRING_REQUEST;
    let res = await globalRequest("post", url, formData, {}, true);
    if (res?.data?.status == 1) {
      dispatch(
        setSnackbar({
          setSnackbarMessage: res?.data?.message,
          setSnackbarOpen: true,
          setSnackbarState: "success",
        })
      );
      callGetFun(true, "addString");
      setOpen(false);
    } else if (res.status == 0) {
      dispatch(
        setSnackbar({
          setSnackbarMessage: res?.data?.message,
          setSnackbarOpen: true,
          setSnackbarState: "error",
        })
      );
    }
  };

  const StringF = () => {
    return (
      <div className="withcheckbox-visible-box" style={{ alignItems: "flex-start" }} onClick={() => handleDropDown()}>
        {hoverActive ? (
          <>
            <div className={`hovermenu`}>
              <MenuItem display={"flex"} alignItems={"center"}>
                <img src={stringIcon} alt="" />
                <LargeSmallSemiBold
                  text={
                    String(hoverData.string_name).length >= 16
                      ? String(hoverData.string_name).substring(0, 15) + "..."
                      : hoverData.string_name
                  }
                  color={Colors.nightGray}
                  padding={"0px 0px 0px 8px"}
                  title={hoverData.string_name}
                />
              </MenuItem>
              <MenuItem display={"flex"} alignItems={"center"}>
                <img src={ownerIcon} alt="" />
                <LargeSmall
                  title={String(hoverData.owner)}
                  text={`Owner: ${
                    String(hoverData.owner).length >= 10
                      ? `${String(hoverData.owner).substring(0, 9)}....`
                      : `${hoverData.owner}`
                  }`}
                  color={Colors.nightGray}
                  padding={"0px 0px 0px 8px"}
                />
              </MenuItem>
              <MenuItem display={"flex"} alignItems={"center"}>
                <img src={membersIcon} alt="" />
                <LargeSmall
                  text={`${hoverData.total_share} other members`}
                  color={Colors.nightGray}
                  padding={"0px 0px 0px 8px"}
                />
              </MenuItem>
              <MenuItem display={"flex"} alignItems={"center"}>
                <img src={editAccessIcon} alt="" />
                <LargeSmall
                  text={hoverData.permission == 0 ? "Edit Access " : "View Access"}
                  color={Colors.nightGray}
                  padding={"0px 0px 0px 10px"}
                />
              </MenuItem>
            </div>
          </>
        ) : null}

        <Boxed
          onClick={callString}
          onMouseOver={callDetails}
          display={"flex"}
          alignItems={"flex-start"}
          justifyContent={"space-between"}
          className={`strings-manage ttc ${smClicked}`}
        >
          {isAutoRichDataAvailable ? <small className="pink-dot"></small> : null}

          <Body
            className={"text-truncate width150"}
            text={dataObj.string_name}
            color={Colors.nightGray}
            padding={"0px 0px"}
          />
          {dropDownShow ? (
            <>
              <ClickAwayListener onClickAway={handleClickAway}>
                <Boxed style={{ position: "relative" }}>
                  <button
                    className="thd-dropdown"
                    id={`bb`}
                    type="button"
                    onClick={() => {
                      if (trialData?.checkPay) {
                        handleClick();
                      } else {
                        dispatch(
                          setSnackbar({
                            snackbarMessage: strings.dontHaveActivePlan,
                            snackbarOpen: true,
                            snackbarState: "error",
                          })
                        );
                      }
                    }}
                  >
                    <img src={threeDottesIcon} alt="" />
                  </button>
                  {open ? (
                    <Box sx={styles} className="drop-box-open">
                      {isChangeOwner ? (
                        <>
                          <MenuItem onClick={changeOwner} display={"flex"} alignItems={"center"}>
                            <Body
                              text={strings.changeOwner}
                              color={Colors.nightPurple}
                              padding={"0px 0px 0px 10px"}
                              className={"weight-body"}
                            />
                          </MenuItem>
                        </>
                      ) : null}

                      {isMoved ? (
                        <>
                          <MenuItem onClick={move} display={"flex"} alignItems={"center"}>
                            <Body
                              text={strings.move}
                              color={Colors.nightPurple}
                              padding={"0px 0px 0px 8px"}
                              className={"weight-body"}
                            />
                          </MenuItem>
                        </>
                      ) : null}

                      {isCollabration ? (
                        <>
                          <MenuItem onClick={collaborate} display={"flex"} alignItems={"center"}>
                            <Body
                              text={strings.collaborate}
                              color={Colors.nightPurple}
                              padding={"0px 0px 0px 8px"}
                              className={"weight-body"}
                            />
                          </MenuItem>
                        </>
                      ) : null}

                      {isRename ? (
                        <>
                          <MenuItem onClick={rename} display={"flex"} alignItems={"center"}>
                            <Body
                              text={strings.rename}
                              color={Colors.nightPurple}
                              padding={"0px 0px 0px 8px"}
                              className={"weight-body"}
                            />
                          </MenuItem>
                        </>
                      ) : null}

                      {isDelete ? (
                        <>
                          <MenuItem onClick={deletes} display={"flex"} alignItems={"center"}>
                            <Body
                              text={strings.delete}
                              color={Colors.nightPurple}
                              padding={"0px 0px 0px 10px"}
                              className={"weight-body"}
                            />
                          </MenuItem>
                        </>
                      ) : null}

                      {isDuplicate ? (
                        <>
                          <MenuItem onClick={duplicate} display={"flex"} alignItems={"center"}>
                            <Body
                              text={strings.duplicate}
                              color={Colors.nightPurple}
                              padding={"0px 0px 0px 10px"}
                              className={"weight-body"}
                            />
                          </MenuItem>
                        </>
                      ) : null}
                    </Box>
                  ) : null}
                </Boxed>
              </ClickAwayListener>
            </>
          ) : null}
        </Boxed>
        {type == "shareWithMe" || type == "shareWithMe-1" ? (
          isEditChecked ? (
            <>
              <Boxed className="" margin={"6px 0 0 8px"}>
                <Checkbox checked={true} disabled {...label} />
              </Boxed>
            </>
          ) : null
        ) : null}

        {type == "favorite" ? (
          isEditChecked ? (
            enableCheckbox ? (
              <>
                <Boxed className="" margin={"6px 0 0 8px"}>
                  <Checkbox checked={checkStatus} onClick={(e) => checkBoxAction(e)} {...label} />
                </Boxed>
              </>
            ) : (
              <>
                <Boxed className="" margin={"6px 0 0 8px"}>
                  <Checkbox checked={true} disabled {...label} />
                </Boxed>
              </>
            )
          ) : null
        ) : null}

        {type == "myString" ? (
          isEditChecked ? (
            enableCheckbox ? (
              <>
                <Boxed className="" margin={"6px 0 0 8px"}>
                  <Checkbox checked={checkStatus} onClick={(e) => checkBoxAction(e)} {...label} />
                </Boxed>
              </>
            ) : (
              <>
                <Boxed className="" margin={"6px 0 0 8px"}>
                  <Checkbox checked={true} disabled {...label} />
                </Boxed>
              </>
            )
          ) : null
        ) : null}
      </div>
    );
  };

  const NoString = () => {
    return (
      <div className="withcheckbox-visible-box">
        <Boxed display={"flex"} alignItems={"center"} justifyContent={"space-between"} className="strings-manage">
          <Body
            className={"text-truncate width150"}
            text={dataObj.string_name}
            color={Colors.nightGray}
            padding={"0px 0px"}
          />
        </Boxed>
      </div>
    );
  };

  useEffect(() => {
    setCollaborateOpen(false);
    setMoveOpen(false);
    setDeleteOpen(false);
    setRenameOpen(false);
  }, [snackbarObj?.snackbarOpen]);

  const callingToggleApi = async (status = 0) => {
    let params = {
      team_id: auth?.team_id,
      string_id: dataObj.id,
      user_id: auth?.user_id,
      type: status,
    };

    const res = await globalRequest("post", KEYWORD_STRING.STRING_TOGGLE_UPDATE, params, {}, true);
    if (res.data.status == 1) {
      // console.log("res ", res.data.status);
      dispatch(changeData(true));
    }
  };

  return (
    <>
      {id != 0 ? <StringF /> : <NoString />}

      {collaborateOpen ? (
        <LetsCollaborate
          closePopup={(param) => {
            setCollaborateOpen(false);
            if (param == "success") {
              callingToggleApi(1);
            }
            callGetFun(true, "dontChange");
          }}
          folderOrStringName={dataObj.string_name}
          folderOrStringId={id}
          type={"string"}
          stringType={type}
        />
      ) : null}

      {moveOpen ? (
        <MoveStringToFolder
          closePopup={() => setMoveOpen(false)}
          stringId={dataObj.id}
          stringName={dataObj.string_name}
          type={"single"}
          callback={(bool, folderIdForRequest) => {
            callGetFun(true, "moveString");
            setOpen(false);
            folderId(folderIdForRequest);
          }}
        />
      ) : null}

      {renameOpen ? (
        <RenameString
          closePopup={() => setRenameOpen(false)}
          stringName={dataObj.string_name}
          stringId={dataObj.id}
          callback={(renameString, bool) => {
            if (bool) {
              callGetFun(true, "renameString");
              setOpen(false);

              if (Object.keys(stringData).length != 0) {
                let l = {
                  ...stringData,
                  string_name: renameString,
                };
                setStringData(l);
              }
            }
          }}
        />
      ) : null}

      {deleteOpen ? (
        <DeleteStrings
          closePopup={() => setDeleteOpen(false)}
          stringId={dataObj.id}
          stringName={dataObj.string_name}
          callback={(bool) => {
            if (bool) {
              callGetFun(true, "deleteString");
              setOpen(false);
            }
          }}
          collaborationCount={stringDetail?.total_share}
        />
      ) : null}

      {changeOwnerModal ? (
        <ChangeStringOwner
          closePopup={() => {
            setChangeOwnerModal(false);
          }}
          userId={auth?.user_id}
          stringFolderData={dataObj}
          type={"string"}
          role={auth?.access}
          callback={() => {
            callGetFun(true, "changeOwner");
          }}
          userInfoForChangeOwner={userInfoForChangeOwner}
        />
      ) : null}
    </>
  );
}

export default Strings;
