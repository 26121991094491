import { createSlice } from "@reduxjs/toolkit";

export const checkSaveStatus = createSlice({
  name: "checkSaveStatus",
  initialState: {
    value: "release",
  },
  reducers: {
    changeSaveStatus: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { changeSaveStatus } = checkSaveStatus.actions;
export const currentSaveStatus = (state) => state.checkSaveStatus.value;
export default checkSaveStatus.reducer;
