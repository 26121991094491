const getOpenClose = (str) => {

    let O = [],
        isQuo = false;
    let C = [];

    for (let i = 0; i < str.length; i++) {
        
        if (str.charAt(i) === "(" && i === 0) O.push(i);
        if (str.charAt(i) === ")" && i === 0) C.push(i);

        if (str.charAt(i) === '"' && i === 0) isQuo = true;

        if (i > 0) {
            if (str.charAt(i) === '"') isQuo = !isQuo;

            if (!isQuo) {
                if (str.charAt(i) === "(") {
                    O.push(i);
                } else if (str.charAt(i) === ")") {
                    C.push(i);
                    if (O.length > 1) {
                        C.pop();
                        O.pop();
                    } else if (O.length === 1) {
                        C.pop();
                        O.pop();
                    }
                }
            }
        }
    }
    return { O, C };
};

export default getOpenClose;