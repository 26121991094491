import { createSlice } from "@reduxjs/toolkit";

export const discountAmount = createSlice({
  name: "discountAmount",
  initialState: {
    value: 0,
  },
  reducers: {
    setDiscountAmount: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setDiscountAmount } = discountAmount.actions;
export const currentDiscountAmount = (state) => state.discountAmount.value;
export default discountAmount.reducer;
