import React, { useState, useEffect } from "react";
import * as Colors from "../../Styles-Elements/Colors";
import { LargeBody, Body } from "../../Styles-Elements/Labels";
import "./KeywordStrings.css";
import { makeStyles } from "@material-ui/core/styles";
import datepickerIconPurple from "../../image-assets/datepickericon-purple.svg";
import dropdownIconLight from "../../image-assets/dropdown-icon-light.svg";
import { Boxed } from "../../Styles-Elements/Box";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import getMonthName from "./../../GlobalModule/getMonthName";
import { KEYWORD_STRING } from "./../../Helpers/Enums";
import { useDispatch, useSelector } from "react-redux";
import { tooledUpLocalData } from "../../redux/reducers/getTooledupData";
import globalRequest from "../../GlobalModule/globalRequest";
import socket from "../../socket";
const strings = require("../../localisation_en.json");

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  root: {
    position: "relative",
  },
  dropdown: {
    position: "absolute",
    top: 35,
    right: 0,
    left: 0,
    zIndex: 10,
    border: "none",
    padding: "0",
    backgroundColor: "transparent",
  },
  dropdownmenu: {
    position: "absolute",
    bottom: 55,
    right: 0,
    left: 0,
    zIndex: 10,
    border: "none",
    boxShadow:
      "0px 0px 15px -3px rgba(0, 0, 0, 0.1), 0px 5px 6px -2px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
    padding: "8px 16px 0px 16px",
    borderRadius: "6px",
    backgroundColor: "#fff",
    overflowX: "hidden",
    overflowY: "auto",
    height: "180px",
  },
  dropdownicons: {
    width: "190px",
    position: "absolute",
    top: 40,
    right: 0,
    left: 0,
    zIndex: 1,
    border: "none",
    boxShadow:
      "0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
    borderRadius: "6px",
    backgroundColor: "#fff",
    padding: "4px",
  },
}));

const RestorePrevious = (props) => {
  const auth = useSelector(tooledUpLocalData);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const classes = useStyles();
  const [open2, setOpen2] = React.useState(false);

  useEffect(() => {
    socket.on("get_list_revision_history", (data) => {
      if (data?.user_id == auth?.user_id) {
        setData(data?.data);
      }
    });
  }, []);

  useEffect(() => {
    if (props.stringId) {
      getApi(props.stringId);
    }
  }, [props.stringId]);
  const getApi = async (stringId) => {
    const response = await globalRequest(
      "get",
      KEYWORD_STRING.GET_REVISION_LIST,
      {},
      {
        params: {
          role: auth?.access,
          user_id: auth?.user_id,
          string_id: stringId,
        },
      },
      true
    );
    if (response.data.status == 1) {
      setData(response.data.data);
    }
  };
  const handleClick2 = () => {
    setOpen2((prev) => !prev);
  };
  const handleClickAway2 = () => {
    setOpen2(false);
  };
  const equalDate = (date1) => {
    let date = new Date(date1);
    let current = new Date();

    let getPmAm = date.getHours() >= 12 ? "PM" : "AM";
    let getHourMinutes = `${date.getHours() > 9 ? date.getHours() : "0" + date.getHours()}:${
      date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes()
    } ${getPmAm}`;
    let newDate = `${date.getDate()} ${getMonthName(date.getMonth())} ${date.getFullYear()} - ${getHourMinutes}`;
    if (
      date.getMonth() == current.getMonth() &&
      date.getDate() == current.getDate() &&
      date.getFullYear() == current.getFullYear()
    ) {
      return `Today - ${getHourMinutes}`;
    } else if (
      date.getMonth() == current.getMonth() &&
      date.getDate() == current.getDate() - 1 &&
      date.getFullYear() == current.getFullYear()
    ) {
      return `Yesterday - ${getHourMinutes}`;
    } else {
      return newDate;
    }
  };

  return (
    <>
      {props?.permission ? (
        <div className="ks-drop-block ks-drop-block-new-border">
          <Body text={strings.restoreToAPreviousVersion} color={Colors.nightPurple} padding={"0px 0 8px 0"} />
          <ClickAwayListener onClickAway={handleClickAway2}>
            <div className={classes.root}>
              <button className="dropdown-holder-style" onClick={handleClick2}>
                <LargeBody text={strings.selectItem} />
                <img src={dropdownIconLight} className="dropdown-icon-holder" alt="" />
              </button>
              {open2 ? (
                <div className={classes.dropdownmenu}>
                  {data.length ? (
                    data.map((obj, index) => {
                      return (
                        <a
                          onClick={() => {
                            props.dataCallback(obj, index);
                          }}
                          key={index}
                          className="ks-drop-select-value"
                          style={{ fontFamily: "Poppins" }}
                        >
                          <Boxed display="flex">
                            <img src={datepickerIconPurple} className="ks-dsv-icon" alt="" />
                            <span className="ks-span1">{equalDate(obj.created_on)}</span>
                          </Boxed>
                          <span className="ks-span2">
                            {obj.user_name == " " || obj.user_name == "" ? obj.user_email : obj.user_name}
                          </span>
                        </a>
                      );
                    })
                  ) : (
                    <>
                      <a className="ks-drop-select-value ks-dsv-cursor" style={{ cursor: "unset !important" }}>
                        <Boxed display="flex">
                          <img
                            src={datepickerIconPurple}
                            className="filter-night-purple ks-dsv-icon ks-dsv-img-cursor"
                            alt=""
                          />
                          <span className="ks-span1">No Record Found.</span>
                        </Boxed>
                      </a>
                    </>
                  )}
                </div>
              ) : null}
            </div>
          </ClickAwayListener>
        </div>
      ) : (
        <div className="ks-drop-block ks-drop-block-new-border">
          <Body text={strings.restoreToAPreviousVersion} color={Colors.nightPurple} padding={"24px 0 8px 0"} />
          <ClickAwayListener onClickAway={handleClickAway2}>
            <div className={classes.root}>
              <button className="dropdown-holder-style">
                <LargeBody text={strings.selectItem} color={"gray"} />
                <img src={dropdownIconLight} className="dropdown-icon-holder" alt="" />
              </button>
            </div>
          </ClickAwayListener>
        </div>
      )}
    </>
  );
};

export default RestorePrevious;
