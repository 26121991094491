import { BaseUrl } from "../BaseUrl";
import { PLANID } from "./Payments";

export const BASE_URL = BaseUrl;

// All popup types the app has
export const POPUP_TYPE = {
  RESET_PASSWORD: "resetPassword",
  FORGOT_PASSWORD: "forgotPassword",
  COUPON_CODE: "couponCode",
  PLAN_UPGRADE: "planUpgrade",
  PLAN_DOWNGRADE: "planDowngrade",
  UPGRADE_TEAM: "upgradeTeam",
  DOWNGRADE_TEAM: "downgradeTeam",
  CHANGE_PASSWORD: "changePassword",
  EDIT_POSTCODE: "editPostcode",
  CANCEL_SUBSCRIPTION: "cancelSubscription",
  ANNUAL_SUBSCRIPTION: "AnnualSubscription",
  DOWNGRADE_CONFIRMATION: "DowngradeConfirmation",
  SHARE_YOUR_INVOICE: "ShareYourInvoice",
  ENOUGH_SEATS: "EnoughSeats",
  AAD_NEW_SQUAD: "addNewSquad",
  CHANGE_PROFILE_DETAIL: "changeProfileDetail",
  MOVE_USERS_NEW_SQUAD: "MoveUsersNewSquad",
  CHANGE_USER_TO_ADMIN: "ChangeUserToBeAnAdmin",
  INVITE_NEW_MEMBERS: "InviteNewMembers",
  COPY_STRING_BETWEEN_COLLEAGE: "CopyStringBetweenColleage",
  LETS_COLLABORATE: "LetsCollaborate",
  DELETE_ALL_STRINGS: "DeleteAllStrings",
  ADD_STRING: "AddString",
  ADD_FOLDER: "AddFolder",
  RENAME_STRING: "RenameString",
  CHANGE_STRING_STYLE: "ChangeStringStyle",
  REVERT_STRING_PREVIOUS_VERSION: "RevertStringPreviousVersion",
  ADD_VARIATION: "AddVariation",
  EXTENSION_POPUP1: "ExtensionPopup",
  CREATE_NEW_STRING: "CreateNewString",
  CREATE_FOLDER: "CreateFolder",
  RENAME_FOLDER: "RenameFolder",
  DELETE_FOLDER_CONTENT: "DeleteFolderContent",
  DELETE_STRINGS: "DeleteStrings",
  DELETE_SELECTED_ITEMS: "DeleteSelectedItems",
  MOVE_STRING_TO_FOLDER: "MoveStringToFolder",
  MOVE_SELECTED_STRING_TO_FOLDER: "MoveSelectedStringToFolder",
  TRANSFER: "Transfer",
  // new popups start
  MONTHLY_SWITCH_PLAN: "MonthlySwitchPlan",
  ANNUAL_SWITCH_PLAN: "AnnualSwitchPlan",
  CANCEL_SUBSCRIPTION_RENEWAL: "CancelSubscriptionRenewal",
  TEAM_GROWING: "TeamGrowing",
  TEAM_DGRADE: "TeamDgrade",
  ENOUGH_SEATS_EVERYONE: "EnoughSeatsEveryone",
};

// Toggle states helping to import a company / keyword string
export const STRING_TYPE = {
  COMPANY_STRING: "CompanyString",
  KEYWORD_STRING: "KeywordString",
};

// Toggle states helping to import a user / admin role
export const ROLE_TYPE = {
  ADMIN: "Admin",
  USER: "User",
};

export const REQUEST = {
  LOGIN_REQUEST: BASE_URL + "api/auth/login",
  SIGNUP_REQUEST: BASE_URL + "api/auth/signup",
  SIGNUP_OTP_REQUEST: BASE_URL + "api/auth/signup_otp",
  CHOOSE_PLAN_REQUEST: BASE_URL + "api/auth/plan_update",
  SIGNUP_DETAIL_REQUEST: BASE_URL + "api/auth/signup_detail",
  CHECKOUT_REQUEST: BASE_URL + "api/auth/add_subscription",
  GETPLAN_REQUEST: BASE_URL + "api/user/get_current_plan",
  PROFILE_UPDATE_REQUEST: BASE_URL + "api/user/upload_profile_image",
  CHANGE_PASSWORD_REQUEST: BASE_URL + "api/user/change_password",
  FORGOT_PASSWORD_REQUEST: BASE_URL + "api/auth/forgot_password",
  RESET_PASSWORD_REQUEST: BASE_URL + "api/auth/reset_password",
  LOGOUT_REQUEST: BASE_URL + "api/auth/logout",
  REJECT_DOMAIN_REQUEST: BASE_URL + "api/auth/reject_emaildomain",
  CANCEL_REQUEST: BASE_URL + "api/user/cancel_subscription",
  UPGRADE_DOWNGRADE_REQUEST: BASE_URL + "api/pay/chg_subscription",
  SHARE_INVOICE_REQUEST: BASE_URL + "api/user/share_subscription_receipt",
  POST_CODE_REQUEST: BASE_URL + "api/user/change_postcode_request",
  UPGRADE_TEAM_REQUEST: BASE_URL + "api/pay/adduser_on_subscription",
  DOWNGRADE_TEAM_REQUEST: BASE_URL + "api/pay/chg_users_subscription",
  GET_COUNTRY_LIST_REQUEST: BASE_URL + "api/auth/get_allcountry",
  CREATE_STRING_REQUEST: BASE_URL + "api/string/create_string",
  GET_ALL_STRING_REQUEST: BASE_URL + "api/string/all_string",
  MARK_FAVORITE_STRING_REQUEST: BASE_URL + "api/string/markfavorites_string",
  DUPLICATE_STRING_REQUEST: BASE_URL + "api/string/duplicate_string",
  RENAME_STRING_REQUEST: BASE_URL + "api/string/update_string_name",
  DELETE_STRING_REQUEST: BASE_URL + "api/string/delete_string",
  CREATE_STRING_NAME_REQUEST: BASE_URL + "api/string/create_string_name",
  GET_TAB_FILL: BASE_URL + "api/auth/get_tab_filled", // @param - user_id
  ONBOARD_TAB_UPDATE: BASE_URL + "api/user/signup_tab_update",
  UPDATE_STRING: BASE_URL + "api/string/update_string",
  USER_DETAIL_SUBSCRIPTION: BASE_URL + "api/pay/userdetail_subscription",
  CHANGE_SUBSCRIPTION: BASE_URL + "api/pay/chg_subscription",
  UPDATE_USER_ACTION: BASE_URL + "api/team/update_useraction",
  GET_SHARED_RECEIPT_USER: BASE_URL + "api/user/get_shared_receipt_users",
  API_KEY: "racoons@user#123",
};

export const ACTION_TYPE = {
  LOGIN: "login",
  LOGOUT: "logout",
  SIGNUP: "signup",
  CHOOSE_PLAN: "choose_plan",
  SIGNUP_DETAIL: "signup_detail",
  PROFILE_UPDATE: "profile_update",
  CHANGE_PASSWORD: "change_password",
  FORGOT_PASSWORD: "forgot_password",
  RESET_PASSWORD: "reset_password",
  REJECT_DOMAIN: "reject_domain",
  REQEST_FAILED: "request_failed",
  TOKEN_EXPIRED: "Token expired",
  CHECKOUT: "CHECKOUT",
  GETPLAN: "GETPLAN",
  CANCEL_PLAN: "CANCEL",
  UPGRADE_DOWNGRADE: "UPGRADE_DOWNGRADE",
  SHARE_INVOICE: "SHARE_INVOICE",
  POST_CODE: "UPDATE_POSTCODE",
  UPGRADE_TEAM: "UPGRADE_TEAM",
  DOWNGRADE_TEAM: "DOWNGRADE_TEAM",
  GET_COUNTRY_LIST: "GET_COUNTRY_LIST",
  CREATE_STRING: "CREATE_STRING",
  GET_ALL_STRING: "GET_ALL_STRING",
  MARK_FAVORITE_STRING: "MARK_FAVORITE_STRING",
  DUPLICATE_STRING: "DUPLICATE_STRING",
  RENAME_STRING: "RENAME_STRING",
  DELETE_STRING: "DELETE_STRING",
  CREATE_STRING_NAME: "CREATE_STRING_NAME",
  UPDATE_ROLE: "UPDATE_ROLE",
};

// New folder file states
export const NEWFILEFOLDER_STATE = {
  FILE_STRINGS: "FileStrings",
  FOLDER_STRING: "FolderStrings",
};

export const NEW_PLAN_ID = PLANID;

export const OLD_PLAN_ID = {
  MONTHLY: 651104,
  YEARLY: 651105,
};

export const FREE_PLAN_ID = {
  MONTHLY_FREE: 17506,
  YEARLY_FREE: 17507,
};

//new code
export const PLAN_AMOUNT = {
  OLD: {
    YEARLY: 89.88,
    MONTHLY: 14.99,
  },
  NEW: {
    YEARLY: 300,
    MONTHLY: 25,
  },
  MOST_NEW: {
    YEARLY: 395,
    MONTHLY: 49,
  },
  MOST_NEW_ONE: {
    YEARLY: 300,
    MONTHLY: 34,
  },
};

// Payment plan types
export const PAYMENT_PLAN_TYPE = {
  ANNUAL_PRO: "annual",
  MONTHLY_PRO: "monthly",
  FREE: "Free",
};

export const PLAN_TYPE = {
  ANNUAL: "annual",
  MONTHLY: "monthly",
  ENTERPRISE: "Enterprise",
};
// Toggle states helping to invite a members
export const INVITE_TYPE = {
  INVITE_BY_EMAIL: "InviteByEmail",
  IMPORT_MEMBERS: "ImportMembers",
};

export const USER_TYPE = {
  EXISTING_USER: "AnExistingUser",
  NEW_USER: "ANewUser",
};

// Toggle states helping to builf preview
export const BUILDPREVIEW_TYPE = {
  BUILD: "InviteByEmail",
  PREVIEW: "ImportMembers",
};

// Toggle states helping to add folder add string
export const FOLDER_STRING_TYPE = {
  SELECT_FOLDER: "SelectFolder",
  SELECT_STRING: "SelectString",
};

// Toggle states helping to select view
export const KEYWORD_VIEW_TYPE = {
  BUILDER_VIEW: "BuilderView",
  ADVANCED_VIEW: "AdvancedView",
  STANDARD_VIEW: "StandardView",
};

// Toggle states helping to select view
export const VERSION_TYPE = {
  VERSION_1: "Version1",
  VERSION_2: "Version2",
  VERSION_3: "Version3",
  VERSION_4: "Version4",
};

// Sidebar states
export const SIDEBAR_STATE = {
  COMPANY_STRINGS: "CompanyStrings",
  KEYWORD_STRINGS: "KeywordStrings",
  IMPORT_STRINGS: "ImportStrings",
  MANAGE_USERS: "ManageUsers",
  SETTINGS: "Settings",
  ACADEMY: "Academy",
  POWERUP: "PowerUp",
  GADGETS: "Gadgets",
  DASHBOARD: "Dashboard",
};

export const SIDEBAR_STATE_FOR_LOCAL_STORAGE = {
  COMPANY_STRINGS: "company",
  KEYWORD_STRINGS: "keyword",
  IMPORT_STRINGS: "importString",
  MANAGE_USERS: "manageUser",
  SETTINGS: "setting",
  ACADEMY: "academy",
  POWERUP: "powerUp",
  GADGETS: "gadget",
  DASHBOARD: "dashboard",
};

//zaid hasan
//24-feb-2022

export const API_TEAM = {
  INVITE_TEAMMATE: BASE_URL + "api/team/invite_teammate",
  GET_REMAIN_USER: BASE_URL + "api/team/get_total_user",
  GET_ALL_TEAM_USER: BASE_URL + "api/team/all_team_users",
  CHANGE_ROLE_STATUS: BASE_URL + "api/team/updateteam_role",
  RESEND_INVITE: BASE_URL + "api/team/resend_invitation",
  SINGLE_EXPORT: BASE_URL + "api/team/exportuser_string",
};

export const MANAGE_TEAM = {
  export_data: BASE_URL + "api/team/exportmultiuser_string",
  delete_user: BASE_URL + "api/team/delete_multiuser",
  delete_sing_user: BASE_URL + "api/team/delete_singleuser",
  upload_csv: BASE_URL + "api/team/teammate_import",
  get_csv: BASE_URL + "uploads/sample_file.csv",
  get_single_user: BASE_URL + "api/user/user_detail",
};

export const KEYWORD_STRING = {
  CREATE_FOLDER: BASE_URL + "api/folder/create_folder",
  GET_ALL_STRING_FOLDER: BASE_URL + "api/string/all_string_listing",
  STRING_FROM_FOLDER: BASE_URL + "api/string/allstring_fromfolder",
  UPDATE_FOLDER_NAME: BASE_URL + "api/folder/update_folder_name",
  DELETE_FOLDER: BASE_URL + "api/folder/delete_folder",
  TEAM_USER: BASE_URL + "api/folder/all_team_users",
  COLLABORATE: BASE_URL + "api/string/collaborate_stringfolder",
  GET_FOLDER: BASE_URL + "api/folder/all_folders",
  MOVE_STRING: BASE_URL + "api/folder/movestring_tofolder",
  RENAME_STRING: BASE_URL + "api/string/update_string_name",
  DELETE_STRING: BASE_URL + "api/string/delete_string",
  DELETE_MULTI_STRING_FOLDER: BASE_URL + "api/folder/deletemulti_stringfolder",
  GET_STRING_DETAILS: BASE_URL + "api/folder/getdetail_stringfolder",
  GET_COLLABORATION_LIST: BASE_URL + "api/team/get_collaborate_stringuser",
  STRING_TOGGLE_UPDATE: BASE_URL + "api/string/update_stringtoggle",
  STRING_TOGGLE_GET: BASE_URL + "api/string/get_stringtoggle",
  GET_STRING_DATA_FOR_SOCKET: BASE_URL + "api/string/get_stringsocket",
  GET_COUNT_STRING_FOLDER_COLLABORATION: BASE_URL + "api/string/get_collaborators_count",
  GET_REVISION_LIST: BASE_URL + "api/string/all_revision_string",
  REVISION_HISTORY: BASE_URL + "api/string/restore_revision_string",
  STRING_ID_DATA: BASE_URL + "api/extension/get_string_data",
  GET_ALL_STRING_OWNER: BASE_URL + "api/string/all_string_listing_owner",
  CHANGE_OWNERSHIP_STRING: BASE_URL + "api/folder/changeowner_folderstring",
};

export const IMPORT_EXPORT_STRING = {
  IMPORT_STRING: BASE_URL + "api/importexport/import_string",
  TRANSFER_DATA_EXISTING_USER: BASE_URL + "api/team/transfer_string_delete_users",
  TRANSFER_DATA_NEW_USER: BASE_URL + "api/team/transfer_invite_teammate",
};

export const API_TRANSLATION = {
  DEDUCT_CREDIT: BASE_URL + "api/translate/translate_credit_action",
  GET_TRANSLATION_CREDIT: BASE_URL + "api/translate/get_credit_detail",
  GET_RECRUITER: BASE_URL + "api/translate/get_team_listing_withcredit",
  BATCH_FILE_SAVE: BASE_URL + "api/translate/savefile_forbatch_language",
  BATCH_FILE_STATUS_UPDATE: BASE_URL + "api/translate/update_batchfile_status",
  BATCH_FILE_RESPONSE: BASE_URL + "api/translate/batch_language_translation_response",
  READ_GLOSSARY_FILE: BASE_URL + "api/cron/get_glossary_data",
  SAVE_GLOSSARY_FILE: BASE_URL + "api/cron/save_translation_in_csv_file",
  SAVE_GET_SOURCE_lANGUAGE: BASE_URL + "api/translate/language_action",
  SAVE_FAVOURITE_lANGUAGE: BASE_URL + "api/translate/language_favourite_action",
  GET_FAVOURITE_lANGUAGE: BASE_URL + "api/translate/get_favourite_language",
  TRANSLATE_OPEN_AI_CALL: BASE_URL + "api/translate/translate-open-ai-call",
};

export const STORAGE = {
  USER_DATA: "de2FBnZbkw",
  TOOLED_UP_LOCAL_DATA: "7Ze38z97Ob",
  TEMPORARY_DATA: "YFKUgeVdXn",
  PLAN_TYPE: "1K92M9iuYy",
  USE_LESS_DATA: "cXMBwJ1lx7",
  SELECTION_TYPE: "dl2pMC6m8G",
  GET_CURRENT_PLAN: "KA5Unm0ZUr",
  FOLDER_DATA_KEYWORD: "quxgAHznrp",
  FOLDER_DATA_COMPANY: "tyRXKcnf1a",
  STRING_COLLECTION: "wSXSf7ULJr",
  FOLDER_DROPDOWN: "slTAVy3EVn",
  FOR_MAIN_DROPDOWN_COMPANY: "bzELNlM5Og",
  FOR_MAIN_DROPDOWN_KEYWORD: "N8PkIpA1Nu",
  NEW_SIDE_BAR: "1k7E4uNycX",
  FIRST_LOGIN: "nUiQCtdZow",
  GET_ALL_STRING_KEYWORD: "a9eSnJcqdv",
  GET_ALL_STRING_COMPANY: "0htYF7aZfD",
  VIEW_TYPE: "noJB75iw7Q",
  STRING_ID: "F9XRojOkGv",
  FOR_MAIN_ACTIVE_DROPDOWN_KEYWORD: "N8PkIpA1N1u",
  SETTING: "U656ghfgwW",
  ACTIVETRANSLATE: "U65ERWER6ghfgwW",
  BOO_THE_BOOLEAN: "cXMBwEfgwW",
  STORE_SELECTED_LANG: "EfgwUERfgw",
  EXPORT_LOGIN: "UERfgfgwEf",
};

export const NEW_API = {
  GET_ALL_STRING: BASE_URL + "api/string/get_string_listing",
  GET_SINGLE_STRING: BASE_URL + "api/extension/get_string_data",
  GET_DETAILS: BASE_URL + "api/string/get_string_dataobject",
  FOLDER_USER_LIST: BASE_URL + "api/folder/all_userslist_containfolder",
  FOLDER_LIST: BASE_URL + "api/folder/user_folderlist",
  ADD_QUOTES: BASE_URL + "api/extension/get_string_with_quotes",
  LAST_INTERACTION: BASE_URL + "api/extension/update_last_interaction",
  COPY_COMPANY_KEYWORD: BASE_URL + "api/deconstruct/get_stringdata", // user_id , type , string_id
  ADD_LOCAL_STORAGE: BASE_URL + "api/deconstruct/action_localdata", //  user_id , type , data
  GET_LOCAL_STORAGE: BASE_URL + "api/deconstruct/get_localstoragedata", // user_id , type
  SWITCH_PLAN: BASE_URL + "api/pay/change_subscription_delay",
  TEAM_OVERVIEW: BASE_URL + "api/dashboardacademy/get_team_overview",
  INDIVIDUAL_OVERVIEW: BASE_URL + "api/dashboardacademy/get_individual_overview",
  ACADEMY_GET_CHAPTER_AND_LESSON: BASE_URL + "api/dashboardacademy/get_all_chapter_lessons",
  ACADEMY_FAVORITE_UNFAVORITE: BASE_URL + "api/dashboardacademy/favourite_lessons_action",
  ACADEMY_LESSON_COMPLETE_ACTION: BASE_URL + "api/dashboardacademy/lessons_complete_action",
  ACADEMY_GET_NOTE: BASE_URL + "api/dashboardacademy/get_lesson_note",
  ACADEMY_ASK_QUESTION: BASE_URL + "api/dashboardacademy/add_question",
  ACADEMY_PROGRESS_BAR: BASE_URL + "api/dashboardacademy/get_academy_progress",
  ACADEMY_DOWNLOAD_NOTE: BASE_URL + "api/dashboardacademy/generate_pdf",
  ACADEMY_POST_LAST_VIEWED_NOTE: BASE_URL + "api/dashboardacademy/update_lastview_lesson",
  ACADEMY_GET_LAST_VIEWED_NOTE: BASE_URL + "api/dashboardacademy/get_lastview_lesson",
  BUG_REPORT: BASE_URL + "api/cron/report_bug",
  INVOICES: (userId) => BASE_URL + `api/pay/invoices/${userId}`,
};

export const AUTO_BUILD = {
  UPDATE_STRING: BASE_URL + "auto-build/string-update",
  AUTO_BUILD_OPEN_AI: BASE_URL + "auto-build/auto-build-first-feature",
  CHECK_RACOON_INTEL: BASE_URL + "auto-build/check-intel-racoons",
  UPDATE_INTEL_ON_CONFIRM: BASE_URL + "auto-build/update-intel-keyword-on-confirm",
  GET_AUTO_ENRICH_DATA: BASE_URL + "auto-build/get-auto-enrich-data",
  GET_AUTOENRICH_DATA: BASE_URL + "auto-build/get-autoenrich-data",
};

export const UNIQUE_DOM_ID = "FXRojOkGv";
export const MSG_DISPLAY = "KW5oOmzRLL";
export const MSG = "ZXMl7F58qK";

export const COLORS = {
  QUOTES_COLOR_HTML: "#F3862D",
  QUOTES_COLOR_RGB: "rgb(243, 134, 45)",
};

export const MAIN_TEXT = "m4kJ-4nAH-4oIFTA";
export const ADVANCE_KEYWORD_ID = "OPDo4oIFTA-kQ4vuc0zII";
export const TEXT_INPUT = "wwDVtmnKTJ";

export const MISSING_OPERATOR_ERROR = {
  OPERATOR: "Missing Operator",
  OPEN_PARA: "Oops, you missed an opening bracket",
  CLOSE_PARA: "Don’t forget to add a closing bracket",
  ONLY_QUOTES: "Heads up, i've identified duplicate quotation marks",
  QUOTATION: "Uh o, you missed a quotation mark",
  DUPLICATE: "Heads up, I have found duplicate operators",
  CANNOT_START_OPERATOR: {
    AND: "Your search string cannot start with the AND operator",
    OR: "Your search string cannot start with the OR operator",
  },
  CANNOT_END_OPERATOR: {
    AND: "Your search string cannot end with the AND operator",
    OR: "Your search string cannot end with the OR operator",
    NOT: "Your search string cannot end with the NOT operator",
  },
  INITIAL_AND_NOT: "Missing AND / NOT operator at start of line",
  TWO_QUOTATION_MARK: "Heads up, I have identified duplicate quotation marks",
};

export const LEARN_MORE_VIDEO_URL = "https://www.veed.io/view/4765bad3-0fb5-40b7-8fc3-9df7340c1cbf?panel=share";

export const NEW_MODULE_API = {
  SIGNUP_DETAILS: BASE_URL + "api/home/signup-details",
  GET_CREDIT: (userId) => BASE_URL + `api/home/get-credit/${userId}`,
  GET_TRIAL: (userId) => BASE_URL + `api/home/get-trial/${userId}`,
  CHECK_REMAINING_CREDIT: (userId, type) => BASE_URL + `api/home/check-remaining-credit/${userId}/${type}`,
  GET_ALL_CREDIT: (userId, access) => BASE_URL + `api/home/get-all-credit/${userId}/${access}`,
  UPDATE_STRING_AND_KEYWORD_SUGGESTION: BASE_URL + `api/home/update-string-and-keyword-suggestion`,
  UPDATE_ENRICH_ALERT: BASE_URL + `api/home/update-enrich-alert`,
  GET_ALERT: (userId) => BASE_URL + `api/home/get-alert/${userId}`,
  UPDATE_TOKEN: BASE_URL + `api/home/update-token`,
};

export const ON_TRIAL_STATUS = {
  TRIAL: "trial",
  TRIAL_COMPLETED: "trial_completed",
  PAYMENT_ACCEPTED: "payment_accepted",
  PAYMENT_DECLINED: "payment_declined",
};

// m4kJvE4nAH
// 7H5jmSfEU4
// ZXMl7F58qK
// MQ2jbaoGz1
// o4OKbDETft
// objt2WMZhB
// tWLNUxUyUf
// OPDo4oIFTA
// iIQxR5jBkZ
// kQ4vuc0zII
// SKzGl26k77
// l44Icnu1ez
// fysYhsmQeJ
// 1PcLdMp4G9
// UZke0C7Qp1
// 4fJM0rQOK9
// 0X3lY3f6gM
// nlNzwNxVMj
// xxHi7AxOAN
// Jvipb22oe8
// wwDVtmnKTJ
// u7GLaQm8r4
// pRQtAugdQE
// 8yBv29vS85
// LBYIpEBv9v
// KuEfohbqdT
// JxLfM6V8Mh
// wxprAjZUsE
// GCncKC8vti
