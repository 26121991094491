import React, { Component } from 'react'
import * as Colors from './Colors'
import * as FontStyles from './FontStyles'

import styled, { css, keyframes } from 'styled-components/macro'

export const ButtonWrapper = styled.div`
  position: relative;
  margin: ${props => props.margin};
  width: 100%;

`;


const fadeInKeyframe = keyframes`
  from {opacity: 0.1;} 
  to {opacity: 1;}
`;

const animation = props =>
  css`
    ${fadeInKeyframe} 0.6s linear;
  `

const ButtonProvider = styled.button`
  font-family: ${props => FontStyles.getFontFamily(props.theme)};
  font-weight: ${props => FontStyles.getFontWeight(props.theme)};
  font-size: ${props => FontStyles.getFontSize(props.theme)};
  padding: ${props => props.padding};
  margin: ${props => props.margin};
  flex-grow: ${props => props.flexGrow};
  background-color: ${props => props.backgroundColor};
  border-radius: ${props => props.borderRadius};
  border-width: ${props => props.borderWidth};
  border-color: ${props => props.borderColor};
  color: ${props => props.color};
  text-align: ${props => props.textAlign};
  width: ${props => props.width};
  white-space: ${props => props.whiteSpace};
  text-decoration: ${props => props.textDecoration};
  cursor: pointer;
  &:focus {
    animation: ${props => props.fadeIn === true ? animation : ''};
    outline: none;
  }
`; 

ButtonProvider.defaultProps = {
  theme: FontStyles.body,
  padding: 'initial',
  mobilePadding: 'initial',
  mobileMargin: 'initial',
  margin: 'initial',
  flexGrow: 0,
  backgroundColor: 'initial',
  borderRadius: '0',
  borderWidth: '0',
  borderColor: 'initial',
  borderRadius: '0',
  color: 'black',
  textAlign: 'center',
  width: '100%',
  mobileWidth: 'auto',
  fadeIn: 'false'
}


/**
 * Represents a Bold button with 
 * font size: 20px
 * font name: Poppins
 * font weight: Bold
 * background color: Dark Purple
 * padding: 16px 40px
 */
export function LargeRounded(props) {
  return <ButtonProvider theme={FontStyles.subtitleBold} 
            padding={'16px 40px'} 
            margin={props.margin}
            flexGrow={props.flexGrow}
            backgroundColor={Colors.darkPurple}
            color={Colors.mainLight}
            borderRadius={'32px'}
            borderWidth={'0px'}
            textAlign={props.width}
            width={props.width}
            fadeIn={props.fadeIn}
            onClick={props.onClick}
            mobileWidth={props.mobileWidth}
            mobileMargin={props.mobileMargin}
            >
              {props.text}
          </ButtonProvider>
}

/**
 * Represents a Medium button with 
 * font size: 16px
 * font name: Poppins
 * font weight: Medium
 * background color: Dark Purple
 * padding: 12px 24px
 */
export function MainRoundedButton(props) {
  return <ButtonProvider theme={FontStyles.largeBody} 
            padding={props.padding ? props.padding : '11px 24px'} 
            margin={props.margin}
            flexGrow={props.flexGrow}
            backgroundColor={ props.backgroundColor ? props.backgroundColor : Colors.darkPurple}
            color={Colors.mainLight}
            borderRadius={props.borderRadius ? props.borderRadius : '24px'}
            borderWidth={'0px'}
            textAlign={props.width}
            width={props.width}
            fadeIn={props.fadeIn}
            onClick={props.onClick}
            mobileWidth={props.mobileWidth}
            mobileMargin={props.mobileMargin}
            className={props.className}
            style={props.style}
            type={props.type}
            disabled={props.disabled}
            id={props.id}
            >
              {props.text}
          </ButtonProvider>
}

/**
 * Represents a Medium button with 
 * font size: 16px
 * font name: Poppins
 * font weight: Medium
 * background color: Bright Green
 * padding: 12px 24px
 */
export function SecondaryRoundedButton(props) {
  return <ButtonProvider theme={FontStyles.largeBody} 
            padding={'11px 24px'} 
            margin={props.margin}
            flexGrow={props.flexGrow}
            backgroundColor={Colors.brightGreen}
            color={Colors.nightPurple}
            borderRadius={'24px'}
            borderWidth={'0px'}
            textAlign={props.width}
            width={props.width}
            fadeIn={props.fadeIn}
            onClick={props.onClick}
            mobileWidth={props.mobileWidth}
            mobileMargin={props.mobileMargin}
            disabled={props.disable}
            type={props.type}
            >
              {props.text}
          </ButtonProvider>
}

/**
 * Represents a Medium button with 
 * font size: 16px
 * font name: Poppins
 * font weight: Medium
 * background color: Bright Green
 * padding: 12px 24px
 */
export function CustomRoundedButton(props) {
  return <ButtonProvider theme={props.theme ? props.theme : FontStyles.largeBody}
            padding={props.padding ? props.padding : '11px 24px'} 
            margin={props.margin}
            flexGrow={props.flexGrow}
            backgroundColor={props.backgroundColor}
            color={props.color}
            borderRadius={props.borderRadius ? props.borderRadius : '24px'}
            borderWidth={'0px'}
            textAlign={props.width}
            width={props.width}
            fadeIn={props.fadeIn}
            onClick={props.onClick}
            mobileWidth={props.mobileWidth}
            mobileMargin={props.mobileMargin}
            whiteSpace={props.whiteSpace ? props.whiteSpace : 'nowrap'}
            style={props.stylebutton}
            disabled={props.disable}
            className={props.className}
            >
              {props.text}
          </ButtonProvider>
}

/**
 * Represents a Medium button with 
 * font size: 16px
 * font name: Poppins
 * font weight: Medium
 * background color: Clear
 * border color: Clear
 * padding: 12px 24px
 */
export function BorderedRoundedButton(props) {
  return <ButtonProvider theme={FontStyles.largeBody} 
            padding={props.padding ? props.padding : '11px 24px'}
            margin={props.margin}
            flexGrow={props.flexGrow}
            borderColor={props.borderColor ? props.borderColor : Colors.darkPurple}
            color={props.color ? props.color : Colors.darkPurple}
            borderRadius={'24px'}
            borderWidth={'1px'}
            textAlign={props.width}
            width={props.width}
            fadeIn={props.fadeIn}
            onClick={props.onClick}
            mobileWidth={props.mobileWidth}
            mobileMargin={props.mobileMargin}
            className={props.className}
            >
              {props.text}
              {props.img}
          </ButtonProvider>
}

/**
 * Represents a Medium button with 
 * font size: 16px
 * font name: Poppins
 * font weight: Medium
 * background color: Clear
 * border color: Clear
 * padding: 8px 8px
 */
export function ClearButton(props) {
  return <ButtonProvider theme={props.theme ? props.theme : FontStyles.largeBody} 
            padding={props.padding ? props.padding : '8px 8px'}
            margin={props.margin}
            color={props.color ? props.color : Colors.darkGray}
            flexGrow={props.flexGrow}
            textAlign={props.textAlign}
            width={props.width}
            fadeIn={props.fadeIn}
            onClick={props.onClick}
            mobileWidth={props.mobileWidth}
            mobileMargin={props.mobileMargin}
            className={props.className}
            id={props.id}
            >
              {props.text}
          </ButtonProvider>
}

/**
 * Represents a Medium button with 
 * font size: 10px
 * font name: Poppins
 * font weight: Medium
 * background color: Clear
 * border color: Dark Purple
 * padding: 8px 32px
 */
export function BorderedSmallRoundedButton(props) {
  return <ButtonProvider theme={props.theme ? props.theme : FontStyles.small} 
            padding={props.padding ? props.padding : '8px 32px'}
            margin={props.margin}
            color={props.color ? props.color : Colors.darkPurple}
            borderColor={props.borderColor ? props.borderColor : Colors.darkPurple}
            borderWidth={'1px'}
            borderRadius={'16px'}
            flexGrow={props.flexGrow}
            textAlign={props.textAlign}
            width={props.width}
            fadeIn={props.fadeIn}
            onClick={props.onClick}
            mobileWidth={props.mobileWidth}
            mobileMargin={props.mobileMargin}
            >
              {props.text}
          </ButtonProvider>
}




/**
 * Represents a Medium button with 
 * font size: 16px
 * font name: Poppins
 * font weight: Medium
 * background color: Clear
 * border color: Dark Purple
 * padding: 8px 32px
 */
 export function CreateButton(props) {
  return <ButtonProvider theme={FontStyles.largeBody} 
            padding={props.padding ? props.padding : '8px 32px'}
            margin={props.margin}
            backgroundColor={Colors.sharpGreen}
            color={props.color ? props.color : Colors.darkPurple}
            borderColor={props.borderColor ? props.borderColor : Colors.darkPurple}
            borderWidth={'0px'}
            borderRadius={'8px'}
            flexGrow={props.flexGrow}
            textAlign={props.textAlign}
            width={props.width}
            fadeIn={props.fadeIn}
            onClick={props.onClick}
            mobileWidth={props.mobileWidth}
            mobileMargin={props.mobileMargin}
            className={props.className}
            >
              {props.text}
          </ButtonProvider>
}


/**
 * Represents a Medium button with 
 * font size: 14px
 * font name: Poppins
 * font weight: Medium
 * background color: white Gray
 * border color: white Gray
 * padding: 8px 32px
 */
 export function EditMemberButton(props) {
  return <ButtonProvider theme={FontStyles.body} 
            padding={props.padding ? props.padding : '6px 24px'}
            margin={props.margin}
            backgroundColor={props.backgroundcolor ? props.backgroundcolor : Colors.whiteGray}
            color={props.color ? props.color : Colors.nightPurple}
            borderColor={props.borderColor ? props.borderColor : Colors.whiteGray}
            borderWidth={'0px'}
            borderRadius={'16px'}
            flexGrow={props.flexGrow}
            textAlign={props.textAlign}
            width={props.width}
            fadeIn={props.fadeIn}
            onClick={props.onClick}
            mobileWidth={props.mobileWidth}
            mobileMargin={props.mobileMargin}
            >
              {props.text}
          </ButtonProvider>
}