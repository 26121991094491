export const white = "#FFFFFF";
export const whiteGray = "#F5F6F9";
export const whiteMidGray = "#EBEBF0";
export const lightPurple = "#EDE6FC";
export const darkPurple = "#4A0FA3";
export const lightGray = "#D9D9D9";
export const darkGray = "#8F8F8F";
export const nightPurple = "#261052";
export const nightPurple2 = "#290B55";
export const brightGreen = "#73E0A7";
export const mainLight = "#FEFEFE";
export const lightGreen = "#d9fbf1";
export const sharpGreen = "#00E4A2";
export const nightGray = "#373737";
export const lightRed = "#EB5757";
export const darkPurpleGray = "#757478";
export const softGray = "#F1F1F1";
export const darkestGray = "#7A7A7A";
export const black = "#030303";
export const darkGray01 = "#8c8c8c";
