import { createSlice } from "@reduxjs/toolkit";

export const paddleAmount = createSlice({
  name: "paddleAmount",
  initialState: {
    value: {
      total: 0,
      tax: 0,
      grandTotal: 0,
    },
  },
  reducers: {
    setPaddleAmount: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setPaddleAmount } = paddleAmount.actions;
export const currentAmount = (state) => state.paddleAmount.value;
export default paddleAmount.reducer;
