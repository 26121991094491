import { useCallback, useEffect, useState } from "react";
import * as Colors from "../../../Styles-Elements/Colors";
import { Boxed } from "../../../Styles-Elements/Box";
import { Body } from "../../../Styles-Elements/Labels";
import { CustomRoundedButton } from "../../../Styles-Elements/Buttons";
import "./NewAcademySidebar.css";
import downloadBlackIcon from "../../image-assets/download-black.svg";
import searchIcon from "../../image-assets/searchicon.svg";
import searchCloseIcon from "../../image-assets/searchclose.svg";
import Chapter from "./Chapter";
import strings from "../../../localisation_en.json";
import globalRequest from "../../../GlobalModule/globalRequest";
import { NEW_API } from "../../../Helpers/Enums";
import { useDispatch, useSelector } from "react-redux";
import { tooledUpLocalData } from "../../../redux/reducers/getTooledupData";
import { changeLoader } from "../../../redux/reducers/loader";
import { currentLikeDislike } from "../../../redux/reducers/likeDislikeChange";
import { changeLessonData } from "../../../redux/reducers/lessonData";
import { setSnackbar } from "../../../redux/reducers/snackbar";
import { InputWrapper, TextSearchfield } from "../../../Styles-Elements/Inputs";
const NewAcademySidebar = (props) => {
  const [chapterLessonData, setChapterLessonData] = useState([]);
  const [chapterLessonSearch, setChapterLessonSearch] = useState([]);
  const auth = useSelector(tooledUpLocalData);
  const dispatch = useDispatch();
  const likeDislike = useSelector(currentLikeDislike);
  const [academyProgress, setAcademyProgress] = useState("0%");
  const [progressColor, setProgressColor] = useState("red");
  const [expanded, setExpanded] = useState(null);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (search) {
      let regex = new RegExp(String(search).toLowerCase(), "g");
      let a = [];
      chapterLessonSearch.forEach((obj) => {
        let lessons = [];
        obj.lessons.forEach((obj1) => {
          let name = String(obj1.name).toLowerCase();
          if (name.match(regex)) {
            lessons.push(obj1);
          }
        });
        if (lessons.length) {
          a.push({
            ...obj,
            lessons: lessons,
          });
        }
      });
      setChapterLessonData(a);
    } else {
      setChapterLessonData(chapterLessonSearch);
    }
  }, [search]);

  const progressBarApi = useCallback(() => {
    dispatch(changeLoader(true));
    globalRequest(
      "get",
      NEW_API.ACADEMY_PROGRESS_BAR,
      {},
      {
        params: {
          user_id: auth?.user_id,
          team_id: auth?.team_id,
        },
      },
      true
    )
      .then((res) => {
        let data = res?.data;
        if (data?.status == 1) {
          setAcademyProgress(`${data?.data?.academy_progress}`);
        }
        dispatch(changeLoader(false));
      })
      .catch((err) => {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: "something went wrong ",
            snackbarState: "error",
          })
        );
        dispatch(changeLoader(false));
      });
  });

  const chapterLessonApi = useCallback(() => {
    // dispatch(changeLoader(true));
    globalRequest(
      "get",
      NEW_API.ACADEMY_GET_CHAPTER_AND_LESSON,
      {},
      {
        params: {
          user_id: auth?.user_id,
          team_id: auth?.team_id,
        },
      },
      true
    )
      .then((res) => {
        let data = res.data;
        if (data?.status == 1) {
          setChapterLessonData(data?.data);
          setChapterLessonSearch(data?.data);
        }
        // dispatch(changeLoader(false));
      })
      .catch((err) => {
        // dispatch(changeLoader(false));
      });
  });

  const downloadNotesApi = useCallback(() => {
    dispatch(changeLoader(true));
    globalRequest(
      "get",
      NEW_API.ACADEMY_DOWNLOAD_NOTE,
      {},
      {
        params: {
          user_id: auth?.user_id,
        },
      },
      true
    )
      .then((res) => {
        let data = res.data;
        if (data?.status == 1) {
          let a = document.createElement("a");
          a.href = data?.data?.document;
          a.download = true;
          a.setAttribute("target", "_blank");
          a.click();
        }
        dispatch(changeLoader(false));
      })
      .catch((err) => {
        dispatch(changeLoader(false));
      });
  });

  const getLastViewedNote = useCallback(() => {
    // dispatch(changeLoader(true));
    globalRequest(
      "get",
      NEW_API.ACADEMY_GET_LAST_VIEWED_NOTE,
      {},
      {
        params: {
          user_id: auth?.user_id,
        },
      },
      true
    )
      .then((res) => {
        let data = res.data;

        if (data?.status == 1) {
          let datas = data?.data;
          if (Object.keys(datas).length == 0) {
            let lesson = chapterLessonData[1]?.lessons[0];
            dispatch(
              changeLessonData({
                ...lesson,
                chapter_id: chapterLessonData[1].id,
              })
            );
            setExpanded(1);
          } else {
            dispatch(changeLessonData(datas));
            setExpanded(datas?.chapter_id - 1);
          }
        }
        // dispatch(changeLoader(false));
      })
      .catch((err) => {
        // dispatch(changeLoader(false));
      });
  });

  useEffect(() => {
    if (chapterLessonData.length) {
      if (!search) {
        getLastViewedNote();
      }
    }
  }, [chapterLessonData, search]);

  useEffect(() => {
    let progressCount = academyProgress;
    if (progressCount >= 50 && progressCount <= 99) {
      setProgressColor("orange");
    } else if (progressCount == 100) {
      setProgressColor("green");
    }
  }, [academyProgress]);

  useEffect(() => {
    chapterLessonApi();
    progressBarApi();
  }, []);

  useEffect(() => {
    if (likeDislike !== null) {
      setChapterLessonData(likeDislike);
      progressBarApi();
    }
  }, [likeDislike]);

  return (
    <Boxed display={"flex"} flexDirection={"column"} justifyContent={"space-between"} className="academy-sidebar">
      <Boxed display={"flex"} flexDirection={"column"} className="academy-sidebar-header">
        <Body text={strings.yourProgress} color={Colors.nightPurple} padding={"0 0 8px 0"} />
        <div className="progress-bar bar-new-layout">
          <div
            className="progress-inner inner-new-layout"
            style={{
              width: `${academyProgress <= 12 ? 12 : academyProgress}%`,
              backgroundColor: progressColor,
            }}
          >
            {academyProgress}%
          </div>
        </div>
        <div>
          <InputWrapper>
            <TextSearchfield
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search chapters & lessons"
              backgroundColor={Colors.whiteGray}
            />
            <button className="searchbtn">
              <img src={searchIcon} className="" alt="" />
            </button>
            {search ? (
              <button className="searchclosebtn">
                <img
                  onClick={() => {
                    setSearch("");
                    setChapterLessonData(chapterLessonSearch);
                  }}
                  src={searchCloseIcon}
                  className=""
                  alt=""
                />
              </button>
            ) : null}
          </InputWrapper>
        </div>
      </Boxed>
      <div className="academy-sidebar-manage-strings-section">
        {chapterLessonData.length ? (
          chapterLessonData.map((obj, ind) => {
            return (
              <Chapter
                key={ind}
                obj={obj}
                ind={ind}
                handleToggle={() => {
                  if (expanded == ind) {
                    setExpanded(null);
                  } else {
                    setExpanded(ind);
                  }
                }}
                expanded={search ? true : expanded == ind ? true : false}
              />
            );
          })
        ) : (
          <Chapter key={1} obj={{ name: "No data found", lessons: [] }} ind={0} />
        )}
      </div>
      <div className="academy-sidebar-details-section">
        <CustomRoundedButton
          text={strings.downloadAllNotes}
          borderRadius={"5px"}
          padding={"11px 48px 11px 24px"}
          className={"academy-purple-btn"}
          onClick={downloadNotesApi}
        />
        <img src={downloadBlackIcon} className="btn-download-icon" alt="" />
      </div>
    </Boxed>
  );
};
export default NewAcademySidebar;
