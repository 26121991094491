const cursorPosition = () => {
  try {
    let sel = document.getSelection();
    sel.modify("extend", "backward", "paragraphboundary");
    let pos = sel.toString().length;
    if (sel.anchorNode != undefined) sel.collapseToEnd();
    return pos;
  } catch (e) {
    return 0;
  }
};

export default cursorPosition;
