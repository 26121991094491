const logicToSeperateString = (str) => {
  let strs = '("7869425") ' + str;

  let strArr = [...strs];
  let length = strArr.length;

  let paranthesisArr = [];

  let leftPara = 0;
  let rightPara = 0;

  let leftFirstIndex = 0;
  let rightLastIndex = 0;

  let strNumber = [];

  let finalString = "";

  if (length) {
    strArr.forEach((char, index) => {
      strNumber.push(index);

      let a = strArr[index] ? strArr[index] : undefined;

      if (a == "(") {
        if (leftPara == 0) {
          leftFirstIndex = index;
        }
        leftPara += 1;
      }

      if (a == ")") {
        rightPara += 1;
        rightLastIndex = index;
      }

      if (leftPara == rightPara && leftPara != 0 && rightPara != 0) {
        paranthesisArr.push({
          left: leftPara,
          right: rightPara,
          fromIndex: leftFirstIndex,
          toIndex: rightLastIndex,
        });
        leftPara = 0;
        rightPara = 0;
        leftFirstIndex = 0;
        rightLastIndex = 0;
      }
    });

    let allParan = [];
    let allParan1 = [];
    let newArr = [];

    if (paranthesisArr.length) {
      paranthesisArr.forEach((obj, ind) => {
        let left = obj.fromIndex;
        let right = obj.toIndex;

        let leftPrev = paranthesisArr[ind + 1] ? paranthesisArr[ind + 1] : { fromIndex: strArr.length };

        if (leftPrev) {
          newArr.push({
            fromIndex: right + 1,
            toIndex: leftPrev.fromIndex - 1,
          });
        }

        let index = [];
        let strNew = "";
        for (let i = left; i <= right; i++) {
          index.push(i);
          strNew += strArr[i];
          allParan1.push(i);
        }
        allParan.push({
          index: index,
          string: String(strNew).replace(/and/g, "_=0188=_").replace(/not/g, "_=0189=_").replace(/AND/g, "_=0188=_").replace(/NOT/g, "_=0189=_"),
        });
      });
    }

    if (newArr.length) {
      newArr.forEach((obj, ind) => {
        let fromIndex = obj.fromIndex;
        let toIndex = obj.toIndex;
        let index = [];
        let strNew = "";
        for (let i = fromIndex; i <= toIndex; i++) {
          index.push(i);
          strNew += strArr[i];
          allParan1.push(i);
        }
        allParan.push({
          index: index,
          string: String(strNew),
        });
      });
    }

    if (allParan.length) {
      let finalArray = allParan.sort((a, b) => {
        return a.index[0] < b.index[0] ? -1 : 1;
      });

      finalArray.forEach((obj, ind) => {
        let string = obj.string;
        finalString += string;
      });
    }
  }

  return String(finalString).replace(`("7869425") `, "");
};

export default logicToSeperateString;
