import { configureStore } from "@reduxjs/toolkit";
import getLocalData from "./reducers/getLocalData";
import getPlanDetail from "./reducers/getPlanDetail";
import tooledUpData from "./reducers/getTooledupData";
import letCollab from "./reducers/letCollab";
import loader from "./reducers/loader";
import localString from "./reducers/localString";
import sidebar from "./reducers/sidebar";
import snackbar from "./reducers/snackbar";
import stringId from "./reducers/stringId";
import typingStatus from "./reducers/typingStatus";
import lessonData from "./reducers/lessonData";
import pagesChange from "./reducers/pagesChange";
import likeDislikeChange from "./reducers/likeDislikeChange";
import checkSaveStatus from "./reducers/checkSaveStatus";
import autoBuildValues from "./reducers/autoBuildValues";
import socketUserData from "./reducers/socketUserData";
import string from "./reducers/string";
import autoEnrichBulb from "./reducers/autoEnrichBulb";
import intelCountKeywordSuggestion from "./reducers/intelCountKeywordSuggestion";
import paddleAmount from "./reducers/paddleAmount";
import discountAmount from "./reducers/discountAmount";
import getTrial from "./reducers/getTrial";
import getCredit from "./reducers/getCredit";
import trialConfirm from "./reducers/trialConfirm";
import openInvite from "./reducers/openInvite";
import onTranslate from "./reducers/onTranslate";
import enrichAlert from "./reducers/enrichAlert";

export default configureStore({
  reducer: {
    tooledUpData: tooledUpData,
    snackbar: snackbar,
    localString: localString,
    loader: loader,
    getPlanDetail: getPlanDetail,
    letCollab: letCollab,
    sidebar: sidebar,
    typingStatus: typingStatus,
    getLocalData: getLocalData,
    stringId: stringId,
    lessonData: lessonData,
    pagesChange: pagesChange,
    likeDislikeChange: likeDislikeChange,
    checkSaveStatus: checkSaveStatus,
    autoBuildValues: autoBuildValues,
    socketUserData: socketUserData,
    string: string,
    paddleAmount: paddleAmount,
    autoEnrichBulb: autoEnrichBulb,
    intelCountKeywordSuggestion: intelCountKeywordSuggestion,
    discountAmount: discountAmount,
    getTrial: getTrial,
    getCredit: getCredit,
    trialConfirm: trialConfirm,
    openInvite: openInvite,
    onTranslate: onTranslate,
    enrichAlert : enrichAlert
  },
});
