import { NEW_MODULE_API } from "../Helpers/Enums";
import globalRequest from "./globalRequest";

const updateToken = async (userId = 0, type = "web") => {
  let url = NEW_MODULE_API.UPDATE_TOKEN;
  let response = await globalRequest(
    "post",
    url,
    {
      userId: userId,
      type: type,
    },
    {},
    true
  );
  return response;
};

export default updateToken;
