import React from "react";
import "antd/dist/antd.css";
import * as Colors from "../Styles-Elements/Colors";
import {
  Subheading,
  Subtitle,
  LargeBody,
} from "../Styles-Elements/Labels";
import { ClearButton, MainRoundedButton } from "../Styles-Elements/Buttons";

import booNeutral from "../image-assets/boo-neutral.png";

import Scrollbar from "react-smooth-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { tooledUpLocalData } from "../redux/reducers/getTooledupData";
// Importing localised strings
const strings = require("../localisation_en.json");

//@props - closePopup

const InactiveScreen = ({
  callback
}) => {
  const auth = useSelector(tooledUpLocalData);
  const dispatch = useDispatch();

  return (
    <div className="container">
      <div className="container-modal">
        <div className="popup-boo-container neutral">
          <img src={booNeutral} width={120} height={120} />
        </div>
        <Scrollbar className="container-modal-mid">
          <Subtitle
            text={strings.areYouThere}
            color={Colors.nightPurple}
            textAlign={"center"}
            padding={"24px 0"}
          />
          <LargeBody
            text={strings.inactiveMessage}
            textAlign={"center"}
            color={Colors.darkGray}
            padding={"0 0 16px 0"}
          />

          <MainRoundedButton
            text={strings.letsgo}
            margin={"24px 0 8px 0"}
            type="submit"
            onClick={callback}
          />
        </Scrollbar>
      </div>
    </div>
  );
};

export default InactiveScreen;
