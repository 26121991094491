const replaceSmallAndOrNot = (str) => {
  return str
    ? String(str)
        .replace(/"or"/g, `" OR "`)
        .replace(/"oR"/g, `" OR "`)
        .replace(/"Or"/g, `" OR "`)
        .replace(/"and"/g, `" AND "`)
        .replace(/"And"/g, `" AND "`)
        .replace(/"aNd"/g, `" AND "`)
        .replace(/"anD"/g, `" AND "`)
        .replace(/"not"/g, `" NOT "`)
        .replace(/"Not"/g, `" NOT "`)
        .replace(/"nOt"/g, `" NOT "`)
        .replace(/"noT"/g, `" NOT "`)
        .replace(/"\sor\s"/g, `" OR "`)
        .replace(/"\soR\s"/g, `" OR "`)
        .replace(/"\sOr\s"/g, `" OR "`)
        .replace(/"\sand\s"/g, `" AND "`)
        .replace(/"\sAnd\s"/g, `" AND "`)
        .replace(/"\saNd\s"/g, `" AND "`)
        .replace(/"\sanD\s"/g, `" AND "`)
        .replace(/"\snot\s"/g, `" NOT "`)
        .replace(/"\sNot\s"/g, `" NOT "`)
        .replace(/"\snOt\s"/g, `" NOT "`)
        .replace(/"\snoT\s"/g, `" NOT "`)
        .replace(/"OR"/g, `" OR "`)
        .replace(/"AND"/g, `" AND "`)
        .replace(/"NOT"/g, `" NOT "`)
    : "";
};

export default replaceSmallAndOrNot;
