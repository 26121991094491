import { REQUEST } from "../Helpers/Enums";
import globalRequest from "./globalRequest";

const getAllCountry = async () => {
    let response = await globalRequest("get", REQUEST.GET_COUNTRY_LIST_REQUEST, {}, false);
    let data = response.data;
    if (data.status == 1) {
        return data.data;
    } else {
        return [];
    }
}

export default getAllCountry;