const replaceNewLine = (string = "") => {
  if (string) {
    let newString = "";
    let text = String(string);
    let strArr = [...text];
    if (strArr.length) {
      strArr.forEach((char) => {
        if (char == "\n") {
          newString += " ";
        } else {
          newString += char;
        }
      });
    }

    return newString;
  } else {
    return string;
  }
};

export default replaceNewLine;
