const isEvenDoubleQuotes = (index, string) => {
    let dq = 0;
    let subString = string.substring(0, index);
    dq = (subString.match(/"/g) || "").length;
    if (dq % 2 === 1) {
        return false;
    }
    return true;
}

export default isEvenDoubleQuotes;