import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { SIDEBAR_STATE, SIDEBAR_STATE_FOR_LOCAL_STORAGE, STORAGE } from "../../Helpers/Enums";
import { ClearButton } from "../Buttons";
import * as Colors from "../Colors";
import { LargeBody, Body } from "../../Styles-Elements/Labels";
import * as FontStyles from "../FontStyles";
import "./Sidebar.css";
import dashboardIcon from "../../image-assets/dashboard.svg";
import usersIcon from "../../image-assets/users-icon.svg";
import companyIcon from "../../image-assets/company-icon.svg";
import settingsIcon from "../../image-assets/settings-icon.svg";
import exitIcon from "../../image-assets/exit-icon.svg";
import listIcon from "../../image-assets/list-icon.svg";
import lockIcon from "../../image-assets/lock-icon.svg";
import RaccoonIcon from "../../image-assets/raccoon-logo.png";
import sidebarImport from "../../image-assets/sidebar-import.svg";
import { REQUEST } from "../../Helpers/Enums";
import booHappy from "../../image-assets/boo-happy.png";
import globalRequest from "../../GlobalModule/globalRequest";
import { setData, tooledUpLocalData } from "../../redux/reducers/getTooledupData";
import { useDispatch } from "react-redux";
import { stringData } from "../../redux/reducers/localString";
import addDeleteGetLocalStorage from "../../GlobalModule/addDeleteGetLocalStorage";
import ChangePassword from "../../dialogs/ChangePassword";
import ProfileDetailPopUp from "../../dialogs/ProfileDetail";
import { currentTypingStatus } from "../../redux/reducers/typingStatus";
import { setSnackbar } from "../../redux/reducers/snackbar";
import { changeSidebar, currentSidebar } from "../../redux/reducers/sidebar";
import academyIcon from "../../image-assets/academy_2_1.svg";
import { changePage, currentPage } from "../../redux/reducers/pagesChange";
import { currentTrial } from "../../redux/reducers/getTrial";

const Sidebar = ({ paymentStatus }) => {
  const auth = useSelector(tooledUpLocalData);
  const strings = useSelector(stringData);
  const typeStatus = useSelector(currentTypingStatus);
  const typeParam = useSelector(currentSidebar);
  const dispatch = useDispatch();
  const pages = useSelector(currentPage);
  const trialData = useSelector(currentTrial);

  const [activeState, setActiveState] = useState(SIDEBAR_STATE.DASHBOARD);
  const [typingStatus, setTypingStatus] = useState("release");

  const [changeProfile, setChangeProfile] = useState(false);

  const handleClick = (e) => {
    setChangeProfile(true);
  };

  let history = useHistory();

  useEffect(() => {
    if (typeStatus == "typing") {
      setTypingStatus("typing");
    } else {
      setTypingStatus("release");
    }
  }, [typeStatus]);

  useEffect(() => {
    if (paymentStatus == "trialing" || paymentStatus == "active") {
      for (let obj in SIDEBAR_STATE_FOR_LOCAL_STORAGE) {
        let value = SIDEBAR_STATE_FOR_LOCAL_STORAGE[obj];
        let check = SIDEBAR_STATE[obj];

        if (typeParam == value) {
          setActiveState(check);
          dispatch(changePage(check));
        }
      }
    } else if (paymentStatus == "paused" || paymentStatus == "past_due") {
      setActiveState(SIDEBAR_STATE.MANAGE_USERS);
      dispatch(changePage(SIDEBAR_STATE.MANAGE_USERS));
    }
  }, [paymentStatus]);

  useEffect(() => {
    if (typeParam == SIDEBAR_STATE_FOR_LOCAL_STORAGE.SETTINGS) {
      setActiveState(SIDEBAR_STATE.SETTINGS);
      dispatch(changePage(SIDEBAR_STATE.SETTINGS));
    }
  }, [typeParam]);

  const handleLogout = async (e) => {
    let userId;

    if (auth?.user_id) {
      userId = auth?.user_id;
    }
    let formData = new FormData();
    formData.append("user_id", userId);
    formData.append("type", "web");
    await globalRequest("post", REQUEST.LOGOUT_REQUEST, formData, {}, false);
    localStorage.removeItem(STORAGE.TOOLED_UP_LOCAL_DATA);
    localStorage.removeItem(STORAGE.VIEW_TYPE);
    localStorage.removeItem(STORAGE.NEW_SIDE_BAR);
    dispatch(setData());
    history.push("/");
  };

  const Profile = () => {
    let profilePic = auth?.img_path;
    let userId = auth?.user_id;
    let firstName = auth?.firstname;

    let profileUrl = null;
    if (profilePic != "") {
      profileUrl = profilePic;
    } else {
      if (firstName == "") {
        profileUrl = booHappy;
      } else {
        profileUrl = "nameImage";
      }
    }

    return (
      <>
        {profileUrl == "nameImage" ? (
          <>
            <b
              className="sidebar-profile-char spc-2"
              onClick={(e) => {
                handleClick(e);
              }}
            >{`${auth?.firstname.charAt(0).toUpperCase()}${auth?.lastname.charAt(0).toUpperCase()}`}</b>
          </>
        ) : (
          <>
            <img
              src={profileUrl}
              alt=""
              className="sidebar-profile-img"
              onClick={(e) => {
                handleClick(e);
              }}
            />
          </>
        )}
      </>
    );
  };

  return (
    <div className="sidebar">
      {/* <img src={sbRightClose} className="sbclose" /> */}
      <div className="sidebar-header">
        <img src={RaccoonIcon} width={40} height={40} alt="" />
      </div>
      <div className="sidebar-profile-holder">
        <Profile />
        <div className="side-righttext" style={{ textTransform: "capitalize" }}>
          <LargeBody text={`${auth?.firstname} ${auth?.lastname}`} color={Colors.nightPurple} />
          <Body text={auth?.access} color={Colors.darkGray} />
        </div>
      </div>

      {/* sidebar menu */}

      {paymentStatus == "trialing" || paymentStatus == "active" ? (
        <>
          <div className="sidebar-manage-strings-section">
            <div
              className={
                activeState === SIDEBAR_STATE.DASHBOARD ? "sidebar-button-holder selected" : "sidebar-button-holder"
              }
              onClick={() => {
                setActiveState(SIDEBAR_STATE.DASHBOARD);
                dispatch(changePage(SIDEBAR_STATE.DASHBOARD));
                addDeleteGetLocalStorage(
                  STORAGE.NEW_SIDE_BAR,
                  SIDEBAR_STATE_FOR_LOCAL_STORAGE.DASHBOARD,
                  "add",
                  "single"
                );
                dispatch(changeSidebar());
              }}
            >
              <img
                src={dashboardIcon}
                className={activeState === SIDEBAR_STATE.DASHBOARD ? "filter-night-purple" : "filter-dark-purple-gray"}
                alt=""
              />
              <ClearButton
                theme={FontStyles.body}
                text={strings.dashboard}
                textAlign={"left"}
                padding={"8px 8px 8px 16px"}
                className="sidebar-btn-text"
                color={activeState === SIDEBAR_STATE.DASHBOARD ? Colors.nightPurple : Colors.darkPurpleGray}
              />
            </div>

            <div
              className={
                activeState === SIDEBAR_STATE.KEYWORD_STRINGS
                  ? "sidebar-button-holder selected"
                  : "sidebar-button-holder"
              }
              onClick={() => {
                if (typingStatus == "typing") {
                  dispatch(
                    setSnackbar({
                      snackbarMessage: strings.YouWereTooFast,
                      snackbarOpen: true,
                      snackbarState: "error",
                    })
                  );
                } else {
                  setActiveState(SIDEBAR_STATE.KEYWORD_STRINGS);
                  dispatch(changePage(SIDEBAR_STATE.KEYWORD_STRINGS));
                  //new Sidebar
                  addDeleteGetLocalStorage(
                    STORAGE.NEW_SIDE_BAR,
                    SIDEBAR_STATE_FOR_LOCAL_STORAGE.KEYWORD_STRINGS,
                    "add",
                    "single"
                  );
                  dispatch(changeSidebar());
                  // window.location.replace("/main-app");
                }
              }}
            >
              <img
                src={listIcon}
                className={
                  activeState === SIDEBAR_STATE.KEYWORD_STRINGS ? "filter-night-purple" : "filter-dark-purple-gray"
                }
                alt=""
              />
              <ClearButton
                theme={FontStyles.body}
                text={strings.keywordStrings}
                textAlign={"left"}
                id={"keywordString"}
                padding={"8px 8px 8px 16px"}
                className="sidebar-btn-text"
                color={activeState === SIDEBAR_STATE.KEYWORD_STRINGS ? Colors.nightPurple : Colors.darkPurpleGray}
              />
            </div>

            <div
              className={
                activeState === SIDEBAR_STATE.COMPANY_STRINGS
                  ? "sidebar-button-holder selected"
                  : "sidebar-button-holder"
              }
              onClick={() => {
                if (typingStatus == "typing") {
                  dispatch(
                    setSnackbar({
                      snackbarMessage: strings.YouWereTooFast,
                      snackbarOpen: true,
                      snackbarState: "error",
                    })
                  );
                } else {
                  setActiveState(SIDEBAR_STATE.COMPANY_STRINGS);
                  dispatch(changePage(SIDEBAR_STATE.COMPANY_STRINGS));

                  //new Sidebar
                  addDeleteGetLocalStorage(
                    STORAGE.NEW_SIDE_BAR,
                    SIDEBAR_STATE_FOR_LOCAL_STORAGE.COMPANY_STRINGS,
                    "add",
                    "single"
                  );
                  dispatch(changeSidebar());
                }
              }}
            >
              <img
                src={companyIcon}
                className={
                  activeState === SIDEBAR_STATE.COMPANY_STRINGS ? "filter-night-purple" : "filter-dark-purple-gray"
                }
                alt=""
              />
              <ClearButton
                theme={FontStyles.body}
                text={strings.companyStrings}
                textAlign={"left"}
                padding={"8px 8px 8px 16px"}
                className="sidebar-btn-text"
                id={"companyString"}
                color={activeState === SIDEBAR_STATE.COMPANY_STRINGS ? Colors.nightPurple : Colors.darkPurpleGray}
              />
            </div>
          </div>
          <div className="sidebar-details-section">
            {trialData?.checkPay ? (
              <div
                className={
                  activeState === SIDEBAR_STATE.ACADEMY ? "sidebar-button-holder selected" : "sidebar-button-holder"
                }
                onClick={() => {
                  if (typingStatus == "typing") {
                    dispatch(
                      setSnackbar({
                        snackbarMessage: strings.YouWereTooFast,
                        snackbarOpen: true,
                        snackbarState: "error",
                      })
                    );
                  } else {
                    setActiveState(SIDEBAR_STATE.ACADEMY);
                    dispatch(changePage(SIDEBAR_STATE.ACADEMY));
                    addDeleteGetLocalStorage(
                      STORAGE.NEW_SIDE_BAR,
                      SIDEBAR_STATE_FOR_LOCAL_STORAGE.ACADEMY,
                      "add",
                      "single"
                    );
                    dispatch(changeSidebar());
                  }
                }}
              >
                <img
                  src={academyIcon}
                  className={activeState === SIDEBAR_STATE.ACADEMY ? "filter-night-purple" : "filter-dark-purple-gray"}
                  alt=""
                />
                <ClearButton
                  theme={FontStyles.body}
                  text={strings.academy}
                  textAlign={"left"}
                  padding={"8px 8px 8px 16px"}
                  className="sidebar-btn-text"
                  color={activeState === SIDEBAR_STATE.ACADEMY ? Colors.nightPurple : Colors.darkPurpleGray}
                />
              </div>
            ) : null}

            <div
              className={
                activeState === SIDEBAR_STATE.IMPORT_STRINGS
                  ? "sidebar-button-holder selected"
                  : "sidebar-button-holder"
              }
              onClick={() => {
                if (typingStatus == "typing") {
                  dispatch(
                    setSnackbar({
                      snackbarMessage: strings.YouWereTooFast,
                      snackbarOpen: true,
                      snackbarState: "error",
                    })
                  );
                } else {
                  setActiveState(SIDEBAR_STATE.IMPORT_STRINGS);
                  dispatch(changePage(SIDEBAR_STATE.IMPORT_STRINGS));

                  addDeleteGetLocalStorage(
                    STORAGE.NEW_SIDE_BAR,
                    SIDEBAR_STATE_FOR_LOCAL_STORAGE.IMPORT_STRINGS,
                    "add",
                    "single"
                  );
                  dispatch(changeSidebar());
                }
              }}
            >
              <img
                src={sidebarImport}
                className={
                  activeState === SIDEBAR_STATE.IMPORT_STRINGS ? "filter-night-purple" : "filter-dark-purple-gray"
                }
                alt=""
              />
              <ClearButton
                theme={FontStyles.body}
                text={strings.importStrings}
                textAlign={"left"}
                padding={"8px 8px 8px 16px"}
                className="sidebar-btn-text"
                color={activeState === SIDEBAR_STATE.IMPORT_STRINGS ? Colors.nightPurple : Colors.darkPurpleGray}
              />
            </div>

            {auth?.access == "admin" || auth?.access == "owner" ? (
              <>
                <div
                  className={
                    activeState === SIDEBAR_STATE.MANAGE_USERS
                      ? "sidebar-button-holder selected"
                      : "sidebar-button-holder"
                  }
                  onClick={() => {
                    if (typingStatus == "typing") {
                      dispatch(
                        setSnackbar({
                          snackbarMessage: strings.YouWereTooFast,
                          snackbarOpen: true,
                          snackbarState: "error",
                        })
                      );
                    } else {
                      setActiveState(SIDEBAR_STATE.MANAGE_USERS);
                      dispatch(changePage(SIDEBAR_STATE.MANAGE_USERS));

                      addDeleteGetLocalStorage(
                        STORAGE.NEW_SIDE_BAR,
                        SIDEBAR_STATE_FOR_LOCAL_STORAGE.MANAGE_USERS,
                        "add",
                        "single"
                      );
                      dispatch(changeSidebar());
                    }
                  }}
                >
                  <img
                    src={usersIcon}
                    className={
                      activeState === SIDEBAR_STATE.MANAGE_USERS ? "filter-night-purple" : "filter-dark-purple-gray"
                    }
                    alt=""
                  />
                  <ClearButton
                    theme={FontStyles.body}
                    text={strings.manageUsers}
                    textAlign={"left"}
                    padding={"8px 8px 8px 16px"}
                    className="sidebar-btn-text"
                    color={activeState === SIDEBAR_STATE.MANAGE_USERS ? Colors.nightPurple : Colors.darkPurpleGray}
                  />
                </div>
              </>
            ) : null}

            <div
              onClick={() => {
                if (typingStatus == "typing") {
                  dispatch(
                    setSnackbar({
                      snackbarMessage: strings.YouWereTooFast,
                      snackbarOpen: true,
                      snackbarState: "error",
                    })
                  );
                } else {
                  setActiveState(SIDEBAR_STATE.SETTINGS);
                  dispatch(changePage(SIDEBAR_STATE.SETTINGS));
                  addDeleteGetLocalStorage(
                    STORAGE.NEW_SIDE_BAR,
                    SIDEBAR_STATE_FOR_LOCAL_STORAGE.SETTINGS,
                    "add",
                    "single"
                  );
                  dispatch(changeSidebar());
                }
              }}
              className={
                activeState === SIDEBAR_STATE.SETTINGS ? "sidebar-button-holder selected" : "sidebar-button-holder"
              }
            >
              <img
                src={settingsIcon}
                className={activeState === SIDEBAR_STATE.SETTINGS ? "filter-night-purple" : "filter-dark-purple-gray"}
                alt=""
              />
              <ClearButton
                theme={FontStyles.body}
                text={strings.settings}
                textAlign={"left"}
                padding={"8px 8px 8px 16px"}
                className="sidebar-btn-text"
                color={activeState === SIDEBAR_STATE.SETTINGS ? Colors.nightPurple : Colors.darkPurpleGray}
              />
            </div>

            <div className="separator-line" />
            <div
              className="sidebar-exit-button-holder"
              onClick={(e) => {
                handleLogout(e);
              }}
            >
              <img src={exitIcon} className={"filter-dark-purple-gray"} height={24} alt="" />
              <ClearButton
                theme={FontStyles.body}
                text={strings.logOut}
                textAlign={"left"}
                padding={"8px 8px 8px 16px"}
                className="sidebar-btn-text"
                color={Colors.darkPurpleGray}
              />
            </div>
          </div>
        </>
      ) : paymentStatus == "paused" || paymentStatus == "past_due" ? (
        <>
          <div className="sidebar-manage-strings-section">
            {auth?.access == "admin" || auth?.access == "owner" ? (
              <>
                <div
                  className={
                    activeState === SIDEBAR_STATE.MANAGE_USERS
                      ? "sidebar-button-holder selected"
                      : "sidebar-button-holder"
                  }
                  onClick={() => {
                    if (typingStatus == "typing") {
                      dispatch(
                        setSnackbar({
                          snackbarMessage: strings.YouWereTooFast,
                          snackbarOpen: true,
                          snackbarState: "error",
                        })
                      );
                    } else {
                      setActiveState(SIDEBAR_STATE.MANAGE_USERS);
                      dispatch(changePage(SIDEBAR_STATE.MANAGE_USERS));

                      addDeleteGetLocalStorage(
                        STORAGE.NEW_SIDE_BAR,
                        SIDEBAR_STATE_FOR_LOCAL_STORAGE.MANAGE_USERS,
                        "add",
                        "single"
                      );
                      dispatch(changeSidebar());
                    }
                  }}
                >
                  <img
                    src={usersIcon}
                    className={
                      activeState === SIDEBAR_STATE.MANAGE_USERS ? "filter-night-purple" : "filter-dark-purple-gray"
                    }
                    alt=""
                  />
                  <ClearButton
                    theme={FontStyles.body}
                    text={strings.manageUsers}
                    textAlign={"left"}
                    padding={"8px 8px 8px 16px"}
                    className="sidebar-btn-text"
                    color={activeState === SIDEBAR_STATE.MANAGE_USERS ? Colors.nightPurple : Colors.darkPurpleGray}
                  />
                </div>
              </>
            ) : null}

            <div
              onClick={() => {
                if (typingStatus == "typing") {
                  dispatch(
                    setSnackbar({
                      snackbarMessage: strings.YouWereTooFast,
                      snackbarOpen: true,
                      snackbarState: "error",
                    })
                  );
                } else {
                  setActiveState(SIDEBAR_STATE.SETTINGS);
                  dispatch(changePage(SIDEBAR_STATE.SETTINGS));

                  addDeleteGetLocalStorage(
                    STORAGE.NEW_SIDE_BAR,
                    SIDEBAR_STATE_FOR_LOCAL_STORAGE.SETTINGS,
                    "add",
                    "single"
                  );
                  dispatch(changeSidebar());
                }
              }}
              className={
                activeState === SIDEBAR_STATE.SETTINGS ? "sidebar-button-holder selected" : "sidebar-button-holder"
              }
            >
              <img
                src={settingsIcon}
                className={activeState === SIDEBAR_STATE.SETTINGS ? "filter-night-purple" : "filter-dark-purple-gray"}
                alt=""
              />
              <ClearButton
                theme={FontStyles.body}
                text={strings.settings}
                textAlign={"left"}
                padding={"8px 8px 8px 16px"}
                className="sidebar-btn-text"
                color={activeState === SIDEBAR_STATE.SETTINGS ? Colors.nightPurple : Colors.darkPurpleGray}
              />
            </div>

            <div className="separator-line" />
            <div
              className="sidebar-exit-button-holder"
              onClick={(e) => {
                handleLogout(e);
              }}
            >
              <img src={exitIcon} className={"filter-dark-purple-gray"} height={24} alt="" />
              <ClearButton
                theme={FontStyles.body}
                text={strings.logOut}
                textAlign={"left"}
                padding={"8px 8px 8px 16px"}
                className="sidebar-btn-text"
                color={Colors.darkPurpleGray}
              />
            </div>
          </div>
        </>
      ) : null}

      {changeProfile ? <ProfileDetailPopUp closePopup={() => setChangeProfile(false)} /> : null}
    </div>
  );
};

export default Sidebar;
