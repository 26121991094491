import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import * as Colors from "../Styles-Elements/Colors";
import { Subheading, Subtitle } from "../Styles-Elements/Labels";
import { Textfield } from "../Styles-Elements/Inputs";
import {
  ClearButton,
  MainRoundedButton,
} from "../Styles-Elements/Buttons";
import booNeutral from "../image-assets/boo-neutral.png";

import { REQUEST, ACTION_TYPE } from "../Helpers/Enums";
import Scrollbar from "react-smooth-scrollbar";
import { CheckToken } from "../Helpers/CheckToken";
import closePopUpByKey from "../GlobalModule/closePopUpByKey";
import focus from "../GlobalModule/focus";
import { useDispatch, useSelector } from "react-redux";
import { planDetails } from "../redux/reducers/getPlanDetail";
import { tooledUpLocalData } from "../redux/reducers/getTooledupData";
import globalRequest from "../GlobalModule/globalRequest";
import { setSnackbar } from "../redux/reducers/snackbar";
import { changeLoader } from "../redux/reducers/loader";
// Importing localised strings
const strings = require("../localisation_en.json");

const PostCode = (props) => {
  const auth = useSelector(tooledUpLocalData);
  const planDetail = useSelector(planDetails);
  const dispatch = useDispatch();

  const [inputFieldEntry, setInputFieldEntry] = useState({
    postCode: planDetail?.postcode ? planDetail?.postcode : "",
  });

  const handleInputChange = (e, clear) => {
    if (clear !== true) {
      setInputFieldEntry({
        ...inputFieldEntry,
        [e.currentTarget.name]: e.currentTarget.value,
      });
    } else {
      setInputFieldEntry({});
    }
  };

  useEffect(() => {
    closePopUpByKey(props.closePopup);
    focus("postcode");
  }, []);

  const handleSubmitPostCode = async (e) => {
    e.preventDefault();

    if (inputFieldEntry.postCode !== "" && inputFieldEntry.postCode != null) {
      const formData = new FormData();
      formData.append("user_id", auth?.user_id);
      formData.append("first_name", auth?.firstname);
      formData.append("post_code", inputFieldEntry.postCode);

      dispatch(changeLoader(true));
      const response = await globalRequest(
        "post",
        REQUEST.POST_CODE_REQUEST,
        formData,
        {},
        true
      );
      const res = response.data;

      if (res.status == 1) {
        dispatch(
          setSnackbar({
            snackbarMessage: res.message,
            snackbarOpen: true,
            snackbarState: "success",
          })
        );
        dispatch(changeLoader(false));
        props.setPostCode(res.data.post_code);
      } else {
        dispatch(
          setSnackbar({
            snackbarMessage: res.message,
            snackbarOpen: true,
            snackbarState: "error",
          })
        );
        dispatch(changeLoader(false));
      }
    } else {
      dispatch(
        setSnackbar({
          snackbarMessage: "Please enter post code ",
          snackbarOpen: true,
          snackbarState: "error",
        })
      );
    }
  };
  return (
    <div className="container">
      <div className="container-modal">
        <div className="popup-boo-container neutral">
          <img src={booNeutral} width={120} height={120} />
        </div>
        <Scrollbar className="container-modal-mid">
          <Subtitle
            text={strings.changeYourPostcode}
            color={Colors.nightPurple}
            textAlign={"center"}
            padding={"24px 0"}
          />
          <form onSubmit={handleSubmitPostCode}>
            <Subheading
              text={strings.postcode}
              color={Colors.nightPurple}
              padding={"0 0 8px 0"}
            />
            <Textfield
              name={"postCode"}
              value={inputFieldEntry.postCode}
              onChange={(e) => handleInputChange(e)}
              placeholder={strings.enterANewPostcode}
              required={true}
              id="postcode"
            />
            <MainRoundedButton
              text={strings.changePostcode}
              margin={"24px 0 8px 0"}
              type="submit"
            />
          </form>
          <ClearButton
            text={strings.cancel}
            onClick={() => {
              setInputFieldEntry({ postCode: "" });
              //props.setSnackbarOpen(false);
              props.closePopup();
            }}
          />
        </Scrollbar>
      </div>
    </div>
  );
};

export default PostCode;
