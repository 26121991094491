import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "antd/dist/antd.css";
import * as Colors from "../Styles-Elements/Colors";
import { Subtitle, LargeBodyBold, LabelWrapper, LargeBody } from "../Styles-Elements/Labels";

import { ClearButton, SecondaryRoundedButton } from "../Styles-Elements/Buttons";
import booHappy from "../image-assets/boo-happy.png";

import Scrollbar from "react-smooth-scrollbar";

import closePopUpByKey from "../GlobalModule/closePopUpByKey";
import { tooledUpLocalData } from "../redux/reducers/getTooledupData";
import { planDetails } from "../redux/reducers/getPlanDetail";
import moment from "moment";
import { PAYMENT_PLAN_TYPE, PLAN_TYPE, REQUEST, PLAN_AMOUNT } from "../Helpers/Enums";
import globalRequest from "../GlobalModule/globalRequest";
import { setSnackbar } from "../redux/reducers/snackbar";
import { changeLoader } from "../redux/reducers/loader";
// Importing localised strings
const strings = require("../localisation_en.json");

const TeamGrowing = ({ closePopup, currentNoOfUser, downgradeUser, subTotal }) => {
  const auth = useSelector(tooledUpLocalData);
  const planDetail = useSelector(planDetails);
  const dispatch = useDispatch();
  let endDate = planDetail?.end_date;
  const billingDate = moment(endDate).format("DD MMM YYYY");

  const [calculateFrom, setCalculateFrom] = useState(0);
  const [amountOfCurrentUser, setAmountOfCurrentUser] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [extraSeats, setExtraSeats] = useState(0);

  useEffect(() => {
    let cal = planDetail?.plan_amount;
    setCalculateFrom(cal);
  }, [planDetail]);

  useEffect(() => {
    if (calculateFrom != 0) {
      const aOfCurrentUser = (currentNoOfUser - planDetail?.total_user) * calculateFrom;
      const tAmount = currentNoOfUser * calculateFrom;
      const eSeats = currentNoOfUser - planDetail?.total_user;

      setAmountOfCurrentUser(aOfCurrentUser);
      setTotalAmount(tAmount);
      setExtraSeats(eSeats);
    }
  }, [calculateFrom]);

  const checkout = async (e) => {
    e.preventDefault();

    const planType = planDetail?.plan_type;
    const userId = auth?.user_id;

    let d = new Date();
    let month = d.getMonth() + 1;
    let day = d.getDate();
    let year = d.getFullYear();

    let d1 = `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
    let currentDate = moment(d1);
    let lastDate = moment(endDate);
    let currentAmountPrice = planDetail?.plan_amount;
    let dayss = lastDate.diff(currentDate, "days");
    let equal = 30;
    if (month === 2) {
      equal = 29;
    }
    let days = dayss == equal ? 31 : dayss == 366 ? 365 : dayss;
    let dayDivides = planType == PLAN_TYPE.MONTHLY ? 31 : 365;
    let amount = planDetail?.plan_amount / dayDivides;
    let totalUser = planDetail?.total_user;
    let totalAmount = (totalUser + extraSeats) * currentAmountPrice;
    let modifiedAmount = (totalUser + extraSeats - planDetail?.subscription_user) * currentAmountPrice;
    let remainingAmount = (days * amount * extraSeats).toFixed(2);
    let modifiedUser = extraSeats;
    let modifiedId = planDetail?.modifier_id ? planDetail?.modifier_id : 0;

    const data = {
      user_id: userId,
      subscription_id: planDetail?.subscription_id,
      total_amount: parseFloat(totalAmount.toFixed(2)),
      total_user: totalUser + extraSeats,
      modified_amount: modifiedAmount,
      remaining_amount: parseFloat(remainingAmount),
      modified_user: modifiedUser,
      modifier_id: modifiedId,
      plan_type: planType,
    };

    dispatch(changeLoader(true));
    let response = await globalRequest("post", REQUEST.UPGRADE_TEAM_REQUEST, data, {}, true);
    let res = response.data;
    if (res.status == 1) {
      dispatch(
        setSnackbar({
          snackbarMessage: res.message,
          snackbarState: "success",
          snackbarOpen: true,
        })
      );
      dispatch(changeLoader(false));
      downgradeUser(res?.data?.total_user);
    } else {
      dispatch(
        setSnackbar({
          snackbarMessage: res.message,
          snackbarState: "error",
          snackbarOpen: true,
        })
      );
      dispatch(changeLoader(false));
    }
  };

  useEffect(() => {
    closePopUpByKey(closePopup);
  }, []);

  return (
    <div className="container">
      <div className="container-modal">
        <div className="popup-boo-container positive">
          <img src={booHappy} width={120} height={120} alt="" />
        </div>
        <Scrollbar className="container-modal-mid">
          <Subtitle
            text={strings.yourTeamIsGrowing_Boom}
            color={Colors.nightPurple}
            textAlign={"center"}
            padding={"24px 0 16px 0"}
          />
          <LabelWrapper justifyContent={"center"} padding={"0px 0px 24px 0px"}>
            <LargeBody text={strings.youAreAboutToIncreaseTheNumberOf} color={Colors.darkGray} />
            <LargeBody text={strings.paidSeatsFrom} color={Colors.darkGray} />
            <LargeBodyBold text={planDetail?.total_user} color={Colors.darkGray} padding={"0 0 0 4px"} />
            <LargeBody text={strings.to} color={Colors.darkGray} padding={"0 0 0 4px"} />
            <LargeBodyBold text={currentNoOfUser} color={Colors.darkGray} padding={"0 0 0 4px"} />
          </LabelWrapper>
          <LabelWrapper justifyContent={"center"} padding={"0px 0px 24px 0px"}>
            <LargeBody text={strings.youWillBeImmediatelyBilledAProratedAmount} color={Colors.darkGray} />
            <LargeBody text={strings.Of} color={Colors.darkGray} padding={"0 0 0 4px"} />

            {planDetail?.payment_status == "active" ? (
              <LargeBodyBold text={`£ ${subTotal}`} color={Colors.darkGray} padding={"0 0 0 4px"} />
            ) : null}

            {planDetail?.payment_status != "active" ? (
              <LargeBodyBold text={`£0.00`} color={Colors.darkGray} padding={"0 0 0 4px"} />
            ) : null}

            <LargeBody text={strings.addLocalTaxesForThe} color={Colors.darkGray} padding={"0 0 0 4px"} />
            <LargeBodyBold text={extraSeats} color={Colors.darkGray} padding={"0 0 0 4px"} />
            <LargeBody
              text={extraSeats > 1 ? "extra seats." : strings.extraSeat}
              color={Colors.darkGray}
              padding={"0 0 0 4px"}
            />
          </LabelWrapper>
          <LabelWrapper justifyContent={"center"} padding={"0px 0px 16px 0px"}>
            <LargeBody text={strings.onYourNextBillingCycleDue} color={Colors.darkGray} />
            <LargeBodyBold text={billingDate} color={Colors.darkGray} padding={"0 0 0 4px"} />
            <LargeBody text={strings.youWillBeBilledFor} color={Colors.darkGray} padding={"0 0 0 4px"} />
            <LargeBodyBold text={currentNoOfUser} color={Colors.darkGray} padding={"0 0 0 4px"} />
            <LargeBody text={strings.seatsAt} color={Colors.darkGray} padding={"0 0 0 4px"} />
            <LargeBodyBold text={"£" + totalAmount.toFixed(2)} color={Colors.darkGray} padding={"0 0 0 4px"} />
            <LargeBody text={strings.addLocalTaxes} color={Colors.darkGray} padding={"0 0 0 4px"} />
          </LabelWrapper>
          <SecondaryRoundedButton text={strings.proceed} margin={"24px 0 8px 0"} onClick={checkout} />
          <ClearButton text={strings.cancel} onClick={() => closePopup()} />
        </Scrollbar>
      </div>
    </div>
  );
};

export default TeamGrowing;
