import { Boxed } from "../../Styles-Elements/Box";
import { LargeBody } from "../../Styles-Elements/Labels";
import * as Colors from "../../Styles-Elements/Colors";
import { BorderedRoundedButton } from "../../Styles-Elements/Buttons";
import greenLine from "../../image-assets/green-auto-build.png";
import pikBlb from "../../image-assets/pik-blb.png";
import purpleGlobe from "../../image-assets/purple-globe.png";
import rightArrowBg from "../../image-assets/right-arrow-bg.png";
import powerIcon from "../../image-assets/power.png";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { tooledUpLocalData } from "../../redux/reducers/getTooledupData";
import { memo, useEffect, useState } from "react";
import { changeCredit, currentCredit } from "../../redux/reducers/getCredit";
import getCredit from "../../GlobalModule/getCredit";
import { currentTrial } from "../../redux/reducers/getTrial";
import daysBetweenDate from "../../GlobalModule/daysBetweenDate";
import CreditManagementModal from "../../dialogs/CreditManagementModal";
import ManagementPlanSelection from "../../dialogs/ManagementPlanSelection";
import "./FreeTrialHeader.css";

const strings = require("../../localisation_en.json");
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "#373737",
    boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.25)",
    fontSize: 14,
    fontWeight: 500,
    padding: "12px 16px",
    borderRadius: "4px",
    fontFamily: "Poppins",
  },
}))(Tooltip);
const FreeTrialHeader = () => {
  const auth = useSelector(tooledUpLocalData);
  const dispatch = useDispatch();
  const creditData = useSelector(currentCredit);
  const trialData = useSelector(currentTrial);
  const [freeTrialDay, setFreeTrialDay] = useState(0);
  const [openCredit, setOpenCredit] = useState(false);
  const [openPayment, setOpenPayment] = useState(false);

  useEffect(() => {
    (async () => {
      let data = await getCredit(auth?.id);
      dispatch(changeCredit(data));
    })();
  }, []);

  useEffect(() => {
    let createdAt = new Date();
    let expireOn = new Date(trialData?.expire_on);
    let days = daysBetweenDate(createdAt, expireOn);

    if (createdAt > expireOn) {
      setFreeTrialDay(0);
    } else {
      if (days > 0) {
        setFreeTrialDay(days);
      }
    }
  }, [trialData]);

  return (
    <>
      <div className="inner-new-header">
        <div className="inh-gray-box">
          <Boxed display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
            <LargeBody
              text={strings.tokensRemaining}
              fontWeight={"600"}
              color={Colors.nightGray}
              padding={"0 8px 0px 0"}
            />
            <Boxed display={"flex"} alignItems={"center"} gap={"10px"}>
              <LightTooltip title={"Auto Build Feature"} placement="bottom-start">
                <Boxed display={"flex"} alignItems={"center"} className="token-count-no-box">
                  <img src={greenLine} alt="" style={{ height: "20px" }} />
                  <LargeBody
                    text={creditData?.auto_build ? creditData?.auto_build : 0}
                    fontWeight={"600"}
                    color={Colors.sharpGreen}
                    padding={"0 0px 0px 8px"}
                  />
                </Boxed>
              </LightTooltip>
              <LightTooltip title={"Enrich Feature"} placement="bottom-start">
                <Boxed display={"flex"} alignItems={"center"} className="token-count-no-box">
                  <img src={pikBlb} alt="" />
                  <LargeBody
                    text={creditData?.auto_enrich ? creditData?.auto_enrich : 0}
                    fontWeight={"600"}
                    color={"#FF66C4"}
                    padding={"0 0px 0px 8px"}
                  />
                </Boxed>
              </LightTooltip>
              <LightTooltip title={"Translation Feature"} placement="bottom-start">
                <Boxed display={"flex"} alignItems={"center"} className="token-count-no-box">
                  <img src={purpleGlobe} alt="" />
                  <LargeBody
                    text={creditData?.translations ? creditData?.translations : 0}
                    fontWeight={"600"}
                    color={Colors.darkPurple}
                    padding={"0 0px 0px 8px"}
                  />
                </Boxed>
              </LightTooltip>
              <img src={rightArrowBg} alt="" style={{ cursor: "pointer" }} onClick={() => setOpenCredit(true)} />
            </Boxed>
          </Boxed>
        </div>
        <div className="inh-gray-box">
          <Boxed display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
            <LargeBody
              text={strings.freeTrialDaysRemaining}
              fontWeight={"600"}
              color={Colors.nightGray}
              padding={"0 8px 0px 0"}
            />
            <Boxed display={"flex"} alignItems={"center"} gap={"10px"}>
              <Boxed display={"flex"} alignItems={"center"} className="token-count-no-box">
                <LargeBody text={freeTrialDay} fontWeight={"600"} color={Colors.nightGray} padding={"0 0px 0px 0px"} />
              </Boxed>
              {auth?.access === "owner" ? (
                <BorderedRoundedButton
                  text={strings.upgradeNow}
                  img={<img src={powerIcon} style={{ marginLeft: "16px" }} alt="" />}
                  color={Colors.mainLight}
                  className={"inh-btn"}
                  onClick={() => setOpenPayment(true)}
                />
              ) : null}
            </Boxed>
          </Boxed>
        </div>
      </div>
      {openCredit ? (
        <CreditManagementModal
          closePopup={() => setOpenCredit(false)}
          upgradeDisplay={() => {
            setOpenPayment(true);
            setOpenCredit(false);
          }}
        />
      ) : (
        false
      )}
      {openPayment ? <ManagementPlanSelection closePopup={() => setOpenPayment(false)} /> : null}
    </>
  );
};
export default memo(FreeTrialHeader);
