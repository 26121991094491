// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  // eslint-disable-next-line no-restricted-globals
  self.onmessage = (event) => {
    let { string } = event?.data;


    if (string) {
      const isEvenDoubleQuotes = (index, string) => {
        let dq = 0;
        let subString = string.substring(0, index);
        dq = (subString.match(/"/g) || "").length;
        if (dq % 2 === 1) {
          return false;
        }
        return true;
      };

      const hasWhiteSpace = (s) => {
        return /\s/.test(s);
      };

      const addAutoQuotesAndPara = (str) => {
        let strArr = [...str];
        let globalLength = strArr.length;

        let addQuotes = [];
        let addQuotesPara = [];

        let indexs = [];

        if (globalLength > 0) {
        
          strArr.forEach((char, index) => {
            const first = strArr[index] ? strArr[index].toLowerCase() : undefined;
            const second = strArr[index + 1] ? strArr[index + 1].toLowerCase() : undefined;
            const third = strArr[index + 2] ? strArr[index + 2].toLowerCase() : undefined;
            const fourth = strArr[index + 3] ? strArr[index + 3].toLowerCase() : undefined;
            const five = strArr[index + 4] ? strArr[index + 4].toLowerCase() : undefined;

            // add Space Logics End

            // add Quotes Logics Start

            if (
              (hasWhiteSpace(first) && second == "a" && third == "n" && fourth == "d") ||
              (hasWhiteSpace(first) && second == "n" && third == "o" && fourth == "t")
            ) {
              addQuotes.push({
                index: index,
                operator: "and",
              });
            } else if (hasWhiteSpace(first) && second == "o" && third == "r") {
              addQuotes.push({
                index: index,
                operator: "or",
              });
            } else if (first == ")") {
              addQuotesPara.push({
                index: index,
                operator: "or",
              });
            }

            if (
              (hasWhiteSpace(first) && second == "a" && third == "n" && fourth == "d" && five == '"') ||
              (hasWhiteSpace(first) && second == "n" && third == "o" && fourth == "t" && five == '"')
            ) {
              indexs.push({
                index: index + 3,
                fors: "space",
                bool: false,
                operator: "and",
              });
            } else if (hasWhiteSpace(first) && second == "o" && third == "r" && fourth == '"') {
              indexs.push({
                index: index + 2,
                fors: "space",
                bool: false,
                operator: "or",
              });
            }
          });
          // string array end

          // add Quotes Logics
          if (addQuotesPara.length) {
            addQuotesPara.forEach((charObj, index) => {
              let first = addQuotesPara[index - 1] ? addQuotesPara[index - 1].index : 0;
              let last = charObj.index;

              if (isEvenDoubleQuotes(charObj.index, str)) {
                for (let i = last; i >= first; i--) {
                  let strA = strArr[i];

                  let secondString1 = strArr[i - 1] ? strArr[i - 1].toLowerCase() : undefined;
                  let thirdString1 = strArr[i - 2] ? strArr[i - 2].toLowerCase() : undefined;
                  let fourString1 = strArr[i - 3] ? strArr[i - 3].toLowerCase() : undefined;
                  let fiveString1 = strArr[i - 4] ? strArr[i - 4].toLowerCase() : undefined;

                  if (strA == "(") {
                    let getFirst = i;
                    for (let j = last; j >= i; j--) {
                      let s = strArr[j];
                      if (s == '"') {
                        return;
                      } else {
                        getFirst = j;
                      }
                    }

                    indexs.push({
                      index: getFirst,
                      fors: "quotes",
                      bool: false,
                      operator: charObj.operator,
                    });
                    indexs.push({
                      index: last,
                      fors: "quotes",
                      bool: false,
                      operator: charObj.operator,
                    });

                    return;
                  } else if (
                    (hasWhiteSpace(strA) &&
                      secondString1 == "d" &&
                      thirdString1 == "n" &&
                      fourString1 == "a" &&
                      hasWhiteSpace(fiveString1)) ||
                    (hasWhiteSpace(strA) &&
                      secondString1 == "t" &&
                      thirdString1 == "o" &&
                      fourString1 == "n" &&
                      hasWhiteSpace(fiveString1)) ||
                    (hasWhiteSpace(strA) && secondString1 == "r" && thirdString1 == "o" && hasWhiteSpace(fourString1))
                  ) {
                    let getFirst = i;
                    for (let j = last; j >= i; j--) {
                      let s = strArr[j];
                      if (s == '"') {
                        return;
                      } else {
                        getFirst = j;
                      }
                    }

                    indexs.push({
                      index: getFirst,
                      fors: "quotes",
                      bool: false,
                      operator: charObj.operator,
                    });
                    indexs.push({
                      index: last,
                      fors: "quotes",
                      bool: false,
                      operator: charObj.operator,
                    });

                    return;
                  }
                }
              }
            });
          }

          if (addQuotes.length) {
            addQuotes.forEach((charObj, index) => {
              if (isEvenDoubleQuotes(charObj.index, str)) {
                if (addQuotes[index - 1]) {
                  let firstString = addQuotes[index - 1].index;
                  let lastString = charObj.index;

                  for (let i = lastString; i >= firstString; i--) {
                    let strA = strArr[i];

                    let secondString1 = strArr[i - 1] ? strArr[i - 1].toLowerCase() : undefined;
                    let thirdString1 = strArr[i - 2] ? strArr[i - 2].toLowerCase() : undefined;
                    let fourString1 = strArr[i - 3] ? strArr[i - 3].toLowerCase() : undefined;
                    let fiveString1 = strArr[i - 4] ? strArr[i - 4].toLowerCase() : undefined;

                    let plusA = strArr[i + 1] ? strArr[i + 1].toLowerCase() : undefined;
                    let plusB = strArr[i + 2] ? strArr[i + 2].toLowerCase() : undefined;
                    let plusC = strArr[i + 3] ? strArr[i + 3].toLowerCase() : undefined;
                    let plusD = strArr[i + 4] ? strArr[i + 4].toLowerCase() : undefined;

                    if (strA == '"') {
                      return;
                    } else if (strA == "(") {
                      if (i != lastString) {
                        indexs.push({
                          index: i,
                          fors: "quotes",
                          bool: false,
                          operator: charObj.operator,
                        });
                        indexs.push({
                          index: lastString,
                          fors: "quotes",
                          bool: false,
                          operator: charObj.operator,
                        });
                      }

                      return;
                    } else if (strA == ")" && hasWhiteSpace(plusA)) {
                      if (
                        (hasWhiteSpace(plusA) && plusB == "a" && plusC == "n" && plusD == "d") ||
                        (hasWhiteSpace(plusA) && plusB == "n" && plusC == "o" && plusD == "t") ||
                        (hasWhiteSpace(plusA) && plusB == "o" && plusC == "r")
                      ) {
                        return;
                      }
                    } else if (
                      (hasWhiteSpace(strA) &&
                        secondString1 == "d" &&
                        thirdString1 == "n" &&
                        fourString1 == "a" &&
                        hasWhiteSpace(fiveString1)) ||
                      (hasWhiteSpace(strA) &&
                        secondString1 == "t" &&
                        thirdString1 == "o" &&
                        fourString1 == "n" &&
                        hasWhiteSpace(fiveString1)) ||
                      (hasWhiteSpace(strA) && secondString1 == "r" && thirdString1 == "o" && hasWhiteSpace(fourString1))
                    ) {
                      if (i != lastString) {
                        indexs.push({
                          index: i,
                          fors: "quotes",
                          bool: false,
                          operator: charObj.operator,
                        });
                        indexs.push({
                          index: lastString,
                          fors: "quotes",
                          bool: false,
                          operator: charObj.operator,
                        });
                      }

                      return;
                    } else {
                      // console.log("else ",i);
                    }
                  }
                } else {
                  let firstString = 0;
                  let lastString = charObj.index;

                  let getFirst = 0;
                  let getLast = 0;
                  let ifNot = "";

                  for (let i = firstString; i <= lastString; i++) {
                    ifNot += strArr[i];
                  }

                  ifNot = ifNot.trim().toLowerCase();

                  if (ifNot !== "not" && ifNot !== "and" && ifNot !== "or") {
                    for (let i = lastString; i >= firstString; i--) {
                      let strA = strArr[i];

                      let plusA = strArr[i + 1] ? strArr[i + 1].toLowerCase() : undefined;
                      let plusB = strArr[i + 2] ? strArr[i + 2].toLowerCase() : undefined;
                      let plusC = strArr[i + 3] ? strArr[i + 3].toLowerCase() : undefined;
                      let plusD = strArr[i + 4] ? strArr[i + 4].toLowerCase() : undefined;

                      if (strA == '"') {
                        return;
                      } else if (strA == "(") {
                        if (i != lastString) {
                          indexs.push({
                            index: i,
                            fors: "quotes",
                            bool: false,
                            operator: charObj.operator,
                          });
                          indexs.push({
                            index: lastString,
                            fors: "quotes",
                            bool: false,
                            operator: charObj.operator,
                          });
                        }
                        return;
                      } else if (strA == ")" && hasWhiteSpace(plusA)) {
                        if (
                          (hasWhiteSpace(plusA) && plusB == "a" && plusC == "n" && plusD == "d") ||
                          (hasWhiteSpace(plusA) && plusB == "n" && plusC == "o" && plusD == "t") ||
                          (hasWhiteSpace(plusA) && plusB == "o" && plusC == "r")
                        ) {
                          return;
                        } else {
                          if (i != lastString) {
                            indexs.push({
                              index: i + 1,
                              fors: "quotes",
                              bool: false,
                              operator: charObj.operator,
                            });
                            indexs.push({
                              index: lastString,
                              fors: "quotes",
                              bool: false,
                              operator: charObj.operator,
                            });
                          }
                        }
                      } else {
                        let firstString1 = i;
                        let lastString1 = lastString;

                        getFirst = firstString1;
                        getLast = lastString1;
                      }
                    }

                    if (getFirst != getLast) {
                      indexs.push({
                        index: getFirst,
                        fors: "quotes",
                        bool: true,
                        operator: charObj.operator,
                      });
                      indexs.push({
                        index: getLast,
                        fors: "quotes",
                        bool: false,
                        operator: charObj.operator,
                      });
                    }
                  }
                }
              }
            });
          }
        }
        return indexs;
      };

      postMessage(addAutoQuotesAndPara(string));
    }
  };
};
