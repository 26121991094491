import * as Colors from "../../../../Styles-Elements/Colors";
import { Subtitle } from "../../../../Styles-Elements/Labels";
import { ClearButton, CustomRoundedButton } from "../../../../Styles-Elements/Buttons";
import { TextAreafield } from "../../../../Styles-Elements/Inputs";
import "./../AcademyPopups.css"
import booThinking from "../../../../image-assets/boo-thinking.png";
const strings = require('../../../../localisation_en.json')
const SubmitQuestion = ({ closePopup, message, submit }) => {
  return (
    <div className="container">
      <div className="container-modal" style={{ marginTop: '90px' }}>
        <div className="popup-boo-container positive">
          <img src={booThinking} width={120} height={120} alt="" />
        </div>
        <div className="container-modal-mid">
          <Subtitle text={strings.howCanWeHelp} color={Colors.nightPurple} textAlign={"center"} padding={"24px 0 28px 0"} />
          <TextAreafield onChange={message} placeholder={strings.typeYourQuestionHere} className={'question-textarea'} />
          <CustomRoundedButton
            onClick={submit}
            text={strings.submitQuestion}
            margin={"24px 0 8px 0"}
            color={Colors.nightPurple}
            backgroundColor={Colors.sharpGreen}
          />
          <ClearButton text={strings.cancel} onClick={() => closePopup()} />
        </div>
      </div>
    </div>
  );
};
export default SubmitQuestion;