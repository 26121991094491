import "./../advanceEditor.css";
import editorCloseRed from "../../image-assets/close-red.svg";
import editorSearch from "../../image-assets/searcheditor.svg";
// import Box from "@material-ui/core/Box";
import { Boxed } from "../../Styles-Elements/Box";
import isEvenDoubleQuotes from "../../editor/isEvenDoubleQuotes";
import { useEffect, useState } from "react";
import hasWhiteSpace from "../../editor/hasWhiteSpace";
import copyIcon from "../../image-assets/copyicon.svg";
import strings from "../../localisation_en.json";
import { Body } from "../../Styles-Elements/Labels";
import * as Colors from "../../Styles-Elements/Colors";
import socket from "../../socket";
import { useDispatch, useSelector } from "react-redux";
import { tooledUpLocalData } from "../../redux/reducers/getTooledupData";
import globalRequest from "../../GlobalModule/globalRequest";
import { NEW_API } from "../../Helpers/Enums";
import { changeLoader } from "../../redux/reducers/loader";
import { setSnackbar } from "../../redux/reducers/snackbar";
import copy from "copy-to-clipboard";
import removeAllSpaces from "../../editor/removeAllSpaces";
import userTypingImg from "../../image-assets/boo-happy.png";
import eyeIcon from "../../image-assets/Eye.svg";
import booNegative from "../../image-assets/boo-negative.png";
import CopySection from "../../GlobalModule/CopySection";
import TypingView from "../../GlobalModule/TypingView";

//@param
//currentActiveString = {
// string_boolean : "the boolean String."
//}

const randomId = parseInt(Math.random() * 9999 + 100);

const AdvanceEditorCompany = ({
  currentActiveString,
  wsResponse,
  currentStringCallback,
  isShareWithMe,
  isDeletedString,
}) => {
  //headText for headline text
  //contentArray has string_boolean as the object
  // {
  //   headText : "1",
  //   contentArray : [
  //     {
  //       string_boolean: "124 CD RECRUITMENT AGENCY LTD 124 ",
  //       index: [1, 2, 3, 4, 5],
  //     }
  //   ]
  // }

  // console.log(currentActiveString?.string_boolean);

  const [finalArray, setFinalArray] = useState([]);
  const [mainString, setMainString] = useState("");
  const auth = useSelector(tooledUpLocalData);
  const [editByClose, setEditByClose] = useState(false);
  const [socketData, setSocketData] = useState({});
  const dispatch = useDispatch();
  const [currentString, setCurrentString] = useState({});
  const [permission, setPermission] = useState(0);
  const [textCount, setTextCount] = useState(0);

  useEffect(() => {
    setCurrentString(currentActiveString);
    setPermission(currentActiveString?.string_detail?.permission);
    setTextCount(String(currentActiveString?.string_boolean ? currentActiveString?.string_boolean : "").trim().length);
  }, [currentActiveString]);

  useEffect(() => {
    if (currentString?.string_boolean) {
      let removeSpace = removeAllSpaces(currentString?.string_boolean);
      setMainString(removeSpace);
      currentStringCallback(removeSpace);
      // setMainString(currentString?.string_boolean);
    } else {
      setMainString(" ");
    }
  }, [currentString]);

  useEffect(() => {
    if (mainString) {
      const word = getAllWordWithOperator(mainString);
      setFinalArray(word);
      socketEmitting();
    }
  }, [mainString]);

  useEffect(() => {
    let data = socketData;
    if (data?.string_type == "company") {
      if (
        data.browser_type == "chrome" &&
        data.typing_user_id1 == auth?.user_id &&
        data.string_id == currentString?.id &&
        data.status == ""
      ) {
        htmlRender(data);
      }

      if (
        data.browser_type == "web" &&
        data.status == "" &&
        data.string_id == currentActiveString?.id &&
        data.typing_user_id1 == auth?.user_id &&
        data.random_id !== randomId
      ) {
        htmlRender(data);
      }

      if (data.status == "" && data.string_id == currentString?.id && data.typing_user_id1 != auth?.user_id) {
        htmlRender(data);
      }
    }
  }, [socketData]);

  const htmlRender = (data) => {
    try {
      let str = data.string_boolean;
      if (str) {
        setTextCount(String(str).trim().length);
        setMainString(str);
        currentStringCallback(data.string_boolean);
      }
    } catch (e) {}
  };

  useEffect(() => {
    socket.on("poststring", (data) => {
      setSocketData(data);
    });
  }, []);

  const socketEmitting = () => {
    if (editByClose) {
      let stringBoolean = String(mainString).trim();
      let data = {
        string_id: currentString?.id,
        user_id: auth?.user_id,
        typing_user_id: auth?.user_id,
        status: "release",
        name: auth?.firstname + " " + auth?.lastname,
        string_boolean: stringBoolean,
        string_type: "company",
        browser_type: "web",
        random_id: randomId,
      };
      currentStringCallback(stringBoolean);
      socket.emit("poststring", data);
      wsResponse(true);
    }
  };

  const getAllWordWithOperator = (str) => {
    let strA = `${str} and `;
    let strArr = [...strA.toLowerCase()];

    let newString = "";
    let indexArr = [];
    let finalA = [];
    let finalArry = [];

    strArr.forEach((char, index) => {
      let a = strArr[index] ? strArr[index].toLowerCase() : null;
      let b = strArr[index + 1] ? strArr[index + 1].toLowerCase() : null;
      let c = strArr[index + 2] ? strArr[index + 2].toLowerCase() : null;
      let d = strArr[index + 3] ? strArr[index + 3].toLowerCase() : null;
      let e = strArr[index + 4] ? strArr[index + 4].toLowerCase() : null;

      let conditionAnd =
        isEvenDoubleQuotes(index, str) && hasWhiteSpace(a) && b == "a" && c == "n" && d == "d" && hasWhiteSpace(e);
      let conditionNot =
        isEvenDoubleQuotes(index, str) && hasWhiteSpace(a) && b == "n" && c == "o" && d == "t" && hasWhiteSpace(e);
      let conditionOr = isEvenDoubleQuotes(index, str) && hasWhiteSpace(a) && b == "o" && c == "r" && hasWhiteSpace(d);

      if (conditionAnd || conditionOr || conditionNot) {
        finalA.push({
          string: newString,
          index: indexArr,
        });
        newString = "";
        indexArr = [];
      } else {
        newString += strArr[index];
        indexArr.push(index);
      }
    });

    if (finalA.length) {
      finalA.forEach((obj) => {
        let string = obj.string;
        let index = obj.index;
        let stringNew = "";
        let arrayNew = [];
        let operatorQuotes = [];
        let operatorQuotesString = "";

        index.forEach((ind, inds) => {
          let a = strArr[ind];
          if (!isEvenDoubleQuotes(ind, str)) {
            if (a != '"') {
              stringNew += strArr[ind];
              arrayNew.push(ind);
            }
          } else {
            if (a != '"') {
              operatorQuotes.push(ind);
              operatorQuotesString += strArr[ind];
            }
          }

          if (a == '"') {
            operatorQuotes.push(ind);
            operatorQuotesString += strArr[ind];
          }
        });
        finalArry.push({
          string: stringNew,
          index: arrayNew,
          operator: {
            string: operatorQuotesString,
            index: operatorQuotes,
          },
        });
        stringNew = "";
        arrayNew = [];
        operatorQuotes = [];
        operatorQuotesString = "";
      });
    }

    let word = finalArry.sort((a, b) => {
      return a.string < b.string ? -1 : 1;
    });

    let wordSorting = [];

    if (word.length) {
      word.forEach((obj, index) => {
        let string = String(obj.string);
        let upper = string.toUpperCase();
        let charInside = upper[0];

        let find = wordSorting.find((a) => a.headText == charInside);

        if (find) {
          let findIndex = wordSorting.findIndex((a) => a.headText == charInside);
          wordSorting[findIndex].contentArray.push({
            string_boolean: string.toUpperCase(),
            objects: obj,
          });
        } else {
          wordSorting.push({
            headText: charInside,
            contentArray: [
              {
                string_boolean: string.toUpperCase(),
                objects: obj,
              },
            ],
          });
        }
      });
    }
    return wordSorting;
  };

  const copyToClipboard = async () => {
    dispatch(changeLoader(true));

    let response = await globalRequest(
      "get",
      NEW_API.COPY_COMPANY_KEYWORD,
      {},
      {
        params: {
          user_id: auth?.user_id,
          type: currentString?.string_type,
          string_id: currentString?.id,
        },
      },
      true
    );
    let data = response?.data;
    if (data?.status == 1) {
      let strData = data?.data?.string_boolean;

      copy(strData);

      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: "String successfully copied",
          snackbarState: "success",
        })
      );
    } else {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: data?.message,
          snackbarState: "error",
        })
      );
    }

    dispatch(changeLoader(false));
  };

  const RenderButtons = () => {
    return (
      <></>
      // <div className="test-btns">
      //   <button className="test-btn">""</button>
      //   <button className="test-btn">*</button>
      //   <button className="test-btn">()</button>
      //   <button className="test-btn">OR</button>
      // </div>
    );
  };

  const StringBooleanContent = ({ string, objects }) => {
    const search = () => {
      window.open(`https://www.google.com/search?q=${string}`, "_blank");
    };

    const remove = () => {
      let str1 = String(mainString);
      const index1 = objects.index;
      const index2 = objects.operator.index;
      const index3 = [...index1, ...index2].sort((a, b) => (a < b ? -1 : 1)); // i have to delete this index3
      let first = index3[0];
      let last = index3[index3.length - 1];

      //if the zero index value is 0 then we will put the condition of and,or,not
      if (first == 0) {
        let a = str1[last + 1] ? str1[last + 1].toLowerCase() : undefined;
        let b = str1[last + 2] ? str1[last + 2].toLowerCase() : undefined;
        let c = str1[last + 3] ? str1[last + 3].toLowerCase() : undefined;
        let d = str1[last + 4] ? str1[last + 4].toLowerCase() : undefined;
        let e = str1[last + 5] ? str1[last + 5].toLowerCase() : undefined;

        let conditionAnd = hasWhiteSpace(a) && b == "a" && c == "n" && d == "d" && hasWhiteSpace(e);
        let conditionNot = hasWhiteSpace(a) && b == "n" && c == "o" && d == "t" && hasWhiteSpace(e);

        let conditionOr = hasWhiteSpace(a) && b == "o" && c == "r" && hasWhiteSpace(d);

        let s = str1;
        if (conditionAnd || conditionNot) {
          s = str1.substring(0, first) + str1.substring(last + 5);
        } else if (conditionOr) {
          s = str1.substring(0, first) + str1.substring(last + 4);
        } else {
          s = " ";
        }
        setTextCount(String(s).trim().length);
        setMainString(s);
      } else {
        let newString = str1.substring(0, first) + str1.substring(last + 1);
        setTextCount(String(newString).trim().length);
        setMainString(newString);
      }
      setEditByClose(true);
    };

    return (
      <>
        <Boxed display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
          <span className="p3 p3-width">{string}</span>
          <Boxed className="">
            <img
              onClick={() => {
                if (!isDeletedString) {
                  search();
                }
              }}
              src={editorSearch}
              alt=""
              className="editor-serach"
            />
            <img
              onClick={() => {
                if (!isDeletedString) {
                  remove();
                }
              }}
              src={editorCloseRed}
              alt=""
              className="editor-close"
            />
          </Boxed>
        </Boxed>
        <div class="line-dashed"></div>
      </>
    );
  };

  const MainContent = ({ headText, contentArray }) => {
    return (
      <>
        <p className="m-0 p2" style={{ marginBottom: "10px", fontWeight: "bold" }}>
          {headText}
        </p>
        {contentArray.map((obj, index) => {
          if (obj.string_boolean) {
            return <StringBooleanContent string={obj.string_boolean} objects={obj.objects} />;
          }
        })}
      </>
    );
  };

  const permissions = () => {
    let r =
      (auth?.access == "owner" && !isShareWithMe) ||
      (auth?.access == "admin" && !isShareWithMe) ||
      (auth?.access == "user" && permission == 0 && !isShareWithMe) ||
      (auth?.access == "owner" && isShareWithMe) ||
      (auth?.access == "admin" && isShareWithMe) ||
      (auth?.access == "user" && isShareWithMe)
        ? socketData?.status == ""
          ? null
          : socketData?.status == "typing" &&
            auth?.user_id != socketData.typing_user_id &&
            socketData?.typing_user_id != 0 &&
            socketData?.string_id == currentString?.id
          ? true
          : false
        : true;

    return r;
  };

  return (
    <div className="cl-dec-main-div" style={{ border: "2px solid #f1f1f1",borderRadius: "4px" }}>
      <RenderButtons />
      <div className={isDeletedString ? "add-gray-bg" : `${permissions() ? "add-gray-bg" : ""}`}>
        {isDeletedString ? (
          <div className="no-string-data">
            <img src={booNegative} className="boo-sad-img" />
            <Body
              text={strings.UnfortunatelyThestringownerhasremovedyourcollaborationaccess_text}
              color={Colors.darkPurple}
            />
          </div>
        ) : null}

        <div className="scroll-view cl-dec-sv" style={{ padding: "16px" }}>
          {finalArray.map((obj) => {
            return (
              <>
                <MainContent headText={obj.headText} contentArray={obj.contentArray} />
              </>
            );
          })}
        </div>

        {auth?.access == "user" && permission == 1 && !isShareWithMe ? (
          <TypingView socketData={{}} type="view" />
        ) : socketData?.status == "typing" &&
          auth?.user_id != socketData.typing_user_id &&
          socketData?.typing_user_id != 0 &&
          socketData.string_id == currentActiveString?.id ? (
          <TypingView socketData={socketData} type="typing" />
        ) : (
          <CopySection textCount={textCount} />
        )}
      </div>
    </div>
  );
};

export default AdvanceEditorCompany;
