import { useCallback, useEffect, useRef, useState } from "react";
import * as Colors from "../../../Styles-Elements/Colors";
import { LargeBody, Subtitle, LargeSmall } from "../../../Styles-Elements/Labels";
import { CustomRoundedButton } from "../../../Styles-Elements/Buttons";
import "./NewAcademy.css";
import SubmitQuestion from "../../Styles-Elements/AcademyPopups/childs/SubmitQuestion";
import strings from "../../../localisation_en.json";
import { useDispatch, useSelector } from "react-redux";
import { changeLessonData, currentLesson } from "../../../redux/reducers/lessonData";
import socket from "../../../socket";
import { NEW_API } from "../../../Helpers/Enums";
import globalRequest from "../../../GlobalModule/globalRequest";
import { changeLoader } from "../../../redux/reducers/loader";
import { tooledUpLocalData } from "../../../redux/reducers/getTooledupData";
import { setSnackbar } from "../../../redux/reducers/snackbar";
import { TextAreafield } from "../../../Styles-Elements/Inputs";
import cloudIcon from "../../image-assets/cloud-icon.svg";
import uploadIcon from "../../image-assets/cloud-computing.png";
import { changeLikeDislike, currentLikeDislike } from "../../../redux/reducers/likeDislikeChange";
import gabi1 from "../../image-assets/gabi1.png";
import mitch1 from "../../image-assets/mitch1.png";
import { changeSaveStatus } from "../../../redux/reducers/checkSaveStatus";
import { Boxed } from "../../../Styles-Elements/Box";
let textTimer = null;
const NewAcademy = () => {
  const lessons = useSelector(currentLesson);
  const dispatch = useDispatch();
  const auth = useSelector(tooledUpLocalData);
  const [submitQuestion, setSubmitQuestion] = useState(false);
  const [lessonId, setLessonId] = useState(0);
  const [note, setNote] = useState("");
  const [uploading, setUploading] = useState(cloudIcon);
  const videoId = useRef();
  const likeDislike = useSelector(currentLikeDislike);
  const [description, setDescription] = useState("");
  const iframeVideo = useRef();

  useEffect(() => {
    if (lessons !== null) {
      setLessonId(lessons?.id);
    }
    //id,name,description,video_url,is_fav,is_completed
  }, [lessons]);

  useEffect(() => {
    if (lessonId != 0) {
      getNoteApi();
    }
  }, [lessonId]);

  const askQuestionApi = useCallback(() => {
    if (description) {
      dispatch(changeLoader(true));
      globalRequest(
        "post",
        NEW_API.ACADEMY_ASK_QUESTION,
        {
          user_id: auth?.user_id,
          lesson_id: lessons?.id,
          team_id: auth?.team_id,
          chapter_id: lessons?.chapter_id,
          description: description,
        },
        {},
        true
      )
        .then((res) => {
          let data = res?.data;
          if (data?.status == 1) {
            setSubmitQuestion(false);
          }
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarMessage: data?.message,
              snackbarState: data?.status == 1 ? "success" : "error",
            })
          );
          dispatch(changeLoader(false));
        })
        .catch((err) => {
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarMessage: "something went wrong ",
              snackbarState: "error",
            })
          );
          dispatch(changeLoader(false));
        });
    } else {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: "Please type your question first.",
          snackbarState: "error",
        })
      );
    }
  });

  const getNoteApi = useCallback(() => {
    // dispatch(changeLoader(true));
    globalRequest(
      "get",
      NEW_API.ACADEMY_GET_NOTE,
      {},
      {
        params: {
          user_id: auth?.user_id,
          lesson_id: lessonId,
        },
      },
      true
    )
      .then((res) => {
        let data = res?.data;
        if (data?.status == 1) {
          let datas = data?.data;
          if (Object.keys(datas).length !== 0) {
            setNote(datas?.notes);
          } else {
            setNote("");
          }
        }
        // dispatch(changeLoader(false));
      })
      .catch((err) => {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: "something went wrong ",
            snackbarState: "error",
          })
        );
        // dispatch(changeLoader(false));
      });
  });

  const completeApi = useCallback(() => {
    dispatch(changeLoader(true));
    globalRequest(
      "post",
      NEW_API.ACADEMY_LESSON_COMPLETE_ACTION,
      {
        user_id: auth?.user_id,
        team_id: auth?.team_id,
        lesson_id: lessonId,
      },
      {},
      true
    )
      .then((res) => {
        let data = res?.data;
        if (data?.status == 1) {
          dispatch(
            changeLessonData({
              ...lessons,
              is_completed: 1,
            })
          );
          dispatch(changeLikeDislike(data?.data));
        }
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: data?.message,
            snackbarState: data?.status == 1 ? "success" : "error",
          })
        );
        dispatch(changeLoader(false));
      })
      .catch((err) => {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: "something went wrong ",
            snackbarState: "error",
          })
        );
        dispatch(changeLoader(false));
      });
  });

  useEffect(() => {
    setTimeout(() => {
      setUploading(cloudIcon);
    }, 2000);
  }, [uploading]);

  const requestFullScreen = () => {
    let videoFrame = iframeVideo.current;

    if (videoFrame.requestFullscreen) {
      videoFrame.requestFullscreen();
    } else if (videoFrame.mozRequestFullScreen) {
      videoFrame.mozRequestFullScreen();
    } else if (videoFrame.webkitRequestFullscreen) {
      videoFrame.webkitRequestFullscreen();
    } else if (videoFrame.msRequestFullscreen) {
      videoFrame.msRequestFullscreen();
    }
  };
  
  return (
    <>
      <div className="academy-holder">
        <Subtitle text={lessons?.name} color={Colors.darkPurple} padding={"0 0 12px 0"} />
        <div>
          <div className="academy-grid-block">
            <div className="top-video-holder">
              <div className="video-tag">
                <iframe
                  ref={iframeVideo}
                  src={lessons?.video_url}
                  width="100%"
                  height="400"
                  frameBorder="0"
                  title={lessons?.name}
                ></iframe>
              </div>
              <div style={{ justifyContent: "right", textAlign: "right" }}>
                <button
                  style={{
                    border: "none",
                    background: "transparent",
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                  onClick={requestFullScreen}
                >
                  Full screen
                </button>
              </div>
              <LargeBody text={strings.makeTrainingNotes} color={Colors.darkPurple} padding={"0 0 20px 0"} />
              <div className="textarea-block">
                <TextAreafield
                  onChange={(e) => {
                    setNote(e.target.value);
                    dispatch(changeSaveStatus("typing"));
                    clearTimeout(textTimer);
                    textTimer = setTimeout(() => {
                      if (lessonId) {
                        let data = {
                          chapter_id: lessons?.chapter_id,
                          lesson_id: lessons?.id,
                          text: e.target.value,
                          user_id: auth?.user_id,
                          team_id: auth?.team_id,
                        };
                        dispatch(changeSaveStatus("release"));
                        socket.emit("note_update", data);
                        setUploading(uploadIcon);
                      }
                    }, 3000);
                  }}
                  placeholder={strings.typeYourNotesHere}
                  className="academy-textarea"
                  value={note}
                />
                <img src={uploading} className="cloudicon" alt="" />
              </div>
            </div>
            <div className="">
              <LargeBody
                text={strings.lessonStatus}
                textAlign={"center"}
                color={Colors.darkPurple}
                padding={"0 0 12px 0px"}
              />
              <div className="get-question-holder lesson-status-holder">
                <CustomRoundedButton
                  text={lessons?.is_completed == 1 ? strings.completed : strings.markAsComplete}
                  borderRadius={"5px"}
                  padding={"8px 28px"}
                  className={lessons?.is_completed == 1 ? "academy-green-complete-btn" : "academy-green-btn"}
                  whiteSpace={"break-spaces"}
                  onClick={() => {
                    completeApi();
                  }}
                />
              </div>
            </div>
            <div className="">
              <LargeBody
                text={strings.needHelp}
                textAlign={"center"}
                color={Colors.darkPurple}
                padding={"0 0 12px 0px"}
              />
              <div className="get-question-holder">
                <CustomRoundedButton
                  text={strings.askQuestion}
                  borderRadius={"5px"}
                  padding={"8px 28px"}
                  className={"academy-purple-btn"}
                  whiteSpace={"break-spaces"}
                  onClick={() => setSubmitQuestion(true)}
                />
              </div>
            </div>
            <Boxed display="flex" flexDirection="column" alignItems="center">
              <LargeBody
                text={strings.lessonTrainer}
                textAlign={"center"}
                color={Colors.darkPurple}
                padding={"0 0 20px 0"}
              />
              <div className="top-welcome-holder" style={{width: '100%'}}>
                <div className="" style={{ position: "relative", zIndex: "2" }}>
                  <LargeSmall
                    text={lessons?.lesson_trainer == "" ? strings.mitch : strings.gabi}
                    textAlign={"center"}
                    color={Colors.nightGray}
                    padding={"0 0 0px 0"}
                  />
                  <LargeSmall
                    text={lessons?.lesson_trainer == "" ? strings.head : strings.coFounder}
                    textAlign={"center"}
                    color={Colors.nightGray}
                    padding={"0 0 20px 0"}
                    className="na-width130"
                  />
                </div>
                <div className="trainer-img-box">
                  <img src={lessons?.lesson_trainer == "" ? mitch1 : gabi1} className="trainer-img" alt="" />
                </div>
              </div>
            </Boxed>
            {submitQuestion ? (
              <SubmitQuestion
                closePopup={() => setSubmitQuestion(false)}
                submit={() => {
                  askQuestionApi();
                }}
                message={(e) => {
                  setDescription(e.target.value);
                }}
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};
export default NewAcademy;
