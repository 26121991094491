import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "antd/dist/antd.css";
import * as Colors from "../Styles-Elements/Colors";
import { Subtitle, Subheading } from "../Styles-Elements/Labels";
import { Textfield } from "../Styles-Elements/Inputs";
import {
  ClearButton,
  SecondaryRoundedButton,
} from "../Styles-Elements/Buttons";
import booHappy from "../image-assets/boo-happy.png";

import Scrollbar from "react-smooth-scrollbar";
import { KEYWORD_STRING, STORAGE } from "../Helpers/Enums";
import focus from "../GlobalModule/focus";
import closePopUpByKey from "../GlobalModule/closePopUpByKey";
import globalRequest from "../GlobalModule/globalRequest";
import { stringData } from "../redux/reducers/localString";
import { tooledUpLocalData } from "../redux/reducers/getTooledupData";
import { setSnackbar } from "../redux/reducers/snackbar";
import { changeLoader } from "../redux/reducers/loader";
import addDeleteGetLocalStorage from "../GlobalModule/addDeleteGetLocalStorage";
import { currentSidebar } from "../redux/reducers/sidebar";


function CreateFolder(props) {

  const strings = useSelector(stringData);
  const auth = useSelector(tooledUpLocalData);
  const typeParam = useSelector(currentSidebar);
  const dispatch = useDispatch();
  const [name, setName] = useState(null);

  let userId = auth?.user_id;
  let teamId = auth?.team_id;

  const submitRequest = async (e) => {
    e.preventDefault();


    if (name == null) {
      dispatch(setSnackbar({
        snackbarMessage: "Folder cannot be empty ",
        snackbarOpen: true,
        snackbarState: 'error'
      }))
    } else {

      dispatch(changeLoader(true));
      let url = KEYWORD_STRING.CREATE_FOLDER;
      let data = {
        team_id: teamId,
        folder_name: name,
        user_id: userId,
        string_type: typeParam,
      };

      let response = await globalRequest("post", url, data, {}, true);

      let data1 = response.data;
      if (data1.status == 1) {
        dispatch(setSnackbar({
          snackbarMessage: data1.message,
          snackbarOpen: true,
          snackbarState: 'success'
        }))
        props.closePopup(false);
        props.callback(true);
      } else {
        dispatch(setSnackbar({
          snackbarMessage: data1.message,
          snackbarOpen: true,
          snackbarState: 'error'
        }))
      }
      dispatch(changeLoader(false));
    }
  };

  useEffect(() => {
    focus("forFocus");
    closePopUpByKey(props.closePopup);
  }, []);

  return (
    <div className={"container"}>
      <div className="container-modal">
        <div className="popup-boo-container positive">
          <img src={booHappy} width={120} height={120} />
        </div>
        <form onSubmit={submitRequest}>
          <Scrollbar className="container-modal-mid">
            <Subtitle
              text={strings.LetsCreateAFolder}
              color={Colors.nightPurple}
              textAlign={"center"}
              padding={"24px 0"}
            />
            <Subheading
              text={strings.nameOfFolder}
              color={Colors.nightPurple}
              padding={"16px 0 8px 0"}
            />
            <Textfield
              id={"forFocus"}
              autoFocus={true}
              required={true}
              onChange={(e) => setName(e.target.value)}
              placeholder={strings.enterANameForYourFolder}
            />

            <SecondaryRoundedButton
              text={strings.create}
              margin={"24px 0 8px 0"}
              type="submit"
            />
            <ClearButton
              text={strings.cancel}
              onClick={() => props.closePopup()}
            />
          </Scrollbar>
        </form>
      </div>
    </div>
  );
}

export default CreateFolder
