import bracketReshuffeling from "./bracketReshuffle";
import isEvenDoubleQuotes from "./isEvenDoubleQuotes";

const colorTheString__ = (andIndexes, notIndexes, orIndexes, parent) => {
  const string = String(parent.innerText);

  if (andIndexes.length) {
    try {
      let childrens = parent.children;
      let j = 0;
      const func = (is) => {
        let i = andIndexes[is];
        if (andIndexes.length != is) {
          if (isEvenDoubleQuotes(i, string)) {
            setTimeout(() => {
              let first = childrens[i] ? childrens[i]?.innerText : "";
              let second = childrens[i + 1] ? childrens[i + 1]?.innerText : "";
              let third = childrens[i + 2] ? childrens[i + 2]?.innerText : "";

              let color1 = childrens[i]?.style?.color;
              let color2 = childrens[i + 1]?.style?.color;
              let color3 = childrens[i + 2]?.style?.color;

              if (childrens.length >= i) {
                if (first == "a" && second == "n" && third == "d") {
                  try {
                    childrens[i].innerText = childrens[i].innerText.toUpperCase();
                    childrens[i + 1].innerText = childrens[i + 1].innerText.toUpperCase();
                    childrens[i + 2].innerText = childrens[i + 2].innerText.toUpperCase();
                  } catch (error) {}
                }

                if (color1 != "rgb(37, 146, 224)" && color2 != "rgb(37, 146, 224)" && color3 != "rgb(37, 146, 224)") {
                  if (first.toLowerCase() == "a" && second.toLowerCase() == "n" && third.toLowerCase() == "d") {
                    try {
                      childrens[i].style.color = "#2592E0";
                      childrens[i + 1].style.color = "#2592E0";
                      childrens[i + 2].style.color = "#2592E0";
                    } catch (error) {}
                  }
                }
              }
              func(j);
              j++;
            }, 0);
          }
        }
      };
      func(j);
    } catch (e) {
      console.log("error and ", e);
    }
  }

  if (notIndexes.length) {
    try {
      let childrens = parent.children;
      let k = 0;
      const func1 = (is) => {
        let i = notIndexes[is];
        if (is != notIndexes.length) {
          if (isEvenDoubleQuotes(i, string)) {
            setTimeout(() => {
              let first = childrens[i] ? childrens[i]?.innerText : "";
              let second = childrens[i + 1] ? childrens[i + 1]?.innerText : "";
              let third = childrens[i + 2] ? childrens[i + 2]?.innerText : "";

              let color1 = childrens[i]?.style?.color;
              let color2 = childrens[i + 1]?.style?.color;
              let color3 = childrens[i + 2]?.style?.color;

              if (childrens.length >= i) {
                if (first == "n" && second == "o" && third == "t") {
                  try {
                    childrens[i].innerText = childrens[i].innerText.toUpperCase();
                    childrens[i + 1].innerText = childrens[i + 1].innerText.toUpperCase();
                    childrens[i + 2].innerText = childrens[i + 2].innerText.toUpperCase();
                  } catch (e) {}
                }

                if (color1 != "rgb(230, 108, 52)" && color2 != "rgb(230, 108, 52)" && color3 != "rgb(230, 108, 52)") {
                  if (first.toLowerCase() == "n" && second.toLowerCase() == "o" && third.toLowerCase() == "t") {
                    try {
                      childrens[i].style.color = "#e66c34";
                      childrens[i + 1].style.color = "#e66c34";
                      childrens[i + 2].style.color = "#e66c34";
                    } catch (e) {}
                  }
                }
              }
              func1(k);
              k++;
            }, 0);
          }
        }
      };
      func1(k);
    } catch (e) {
      console.log("error and ", e);
    }
  }

  if (orIndexes.length) {
    try {
      let childrens = parent.children;
      let l = 0;
      const func2 = (is) => {
        let i = orIndexes[is];
        if (is != orIndexes.length) {
          if (isEvenDoubleQuotes(i, string)) {
            setTimeout(() => {
              let first = childrens[i] ? childrens[i]?.innerText : "";
              let second = childrens[i + 1] ? childrens[i + 1]?.innerText : "";

              let color1 = childrens[i]?.style?.color;
              let color2 = childrens[i + 1]?.style?.color;

              if (childrens.length >= i) {
                if (first == "o" && second == "r") {
                  try {
                    childrens[i].innerText = childrens[i].innerText.toUpperCase();
                    childrens[i + 1].innerText = childrens[i + 1].innerText.toUpperCase();
                  } catch (e) {}
                }

                if (color1 != "rgb(34, 180, 122)" && color2 != "rgb(34, 180, 122)") {
                  if (first.toLowerCase() == "o" && second.toLowerCase() == "r") {
                    try {
                      childrens[i].style.color = "#22b47a";
                      childrens[i + 1].style.color = "#22b47a";
                    } catch (e) {}
                  }
                }
              }
              func2(l);
              l++;
            }, 0);
          }
        }
      };
      func2(l);
    } catch (e) {
      console.log("error and ", e);
    }
  }

  bracketReshuffeling(parent);
};
export default colorTheString__;
