import findAllAndOrNotInsideQuotation from "./findAllAndOrNotInsideQuotation";

const removeAllSpaces = (str) => {
  if (str) {
    let strString = String(str);

    const replaceAt = function (str, index, replacement) {
      return str.substring(0, index) + replacement + str.substring(index + replacement.length);
    };

    let rep1 = strString
      .replace(/\(\s+/g, "(")
      .replace(/\s+\)/g, ")")
      .replace(/\"\s+/g, '"')
      .replace(/\s+\"/g, '"')
      .replace(/\s+/g, " ")

      .replace(/"and"/g, `" AND "`)
      .replace(/\)\sand"/g, `) AND "`)
      .replace(/"and\s\(/g, `" AND (`)
      .replace(/"and\s/g, `" AND `)
      .replace(/\sand"/g, ` AND "`)
      .replace(/"and\(/g, `" AND (`)

      .replace(/"or"/g, `" OR "`)
      .replace(/\)\sor"/g, `) OR "`)
      .replace(/"or\s\(/g, `" OR (`)
      .replace(/"or\s/g, `" OR `)
      .replace(/\sor"/g, ` OR "`)
      .replace(/"or\(/g, `" OR (`)

      .replace(/"not"/g, `" NOT "`)
      .replace(/\)\snot"/g, `) NOT "`)
      .replace(/"not\s\(/g, `" NOT (`)
      .replace(/"not\s/g, `" NOT `)
      .replace(/\snot"/g, ` NOT "`)
      .replace(/"not\(/g, `" NOT (`)

      .replace(/\)\s+and\s+\(/g, ") AND (")
      .replace(/\)\s+not\s+\(/g, ") NOT (")
      .replace(/\)\s+or\s+\(/g, ") OR (")

      .replace(/"AND"/g, `" AND "`)
      .replace(/\)\sAND"/g, `) AND "`)
      .replace(/"AND\s\(/g, `" AND (`)
      .replace(/"AND\s/g, `" AND `)
      .replace(/\sAND"/g, ` AND "`)

      .replace(/"OR"/g, `" OR "`)
      .replace(/\)\sOR"/g, `) OR "`)
      .replace(/"OR\s\(/g, `" OR (`)
      .replace(/"OR\s/g, `" OR `)
      .replace(/\sOR"/g, ` OR "`)

      .replace(/"NOT"/g, `" NOT "`)
      .replace(/\)\sNOT"/g, `) NOT "`)
      .replace(/"NOT\s\(/g, `" NOT (`)
      .replace(/"NOT\s/g, `" NOT `)
      .replace(/\sNOT"/g, ` NOT "`)

      .replace(/\)\s+AND\s+\(/g, ") AND (")
      .replace(/\)\s+NOT\s+\(/g, ") NOT (")
      .replace(/\)\s+OR\s+\(/g, ") OR (")

      .replace(/\)AND\(/g, ") AND (")
      .replace(/\)NOT\(/g, ") NOT (")
      .replace(/\)OR\(/g, ") OR (")

      .replace(/"AND\(/g, `" AND (`)
      .replace(/"OR\(/g, `" OR (`)
      .replace(/"NOT\(/g, `" NOT (`)

      .replace(/\)AND"/g, `) AND "`)
      .replace(/\)NOT"/g, `) NOT "`)
      .replace(/\)OR"/g, `) OR "`)

      .replace(/AND"/g, `AND "`)
      .replace(/NOT"/g, `NOT "`)
      .replace(/OR"/g, `OR "`)

      .replace(/"\s+OR\s+" OR\s/g, `" OR "or `)
      .replace(/"\s+OR\s+" NOT\s/g, `" OR "not `)
      .replace(/"\s+OR\s+" AND\s/g, `" OR "and `)

      .replace(/"\s+AND\s+" OR\s/g, `" AND "or `)
      .replace(/"\s+AND\s+" NOT\s/g, `" AND "not `)
      .replace(/"\s+AND\s+" AND\s/g, `" AND "and `)

      .replace(/"\s+NOT\s+" OR\s/g, `" NOT "or `)
      .replace(/"\s+NOT\s+" NOT\s/g, `" NOT "not `)
      .replace(/"\s+NOT\s+" AND\s/g, `" NOT "and `)

    let find = findAllAndOrNotInsideQuotation(rep1);
    let sss = String(rep1);

    let and = find.and;
    let or = find.or;
    let not = find.not;

    and.forEach((obj) => {
      sss = replaceAt(sss, obj, "a");
      sss = replaceAt(sss, obj + 1, "n");
      sss = replaceAt(sss, obj + 2, "d");
    });
    not.forEach((obj) => {
      sss = replaceAt(sss, obj, "n");
      sss = replaceAt(sss, obj + 1, "o");
      sss = replaceAt(sss, obj + 2, "t");
    });
    or.forEach((obj) => {
      sss = replaceAt(sss, obj, "o");
      sss = replaceAt(sss, obj + 1, "r");
    });

    sss = sss.replace(`not"`, `NOT "`);

    return sss;
  } else {
    return str;
  }
};

export default removeAllSpaces;
