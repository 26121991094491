import "antd/dist/antd.css";
import * as Colors from "../Styles-Elements/Colors";
import { Subtitle, LargeBody } from "../Styles-Elements/Labels";
import {
  ClearButton,
  CustomRoundedButton,
} from "../Styles-Elements/Buttons";
import booThinking from "../image-assets/boo-thinking.png";
import React, { useState, useEffect } from "react";


// Material UI for the snackbar
import Scrollbar from "react-smooth-scrollbar";
import { KEYWORD_STRING } from "../Helpers/Enums";
import closePopUpByKey from "../GlobalModule/closePopUpByKey";
import { tooledUpLocalData } from "../redux/reducers/getTooledupData";
import { useSelector, useDispatch } from "react-redux";
import globalRequest from "../GlobalModule/globalRequest";
import { setSnackbar } from "../redux/reducers/snackbar";
import { changeLoader } from "../redux/reducers/loader";
// Importing localised strings
const strings = require("../localisation_en.json");


function DeleteStrings(props) {

  const auth = useSelector(tooledUpLocalData)
  const dispatch = useDispatch()

  let userId = auth?.user_id;
  const [loading, setLoading] = useState(false);
  const collaborationCount = props.collaborationCount;

  const submitRequest = async () => {

    dispatch(changeLoader(true));

    let url = KEYWORD_STRING.DELETE_STRING;
    let body = {
      team_id: auth?.team_id,
      user_id: userId,
      string_id: props.stringId,
      type: "single",
    };

    let response = await globalRequest("post", url, body, {}, true);
    let data = response.data;
    if (data.status == 1) {
      dispatch(setSnackbar({
        snackbarMessage: data.message,
        snackbarOpen: true,
        snackbarState: "success",
      }));
      props.callback(true,props.stringId);
    } else {
      dispatch(setSnackbar({
        snackbarMessage: data.message,
        snackbarOpen: true,
        snackbarState: "error",
      }));
    }

    dispatch(changeLoader(false));

  };

  useEffect(() => {
    closePopUpByKey(props.closePopup);
  }, []);

  return (
    <div className="container">
      <div className="container-modal">
        <div className="popup-boo-container negative">
          <img src={booThinking} width={120} height={120} />
        </div>
        <Scrollbar className="container-modal-mid">
          <Subtitle
            text={
              collaborationCount > 0
                ? strings.deleteStringDiff
                : strings.doYouWantToDeleteTheString
            }
            color={Colors.nightPurple}
            textAlign={"center"}
            padding={"24px 0"}
          />
          <LargeBody
            text={
              collaborationCount > 0
                ? `By deleting your ${props.stringName} string, all current collaborators will also lose permanent access.`
                : `The ${props.stringName} string will be deleted permanently. You will not be able to undo this action once you proceed.`
            }
            textAlign={"center"}
            color={Colors.darkGray}
            padding={"0 0 16px 0"}
          />
          <CustomRoundedButton
            text={collaborationCount > 0 ? "Proceed" : strings.delete}
            margin={"24px 0 8px 0"}
            color={Colors.mainLight}
            onClick={submitRequest}
            backgroundColor={Colors.lightRed}
          />
          <ClearButton
            text={strings.cancel}
            onClick={() => props.closePopup()}
          />
        </Scrollbar>
      </div>
    </div>
  );
}

export default DeleteStrings;
