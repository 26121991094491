import * as Colors from "../Styles-Elements/Colors";
import { Body, Bold, LabelWrapper, LargeBody, LargeSmallSemiBold } from "../Styles-Elements/Labels";
import booSurprisedImg from "../image-assets/boo-surprised.png";
import closeRedIcon from "../image-assets/close-red.png";
import infoIcon from "../image-assets/info.svg";
import { Boxed } from "../Styles-Elements/Box";
import strings from "../localisation_en.json";
import { BorderedRoundedButton, MainRoundedButton } from "../Styles-Elements/Buttons";
import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { changeAutoEnrichBulb, currentAutoEnrichBulb } from "../redux/reducers/autoEnrichBulb";
import { Checkbox } from "@mui/material";
import { currentIntelCount } from "../redux/reducers/intelCountKeywordSuggestion";
import UseCreditDialog from "../dialogs/UseCreditDialog";
const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#FEFEFE",
      color: "rgba(55, 55, 55, 1)",
      maxWidth: 381,
      fontSize: theme.typography.pxToRem(12),
      padding: "15px 15px",
      borderRadius: "6px",
      boxShadow:
        "0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 5px 6px -2px rgba(0, 0, 0, 0.05), 0px 0px 15px -3px rgb(0, 0, 0, 0.10)",
    },
  })
);
const IntelView = ({ confirm, cancel, racoonIntelCheckbox }) => {
  const dispatch = useDispatch();
  const autoRichValue = useSelector(currentAutoEnrichBulb);
  const [intelValue, setIntelValue] = useState(true);

  const intelCount = useSelector(currentIntelCount);

  return (
    <>
      <Boxed
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        className="success-editor-bottom-view"
        style={{ position: "relative", height: "auto" }}
      >
        <Boxed>
          <Boxed display={"flex"} alignItems={"center"}>
            <div className="popup-boo-container positive" style={{ margin: "0", width: "60px", height: "60px" }}>
              <img src={booSurprisedImg} width="60" height="60" alt="" />
            </div>
            <LabelWrapper display={"flex"} alignItems={"center"} padding={"0 0 0 10px"}>
              <Bold text={intelCount} color={"#FF66C4"} padding={"0px"} margin={"0px 0px 0px 0px"} />
              <Body
                text={strings.newKeywordSuggestionsAvailable}
                color={Colors.nightGray}
                padding={"0px 0px 0px 4px"}
                margin={"0px 0px 0px 0px"}
              />
            </LabelWrapper>
            <Boxed display={"flex"} alignItems={"flex-start"} justifyContent={"space-between"} margin={"0 0 0 15px"}>
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <LargeSmallSemiBold text={"What’s next?"} color={Colors.nightGray} padding={"0 0 16px 0"} />
                    <p style={{ fontFamily: "Poppins", fontSize: "12px" }}>
                      If you agree with the keyword suggestions provided in{" "}
                      <span style={{ color: "#FF66C4" }}>pink</span> and would like to incorporate them, click “
                      <strong style={{ color: "#00E4A2" }}>Confirm</strong>”.
                    </p>
                    <p>
                      You can <strong style={{ color: "#373737" }}>remove</strong> any unwanted suggestions by hovering
                      over a suggestion and clicking the cross.
                    </p>
                    <span className="red-chip">
                      "analyse" <img src={closeRedIcon} style={{ marginLeft: "4px", cursor: "pointer" }} />
                    </span>
                  </React.Fragment>
                }
                placement="left-end"
              >
                <img src={infoIcon} className="info-icon" alt="" />
              </HtmlTooltip>
            </Boxed>
          </Boxed>
        </Boxed>
        <Boxed display={"flex"} flexDirection={"row"} alignItems={"center"} gap={"10px"}>
          <BorderedRoundedButton
            text={strings.Close}
            className={"sharpgreen-outline-btn"}
            padding={"8px 16px"}
            onClick={() => {
              cancel();
              dispatch(
                changeAutoEnrichBulb({
                  ...autoRichValue,
                  isClicked: false,
                })
              );
            }}
          />
          <MainRoundedButton
            onClick={confirm}
            text={strings.confirm}
            className={"sharpgreen-fill-btn"}
            padding={"8px 16px"}
          />
        </Boxed>
      </Boxed>
    </>
  );
};
export default IntelView;
