import { convertStringForTranslate } from "./StringGenrateForTranslate";
import { convertTranslatedWordInString } from "./StringGenrateAfterTranslate";
import { glossaryFileDataByLanguage, saveDataInGlossary } from "./Glossary";
/**
 * save data in glossary file
 */
export const saveDataInGlossaryFile = async (input) => {
  await saveDataInGlossary(input);
};
/**
 * Number Format
 */
export const numberFormat = (value) => {
  if (value) {
    value = value.toLocaleString("en-US");
    return value;
  }
  return 0.0;
};
/**
 * check array with lowecase & trim
 */
export const findIndexInArray = (arr, q) =>
  arr.findIndex((item) => q.toLowerCase().trim() === item.toLowerCase().trim());

/**
 * get gender specific language
 */
export const genderSpecificLanguages = () => {
  return [
    "sq",
    "am",
    "ar",
    "bg",
    "ca",
    "hr",
    "cs",
    "nl",
    "ee",
    "fr",
    "gl",
    "ka",
    "de",
    "gn",
    "he",
    "hu",
    "it",
    "jv",
    "kn",
    "km",
    "ko",
    "ky",
    "lv",
    "lt",
    "ml",
    "mt",
    "mn",
    "my",
    "om",
    "pl",
    "pt",
    "qu",
    "ro",
    "sn",
    "si",
    "sk",
    "sl",
    "es",
    "sv",
    "ta",
    "te",
    "ti",
    "tr",
    "uz",
    "vi",
    "cy",
    "xh",
    "yo",
  ];
};

/**
 * get language list
 */
export const getLanguageList = () => {
  let languages = [
    {
      name: "Afrikaans",
      code: "af",
    },
    {
      name: "Albanian",
      code: "sq",
    },
    {
      name: "Amharic",
      code: "am",
    },
    {
      name: "Arabic",
      code: "ar",
    },
    {
      name: "Armenian",
      code: "hy",
    },
    {
      name: "Assamese",
      code: "as",
    },
    {
      name: "Aymara",
      code: "ay",
    },
    {
      name: "Azerbaijani",
      code: "az",
    },
    {
      name: "Bambara",
      code: "bm",
    },
    {
      name: "Basque",
      code: "eu",
    },
    {
      name: "Belarusian",
      code: "be",
    },
    {
      name: "Bengali",
      code: "bn",
    },
    {
      name: "Bhojpuri",
      code: "bho",
    },
    {
      name: "Bosnian",
      code: "bs",
    },
    {
      name: "Bulgarian",
      code: "bg",
    },
    {
      name: "Catalan",
      code: "ca",
    },
    {
      name: "Cebuano",
      code: "ceb",
    },
    {
      name: "Chinese (Simplified)",
      code: "zh-CN",
    },
    {
      name: "Chinese (Traditional)",
      code: "zh-TW",
    },
    {
      name: "Corsican",
      code: "co",
    },
    {
      name: "Croatian",
      code: "hr",
    },
    {
      name: "Czech",
      code: "cs",
    },
    {
      name: "Danish",
      code: "da",
    },
    {
      name: "Dhivehi",
      code: "dv",
    },
    {
      name: "Dogri",
      code: "doi",
    },
    {
      name: "Dutch",
      code: "nl",
    },
    {
      name: "English",
      code: "en",
    },
    {
      name: "Esperanto",
      code: "eo",
    },
    {
      name: "Estonian",
      code: "et",
    },
    {
      name: "Ewe",
      code: "ee",
    },
    {
      name: "Filipino (Tagalog)",
      code: "fil",
    },
    {
      name: "Finnish",
      code: "fi",
    },
    {
      name: "French",
      code: "fr",
    },
    {
      name: "Frisian",
      code: "fy",
    },
    {
      name: "Galician",
      code: "gl",
    },
    {
      name: "Georgian",
      code: "ka",
    },
    {
      name: "German",
      code: "de",
    },
    {
      name: "Greek",
      code: "el",
    },
    {
      name: "Guarani",
      code: "gn",
    },
    {
      name: "Gujarati",
      code: "gu",
    },
    {
      name: "Haitian Creole",
      code: "ht",
    },
    {
      name: "Hausa",
      code: "ha",
    },
    {
      name: "Hawaiian",
      code: "haw",
    },
    {
      name: "Hebrew",
      code: "he",
    },
    {
      name: "Hindi",
      code: "hi",
    },
    {
      name: "Hmong",
      code: "hmn",
    },
    {
      name: "Hungarian",
      code: "hu",
    },
    {
      name: "Icelandic",
      code: "is",
    },
    {
      name: "Igbo",
      code: "ig",
    },
    {
      name: "Ilocano",
      code: "ilo",
    },
    {
      name: "Indonesian",
      code: "id",
    },
    {
      name: "Irish",
      code: "ga",
    },
    {
      name: "Italian",
      code: "it",
    },
    {
      name: "Japanese",
      code: "ja",
    },
    {
      name: "Javanese",
      code: "jv",
    },
    {
      name: "Kannada",
      code: "kn",
    },
    {
      name: "Kazakh",
      code: "kk",
    },
    {
      name: "Khmer",
      code: "km",
    },
    {
      name: "Kinyarwanda",
      code: "rw",
    },
    {
      name: "Konkani",
      code: "gom",
    },
    {
      name: "Korean",
      code: "ko",
    },
    {
      name: "Krio",
      code: "kri",
    },
    {
      name: "Kurdish",
      code: "ku",
    },
    {
      name: "Kurdish (Sorani)",
      code: "ckb",
    },
    {
      name: "Kyrgyz",
      code: "ky",
    },
    {
      name: "Lao",
      code: "lo",
    },
    {
      name: "Latin",
      code: "la",
    },
    {
      name: "Latvian",
      code: "lv",
    },
    {
      name: "Lingala",
      code: "ln",
    },
    {
      name: "Lithuanian",
      code: "lt",
    },
    {
      name: "Luganda",
      code: "lg",
    },
    {
      name: "Luxembourgish",
      code: "lb",
    },
    {
      name: "Macedonian",
      code: "mk",
    },
    {
      name: "Maithili",
      code: "mai",
    },
    {
      name: "Malagasy",
      code: "mg",
    },
    {
      name: "Malay",
      code: "ms",
    },
    {
      name: "Malayalam",
      code: "ml",
    },
    {
      name: "Maltese",
      code: "mt",
    },
    {
      name: "Maori",
      code: "mi",
    },
    {
      name: "Marathi",
      code: "mr",
    },
    {
      name: "Meiteilon (Manipuri)",
      code: "mni-Mtei",
    },
    {
      name: "Mizo",
      code: "lus",
    },
    {
      name: "Mongolian",
      code: "mn",
    },
    {
      name: "Myanmar (Burmese)",
      code: "my",
    },
    {
      name: "Nepali",
      code: "ne",
    },
    {
      name: "Norwegian",
      code: "no",
    },
    {
      name: "Nyanja (Chichewa)",
      code: "ny",
    },
    {
      name: "Odia (Oriya)",
      code: "or",
    },
    {
      name: "Oromo",
      code: "om",
    },
    {
      name: "Pashto",
      code: "ps",
    },
    {
      name: "Persian",
      code: "fa",
    },
    {
      name: "Polish",
      code: "pl",
    },
    {
      name: "Portuguese (Portugal, Brazil)",
      code: "pt",
    },
    {
      name: "Punjabi",
      code: "pa",
    },
    {
      name: "Quechua",
      code: "qu",
    },
    {
      name: "Romanian",
      code: "ro",
    },
    {
      name: "Russian",
      code: "ru",
    },
    {
      name: "Samoan",
      code: "sm",
    },
    {
      name: "Sanskrit",
      code: "sa",
    },
    {
      name: "Scots Gaelic",
      code: "gd",
    },
    {
      name: "Sepedi",
      code: "nso",
    },
    {
      name: "Serbian",
      code: "sr",
    },
    {
      name: "Sesotho",
      code: "st",
    },
    {
      name: "Shona",
      code: "sn",
    },
    {
      name: "Sindhi",
      code: "sd",
    },
    {
      name: "Sinhala (Sinhalese)",
      code: "si",
    },
    {
      name: "Slovak",
      code: "sk",
    },
    {
      name: "Slovenian",
      code: "sl",
    },
    {
      name: "Somali",
      code: "so",
    },
    {
      name: "Spanish",
      code: "es",
    },
    {
      name: "Sundanese",
      code: "su",
    },
    {
      name: "Swahili",
      code: "sw",
    },
    {
      name: "Swedish",
      code: "sv",
    },
    {
      name: "Tagalog (Filipino)",
      code: "tl",
    },
    {
      name: "Tajik",
      code: "tg",
    },
    {
      name: "Tamil",
      code: "ta",
    },
    {
      name: "Tatar",
      code: "tt",
    },
    {
      name: "Telugu",
      code: "te",
    },
    {
      name: "Thai",
      code: "th",
    },
    {
      name: "Tigrinya",
      code: "ti",
    },
    {
      name: "Tsonga",
      code: "ts",
    },
    {
      name: "Turkish",
      code: "tr",
    },
    {
      name: "Turkmen",
      code: "tk",
    },
    {
      name: "Twi (Akan)",
      code: "ak",
    },
    {
      name: "Ukrainian",
      code: "uk",
    },
    {
      name: "Urdu",
      code: "ur",
    },
    {
      name: "Uyghur",
      code: "ug",
    },
    {
      name: "Uzbek",
      code: "uz",
    },
    {
      name: "Vietnamese",
      code: "vi",
    },
    {
      name: "Welsh",
      code: "cy",
    },
    {
      name: "Xhosa",
      code: "xh",
    },
    {
      name: "Yiddish",
      code: "yi",
    },
    {
      name: "Yoruba",
      code: "yo",
    },
    {
      name: "Zulu",
      code: "zu",
    },
  ];
  return languages;
};
/**
 * read data from glossary file and remove all already translated words
 */
export const glossaryDataGet = async (input = {}) => {
  let laststring = input.laststring;
  let convertedStringForTranslation = await convertStringForTranslate(laststring);
  let glossaryFileData = await glossaryFileDataByLanguage(input);
  if (glossaryFileData?.status == 1 && glossaryFileData?.total_row > 1) {
    let arr = glossaryFileData?.data;
    let allMatchedCharCounter = 0;
    let uniqueWordArray = [];
    let alreadyTranslatedWords = [];
    convertedStringForTranslation?.uniqueWord.map((item) => {
      let obj = arr.find((o) => o?.word.toLowerCase() == item.toLowerCase());
      if (typeof obj !== "undefined") {
        allMatchedCharCounter += item.length;
        alreadyTranslatedWords.push({
          word: obj?.word,
          translate_male: obj?.translate_male,
          translate_female: obj?.translate_female,
        });
      } else {
        uniqueWordArray.push(item);
      }
    });
    let totalWords =
      convertedStringForTranslation?.uniqueWord.join(",").length -
      (convertedStringForTranslation?.uniqueWord.length - 1);
    //return [totalWords - allMatchedCharCounter, uniqueWordArray, alreadyTranslatedWords, glossaryFileData];
    return {
      status: glossaryFileData?.status,
      remaining_char_count: totalWords - allMatchedCharCounter,
      unmatched_words: uniqueWordArray,
      matched_words: alreadyTranslatedWords,
    };
  } else {
    let counts =
      convertedStringForTranslation?.uniqueWord.join(",").length -
      (convertedStringForTranslation?.uniqueWord.length - 1);
    return {
      status: glossaryFileData?.status,
      remaining_char_count: counts,
      unmatched_words: convertedStringForTranslation?.uniqueWord,
      matched_words: [],
    };
  }
};
/**
 * calculate Percentage
 */
export const calculatePercentage = (input) => {
  let p = 0;
  if (Number(input?.char) >= input?.limit) {
    p = 100;
  } else {
    p = (input?.char * 100) / input?.limit;
  }
  return p + "%";
};
/**
 * calculate estimate char count
 */

export const getEstimateCount = async (input) => {
  let converted_string = await convertStringForTranslate(input?.laststring);
  let totalchar = 0;
  let allWord = 0;
  if (converted_string?.uniqueWord.length > 0) {
    totalchar = converted_string?.uniqueWord.join(",").length - (converted_string?.uniqueWord.length - 1);
    return {
      estimate_characters: totalchar,
    };
  }
  return {
    estimate_characters: 0,
  };
};
/**
 *Get colored string
 */
export const getColordString = async (str) => {
  let getString = await convertStringForTranslate(str);
  if (getString?.uniqueWord.length) {
    let d = await convertTranslatedWordInString(str, getString?.uniqueWord, getString);
    return d?.data;
  }
  return "";
};
