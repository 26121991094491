import { Boxed } from "../../../Styles-Elements/Box";
import { Body } from "../../../Styles-Elements/Labels";
import unFillWishlist from "../../image-assets/unfillwishlist.svg";
import fillWishlist from "../../image-assets/fillwishlist.svg";
import checkRoundGray from "../../image-assets/check-round-gray.svg";
import lockIcon from "../../../image-assets/lock.svg";
import checkRoundPurple from "../../image-assets/check-round-purple.svg";
import * as Colors from "../../../Styles-Elements/Colors";
import { useCallback, useEffect, useState } from "react";
import globalRequest from "../../../GlobalModule/globalRequest";
import { NEW_API } from "../../../Helpers/Enums";
import { useDispatch, useSelector } from "react-redux";
import { tooledUpLocalData } from "../../../redux/reducers/getTooledupData";
import { setSnackbar } from "../../../redux/reducers/snackbar";
import { changeLoader } from "../../../redux/reducers/loader";
import { changeLessonData, currentLesson } from "../../../redux/reducers/lessonData";
import { changeLikeDislike } from "../../../redux/reducers/likeDislikeChange";
import { currentSaveStatus } from "../../../redux/reducers/checkSaveStatus";
import { currentTrial } from "../../../redux/reducers/getTrial";

const Lesson = ({ obj, ind, isLocked = false }) => {
  const [like, setLike] = useState(unFillWishlist);
  const [complete, setComplete] = useState(checkRoundGray);
  const auth = useSelector(tooledUpLocalData);
  const dispatch = useDispatch();
  const lessons = useSelector(currentLesson);
  const saveStatus = useSelector(currentSaveStatus);
  const trialData = useSelector(currentTrial);

  useEffect(() => {
    setComplete(obj?.is_completed == 1 ? checkRoundPurple : checkRoundGray);
    setLike(obj?.is_fav == 1 ? fillWishlist : unFillWishlist);
  }, [obj]);

  const likeApi = useCallback(() => {
    dispatch(changeLoader(true));
    globalRequest(
      "post",
      NEW_API.ACADEMY_FAVORITE_UNFAVORITE,
      {
        user_id: auth?.user_id,
        team_id: auth?.team_id,
        lesson_id: obj?.id,
      },
      {},
      true
    )
      .then((res) => {
        let data = res?.data;
        if (data?.status == 1) {
          if (obj?.is_fav == 1) {
            setLike(unFillWishlist);
          } else {
            setLike(fillWishlist);
          }
          dispatch(changeLikeDislike(data?.data));
        }
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: data?.message,
            snackbarState: data?.status == 1 ? "success" : "error",
          })
        );
        dispatch(changeLoader(false));
      })
      .catch((err) => {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: "something went wrong ",
            snackbarState: "error",
          })
        );
        dispatch(changeLoader(false));
      });
  });

  const handleLike = () => {
    likeApi();
  };

  const updateLastViewedNote = useCallback((chapterId, lessonId) => {
    dispatch(changeLoader(true));
    globalRequest(
      "post",
      NEW_API.ACADEMY_POST_LAST_VIEWED_NOTE,
      {
        user_id: auth?.user_id,
        lesson_id: lessonId,
        chapter_id: chapterId,
      },
      {},
      true
    )
      .then((res) => {
        let data = res.data;
        if (data?.status == 1) {
          // console.log("working ",data);
        }
        dispatch(changeLoader(false));
      })
      .catch((err) => {
        dispatch(changeLoader(false));
      });
  });

  return (
    <div key={ind}>
      <Boxed
        onClick={() => {
          if (!isLocked) {
            if (saveStatus == "release") {
              updateLastViewedNote(obj?.chapter_id, obj?.id);
              dispatch(changeLessonData(obj));
            } else {
              dispatch(
                setSnackbar({
                  snackbarMessage: "You were too fast, please wait whilst we are saving your notes",
                  snackbarOpen: true,
                  snackbarState: "error",
                })
              );
            }
          } else {
            let msg = "";

            if (trialData?.status === "payment_accepted") {
              msg =
                auth?.access !== "owner"
                  ? "An annual subscription plan is required to view this lesson. You’ll need to contact your administrator."
                  : "Switch to an annual subscription plan to view this lesson";
            } else {
              msg =
                auth?.access !== "owner"
                  ? "An annual subscription plan is required to view this lesson. You’ll need to contact your administrator"
                  : "Upgrade to an annual subscription plan to view this lesson";
            }
            dispatch(
              setSnackbar({
                snackbarMessage: msg,
                snackbarOpen: true,
                snackbarState: "error",
              })
            );
          }
        }}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        className={`lesson-block ${lessons?.id == obj?.id ? "lesson-block-selected" : null}`}
      >
        <Body text={obj?.name} color={Colors.nightGray} className={"btn-body"} />
        <Boxed display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
          {isLocked ? (
            <img src={lockIcon} alt="" />
          ) : (
            <>
              <img src={like} alt="like and dislike indicator" style={{ margin: "0 4px" }} onClick={handleLike} />
              <img src={complete} alt="complete and incomplete indicator" />
            </>
          )}
        </Boxed>
      </Boxed>
    </div>
  );
};

export default Lesson;
