import { useDispatch } from "react-redux";
import globalRequest from "../GlobalModule/globalRequest";
import { API_TRANSLATION } from "../Helpers/Enums";
import getCredit from "../GlobalModule/getCredit";
import { changeCredit } from "../redux/reducers/getCredit";

const updateCount = (charCounts = 0, auth) => {
  return new Promise(async (resolve, reject) => {
    await globalRequest(
      "put",
      API_TRANSLATION?.DEDUCT_CREDIT,
      {
        user_id: auth?.user_id,
        role: auth?.access,
        team_id: auth?.team_id,
        total_character: charCounts,
        credit_used: 0,
      },
      {},
      true
    );
    resolve(true);
  });
};

export default updateCount;
