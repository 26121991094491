import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "antd/dist/antd.css";
import * as Colors from "../Styles-Elements/Colors";
import { Subtitle, LabelWrapper, LargeBody, LargeBodyBold } from "../Styles-Elements/Labels";

import { ClearButton, CustomRoundedButton } from "../Styles-Elements/Buttons";

import booSurprised from "../image-assets/boo-surprised.png";
import Scrollbar from "react-smooth-scrollbar";

import closePopUpByKey from "../GlobalModule/closePopUpByKey";
import { tooledUpLocalData } from "../redux/reducers/getTooledupData";
import { planDetails } from "../redux/reducers/getPlanDetail";
import moment from "moment";
import { PLAN_AMOUNT, PLAN_TYPE, REQUEST } from "../Helpers/Enums";
import globalRequest from "../GlobalModule/globalRequest";
import { setSnackbar } from "../redux/reducers/snackbar";
import { changeLoader } from "../redux/reducers/loader";
import EnoughSeatsEveryone from "./EnoughSeatsEveryone";
// Importing localised strings
const strings = require("../localisation_en.json");

const TeamDgrade = (props) => {
  const auth = useSelector(tooledUpLocalData);
  const planDetail = useSelector(planDetails);
  const currentNoOfUser = props.currentNoOfUser;
  const billingDate = moment(planDetail?.end_date).format("DD MMM YYYY");
  const [calculateFrom, setCalculateFrom] = useState(0);
  const [amountOfCurrentUser, setAmountOfCurrentUser] = useState(0);

  useEffect(() => {
    let cal = planDetail?.plan_amount;
    setCalculateFrom(cal);
  }, [planDetail]);

  useEffect(() => {
    const aOfCurrentUser = currentNoOfUser * calculateFrom;
    setAmountOfCurrentUser(aOfCurrentUser);
  }, [calculateFrom]);

  const dispatch = useDispatch();

  const totalUser = planDetail?.total_user;
  const [userLength, setUserLength] = useState(0);
  const [enoughtSeatEveryoneM, setEnoughtSeatEveryoneM] = useState(false);

  useEffect(() => {
    let filterActive = props.allUser.filter((a) => a.status == "active");
    if (filterActive) {
      setUserLength(filterActive.length);
    }
  }, [props.allUser]);

  useEffect(() => {
    closePopUpByKey(props.closePopup);
  }, []);

  const confirm = async () => {
    const totalUser = planDetail?.total_user;

    let modifiedId = planDetail.modifier_id ? planDetail.modifier_id : 0;
    let modifiedUser = totalUser - currentNoOfUser;

    let totalAmount = planDetail?.plan_amount * totalUser;
    let currentAmountPrics = planDetail?.plan_amount;

    let lastTotal = totalUser - modifiedUser;
    let lastAmount = lastTotal * currentAmountPrics;

    let lessNo = totalUser - currentNoOfUser;
    let modifiedAmount = (totalUser - lessNo - planDetail?.subscription_user) * currentAmountPrics;

    const data = {
      user_id: auth?.user_id,
      subscription_id: planDetail?.subscription_id,
      total_amount: lastAmount,
      modified_amount: modifiedAmount,
      modified_user: modifiedUser,
      total_user: lastTotal,
      modifier_id: modifiedId,
    };

    dispatch(changeLoader(true));
    let response = await globalRequest("post", REQUEST.DOWNGRADE_TEAM_REQUEST, data, {}, true);

    let res = response.data;
    if (res.status == 1) {
      dispatch(
        setSnackbar({
          snackbarMessage: res.message,
          snackbarOpen: true,
          snackbarState: "success",
        })
      );
      setEnoughtSeatEveryoneM(false);
      props.downgradedUser(res?.data?.total_user);
    } else {
      dispatch(
        setSnackbar({
          snackbarMessage: res.message,
          snackbarOpen: true,
          snackbarState: "error",
        })
      );
    }

    dispatch(changeLoader(false));
  };

  const process = async (e) => {
    e.preventDefault();

    if (currentNoOfUser >= userLength) {
      confirm();
    } else {
      setEnoughtSeatEveryoneM(true);
    }
  };

  return (
    <>
      <div className="container">
        <div className="container-modal">
          <div className="popup-boo-container negative">
            <img src={booSurprised} width={120} height={120} alt="" />
          </div>
          <Scrollbar className="container-modal-mid">
            <Subtitle text={strings.ohNo} color={Colors.nightPurple} textAlign={"center"} padding={"24px 0"} />
            <LabelWrapper justifyContent={"center"} padding={"0 0 24px 0"}>
              <LargeBody text={strings.youAreAboutToReduceTheNumberOf} color={Colors.darkGray} />
              <LargeBody text={strings.paidSeatsFrom} color={Colors.darkGray} padding={"0 0 0 4px"} />
              <LargeBodyBold text={totalUser} color={Colors.darkGray} padding={"0 0 0 4px"} />
              <LargeBody text={strings.to} color={Colors.darkGray} padding={"0 0 0 4px"} />
              <LargeBodyBold text={currentNoOfUser} color={Colors.darkGray} padding={"0 0 0 4px"} />
            </LabelWrapper>
            <LabelWrapper justifyContent={"center"} padding={"0 0 24px 0"}>
              <LargeBody text={strings.yourSubscriptionPlanWillReduceTo} color={Colors.darkGray} />
              <LargeBodyBold text={currentNoOfUser} color={Colors.darkGray} padding={"0 0 0 4px"} />
              <LargeBody text={strings.paidSeats} color={Colors.darkGray} padding={"0 0 0 4px"} />
              <LargeBody text={strings.onThe} color={Colors.darkGray} padding={"0 0 0 4px"} />
              <LargeBodyBold text={billingDate} color={Colors.darkGray} padding={"0 0 0 4px"} />
            </LabelWrapper>
            <LabelWrapper justifyContent={"center"} padding={"0 0 24px 0"}>
              <LargeBody text={strings.onYourNextBillingCycleDue} color={Colors.darkGray} />
              <LargeBodyBold text={billingDate} color={Colors.darkGray} padding={"0 0 0 4px"} />
              <LargeBody text={strings.you} color={Colors.darkGray} padding={"0 0 0 4px"} />
              <LargeBody text={strings.willBeBilledFor} color={Colors.darkGray} padding={"0 0 0 4px"} />
              <LargeBodyBold text={currentNoOfUser} color={Colors.darkGray} padding={"0 0 0 4px"} />

              <LargeBody text={strings.seatsAt} color={Colors.darkGray} padding={"0 0 0 4px"} />
              <LargeBodyBold
                text={"£" + amountOfCurrentUser.toFixed(2)}
                color={Colors.darkGray}
                padding={"0 0 0 4px"}
              />
              <LargeBody text={strings.addLocalTaxes} color={Colors.darkGray} padding={"0 0 0 4px"} />
            </LabelWrapper>
            <CustomRoundedButton
              text={strings.proceed}
              margin={"24px 0 8px 0"}
              color={Colors.mainLight}
              onClick={process}
              backgroundColor={Colors.lightRed}
            />
            <ClearButton text={strings.cancel} onClick={() => props.closePopup()} />
          </Scrollbar>
        </div>
      </div>
      {enoughtSeatEveryoneM ? (
        <EnoughSeatsEveryone
          closePopup={() => setEnoughtSeatEveryoneM(false)}
          allUser={props.allUser}
          currentNoOfUser={currentNoOfUser}
          confirm={() => {
            confirm();
          }}
        />
      ) : null}
    </>
  );
};

export default TeamDgrade;
