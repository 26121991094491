import axios from "axios";
import { REQUEST, STORAGE } from "../Helpers/Enums";
import addDeleteGetLocalStorage from "./addDeleteGetLocalStorage";

import encryptDcrypt from "./encryptDcrypt";

const globalRequest = (method = "get", url, data = {}, options = {}, token = false) => {
  //gloabl axios request for post get put and delete

  let headers = {
    api_key: REQUEST.API_KEY,
  };

  if (token) {
    let json = {};
    const data = addDeleteGetLocalStorage(STORAGE.TOOLED_UP_LOCAL_DATA, {}, "get", "single");
    try {
      json = JSON.parse(data);
    } catch (e) {
      json = {};
    }

    headers.authorization = "Bearer " + json?.token;
    headers.user_id = json?.user_id;
  }

  let d = {
    method: method,
    url: url,
    headers: headers,
    ...options,
  };

  if (data) {
    d.data = data;
  }

  return new Promise((resolve, reject) => {
    axios(d)
      .then((response) => {
        if (response?.data?.status == 2) {
          window.localStorage.removeItem(STORAGE.TOOLED_UP_LOCAL_DATA);
          window.location.href = "/";
        } else {
          resolve(response);
        }
      })
      .catch((err) => {});
  });
};

export default globalRequest;
