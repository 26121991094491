import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import * as Colors from "../Styles-Elements/Colors";
import { Subheading, Subtitle, LargeBody } from "../Styles-Elements/Labels";
import { Textfield } from "../Styles-Elements/Inputs";
import { ClearButton, MainRoundedButton } from "../Styles-Elements/Buttons";

import booNeutral from "../image-assets/boo-neutral.png";

import { REQUEST, ACTION_TYPE } from "../Helpers/Enums";
import Scrollbar from "react-smooth-scrollbar";
import closePopUpByKey from "../GlobalModule/closePopUpByKey";
import focus from "../GlobalModule/focus";
import { useDispatch, useSelector } from "react-redux";
import { tooledUpLocalData } from "../redux/reducers/getTooledupData";
import { setSnackbar } from "../redux/reducers/snackbar";
import globalRequest from "../GlobalModule/globalRequest";
import { changeLoader } from "../redux/reducers/loader";
// Importing localised strings
const strings = require("../localisation_en.json");

//@props - closePopup

const ForgetPassword = (props) => {
  const auth = useSelector(tooledUpLocalData);
  const dispatch = useDispatch();

  const [inputFieldEntry, setInputFieldEntry] = useState({
    postCode: "",
    feedback: "",
    new: "",
    exist: "",
    newPwd: "",
    email: "",
    firstName: auth?.firstname,
    lastName: auth?.lastname,
  });

  const handleInputChange = (e, clear) => {
    if (clear !== true) {
      setInputFieldEntry({
        ...inputFieldEntry,
        [e.currentTarget.name]: e.currentTarget.value,
      });
    } else {
      setInputFieldEntry({});
    }
  };

  useEffect(() => {
    focus("email");
    closePopUpByKey(props.closePopup);
  }, []);

  const validEmailcheck = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateEmailInputs = (email) => {
    if (!validEmailcheck(email)) {
      dispatch(
        setSnackbar({
          snackbarMessage: strings.enterAValidEmail,
          snackbarOpen: true,
          snackbarState: "error",
        })
      );
      return false;
    } else {
      return true;
    }
  };
  const forgotPassword = async (e) => {
    e.preventDefault();
    if (inputFieldEntry.email == null || inputFieldEntry.email == "") {
      dispatch(
        setSnackbar({
          snackbarMessage: strings.enterRecoveryEmail,
          snackbarOpen: true,
          snackbarState: "error",
        })
      );
    } else if (validateEmailInputs(inputFieldEntry.email)) {
      dispatch(changeLoader(true));

      let formData = new FormData();
      formData.append("email", inputFieldEntry.email);

      const response = await globalRequest("post", REQUEST.FORGOT_PASSWORD_REQUEST, formData, {}, false);

      const res = response.data;

      if (res.status == 1) {
        setInputFieldEntry({ ...inputFieldEntry, email: "" });
        dispatch(
          setSnackbar({
            snackbarMessage: res.message,
            snackbarOpen: true,
            snackbarState: "success",
          })
        );
        props.closePopup();
      } else if (res.status == 0) {
        setInputFieldEntry({ ...inputFieldEntry, email: "" });
        dispatch(
          setSnackbar({
            snackbarMessage: res.message,
            snackbarOpen: true,
            snackbarState: "error",
          })
        );
      } else {
        setInputFieldEntry({ ...inputFieldEntry, email: "" });
        dispatch(
          setSnackbar({
            snackbarMessage: res.message,
            snackbarOpen: true,
            snackbarState: "error",
          })
        );
      }
      dispatch(changeLoader(false));
    }
  };
  return (
    <div className="container">
      <div className="container-modal">
        <div className="popup-boo-container neutral">
          <img src={booNeutral} width={120} height={120} />
        </div>
        <Scrollbar className="container-modal-mid">
          <form onSubmit={forgotPassword}>
            <Subtitle
              text={strings.forgotYourPassword}
              color={Colors.nightPurple}
              textAlign={"center"}
              padding={"24px 0"}
            />
            <LargeBody
              text={strings.letsRecoverYourPassword}
              textAlign={"center"}
              color={Colors.darkGray}
              padding={"0 0 16px 0"}
            />
            <Subheading text={strings.email} color={Colors.nightPurple} padding={"0 0 8px 0"} />
            <Textfield
              name={"email"}
              value={inputFieldEntry.email}
              onChange={(e) => handleInputChange(e)}
              placeholder={strings.enterYourEmail}
              id={"email"}
            />
            <MainRoundedButton text={strings.confirm} margin={"24px 0 8px 0"} type="submit" />
          </form>
          <ClearButton text={strings.cancel} onClick={() => props.closePopup()} />
        </Scrollbar>
      </div>
    </div>
  );
};

export default ForgetPassword;
