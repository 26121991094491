import "antd/dist/antd.css";
import * as Colors from "../Styles-Elements/Colors";
import { Subtitle, LargeBody } from "../Styles-Elements/Labels";
import { CustomRoundedButton } from "../Styles-Elements/Buttons";
import booThinking from "../image-assets/boo-thinking.png";
// Material UI for the snackbar
import Scrollbar from "react-smooth-scrollbar";
import { KEYWORD_VIEW_TYPE } from "../Helpers/Enums";
// Importing localised strings
const strings = require("../localisation_en.json");

const DeconstructView = (props) => {
  return (
    <div className="container">
      <div className="container-modal">
        <div className="popup-boo-container negative">
          <img src={booThinking} width={120} height={120} alt="" />
        </div>
        <Scrollbar className="container-modal-mid">
          <Subtitle
            text={
              props.currentKeyView == KEYWORD_VIEW_TYPE.STANDARD_VIEW
                ? strings.unableToSwitchToDeconstructView
                : strings.unableToSwitchToStandardView
            }
            color={Colors.nightPurple}
            textAlign={"center"}
            padding={"24px 0"}
          />
          <LargeBody
            text={
              props.currentKeyView == KEYWORD_VIEW_TYPE.STANDARD_VIEW
                ? strings.thereIsAnErrorInYourString
                : strings.thereIsAnErrorInYourString1
            }
            textAlign={"center"}
            color={Colors.darkGray}
            padding={"0 0 16px 0"}
          />
          <CustomRoundedButton
            text={strings.okIamOnIt}
            margin={"24px 0 8px 0"}
            color={Colors.mainLight}
            backgroundColor={Colors.lightRed}
            onClick={() => props.closePopup()}
          />
        </Scrollbar>
      </div>
    </div>
  );
};

export default DeconstructView;
