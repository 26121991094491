export const convertTranslatedWordInString = async (str = '', allWordTranslated, allIndexex, input = null) => {
    let responseData;
    let singleWithoutDuplicate = allIndexex.uniqueWord;
    let allDoubleQouteString = allIndexex.allWord;
    let allDoubleQouteStringIndex = allIndexex.allWordIndex;
    let AllWordAfterTranslate = [];
    let entryStr = '';
    let translatedWord=[];
    if(input!=null && typeof input?.uniqueWord!=="undefined"){
        singleWithoutDuplicate=input.uniqueWord;
        translatedWord=input.translation.length>0?input.translation:[];
    }
    if (typeof allWordTranslated !== "undefined") {
            Object.entries(allWordTranslated).forEach(([key, val]) => {
                entryStr = val;
                if (entryStr !== undefined && entryStr !== "undefined") {
                    AllWordAfterTranslate.push(val);
                }
            });
        //}
    }
    /**
     * Translated word and before translated word in object key value pair
     */
    let assignValuesObj = Object.assign({}, ...singleWithoutDuplicate.map((e, i) => ({ [e]: AllWordAfterTranslate[i] })))
     if(translatedWord.length>0){
        translatedWord.map((items)=>{
            assignValuesObj = {...assignValuesObj, ...items};
        }) 
     }


    /**
     * Set response data
     */
    return responseData = {
        status: 1,
        data: reBuildStringWithTranslatedForSingleLang(str, allDoubleQouteString, allDoubleQouteStringIndex, assignValuesObj,input)
    }
}

 
/**
 * rebuild string for single language
 */
const reBuildStringWithTranslatedForSingleLang = (str, allDoubleQouteString, allDoubleQouteStringIndex, obj,input) => {
    let newstring = '';
    let beforeIndex;
    let afterIndex;
    let opString;
    let dataCheck = '';
    let colordString = '';
    let dataCheckString = '';
    let orgstr = '';

    let removesource=false;
    if(input!=null && typeof input?.removeSourceLanguage!=="undefined"){
        removesource=input.removeSourceLanguage
    }
   
    allDoubleQouteString.map(function (item, index) {
        dataCheck = getValuesFromObj(obj, item);
        afterIndex = allDoubleQouteStringIndex[index];
        colordString = item.replaceAll('"', '<span style="color: rgb(243, 134, 45);">"</span>')
        dataCheckString = dataCheck.replaceAll('"', '<span style="color: rgb(243, 134, 45);">"</span>')
        dataCheckString=dataCheckString.replaceAll('H144sadfds24114343das4sadsad','<span style="color: rgb(243, 134, 45);">"</span> <span class="or-color-translate">OR</span> <span style="color: rgb(243, 134, 45);">"</span>');

        if (index < 1) {
            if (afterIndex[0] != 0) {
                newstring = getCharBetweenIndex(str, 0, afterIndex[0]);
            }
            if (item.toUpperCase() == dataCheck.toUpperCase()) {
                newstring += colordString;
            } else { 
                newstring += removesource==false?'<span  class="open-breackets-color-translate">(</span>' + colordString + '<span class="or-color-translate"> OR </span>' + dataCheckString + '<span class="closed-breackets-color-translate">)</span>':'<span  class="open-breackets-color-translate">(</span>'+dataCheckString+'<span class="closed-breackets-color-translate">)</span>';
            }

        } else {
            beforeIndex = allDoubleQouteStringIndex[index - 1];
            opString = getCharBetweenIndex(str, beforeIndex[1], afterIndex[0]);
            newstring += opString;
            if (item.toUpperCase() == dataCheck.toUpperCase()) {
                newstring += colordString;
            } else {
                newstring += removesource==false?'<span class="open-breackets-color-translate">(</span>' + colordString + ' <span class="or-color-translate"> OR </span> ' + dataCheckString + '<span class="closed-breackets-color-translate">)</span>':'<span class="open-breackets-color-translate">(</span>'+dataCheckString+'<span class="closed-breackets-color-translate">)</span>';
            }
        } 
        if (allDoubleQouteString.length - 1 == index) {
            newstring += getCharBetweenIndex(str, afterIndex[1], afterIndex[1] + Number((str.length - afterIndex[1])));
        }
    })
    return newstring;
}


/**
 * replace index between between two index
 */
String.prototype.replaceBetween = function (start, end, what) {
    return this.substring(0, start) + what + this.substring(end);
};

/**
 * Get all char between two index
 */
export const getCharBetweenIndex = (str, start, end) => {
    let strings = str.substring(start, end);
    strings = strings.toUpperCase();
    strings = strings.replaceAll("(", '<span class="open-breackets-color-translate">(</span>');
    strings = strings.replaceAll(")", '<span class="closed-breackets-color-translate">)</span>');
    strings = strings.replaceAll("NOT", '<span class="not-color-translate">NOT</span>');
    strings = strings.replaceAll("AND", '<span class="and-color-translate">AND</span>');
    strings = strings.replaceAll("OR", '<span class="or-color-translate">OR</span>');
    return strings;
}

/**
 * Get values form object
 */
const getValuesFromObj = (obj, item) => {
    let values = [];
    let data = Object.keys(obj).filter(
        (key) => {
            item = item.replaceAll('"', "")
            if (key.toLowerCase().trim() == item.toLowerCase().trim()) {
                values.push('"' + obj[key] + '"');
            }
            return true;
        }
    );
    return (values.length > 0) ? values[0] : '';
}









 