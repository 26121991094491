import React, { useEffect, useState } from "react";


import { connect, useDispatch, useSelector } from "react-redux";
import "antd/dist/antd.css";
import * as Colors from "../Styles-Elements/Colors";
import { Subtitle, LabelWrapper, LargeBody, LargeBodyBold, Body } from "../Styles-Elements/Labels";
import { MainRoundedButton, ClearButton } from "../Styles-Elements/Buttons";
import { InputWrapper, TextSearchfield } from "../Styles-Elements/Inputs";

import booThinking from "../image-assets/boo-thinking.png";
import searchIcon from "../image-assets/searchicon.svg";
import usersAvtar from "../image-assets/user1.png";
import Scrollbar from "react-smooth-scrollbar";
import closePopUpByKey from "../GlobalModule/closePopUpByKey";
// import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import { tooledUpLocalData } from "../redux/reducers/getTooledupData";
import { planDetails } from "../redux/reducers/getPlanDetail";
import { BASE_URL, REQUEST } from "../Helpers/Enums";
import globalRequest from "../GlobalModule/globalRequest";
import { setSnackbar } from "../redux/reducers/snackbar";
import { changeLoader } from "../redux/reducers/loader";
import booHappy from '../image-assets/boo-happy.png'
import moment from "moment";
import { Boxed } from "../Styles-Elements/Box";

// Importing localised strings
const strings = require("../localisation_en.json");

const EnoughSeatsEveryone = (props) => {

  const auth = useSelector(tooledUpLocalData);
  const planDetail = useSelector(planDetails);
  const dispatch = useDispatch();

  let endDate = planDetail?.end_date;
  const billingDate = moment(endDate).format("DD MMM YYYY")
  const currentNoOfUser = props.currentNoOfUser;

  const [userIdArray, setUserIdArray] = useState([]);
  const [allUser, setAllUser] = useState([]);
  const [allUserSearch, setAllUserSearch] = useState([]);
  const [userLength, setUserLength] = useState(0)

  useEffect(() => {

    let filter = props.allUser.filter((a) => a.id != auth?.user_id);
    setAllUser(filter);
    setAllUserSearch(filter)

    let filterActive = props.allUser.filter((a) => a.status == 'active');
    setUserLength(filterActive.length);

  }, [props.allUser]);


  useEffect(() => {
    closePopUpByKey(props.closePopup)
  }, []);

  const confirm = async () => {
    if (userIdArray.length >= (userLength - currentNoOfUser)) {
      let url = REQUEST.UPDATE_USER_ACTION;
      const data = {
        status: 'inactive',
        user_id: auth?.user_id,
        pouse_userid: userIdArray
      }

      dispatch(changeLoader(true));
      const response = await globalRequest("post", url, data, {}, true);
      const res = response.data;
      if (res.status == 1) {
        dispatch(setSnackbar({
          snackbarMessage: res.message,
          snackbarOpen: true,
          snackbarState: 'success'
        }));
        props.closePopup()
        props.confirm()
      } else {
        dispatch(setSnackbar({
          snackbarMessage: res.message,
          snackbarOpen: true,
          snackbarState: 'error'
        }));
      }
      dispatch(changeLoader(false));
    } else {
      dispatch(setSnackbar({
        snackbarMessage: `Please select ${userLength - currentNoOfUser} to loss access.`,
        snackbarOpen: true,
        snackbarState: 'error'
      }))
    }
  }

  const submit = () => {

    if (userIdArray.length) {
      confirm()
    } else {
      dispatch(setSnackbar({
        snackbarMessage: "Please select at least one user",
        snackbarOpen: true,
        snackbarState: 'error'
      }))
    }

  }

  const handleSearch = (e) => {
    let v = String(e.target.value).toLowerCase()
    if (v == '') {
      setAllUser(allUserSearch)
    } else {
      let condition = new RegExp(v, 'i')
      const users = allUser.filter((e) => {
        if (condition.test(String(e.full_name).toLowerCase()) || condition.test(String(e.email_id).toLowerCase())) {
          return e
        }
      })
      setAllUser(users)
    }
  }

  const handleChange = (e, id) => {
    if (e.target.checked) {
      setUserIdArray(userIdArray => [...userIdArray, id]);
    } else {
      let filter = userIdArray.filter((a) => a != id);
      setUserIdArray(filter);
    }
  }

  return (
    <div className="container">
      <div className="container-modal">
        <div className="popup-boo-container">
          <img src={booThinking} width={120} height={120} alt="" />
        </div>
        <Scrollbar className="container-modal-mid">
          <Subtitle
            text={strings.notEnoughSeatsForEveryone}
            color={Colors.nightPurple}
            textAlign={"center"}
            padding={"24px 0 16px"}
          />
          <LabelWrapper justifyContent={'center'} padding={'0 0 12px 0'}>
            <LargeBody
              text={strings.on}
              color={Colors.darkGray}
              padding={"0 0 0px 0"}
            />
            <LargeBodyBold
              text={`${billingDate},`}
              color={Colors.darkGray}
              padding={"0 0 0px 4px"}
            />
            <LargeBody
              text={strings.youWillDropFrom}
              color={Colors.darkGray}
              padding={"0 0 0px 4px"}
            />
            <LargeBodyBold
              text={planDetail?.total_user}
              color={Colors.darkGray}
              padding={"0 0 0px 4px"}
            />
            <LargeBody
              text={strings.paidSeatsTo}
              color={Colors.darkGray}
              padding={"0 0 0px 4px"}
            />
            <LargeBodyBold
              text={currentNoOfUser}
              color={Colors.darkGray}
              padding={"0 0 0px 4px"}
            />
            <LargeBody
              text={strings.thereWonotBeEnoughSeatsForTheCurrent}
              color={Colors.darkGray}
              padding={"0 0 0px 4px"}
            />
            <LargeBody
              text={strings.team_Select}
              color={Colors.darkGray}
              padding={"0 0 0px 4px"}
            />
            <LargeBodyBold
              text={(userLength - currentNoOfUser)}
              color={Colors.lightRed}
              padding={"0 0 0px 4px"}
            />
            <LargeBody
              text={strings.teamMemberToLoseAccess}
              color={Colors.darkGray}
              padding={"0 0 0px 4px"}
            />
          </LabelWrapper>

          <div className="popup-selection--box">
            <InputWrapper>
              <TextSearchfield
                placeholder={strings.startTypingAName}
                backgroundColor={Colors.whiteGray}
                onChange={handleSearch}
              />
              <button className="searchbtn">
                <img src={searchIcon} className="" alt="" />
              </button>
            </InputWrapper>
            <Scrollbar className="popup-scroll-box">
              {
                allUser.map((row, index) => {

                  let findId = userIdArray.find((a) => row.id == a);

                  let profileUrl = null;
                  if (row.profile_image) {
                    profileUrl = `${BASE_URL}uploads/profile/${row.id}/${row.profile_image}`;
                  } else {
                    if (row.first_name == "") {
                      profileUrl = booHappy;
                    } else {
                      profileUrl = "nameImage";
                    }
                  }

                  return (
                    <Boxed
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      margin={"0 0 4px 0"}
                      key={index}
                    >
                      <Boxed display={"flex"} alignItems={"center"}>

                        {profileUrl == "nameImage" ? (
                          <>
                            <b className="sidebar-profile-char">
                              {String(row.first_name).charAt(0)}{" "}
                              {String(row.last_name).charAt(0)}
                            </b>
                          </>
                        ) : (
                          <>
                            <img
                              className="users-avtar"
                              src={profileUrl}
                            />
                          </>
                        )}
                        <Body
                          text={row.first_name == '' || row.first_name == ' ' ? row.email_id : row.full_name}
                          textAlign={"center"}
                          color={Colors.nightPurple}
                          padding={"0"}
                          margin={"0 12px 0 12px"}
                        />
                      </Boxed>
                      <Checkbox
                        onChange={(e) => handleChange(e, row.id)}
                        checked={findId == row.id ? true : false}
                        inputProps={{ "aria-label": "primary checkbox" }}
                        disabled={row.status == 'inactive' ? true : userIdArray.length >= (userLength - currentNoOfUser) && findId != row.id ? true : false}
                      />
                    </Boxed>
                  )
                })
              }
            </Scrollbar>
          </div>
          <MainRoundedButton
            text={strings.confirm}
            margin={"32px 0 0px 0"}
            onClick={submit}
          />
          <ClearButton text={strings.cancel} onClick={() => props.closePopup()} />
        </Scrollbar>
      </div>
    </div>
  );
}

export default EnoughSeatsEveryone
