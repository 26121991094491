// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  // eslint-disable-next-line no-restricted-globals
  self.onmessage = (event) => {
    let { string } = event?.data;


    if (string) {
      let str = String(string).toLowerCase();

      const isEvenDoubleQuotes = (index, string) => {
        let dq = 0;
        let subString = string.substring(0, index);
        dq = (subString.match(/"/g) || "").length;
        if (dq % 2 === 1) {
          return false;
        }
        return true;
      };

      const getIndicesOf = (searchStr, str, lastIndex) => {
        let startIndex = 0,
          searchStrLen = searchStr.length;
        let index,
          indices = [];
        str = str;
        searchStr = searchStr;
        while ((index = str.indexOf(searchStr, startIndex)) > -1) {
          if (str.charAt(index - 1).trim().length === 0 && str.charAt(index + lastIndex).trim().length === 0) {
            indices.push(index);
          } else if (str.charAt(index - 1).trim().length === 0 && str.charAt(index + lastIndex).trim().length === 0) {
            indices.push(index);
          } else if (str.charAt(index - 1) === "(" && str.charAt(index + lastIndex).trim().length === 0) {
            indices.push(index);
          } else if (str.charAt(index - 1).trim().length === 0 && str.charAt(index + lastIndex) === ")") {
            indices.push(index);
          } else if (index === 0 && str.charAt(index + lastIndex).trim().length === 0) {
            indices.push(index);
          }

          startIndex = index + searchStrLen;
        }
        return indices;
      };

      let andIndexes = [];
      let orIndexes = [];
      let notIndexes = [];

      if (str.indexOf("and") > -1) andIndexes = getIndicesOf("and", str, 3);
      if (str.indexOf("or") > -1) orIndexes = getIndicesOf("or", str, 2);
      if (str.indexOf("not") > -1) notIndexes = getIndicesOf("not", str, 3);

      let and = [];
      let or = [];
      let not = [];

      andIndexes.forEach((index) => {
        if (isEvenDoubleQuotes(index, str)) {
          and.push(index);
        }
      });

      orIndexes.forEach((index) => {
        if (isEvenDoubleQuotes(index, str)) {
          or.push(index);
        }
      });

      notIndexes.forEach((index) => {
        if (isEvenDoubleQuotes(index, str)) {
          not.push(index);
        }
      });

      postMessage({
        or: or,
        not: not,
        and: and,
        string: string,
      });
    }
  };
};
