import { createSlice } from "@reduxjs/toolkit";

export const string = createSlice({
  name: "string",
  initialState: {
    value: "",
  },
  reducers: {
    setString: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setString } = string.actions;
export const currentString = (state) => state.string.value;
export default string.reducer;
