import strings from "../../../localisation_en.json";
import { Boxed } from "../../../Styles-Elements/Box";
import { Body, LargeBodySemiBold } from "../../../Styles-Elements/Labels";
import unFillWishlist from "../../image-assets/unfillwishlist.svg";
import fillWishlist from "../../image-assets/fillwishlist.svg";
import checkRoundPurple from "../../image-assets/check-round-purple.svg";
import * as Colors from "../../../Styles-Elements/Colors";
import { useEffect, useState } from "react";
import dropArrowIconngreen from "../../image-assets/circle-chevron-top.svg";
import Lesson from "./Lesson";

const Chapter = ({ obj, ind, expanded = false, handleToggle }) => {
 
  return (
    <div key={ind}>
      <Boxed
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        className={`side-btns dropdown-toggle ${expanded ? "expanded" : ""}`}
        onClick={handleToggle}
      >
        <LargeBodySemiBold
          text={obj?.name}
          color={Colors.darkPurple}
          padding={"0 16px 0 0"}
          className={"btn-largebodysemibold"}
        />
        {obj?.lessons.length ? (
          <>
            <img
              style={{ cursor: "pointer" }}
              src={dropArrowIconngreen}
              alt=""
              className={`${expanded ? "btn-rotated" : "btn-rotate"}`}
            />
          </>
        ) : null}
      </Boxed>

      {expanded && (
        <>
          {obj?.lessons.length
            ? obj?.lessons.map((obj1, ind1) => {
                return <Lesson obj={{ ...obj1, chapter_id: obj?.id }} ind={ind1} key={ind1} isLocked={obj.is_locked} />;
              })
            : null}
        </>
      )}
    </div>
  );
};

export default Chapter;
