import { useEffect, useState } from "react";
import * as Colors from "../../Styles-Elements/Colors";
import { Small, Body } from "../../Styles-Elements/Labels";
import { EditMemberButton } from "../../Styles-Elements/Buttons";
import "./KeywordStrings.css";
// import images
// Material UI for the snackbar
// import Box from "@material-ui/core/Box";
import { Boxed } from "../../Styles-Elements/Box";
import Switch from "@material-ui/core/Switch";
import LetsCollaborate from "../../dialogs/LetsCollaborate";
import { BASE_URL, KEYWORD_STRING } from "./../../Helpers/Enums";
import booHappy from "../../image-assets/boo-happy.png";
import RemoveAllCollaboration from "../../dialogs/RemoveAllCollaboration";
import { useDispatch, useSelector } from "react-redux";
import { tooledUpLocalData } from "../../redux/reducers/getTooledupData";
import globalRequest from "../../GlobalModule/globalRequest";
import { setSnackbar } from "../../redux/reducers/snackbar";
import { changeLoader } from "../../redux/reducers/loader";
import { currentCollab } from "../../redux/reducers/letCollab";
// Importing localised strings
const strings = require("../../localisation_en.json");

const StringCollaborationEdit = ({ dataObj, callback }) => {
  const auth = useSelector(tooledUpLocalData);
  const letCollab = useSelector(currentCollab);
  const dispatch = useDispatch();
  const [collaborateOpen, setCollaborateOpen] = useState(false);
  const [collabData, setCollabData] = useState([]);
  const [switchToggle, setSwitchToggle] = useState(false);
  const [openRemoveAll, setOpenRemoveAll] = useState(false);

  const api = async () => {
    const res = await globalRequest(
      "get",
      KEYWORD_STRING.GET_COLLABORATION_LIST,
      {},
      {
        params: {
          user_id: auth?.user_id,
          team_id: auth?.team_id,
          string_id: dataObj?.id,
        },
      },
      true
    );

    if (res.data.status == 1) {
      let filter = res?.data?.data?.filter((a) => a.id != auth?.user_id);
      setCollabData(filter);
    }
  };

  useEffect(() => {
    if (Object.keys(dataObj).length !== 0) {
      api();
      callingGetToggle();
    }
    return () => {
      setCollabData([]);
      setSwitchToggle(false);
    }
  }, [dataObj]);

  useEffect(() => {
    if (collabData.length) {
      callingGetToggle();
    }
  }, [collabData]);

  useEffect(() => {
    api();
  }, [switchToggle]);

  useEffect(() => {
    setSwitchToggle(true);
    api();
  }, [letCollab]);

  const callingToggleApi = async (status = 0) => {
    let params = {
      team_id: auth?.team_id,
      string_id: dataObj.id,
      user_id: auth?.user_id,
      type: status,
    };

    const res = await globalRequest("post", KEYWORD_STRING.STRING_TOGGLE_UPDATE, params, {}, true);
    if (res.data.status == 1) {
      if (status == 1) {
        setSwitchToggle(true);
      } else {
        setSwitchToggle(false);
      }

      callback(true);
    } else {
      dispatch(
        setSnackbar({
          snackbarMessage: res?.data?.message,
          snackbarOpen: true,
          snackbarState: "error",
        })
      );
    }
  };

  const callingGetToggle = async () => {
    let params = {
      user_id: auth?.user_id,
      string_id: dataObj.id,
    };
    const res = await globalRequest(
      "get",
      KEYWORD_STRING.STRING_TOGGLE_GET,
      {},
      {
        params: params,
      },
      true
    );
    if (res.data.status == 1) {
      let data = res.data.data;
      if (collabData.length > 0 && data.collaborate_toggle == 1) {
        setSwitchToggle(true);
      } else {
        setSwitchToggle(false);
      }
    }
  };

  return (
    <>
      <div className="ks-string-filefolder-holder ks-collaboration-holder ks-strg-disabled">
        <div className="grid-item-flex">
          <Body text={strings.stringCollaboration} color={Colors.nightPurple} />
          <Boxed display="flex" alignItems="center">
            <Small text={switchToggle ? "Enabled" : "Disabled"} color={Colors.darkPurple} />
            <Switch
              onChange={(e) => {
                if (e.target.checked) {
                  setCollaborateOpen(true);
                  setSwitchToggle(true);
                  callingToggleApi(1);
                } else {
                  setSwitchToggle(false);
                  setOpenRemoveAll(true);
                }
              }}
              checked={switchToggle}
              color="primary"
              name="checkedB"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </Boxed>
        </div>
        <div className="grid-item-flex">
          <Boxed display="flex" alignItems="center">
            {collabData.map((row, ind) => {
              let profileUrl = null;
              if (row.profile_image) {
                profileUrl = `${BASE_URL}uploads/profile/${row.id}/${row.profile_image}`;
              } else {
                if (row.first_name == "") {
                  profileUrl = booHappy;
                } else {
                  profileUrl = "nameImage";
                }
              }

              return (
                <div key={ind}>
                  {profileUrl == "nameImage" ? (
                    <>
                      <b className="ks-members-area" style={{ color: "blue", textAlign: "center" }}>
                        {String(row.first_name).charAt(0).toUpperCase()}
                        {String(row.last_name).charAt(0).toUpperCase()}
                      </b>
                    </>
                  ) : (
                    <>
                      <img src={profileUrl} className="ks-members-area" alt="" />
                    </>
                  )}
                </div>
              );
            })}
            <Small
              text={collabData.length == 1 ? collabData.length + " Member" : collabData.length + " Members"}
              color={Colors.darkGray}
              margin={"0 8px"}
            />
          </Boxed>
          <EditMemberButton
            text={strings.editMembers}
            width={"144px"}
            backgroundcolor={Colors.mainLight}
            onClick={() => setCollaborateOpen(true)}
          />
        </div>
      </div>

      {collaborateOpen ? (
        <LetsCollaborate
          closePopup={(param) => {
            setCollaborateOpen(false);
            setOpenRemoveAll(false);
            if (param == "success") {
              callingToggleApi(1);
              api();
            }
          }}
          folderOrStringName={dataObj.string_name}
          folderOrStringId={dataObj.id}
          type={"string"}
        />
      ) : null}

      {openRemoveAll ? (
        <RemoveAllCollaboration
          closePopup={() => setOpenRemoveAll(false)}
          handleClick={() => {
            callingToggleApi(0);
            setOpenRemoveAll(false);
          }}
          stringName={dataObj.string_name}
        />
      ) : null}
    </>
  );
};

export default StringCollaborationEdit;
