import { createSlice } from "@reduxjs/toolkit";

export const getCredit = createSlice({
  name: "getCredit",
  initialState: {
    value: {},
  },
  reducers: {
    changeCredit: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeCredit } = getCredit.actions;
export const currentCredit = (state) => state.getCredit.value;
export default getCredit.reducer;
