const bracketReshuffeling = (node) => {
  let arr = [];
  let dq = 0;
  let brackets = ["#A225E0", "#257CE0", "#E560FF", "#64E025"];

  node.childNodes.forEach((item, index) => {
    if (item.textContent.trim() === '"') {
      dq += 1;
      dq = dq % 2;
    }
   
    if (dq === 0) {
      if (item.textContent.trim() === "(") {
        if (item.style.color != brackets[arr.length]) {
          item.style.color = brackets[arr.length];
          arr.push(item);
        }
      }

      if (item.textContent.trim() === ")") {
        if (item.style.color != brackets[arr.length - 1]) {
          item.style.color = brackets[arr.length - 1];
          arr.pop();
        }
      }
    }
  });
};

export default bracketReshuffeling;
