const findAllAndOrNotInsideQuotation = (strText) => {
  if (strText) {
    const findAllAndGetAllIndexesInsideQuotation = (searchWord, fullString, strIndex) => {
      if (searchWord && fullString && strIndex >= 0) {
        let regex = new RegExp(searchWord, "g");
        // alert(regex);
        let newArray = [];
        let match = [];
        while ((match = regex.exec(fullString))) {
          newArray.push(Number(strIndex) + Number(match.index));
        }
        return newArray;
      }
      return [];
    };

    let regExp = /"[^"]+"/g;
    let strArray = [];
    let searchedIndex = "";
    let stringText = "";
    let findArray = [];
    let andArray = [];
    let orArray = [];
    let notArray = [];
    if (strText.length) {
      //check blank double quote
      strText = strText.replace(/""/g, (m) => "  ");
      while ((strArray = regExp.exec(strText))) {
        searchedIndex = strArray.index;
        stringText = strArray[0].toLowerCase();
        findArray = findAllAndGetAllIndexesInsideQuotation("and", stringText, searchedIndex);
        if (findArray) {
          andArray = andArray.concat(findArray);
        }
        //check or operator
        findArray = findAllAndGetAllIndexesInsideQuotation("or", stringText, searchedIndex);
        if (findArray) {
          orArray = orArray.concat(findArray);
        }
        findArray = findAllAndGetAllIndexesInsideQuotation("not", stringText, searchedIndex);
        if (findArray) {
          notArray = notArray.concat(findArray);
        }
      }
    }
    return {
      and: andArray,
      or: orArray,
      not: notArray,
    };
  } else {
    return {
      and: [],
      or: [],
      not: [],
    };
  }
};

export default findAllAndOrNotInsideQuotation