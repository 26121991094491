import { createSlice } from "@reduxjs/toolkit";

export const intelCountKeywordSuggestion = createSlice({
  name: "intelCountKeywordSuggestion",
  initialState: {
    value: 0,
  },
  reducers: {
    setIntelCountKeywordSuggestion: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setIntelCountKeywordSuggestion } = intelCountKeywordSuggestion.actions;
export const currentIntelCount = (state) => state.intelCountKeywordSuggestion.value;
export default intelCountKeywordSuggestion.reducer;
